<template>
  <v-dialog v-model="deleteDialog" persistent max-width="400px">
    <v-card>
      <v-card-title>Delete {{ model }}</v-card-title>
      <v-card-text>
        <p data-cy="delete-dialog">Are you sure you want to delete this {{ model }}?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="deleteSelected" data-cy="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog", "model"],
  methods: {
    deleteSelected() {
      this.$emit("delete");
    }
  },
  computed: {
    deleteDialog: {
      get() {
        return this.dialog;
      },
      set(updated) {
        this.$emit("update:dialog", updated);
      }
    }
  }
};
</script>
