<template>
  <div>
    <h3>INTRODUCTION</h3>
    <p>
      CCS software (<strong>CCS</strong>) is owned and operated by Datanest Software Limited (<strong>Datanest</strong>).
      &nbsp;CCS is a suite of software
      programmes which allow users to collect data,, create figures and
      &nbsp;produce scientific reports on a project-by-project basis.
    </p>
    <p>
      Please read these Terms and Conditions carefully before using CCS.
      &nbsp;CCS is not intended to be a substitute for professional judgment and
      you should not act in reliance upon it without first obtaining
      professional advice as to your particular circumstances.
    </p>
    <p>
      Your access to and use of CCS is conditional on your acceptance of and
      compliance with these Terms and Conditions. These Terms and Conditions
      apply to all users of CCS. By accessing or using CCS you agree to be bound
      by these Terms and Conditions. If you do not accept any part of these
      Terms and Conditions, then you may not access CCS.
    </p>
    <h3>AGREEMENT</h3>
    <h4>1. DEFINITIONS AND INTERPRETATION</h4>
    <p style="padding-left: 80px;">
      1. In these Terms and Conditions, unless the context indicates the
      contrary:&nbsp;<br /><strong>Authorised Users</strong> means any agents,
      employees or contractors of the Client who are approved by the Client to
      use CCS.
    </p>
    <p style="padding-left: 80px;">
      <strong>CCS</strong> means the CCS software suite and all current and
      future modules owned and operated by Datanest which provides a platform
      for Clients to collect data, upload and analyse data, create figures and
      produce scientific reports on a project-by-project basis. &nbsp;CCS
      includes, for the avoidance of doubt, &nbsp;the website (at the domain
      CCS.earth), and Datanest&rsquo;s servers and the Documentation.&nbsp;
    </p>
    <p style="padding-left: 80px;">
      <strong>Client</strong> means any user who Datanest has given permission
      to access and use CCS in accordance with these Terms and Conditions.
    </p>
    <p style="padding-left: 80px;"><strong>Client Data</strong> means:</p>
    <ol>
      <li style="list-style-type: none;">
        <ol>
          <li style="list-style-type: none;">
            <ol style="list-style-type: lower-alpha;">
              <li>
                all data, samples, information (including personal information)
                and other material of any nature entered into or uploaded to CCS
                by the Client or accessed or stored by Datanest in any form for
                processing, storage, hosting or for any other use by Datanest
                in relation to the provision of CCS;
              </li>
              <li>
                all data, outputs, images, information, reports and other
                material generated by the Client&rsquo;s use of CCS; and
              </li>
              <li>
                includes, without limitation, any information provided to
                Datanest in order to register, access and use CCS including any
                information in relation to the Client&rsquo;s business, systems
                or networks or any information relating to the Client&rsquo;s
                employees, contractors, or any other third parties.
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <p style="padding-left: 80px;">
      <strong>Customisation(s)</strong> means a development, enhancement or
      other modification to CCS which is undertaken by Datanest at the request
      of the Client.
    </p>
    <p style="padding-left: 80px;">
      <strong>Documentation</strong> means any user and technical documentation
      supplied, or to be supplied to the Client by Datanest and includes any
      software specifications.
    </p>
    <p style="padding-left: 80px;">
      <strong>Datanest</strong> means Datanest Software Limited, a New Zealand
      registered limited liability company.
    </p>
    <p style="padding-left: 80px;">
      <strong>Fee</strong> means the sum payable by the Client for their use of
      CCS in accordance with clause 8 of these Terms and Conditions.
    </p>
    <p style="padding-left: 80px;">
      <strong>GST </strong>means the tax imposed under the GST Act.
    </p>
    <p style="padding-left: 80px;">
      <strong>GST Act </strong>means Goods and Services Tax Act 1985 (NZ) and
      any amendments thereof.
    </p>
    <p style="padding-left: 80px;">
      <strong>Insolvency Event</strong> in relation to a party (insolvent party)
      means:
    </p>
    <ol>
      <li style="list-style-type: none;">
        <ol>
          <li style="list-style-type: none;">
            <ol style="list-style-type: lower-alpha;">
              <li>
                the insolvent party ceases or takes steps to cease to conduct
                its principal business in the normal manner;
              </li>
              <li>
                the insolvent party enters into or resolves to enter into any
                arrangement, composition or compromise with or assignment for
                the benefit of its creditors or any class of them;
              </li>
              <li>
                the insolvent party is unable to pay its debts when they are due
                or is deemed under the Companies Act 1993 (NZ) to be insolvent;
              </li>
              <li>
                a liquidator or provisional liquidator is appointed to the
                insolvent party or a receiver, receiver and manager,
                administrator, trustee or similar official is appointed over any
                of the assets or undertakings of the insolvent party;
              </li>
              <li>
                an application or order is made or a resolution is passed of the
                winding up of the insolvent party; or
              </li>
              <li>is removed from the Register of Companies.</li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <p style="padding-left: 80px;">
      <strong>Intellectual Property Rights</strong> includes copyright, and all
      current and future registered and unregistered rights conferred under
      statute, common law or equity in in respect of designs, circuit layouts,
      trademarks, know-how, confidential information, patents, inventions,
      discoveries, trade secrets, know-how, domain names, rights in databases,
      and all other proprietary rights, and all equivalent rights and forms of
      protection anywhere in the world, together with all right, interest or
      licence in or to any of the foregoing.
    </p>
    <p style="padding-left: 80px;">
      <strong>Tax Invoice</strong> has the meaning it has in the GST Act.
    </p>
    <p style="padding-left: 80px;">
      <strong>Terms and Conditions</strong> means these Terms and Conditions as
      may be amended by Datanest from time to time.
    </p>
    <p style="padding-left: 80px;">
      <strong>Third Party Software</strong> means any computer software program
      owned and operated by a third party which Datanest has incorporated or
      integrated into CCS and which Datanest is not otherwise authorised to
      sub-license to the Client.
    </p>
    <p style="padding-left: 80px;">
      <strong>Geographic License Region</strong> means within New Zealand.
    </p>
    <p style="padding-left: 40px;">
      2. In these Terms and Conditions unless the contrary intention appears:
    </p>
    <ol>
      <li style="list-style-type: none;">
        <ol style="list-style-type: lower-alpha;">
          <li>the singular includes the plural and vice versa;</li>
          <li>
            a reference to these Terms and Conditions or another instrument
            includes any variation or replacement of them;
          </li>
          <li>
            a reference to a clause number is a reference to a clause in these
            Terms and Conditions
          </li>
          <li>
            the word &ldquo;person&rdquo; includes a firm, a body corporate, a
            partnership, joint venture, an unincorporated body or association,
            or any government agency;
          </li>
          <li>
            a reference to a statute, ordinance, code or other law includes
            regulations and other instruments under it, and consolidations,
            amendments, re-enactments and/or replacements of any of them;
          </li>
          <li>
            if a period of time is specified and dates from a given day or the
            day of an act or event, it is to be calculated exclusive of that
            day;
          </li>
          <li>a reference to a month is to a calendar month;</li>
          <li>a reference to a day is to a calendar day;</li>
          <li>
            a reference to a thing (including any amount) is a reference to the
            whole and each part;
          </li>
          <li>
            the verb &ldquo;include&rdquo; (in all its parts, tenses and
            variants) is not used as, nor is it to be interpreted as, a word of
            limitation;
          </li>
          <li>
            the words &ldquo;including&rdquo;, &ldquo;for example&rdquo; or
            &ldquo;such as&rdquo; do not limit the meaning of the words to which
            the example relates or examples of a similar kind;
          </li>
          <li>
            New Zealand dollars, dollars, NZ$, or $ is a reference to the lawful
            currency of New Zealand; and
          </li>
          <li>
            headings are inserted for convenience and do not affect the
            interpretation of these Terms and Conditions.
          </li>
        </ol>
      </li>
    </ol>
    <h4><strong>2. ACCESS TO AND USE OF CCS </strong></h4>
    <ol>
      <li>
        In consideration of the Client&rsquo;s obligation to pay the Fees under
        clause 8, Datanest grants to the Client a non-exclusive, non-assignable
        licence to access and use CCS in accordance with these Terms and
        Conditions.
      </li>
      <li>
        The Client agrees to use CCS in a manner that complies with all
        applicable laws and regulations and that does not infringe upon
        Datanest&rsquo;s rights, nor the rights of any third parties, nor
        restricts or inhibits their use and enjoyment of CCS.
      </li>
      <li>
        The Client may not, without Datanest&rsquo;s prior written permission,
        in any form or by any means:
        <ol style="list-style-type: lower-alpha;">
          <li>
            allow CCS to be used by any persons other than the Client or its
            Authorised Users;
          </li>
          <li>
            adapt, reproduce, copy, store, distribute, print, display, perform,
            publish or create adaptions from any part of CCS, other than for the
            purpose of fulfilling the purposes for which CCS is being supplied;
          </li>
          <li>
            use any device, including any software which interferes with the
            usual operation of CCS;
          </li>
          <li>
            use the CCS name of branding material to promote the Client's
            business without Datanest&rsquo;s express prior approval;
          </li>
          <li>
            commercialise, copy, or on-sell any information, or materials
            obtained from any part of CCS; or
          </li>
          <li>
            assign or sub-contract any of its rights or obligations under these
            Terms and Conditions to any third party.
          </li>
          <li>
            Use the software license outside of the agreed geographic license
            region.
          </li>
        </ol>
      </li>
      <li>
        The Client is responsible for ensuring that:
        <ol style="list-style-type: lower-alpha;">
          <li>
            at all times, it meets the system requirements which are necessary
            to access and use CCS and that it will take reasonable steps to
            ensure that any computer used to access CCS is protected against
            computer viruses and unauthorised access; and
          </li>
          <li>
            all of its Authorised Users who are approved to use CCS comply at
            all times with these Terms and Conditions.&nbsp;
          </li>
        </ol>
      </li>
    </ol>
    <h4>3. CLIENT DATA</h4>
    <ol>
      <li>
        Datanest will host all of the Client&rsquo;s Data entered into CCS on
        Datanest&rsquo;s cloud server or a third party server.
        Datanest&rsquo;s cloud server and/or third party server is regularly
        backed up and Datanest will use its best endeavours to maintain
        effective security measures to safeguard Client Data from unauthorised
        access, use, copying or disclosure. &nbsp;However the Client
        acknowledges that Datanest shall not be responsible for any Client data
        which is lost, stolen, copied, deleted or subject to unauthorised access
        or use.
      </li>
      <li>
        It is the Client&rsquo;s sole responsibility to ensure the accuracy of,
        and to maintain copies of, any Client Data entered into or uploaded to
        CCS.
      </li>
      <li>
        Datanest does not take any responsibility for the Client Data which the
        Client chooses to upload or enter into CCS. &nbsp;Datanest does not
        pre-screen or monitor the content of any Client Data. Without limiting
        clause 11, in no event shall Datanest be liable for any claims made in
        relation to the Client Data including, but not limited, to any
        misleading statements made and/or incorporated into any Client Data.
        &nbsp;
      </li>
    </ol>
    <h4>4. REGISTRATION AND SECURITY OF PASSWORDS</h4>
    <ol>
      <li>
        By signing up for CCS, the Client agrees that all information provided
        during the registration process is true and accurate and the Client will
        update this information as required in order to keep it current,
        complete and accurate.
      </li>
      <li>
        During the registration process, the Client will be issued a password
        for CCS to operate a Client account. The Client agrees to be fully
        responsible for activities that relate to its Client account including
        the actions of all Authorised Users and for maintaining the
        confidentiality of its password. &nbsp;If the Client has any reason to
        believe that its password has been obtained by someone else without its
        consent, the Client must inform Datanest immediately to disable the
        Client's account, if required.
      </li>
    </ol>
    <h4>5. OWNERSHIP OF INTELLECTUAL PROPERTY</h4>
    <ol>
      <li>
        The Client acknowledges that all Intellectual Property Rights and other
        rights in CCS remain the property of Datanest (other than Third Party
        Software).
      </li>
      <li>
        The Client shall notify CCS as soon as reasonably practicable if it
        becomes aware of:
        <ol style="list-style-type: lower-alpha;">
          <li>
            any infringement of any of CCS&rsquo;s Intellectual Property Rights;
          </li>
          <li>any defect in its use of CCS; or</li>
          <li>
            any material matters that may be of concern to the Client concerning
            CCS which come to the attention of the Client.
          </li>
        </ol>
      </li>
      <li>
        When you use our services, we may create anonymised statistical data
        from your data and usage of our services, including through aggregation.
        Once anonymised, we may use it for our own purposes, such as to provide
        and improve our services, to develop new services or product offerings,
        to identify business trends, and for other uses we communicate to you.
      </li>
      <li>
        When you enter or upload your data into our services, we don&rsquo;t own
        that data but you grant us a licence to use, copy, transmit, store,
        analyse, and back up all data you submit to us through our services,
        including personal data of yourself and others, to: enable you to use
        our services; allow us to improve, develop and protect our services;
        create new services; communicate with you about your subscription; and
        send you information we think may be of interest to you based on your
        marketing preferences.
      </li>
      <li>
        All Intellectual Property Rights in the Client Data remain property of
        the Client. Datanest cannot use, copy, transmit, store or back-up
        Client Data except for in limited circumstances. &nbsp;The Client agrees
        to grant Datanest a limited licence to use, copy, transmit, store, and
        back-up Client Data for the limited purposes of:
        <ol style="list-style-type: lower-alpha;">
          <li>enabling the Client to access and use CCS; and</li>
          <li>
            for any other purpose related to provision of CCS to the Client.
          </li>
        </ol>
      </li>
    </ol>
    <h4>6. THIRD PARTY INFORMATION</h4>
    <ol>
      <li>
        CCS stores and provides the Client with access to a wide range of
        information created or provided by third parties which can be utilised
        by the Client to assist them to analyse, assess and customise its Client
        Data (including but not limited to a number of national and
        international environmental guidelines)(<strong>Third Party Information</strong>). &nbsp;Without limiting clause
        11, in no event shall CCS be
        responsible for the accuracy or reliability of any Third Party
        Information made available to the Client via CCS.
      </li>
    </ol>
    <h4>7. CONTINUITY OF SERVICE</h4>
    <ol>
      <li>
        Datanest will use reasonable endeavors to provide access to CCS on a
        continuous basis, subject to any necessary downtime that may be required
        for system maintenance, repairs and updating, or loss of access
        resulting from matters beyond Datanest&rsquo;s control.
      </li>
      <li>
        Datanest reserves the right to suspend, or otherwise alter the Client's
        access to and use of CCS at any time and without notice in the case of:
        <ol style="list-style-type: lower-alpha;">
          <li>periodic maintenance;</li>
          <li>updates or software upgrades; &nbsp;</li>
          <li>
            where a threat has been identified to the security of CCS;
          </li>
          <li>
            a failure by the Client to make payment of any Fee payable to
            Datanest on the due date; &nbsp;
          </li>
          <li>
            a breach of these Terms and Conditions by the Client; &nbsp;
          </li>
          <li>a requested Customisation by the Client; or &nbsp;</li>
          <li>
            Datanest otherwise being prevented from providing CCS for reasons
            beyond its control.
          </li>
        </ol>
      </li>
      <li>
        When scheduling suspensions or access to CCS for any of the above
        reasons, Datanest will use reasonable means to contact the Client to
        inform them in advance of any suspension or alteration of their access
        to and use of CCS. &nbsp;Without limiting clause 11, in no event shall
        CCS be responsible for any loss arising from any delay or loss of access
        to and use of CCS at any time.
      </li>
    </ol>
    <h4>8. PRIVACY</h4>
    <ol>
      <li>
        Datanest collects information from and about the Client, including but
        not limited to the Client&rsquo;s name and contact details, upon
        registering for CCS. Such information is provided by the Client
        voluntarily. Datanest may also collect and store information about the
        Client's use of CCS, for example, the pages accessed and how many times
        the Client accesses CCS.
      </li>
      <li>
        All Client Data (including the information referred to in clause 8.1)
        will be stored on Datanest&rsquo;s cloud server or via a third-party
        server. &nbsp;Any such information will only be used by Datanest for
        its own statistical purposes. Datanest will never disclose any Client
        Data to any third party without first obtaining the Client&rsquo;s
        consent, unless Datanest is otherwise obliged or permitted by law to
        disclose it. &nbsp;To the extent that any Client Data is personal
        information (as defined in the Privacy Act 1993) then the Client is
        entitled to access and correct such personal information held by
        Datanest.
      </li>
    </ol>
    <h4>9. FEES</h4>
    <ol>
      <li>
        Datanest agrees to grant the Client the right to access and use CCS in
        consideration of the Client&rsquo;s payment of the relevant Fees. &nbsp;
      </li>
      <li>
        The Fee for using CCS will be determined by your subscription type.
        Generally your pricing plan will consist of an annual fee with a limit
        on the number of users able to access the software. &nbsp;The pricing
        plan may vary by region and prices are subject to change over time. If
        prices are to be adjusted you will be notified prior to any changes
        being made.
      </li>
      <li>
        Datanest may provide the Client with the ability to pay any Fees by
        credit card. &nbsp;All Fees paid through a third party payment services
        provider are subject to that third party&rsquo;s terms of service, and
        Datanest will not be responsible for anything contained therein. The
        Client is responsible for payment of any taxes or transaction costs
        associated with payment of the Fee.
      </li>
      <li>
        In the event that payment of the Fee is not received by Datanest within
        the specified timeframe in the Tax Invoice, Datanest will be entitled
        to suspend the Client&rsquo;s access to and use of CCS, until such time
        as payment is received but without prejudice to clause 13 and provided
        the relevant Fees are not in dispute.
      </li>
      <li>
        All Fees and other charges are inclusive of any and all taxes and duties
        apart from GST. &nbsp;The Client will pay GST at the prevailing rate on
        the amount of the Fee at the same time and in the same manner as the Fee
        is payable.
      </li>
      <li>
        From time to time, Datanest (or others on Datanest&rsquo;s behalf) may
        offer trials to use CCS &nbsp;for a specified period without payment or
        at a reduced rate (a &ldquo;Trial&rdquo;). Datanest reserves the right,
        in its absolute discretion, to determine the Client&rsquo;s eligibility
        for a Trial, and, subject to applicable laws, to withdraw or to modify a
        Trial at any time without prior notice and with no liability, to the
        greatest extent permitted under the law.
      </li>
      <li>
        Clients may request a Customisation of CCS for their particular use.
        &nbsp;Any Customisations may be subject to additional Fees. &nbsp;A Fee
        proposal will be issued at the time of the Customisation request to
        indicate the costs associated (if any) with the Customisation.
      </li>
      <li>
        If the Client disputes any amount in a Tax Invoice issued under clause
        9.2, the Client shall:
        <ol style="list-style-type: lower-alpha;">
          <li>
            notify CCS as soon as reasonably practicable, identifying the amount
            in dispute and the reasons for the dispute; and
          </li>
          <li>
            pay by the due date any balance of the Tax Invoice which is not in
            dispute.
          </li>
        </ol>
      </li>
    </ol>
    <h4>10. WARRANTIES</h4>
    <ol>
      <li>
        The Client&rsquo;s use of CCS is at the Client&rsquo;s own risk.
        &nbsp;CCS is provided on an &ldquo;as is&rdquo; and &ldquo;as available
        basis&rdquo; and is provided by Datanest without any warranties of any
        kind, either express or implied. &nbsp;To the fullest extent permissible
        by law, CCS does not warrant that:
        <ol style="list-style-type: lower-alpha;">
          <li>
            access to CCS will be uninterrupted, or secure, or that CCS will be
            error free and that such errors or minor defects will be corrected;
          </li>
          <li>
            CCS will be fit for the Client&rsquo;s particular purpose, or for
            any other purpose;
          </li>
          <li>
            Third Party Information made available via CCS which is used or
            accessed by the Client is accurate, adequate, current, complete or
            suitable for the Client&rsquo;s intended use;
          </li>
          <li>
            all data, outputs, images, information, reports, results and other
            material generated by the Client&rsquo;s use of CCS is fit for the
            Client&rsquo;s particular purpose, reliable or accurate; and
          </li>
          <li>
            any results obtained or generated from CCS will be in accordance
            with the Client&rsquo;s expectations.
          </li>
        </ol>
      </li>
      <li>
        The Client warrants that it has relied upon its own skill and judgment
        as to the suitability of CCS for its purposes and has not relied on any
        representation or upon any description, illustration or specification
        contained in any document, including the Documentation, or any publicly
        available material produced by Datanest.
      </li>
    </ol>
    <h4>11. LIMITATION OF LIABILITY AND INDEMNITY</h4>
    <ol>
      <li>
        To the fullest extent permitted by law, Datanest will not be liable to
        the Client (or any third party), for any damages (whether indirect,
        punitive, special, incidental or consequential damage (including loss of
        business, revenue, profits, use, privacy, data (including Client Data),
        goodwill or other economic advantage)) suffered as a result of the
        Client or anyone else using CCS or any Third Party Software whether such
        liability arises in contract or in tort resulting from:
        <ol style="list-style-type: lower-alpha;">
          <li>
            the Client&rsquo;s use of, or inability to access and use CCS;
          </li>
          <li>
            any failure or performance, error, omission, interruption, defect,
            delay in operation of CCS (including as a result of third parties or
            Third Party Software);
          </li>
          <li>computer virus or communication line failure;</li>
          <li>
            theft, destruction, unauthorised access or alteration of CCS or any
            of its contents including, without limitation, Client Data;
          </li>
          <li>
            any error or inaccuracy in:
            <ol style="list-style-type: lower-roman;">
              <li>
                Client Data which the Client has uploaded to CCS (including any
                misleading statements made and/or incorporated into any Client
                Data);
              </li>
              <li>
                Third Party Information which is stored, accessed or used by the
                Client within CCS; or &nbsp;
              </li>
              <li>
                any data, outputs, images, information, reports, results and
                other material generated by the Client&rsquo;s use of CCS;
              </li>
            </ol>
          </li>
          <li>
            the Client&rsquo;s use of any information (including Third Party
            Information) data, outputs, images, information, reports and other
            material accessed, analysed or generated by the Client&rsquo;s use
            of CCS;
          </li>
          <li>
            any unauthorised access to the Client&rsquo;s account or use of the
            Client&rsquo;s password; or
          </li>
          <li>
            any cause or event reasonably beyond Datanest&rsquo;s control.
          </li>
        </ol>
      </li>
      <li>
        Without limiting the foregoing, in no event will Datanest&rsquo;s
        aggregate liability to the Client arising under or in relation to these
        Terms and Conditions whether arising in contract, tort (including
        negligence), equity or otherwise shall not exceed, in aggregate, the
        total amount of any Fees paid by the Client for the relevant project in
        CCS which has given rise to any such liability.&nbsp;
      </li>
      <li>
        As a condition of the Client&rsquo;s access to and use of CCS, the
        Client agrees to not breach these Terms and Conditions. &nbsp;The Client
        agrees to indemnify and keep indemnified Datanest against any claim,
        proceeding, damage, liability, loss, cost or expense (including legal
        costs on a solicitor and own client basis, whether arising in contract,
        tort (including for negligence) or otherwise arising out of or in
        connection with:
      </li>
      <ol style="list-style-type: lower-alpha;">
        <li>
          a breach by the Client of its obligations under these Terms and
          Conditions; &nbsp;
        </li>
        <li>
          any failure by the Client to ensure that steps are taken to prevent
          unauthorised use of CCS; and
        </li>
        <li>
          any willful, unlawful or negligent act or omission of the Client in
          respect of its access and use of CCS.
        </li>
      </ol>
    </ol>
    <h4>12. CHANGES TO THESE TERMS AND CONDITIONS</h4>
    <ol>
      <li>
        No variation to these Terms and Conditions shall be enforceable except
        if it is made in writing (including online tick box) and agreed by both
        parties.
      </li>
    </ol>
    <h4>13. TERMINATION</h4>
    <ol>
      <li>
        Datanest may suspend or withdraw the Client's right to access and use
        CCS at any time without prior notice if:
        <ol style="list-style-type: lower-alpha;">
          <li>
            the Client has breached these Terms and Conditions or have acted
            fraudulently;
          </li>
          <li>
            the Client dies, becomes bankrupt, or commits an act of bankruptcy
            or if a corporate, suffers and Insolvency Event;
          </li>
          <li>Datanest withdraws CCS as software product;</li>
          <li>
            Datanest considers that it has other reasonable grounds to do so
            (in which case all reasonable efforts will be made to advise the
            Client of the circumstances of withdrawal or suspension); or
          </li>
          <li>
            Datanest is complying with a court order or legislative
            requirement.
          </li>
        </ol>
      </li>
      <li>
        Upon termination of these Terms and Conditions, all rights and
        obligations of the parties immediately cease to have effect except that:
        <ol style="list-style-type: lower-alpha;">
          <li>
            the termination of these Terms and Conditions is without prejudice
            to the rights and obligations of the parties accrued up to and
            including the date of termination (including for the avoidance of
            doubt, the payment of any outstanding Fees); and
          </li>
          <li>
            clauses which, by their nature, are intended to survive termination
            of these Terms and Conditions, survive termination.&nbsp;
          </li>
        </ol>
      </li>
    </ol>
    <h4>14. &nbsp;GENERAL</h4>
    <ol>
      <li>
        If Datanest does not exercise or enforce any right available to it
        under these Terms and Conditions, it does not constitute a waiver of
        those rights.
      </li>
      <li>
        These Terms and Conditions constitute the entire agreement between the
        Client and Datanest and supersede all prior agreements, arrangements,
        understandings and representations (whether oral or written) given by or
        made between Datanest and the Client, relating to the subject matter of
        these Terms and Conditions.
      </li>
      <li>
        The rights and remedies provided in these Terms and Conditions are
        cumulative and not exclusive of any rights or remedies provided by these
        Terms and Conditions or at law.
      </li>
      <li>
        If any provision of these Terms and Conditions becomes or is held to be
        illegal, invalid or unenforceable in any respect, that provision shall
        be read down to the extent necessary to make it legal, valid and
        enforceable or, if it cannot be read down, be deemed severed from these
        Terms and Conditions. Neither such change shall affect the legality,
        validity and enforceability of the other provisions of these Terms and
        Conditions.
      </li>
      <li>
        These Terms and Conditions are governed by the laws of New Zealand. When
        the Client accesses CCS, the Client submits to the exclusive
        jurisdiction of the New Zealand courts.&nbsp;
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: "TermsConditions"
};
</script>
