import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.messages)?_c(VMenu,{staticClass:"transparent",attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',{staticClass:"float-right mr-12"},[_c(VBtn,_vm._g({attrs:{"icon":"","disabled":_vm.messages.length == 0}},on),[_c(VBadge,{attrs:{"content":_vm.messages.length,"value":_vm.messages.length,"color":"green","overlap":""}},[_c(VIcon,{attrs:{"color":"white","info":""}},[_vm._v("fas fa-bell")])],1)],1)],1)]}}],null,false,1822283939)},[(_vm.messages.length > 0)?_c(VList,{attrs:{"subheader":"","two-line":""}},[_c(VSubheader,{attrs:{"inset":""}},[_vm._v("Notifications"),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g({attrs:{"attrs":attrs,"icon":""},on:{"click":function($event){return _vm.readAll()}}},on),[_c(VIcon,{attrs:{"small":"","color":"red"}},[_vm._v("fas fa-times")])],1)]}}],null,false,936826131)},[_c('span',[_vm._v("Read all")])])],1),_vm._l((_vm.messages),function(message,i){return _c(VListItem,{key:i,attrs:{"color":message.success ? '' : 'warning'}},[_c(VListItemIcon,{staticClass:"mr-3 mb-0"},[(message.event == 'report' && message.status == 'completed')?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.downloadReportURL(message)}}},[_c(VIcon,[_vm._v("fas fa-download")])],1):(
            message.event == 'clearance' && message.status == 'completed'
          )?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.downloadClerancePDF(message.pdf_id)}}},[_c(VIcon,[_vm._v("fas fa-download")])],1):_c(VBtn,{attrs:{"fab":"","x-small":"","to":message.to,"color":message.success ? 'success' : 'warning'}},[_c(VIcon,[_vm._v(_vm._s(_vm.icon(message.event)))])],1)],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(message.message)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(message.time)}})],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.read(message.id)}}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("fal fa-times")])],1)],1)],1)})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }