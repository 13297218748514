<template>
  <v-text-field
    spellcheck="true"
    type="text"
    maxlength="10"
    :value="bag"
    @change.native="updateBag"
    :error="error"
    :error-messages="message"
  >
    <template v-slot:prepend-inner>
      <span class="pt-1">
        {{ survey.job_number }}.{{ building.phase ? building.phase + "." : "" }}</span
      >
    </template>
  </v-text-field>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["bag", "id", "building"],
  computed: {
    ...mapGetters(["survey"]),
  },
  data: () => ({
    error: false,
    message: null,
  }),
  methods: {
    ...mapActions(["updateSample"]),
    updateBag(input) {
      this.updateSample({
        data: { id: this.id, bag_code: input.target.value },
        building_id: this.building.id,
      })
        .then(() => {
          this.error = false;
          this.message = null;
        })
        .catch((res) => {
          this.error = true;
          this.message = res.response.data.message;
        });
    },
  },
};
</script>
