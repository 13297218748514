<template>
  <v-data-table
    :headers="headers"
    :items="clearances"
    :sort-by="'inspection_date_time'"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Clearance Certificates</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="createClearance">
          <v-icon small> fas fa-plus </v-icon>
        </v-btn>
      </v-toolbar>
      <v-dialog v-model="dialogPdf" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Please confirm to print this clearance</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="() => (dialogPdf = false)"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="confirmGenerate"
              >Confirm</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this clearance?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="() => (dialogDelete = false)"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="confirmDelete"
              >Confirm</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDuplicate" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Please confirm to duplicate this clearance</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="() => (dialogDuplicate = false)"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="confirmDuplicate"
              >Confirm</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSetActive" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Please confirm to set this Clearance back to Active</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="() => (dialogSetActive = false)"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="confirmSetToActive"
              >Confirm</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDuplicating" max-width="500px">
        <v-card>
          <v-card-title class="headline">Duplicating Clearance...</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDuplicating = false"
              >Hide</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.actions="{ item }">
      <span
        v-if="item.status == 'awaiting_review' || item.status == 'declined'"
      >
        <clearance-review-button
          :clearance="item"
          :type="'building'"
          @load="initialize()"
        />
      </span>
      <span v-if="item.status == 'reviewed'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" @click="duplicateClearanceDialog(item)">
              <v-icon small> fas fa-clone </v-icon>
            </v-btn>
          </template>
          <span>Duplicate</span>
        </v-tooltip>
      </span>
      <span v-if="item.has_report">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              v-on="on"
              @click="downloadClearanceReport(item.id)"
              target="_blank"
              :disabled="!item.has_report"
            >
              <v-icon small> fas fa-file-pdf </v-icon>
            </v-btn>
          </template>
          <span>Latest Clearance generated</span>
        </v-tooltip>
      </span>
      <span v-if="item.status != 'reviewed'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" @click="generatePdf(item)">
              <v-icon small> fas fa-sync </v-icon>
            </v-btn>
          </template>
          <span>Generate Clearance Certificate</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" @click="gotoClearance(item)">
              <v-icon small> fas fa-pencil-alt </v-icon>
            </v-btn>
          </template>
          <span>Edit Clearance</span>
        </v-tooltip>
      </span>
      <span v-if="item.status != 'reviewed'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" @click="deleteClearanceDialog(item)">
              <v-icon small> fas fa-trash-alt </v-icon>
            </v-btn>
          </template>
          <span>Delete Clearance</span>
        </v-tooltip>
      </span>
      <span v-if="item.status == 'reviewed'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" @click="setActiveClearanceDialog(item)">
              <v-icon small> fas fa-undo </v-icon>
            </v-btn>
          </template>
          <span>Set Clearance back to Active</span>
        </v-tooltip>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClearanceReviewButton from "./ClearanceReviewButton";
export default {
  components: { ClearanceReviewButton },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogDuplicate: false,
    dialogDuplicating: false,
    dialogSetActive: false,
    dialogPdf: false,
    selectedItem: null,
    selectedKey: -1,
    clearances: [],
    headers: [
      {
        text: "Clearance",
        align: "start",
        value: "name",
      },
      { text: "Site address", value: "site_address" },
      { text: "Type", value: "class" },
      { text: "Inspection date", value: "inspection_date_time" },
      { text: "Status", value: "readable_status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  computed: {},
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      "getClearances",
      "generateClearance",
      "deleteClearance",
      "duplicateClearance",
      "setClearanceToActive",
      "downloadClearanceReport"
    ]),
    createClearance() {
      this.$router.push(
        "/survey/" +
          this.$route.params.survey +
          "/building/" +
          this.$route.params.building +
          "/clearance"
      );
    },
    initialize() {
      this.clearances = [];
      this.getClearances(this.$route.params.building).then((response) => {
        this.clearances = response.data.clearances;
        this.$emit("updated");
      });
    },
    gotoClearance(clearance) {
      this.$router.push(
        this.$route.params.building + "/clearance/" + clearance.id + "/edit"
      );
    },
    deleteClearanceDialog(clearance) {
      this.selectedItem = clearance;
      this.selectedKey = this.clearances.indexOf(clearance);
      this.dialogDelete = true;
    },
    setActiveClearanceDialog(clearance) {
      this.selectedItem = clearance;
      this.selectedKey = this.clearances.indexOf(clearance);
      this.dialogSetActive = true;
    },
    duplicateClearanceDialog(clearance) {
      this.selectedItem = clearance;
      this.selectedKey = this.clearances.indexOf(clearance);
      this.dialogDuplicate = true;
    },
    confirmDelete() {
      this.deleteClearance(this.selectedItem.id).then(() => {
        this.clearances.splice(this.selectedKey, 1);
      });
      this.dialogDelete = false;
    },
    confirmDuplicate() {
      this.dialogDuplicate = false;
      this.dialogDuplicating = true;
      this.duplicateClearance(this.selectedItem.id).then((response) => {
        this.dialogDuplicating = false;
        this.initialize();
      });
    },
    confirmSetToActive() {
      this.dialogSetActive = false;
      this.setClearanceToActive(this.selectedItem.id).then((response) => {
        this.initialize();
      });
    },
    generatePdf(clearance) {
      this.dialogPdf = true;
      this.selectedItem = clearance;
    },
    confirmGenerate() {
      this.generateClearance({
        clearance_id: this.selectedItem.id,
      });

      this.clearances.forEach((item, key) => {
        if (item.id == this.selectedItem.id) {
          this.clearances[key].has_report = false;
          return;
        }
      });

      this.dialogPdf = false;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
