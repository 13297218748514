import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("fas fa-circle-xmark")])],1),_c(VToolbarTitle,[_vm._v("Edit Survey Scope")])],1),_c(VCardText,[_c(VSpacer),_c(VForm,[_c('tiptap-vuetify',{staticClass:"sec-div mt-3",class:{ 'error-div': _vm.showWorkDetailsErr },attrs:{"extensions":_vm.extensions,"label":'Enter report content for \'Survey Scope\' here...',"name":"details","placeholder":'Enter report content for \'Survey Scope\' here...'},model:{value:(_vm.atemplateContent),callback:function ($$v) {_vm.atemplateContent=$$v},expression:"atemplateContent"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","data-cy":"save-note","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }