/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "state" }]*/
/* eslint-disable no-console */
const getters = {
  dark: state => {
    return state.dark;
  },
  isLoading: state => {
    return state.isLoading;
  },
  invalidInput: state => validator => {
    const elementId = validator.errors.items[0].id;
    return validator.fields.items.find(item => item.id == elementId).el;
  },
  is_reviewer: state => {
    return !state.auth.user
      ? false
      : state.auth.user.roles.find(role => role.id == 2) !== undefined;
  },
  isPM: state => {
    return !state.auth.user
      ? false
      : state.auth.user.roles.find(role => role.id == 4) !== undefined;
  },
  is_awaiting_review: state => building => {
    return building.status_id == 9;
  },
  is_management: state => building => {
    return building.report_type_id == 1;
  },
  is_refurbishment: state => building => {
    return building.report_type_id == 3;
  },
  is_awaiting_result: state => building => {
    return building.status_id == 6;
  },
  is_pre_review: state => building => {
    return building.status_id == 8;
  },
  is_active: state => building => {
    return building.status_id == 5;
  },
  is_needs_edits: state => building => {
    return building.status_id == 7;
  },
  is_reviewed: state => building => {
    return building.status_id == 10;
  },
  browseSurveySearch: state => {
    return state.auth.settings.browse.survey.search;
  },
  browseBuildingSearch: state => {
    return state.auth.settings.browse.building.search;
  },
  browseSurveyStatus: state => {
    return state.auth.settings.browse.survey.status;
  },
  browseBuildingStatus: state => {
    return state.auth.settings.browse.building.status;
  },
  browseBuildingReportType: state => {
    return state.auth.settings.browse.building.report_type;
  },
  notificationType: state => {
    return state.notification.type;
  },
  notificationShow: state => {
    return state.notification.show;
  },
  buildingStatusColors: state => id => {
    return {
      5: "primary lighten-2",
      7: "primary accent-4",
      8: "primary accent-2",
      9: "primary",
      10: "primary darken-2"
    }[id];
  },
  notificationMessage: state => {
    if (state.notification.message != "") {
      clearTimeout(state.notification.timeout);
      state.notification.show = true;
      state.notification.timeout = setTimeout(() => {
        state.notification.show = false;
        state.notification.message = "";
      }, 3000);
    }
    return state.notification.message;
  },
  notificationTimeout: state => {
    return state.notification.timeout;
  },
  messages: state => {
    return state.auth.messages;
  },
  user: state => {
    return state.auth.user;
  },
  selectedUser: state => {
    return state.auth.selected.user;
  },
  getUserById: state => id => {
    return state.auth.users.find(user => user.id == id);
  },
  company: state => {
    return state.auth.company;
  },
  getCompanyById: state => id => {
    return state.companies.data.find(company => company.id == id);
  },
  laboratories: state => {
    return state.auth.laboratories;
  },
  analysisTypes: state => {
    if (!state.analysisTypes) return [];
    return state.analysisTypes.filter(type => !!type.name);
  },
  report_types: state => {
    return state.report_types;
  },
  reportTypeById: state => id => {
    return state.report_types.find(type => type.id == id);
  },
  reportTypes: state => {
    return state.report_types;
  },
  companies: state => {
    return state.companies;
  },
  roles: state => {
    if (!state.roles) return;
    return state.roles.filter(role => role.name != "Root");
  },
  surveys: state => {
    return state.auth.surveys;
  },
  survey: state => {
    return state.auth.selected.survey;
  },
  buildings: state => {
    return state.auth.buildings;
  },
  building: state => {
    return state.auth.selected.building;
  },
  approvals: state => {
    return state.auth.selected.approvals;
  },
  isClearanceCert: state => {
    return (
      state.auth.selected.building.report_type &&
      state.auth.selected.building.report_type == "Clearance Certificate"
    );
  },
  statusColors: state => {
    return {
      5: "primary lighten-2",
      6: "lime darken-3 lighten-2",
      7: "red accent-4",
      8: "light-blue accent-2",
      9: "orange",
      10: "green darken-2"
    };
  },
  floor: state => {
    return state.auth.selected.floor;
  },
  room: state => {
    return state.auth.selected.room;
  },
  rooms: state => {
    return state.auth.rooms;
  },
  getRoom: state => id => {
    return state.auth.rooms.find(room => room.id == id);
  },
  getAssessmentMethodById: state => id => {
    return state.assessment_methods.find(method => method.id == id);
  },
  contaminateTypes: state => {
    return state.contaminate_types;
  },
  assessmentMethods: state => {
    return state.assessment_methods;
  },
  getRoomsByFloor: state => id => {
    if (!Array.isArray(state.auth.rooms)) {
      return [];
    }
    return state.auth.rooms.filter(room => room.floor_id == id);
  },
  getFloorsByBuilding: state => id => {
    if (!Array.isArray(state.auth.floors) || !state.auth.floors) {
      return [];
    }
    return state.auth.floors.filter(floor => floor.building_id == id);
  },
  floors: state => {
    return state.auth.floors;
  },
  clients: state => {
    return state.auth.clients;
  },
  contacts: state => {
    return state.auth.selected.contacts;
  },
  contact: state => {
    return state.auth.selected.contact;
  },
  client: state => {
    return state.auth.selected.client;
  },
  users: state => {
    return state.auth.users;
  },
  extantUsers: state => {
    return state.auth.users.filter(user => user.deleted_at == null);
  },
  getClientById: state => id => {
    return state.auth.clients.find(client => client.id == id);
  },
  extantClients: state => {
    return state.auth.clients.filter(client => client.deleted_at == null);
  },
  getOfficeById: state => id => {
    return state.auth.offices.find(office => office.id == id);
  },
  office: state => {
    return state.auth.selected.office;
  },
  extantOffices: state => {
    if (!state.auth.offices || !Array.isArray(state.auth.offices)) return [];
    return state.auth.offices.filter(office => office.deleted_at == null);
  },
  laboratory: state => {
    if (
      !state.auth.selected.laboratory ||
      !Array.isArray(state.auth.selected.laboratory)
    )
      return [];
    return state.auth.selected.laboratory;
  },
  extantLaboratories: state => {
    return state.auth.laboratories.filter(
      laboratory => laboratory.deleted_at == null
    );
  },
  laboratoryById: state => id => {
    return state.auth.laboratories.find(lab => lab.id == id);
  },
  offices: state => {
    return state.auth.offices;
  },
  admins: state => {
    return state.auth.users.filter(
      user =>
        user.roles.find(role => role.name == "Admin") && user.deleted_at == null
    );
  },
  isAdmin: state => {
    if (!state.auth.user) return false;
    const isRoot =
      state.auth.user.roles.find(role => role.name == "Root") !== undefined;
    const isAdmin =
      state.auth.user.roles.find(role => role.name == "Admin") !== undefined;
    return isRoot || isAdmin;
  },
  isRoot: state => {
    if (!state.auth.user) return false;
    return (
      state.auth.user.roles.find(role => role.name == "Root") !== undefined
    );
  },
  isExporter: state => {
    if (!state.auth.user) return false;
    return (
      state.auth.user.roles.find(role => role.name == "Exporter") !== undefined
    );
  },
  surveyors: state => {
    return state.auth.users.filter(
      user =>
        user.roles.find(role => role.name == "Surveyor") &&
        user.deleted_at == null
    );
  },
  reviewers: state => {
    return state.auth.users.filter(
      user =>
        user.roles.find(role => role.name == "Reviewer") &&
        user.deleted_at == null
    );
  },
  approvers: state => {
    return state.auth.users.filter(
      user =>
        user.roles.find(role => role.name == "Approver") &&
        user.deleted_at == null
    );
  },
  getStatusById: state => id => {
    return state.statuses.find(status => status.id == id);
  },
  item: state => id => {
    return state.auth.items.find(item => item.id == id);
  },
  items: state => {
    return state.auth.items;
  },
  statuses: state => {
    return state.statuses;
  },
  buildingStatuses: state => {
    if (state.statuses) {
      return state.statuses.filter(status => status.type == "Building");
    }
  },
  surveyStatuses: state => {
    return state.statuses.filter(status => status.type == "Survey");
  },
  sampleStatuses: state => {
    return state.sample_statuses;
  },
  sampleStatusById: state => id => {
    return state.sample_statuses.find(status => status.id == id);
  },
  filterSurveysByStatus: state => status => {
    let surveys = state.auth.surveys.map(survey => {
      let surveyItem = { ...survey };
      surveyItem["client"] = state.auth.clients.find(
        client => client.id == survey.client_id
      ).name;
      surveyItem["surveyor"] =
        state.auth.users.find(user => user.id == survey.surveyor_id)
          .full_name || "NA";
      surveyItem["reviewer"] =
        state.auth.users.find(user => user.id == survey.reviewer_id)
          .full_name || "NA";
      surveyItem["status"] = state.statuses.find(
        status => status.id == survey.status_id
      ).name;
      return surveyItem;
    });
    if (!status) return surveys;

    return surveys.filter(survey => survey.status_id == status);
  },
  getItemTable: state => room => {
    let items = state.auth.items.filter(item => item.room_id == room);
    let itemTable = items.map(item => {
      let aItem = { ...item };
      let bagCode = "NA";
      if (aItem.reported_sample) {
        bagCode = `${aItem.reported_sample.name} - ${aItem.reported_sample.sample_number}`;
      }
      aItem["full_name"] = `${item.name} ${item.link} ${item.description}`;
      aItem["method"] = state.assessment_methods.find(
        method => method.id == item.assessment_method_id
      ).name;
      if (item.sample && item.sample.bag_code) {
        bagCode = item.sample.bag_code;
      }
      if (item.sampled_as && item.sampled_as.item_as_id) {
        let sampledItem = state.auth.items.find(
          anItem => anItem.id == item.sampled_as.item_as_id
        );
        if (sampledItem && sampledItem.sample) {
          bagCode = sampledItem.sample.bag_code;
        }
      }
      aItem["bag_code"] = bagCode;
      return aItem;
    });
    return itemTable;
  },
  surveyStatus: state => {
    return state.auth.settings.survey.status;
  },
  getFloorById: state => id => {
    return state.auth.floors.find(floor => floor.id == id);
  },
  selectedItem: state => {
    return state.auth.selected.item;
  },
  selectedFloor: state => {
    return state.auth.selected.floor;
  },
  asbestos: state => {
    return state.contaminates.asbestos;
  },
  asbestosProperties: state => {
    const asbestos = state.contaminates.asbestos;
    let properties = [];
    asbestos.forEach(group => {
      group.properties.forEach(property => {
        properties.push(property);
      });
    });
    return properties;
  },
  asbestosRecommendations: state => {
    return state.recommendations.asbestos;
  },
  itemScore: state => property => {
    let scores = property.scores.map(({ id }) => id);
    return state.auth.selected.item.item_scores.includes(scores);
  },
  amountTypes: state => {
    return state.amountTypes;
  },
  amountTypeById: state => id => {
    return state.amountTypes.find(type => type.id == id);
  },
  buildingSearch: state => {
    return state.auth.search.buildings;
  },
  tableOptions: state => {
    return state.auth.search.options;
  },
  getSelected: state => property => {
    return state.auth.selected[property];
  },
  surveySearch: state => {
    let surveySearch = { ...state.auth.search.surveys };
    if (!Array.isArray(surveySearch.data)) {
      return surveySearch;
    }
    for (let survey of surveySearch.data) {
      let client = state.auth.clients.find(
        client => client.id == survey.client_id
      );
      let surveyor = state.auth.users.find(
        user => user.id == survey.surveyor_id
      );
      let reviewer = state.auth.users.find(
        user => user.id == survey.reviewer_id
      );

      survey["client"] = client ? client.name : "NA";
      survey["surveyor"] = surveyor ? surveyor.full_name : "NA";
      survey["reviewer"] = reviewer ? reviewer.full_name : "NA";
      survey["status"] = state.statuses.find(
        status => status.id == survey.status_id
      ).name;
    }
    return surveySearch;
  },
  note_types: state => {
    return state.note_types;
  },
  sampledAs: state => {
    return state.auth.selected.sampled_as;
  },
  show2fa: state => {
    return state.google_2fa;
  },
  verified2Fa: state => {
    return state.verified_2fa;
  },
  loaded2fa: state => {
    return state.loaded_2fa;
  },
  duplicateCheck: state => item => {
    let elClass = item.is_duplicated ? "warning" : "accent";
    if (item.floor_id && !item.is_scoped && !!item.is_not_suspicious) elClass += " greyed";
    if (!item.link) elClass += " pointer";
    return elClass;
  }
};

export default getters;
