<template>
  <v-row>
    <v-col v-if="floor" class="py-0 my-0">
      <tour tourKey="floor" />
      <the-floor :floor="floor" @updated="updated"></the-floor>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TheFloor from "./TheFloor.vue";
import Tour from "./Tour.vue";

export default {
  components: {
    TheFloor,
    Tour
  },
  mounted() {
    this.getFloor(this.$route.params.floor).then(floor => {
      this.floor = floor;
    });
  },
  watch: {
    "aFloor.is_drawing"() {
      this.updated();
    }
  },
  computed: {
    ...mapGetters({ aFloor: "floor"})
  },
  methods: {
    ...mapActions(["getFloor"]),
    show() {
      this.view = true;
    },
    updated() {
      this.floor = this.aFloor;
    },
  },
  data: () => ({
    floor: null
  })
};
</script>
