<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <h2 class="mb-2"><span class="pr-3 notes-step">Report Template</span></h2>
        </v-col>
      </v-row>
      <v-sheet elevation="1" outlined rounded>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-tabs fixed-tabs>
                  <v-tab
                    data-cy="report-template"
                    v-for="(template, key) in templates"
                    :key="key"
                    @click="step = key"
                    class="mb-0 pt-0"
                  >
                    {{ template }}
                    <span class="ml-3" v-if="hasTemplateText(key) == null"
                      ><v-icon small color="error"
                        >fas fa-exclamation-circle</v-icon
                      ></span
                    >
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="max-note-height">
                <v-window v-model="step">
                  <v-window-item
                    data-cy="template-content"
                    v-for="(template, key) in templates"
                    :value="key"
                    :key="key"
                  >
                    <v-row>
                      <v-col data-cy="template-box" class="h-100">
                        <p
                          class="preline body-1"
                          v-if="hasTemplateText(key) != null"
                          v-html="hasTemplateText(key)"
                        />

                        <div v-else class="h-100 pt-10" justify="center" align="center">
                          <v-icon class="mb-2">fas fa-exclamation</v-icon>
                          <p class="subtitle-2 text--secondary">
                            There is no note available
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row>
        <v-col class="py-0 mb-0 mt-n12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                data-cy="edit-note"
                v-on="on"
                @click="dialog = true"
                fab
                small
                right
                color="info"
                v-if="!is_reviewed(building)"
                class="float-right elevation-0 mr-3"
              >
                <v-icon>fa fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Edit {{ templates[step] }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <template-edit
        :building="building"
        :templates="templates"
        :aTemplateTxt="hasTemplateText(step)"
        :aTemplateType="step"
        :dialog="dialog"
        v-on:close="dialog = false"
        @updated="$emit('updated')"
      ></template-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateEdit from "./TemplateEdit.vue";
export default {
  components: {
    TemplateEdit,
  },
  props: ["notes", "edits", "building"],
  data: () => ({
    step: "introduction_content",
    dialog: false,

    // create a key value pair for the template
    templates: {
      introduction_content: "Introduction",
      recommendation_content: "Recommendations",
      survey_methodology_content: "Survey Methodology",
      limitations_content: "Limitations",
    },
  }),
  mounted() {
    if (this.is_needs_edits(this.building)) this.step = "introduction_content";
  },
  methods: {
    hasTemplateText(templateKey) {
      if (!this.building.report_templates) return null;
      return this.building.report_templates[templateKey] ?? null;
    },
  },
  computed: {
    ...mapGetters(["building", "is_reviewed", "is_needs_edits"]),
  },
  watch: {
    "building.status_id"() {
      if (this.is_needs_edits(this.building)) this.step = "introduction_content";
    },
  },
};
</script>

<style scoped>
.preline {
  white-space: pre-line;
}

.max-note-height {
  height: 13em;
  overflow: auto;
}

.edit-btn {
  display: sticky;
  bottom: 5px;
}
</style>
