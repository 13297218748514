<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="floor.name"
        outlined
        v-validate="'required|max:190'"
        counter
        maxlength="190"
        messages="*Required"
        :error-messages="errors.collect('name')"
        label="Name*"
        data-vv-name="name"
        placeholder="Enter the floor name"
        required
      ></v-text-field>
      <v-text-field
        autocomplete="off"
        label="Order*"
        v-model="floor.order"
        type="number"
        messages="*Required"
        v-validate="'required|numeric'"
        :error-messages="errors.collect('order')"
        data-vv-name="order"
        placeholder="Enter the floor order"
        required
        v-if="floorId"
        outlined
      />
      <v-row>
        <v-col md="6" cols="12">
          <v-autocomplete
            data-cy="copy-floor-plan"
            autocomplete="off"
            v-model="copyFloorId"
            outlined
            :disabled="floors.length == 0"
            :items="floors"
            item-text="name"
            item-value="id"
            :error-messages="errors.collect('copyFloorId')"
            label="Copy floor plan"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col
          v-if="copyFloorId == null"
          md="6"
          cols="12"
          class="floor-upload-step"
          v-cloak
        >
          <v-file-input
            v-if="floorId"
            v-model="file"
            data-cy="floor-image-upload"
            label="Upload floor plan"
            accept="image/*"
            outlined
            @change="handleFileChange"
          ></v-file-input>
          <v-file-input
            v-else
            v-model="file"
            outlined
            label="Upload floor plan"
            accept="image/*"
            data-cy="floor-upload"
            messages="*Required"
            v-validate="copyFloorId == null ? 'required|image' : ''"
            data-vv-name="image"
            :error-messages="errors.collect('image')"
            @change="handleFileChange"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row justify="center">
            <v-col v-if="copyFloorId == null">
              <a-img
                title="new image or existsing image"
                v-if="displayedImageSrc || floorId"
                :src="
                  displayedImageSrc
                    ? displayedImageSrc
                    : `/api/image/floor/${floorId}/image`
                "
                class="img-size"
              ></a-img>
            </v-col>
            <v-col v-else>
              <a-img
                title="Copy Floor Plan"
                :src="`/api/image/floor/${copyFloorId}/image`"
                class="img-size"
                :key="copyFloorId"
              ></a-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["aFloor"],
  data: () => ({
    reader: null,
    displayedImageSrc: null,
    uploadedFile: null,
    imageKey: 1000,
    floors: [],
    copyFloorId: null,
    file: null,
  }),
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  mounted() {
    this.getFloors(this.$route.params.building).then((response) => {
      this.floors = response;
      if (this.floorId && this.floors.length > 0) {
        this.floors = this.floors.filter((floor) => {
          return floor.id != this.floorId;
        });
      }
    });
  },
  methods: {
    ...mapActions(["getFloors"]),
    addDropFile(e) {
      this.reader.readAsDataURL(e.dataTransfer.files[0]);
    },
    handleFileChange(file) {
      if (file) {
        this.resizeAndDisplayImage(file);
      } else {
        this.displayedImageSrc = null;
        this.floor.file = null;
      }
    },
    resizeAndDisplayImage(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // increase the max size of the image to 2000px to make it more clear on the reports
        const maxSize = 2500;
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const resizedImageDataUrl = canvas.toDataURL("image/png");
          this.displayedImageSrc = resizedImageDataUrl;
          this.floor.file = resizedImageDataUrl;
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    floor: {
      get() {
        return this.aFloor;
      },
      set(updated) {
        this.$emit("update:aFloor", updated);
      },
    },
    floorId() {
      return this.$route.params.floor;
    },
  },

  watch: {
    copyFloorId(val) {
      if (val) {
        this.floor.copyFloorId = val;
        this.file = null;
        this.floor.file = null;
        this.displayedImageSrc = null;
      } else {
        this.floor.copyFloorId = null;
      }
    },
  },
};
</script>

<style scoped>
.img-size {
  max-width: 500px;
  margin: auto;
}
</style>
