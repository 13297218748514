const state = {
  auth: {
    reports: null,
    messages: [],
    export: {
      surveys: [],
      buildings: [],
      floors: [],
      rooms: []
    },
    user: null,
    settings: {
      browse: {
        survey: {
          search: null,
          status: null
        },
        building: {
          search: null,
          status: null,
          report_type: null
        }
      },
      survey: {
        status: null
      },
      survey_filter: null
    },
    search: {
      options: { itemsPerPage: 10 },
      buildings: {
        data: [],
        total: 0
      },
      surveys: {
        data: [],
        total: 0
      }
    },
    selected: {
      user: {},
      survey: {
        client_id: null,
        contact_id: null,
        surveyor_id: null,
        reviewer_id: null,
        office_id: null,
        name: null,
        job_number: null,
        notes: null,
        image: null
      },
      building: {
        address: {
          city: null,
          suburb: null,
          postcode: null,
          address: null,
          address2: null
        },
        name: null,
        survey_date: null,
        description: null,
        phase: null,
        image: null,
        report: null,
        is_within_three_months: null,
        is_answering_questions: null,
        is_residential: null,
        is_urgent_transfer: null,
        laboratory_id: null,
        report_type_id: null,
        has_sample_polygon: true,
      },
      approvals: null,
      floor: {
        building_id: null,
        name: null,
        description: null,
        image: null
      },
      room: {
        floor_id: null,
        name: null,
        polygon: null,
        image: null,
        note: null,
        label: false,
        layer: {
          polygons: [],
          labels: []
        }
      },
      item: {
        name: null,
        link: null,
        description: null,
        polygon: null,
        amount: null,
        removal_class_id: null,
        amount_type_id: null,
        assessment_method_id: null,
        room_id: null,
        layer: {
          polygons: [],
          labels: []
        },
        presumed_sample: {
          sample_status_id: null
        },
        item_scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        item_recommendations: [],
        contaminate_id: null
      },
      sampled_as: {
        name: null,
        link: null,
        description: null,
        polygon: null,
        amount: null,
        removal_class_id: null,
        amount_type_id: null,
        assessment_method_id: null,
        room_id: null,
        presumed_sample: {
          sample_status_id: null
        },
        item_scores: [],
        item_recommendations: []
      },
      office: {
        name: null,
        address: {
          city: null,
          suburb: null,
          postcode: null,
          address: null,
          address2: null
        }
      },
      laboratory: {
        name: null,
        address: {
          city: null,
          suburb: null,
          postcode: null,
          address: null,
          address2: null
        }
      },
      client: {},
      contacts: [],
      contact: null
    },
    company: null,
    clients: [],
    offices: [],
    users: [],
    laboratories: [],
    surveys: [],
    buildings: [],
    floors: [],
    rooms: [],
    items: []
  },
  notification: {
    show: false,
    type: null,
    message: "",
    timeout: null
  },
  contaminates: {
    asbestos: null
  },
  recommendations: {
    asbestos: null
  },
  amountTypes: null,
  assessment_methods: null,
  statuses: null,
  sample_statuses: null,
  companies: null,
  roles: null,
  report_types: null,
  note_types: null,
  analysisTypes: null,
  dark: false,
  isLoading: false,
  contaminate_types: null,
  google_2fa: true,
  verified_2fa: false,
  loaded_2fa: false,
};

export default state;
