<template>
  <v-row>
    <v-col>
      <v-data-table  data-cy="client-page" :headers="headers" :items="contacts" :search="search" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              spellcheck="true"
              aria-autocomplete="no"
              v-model.lazy="search"
              append-icon="fas fa-search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-btn data-cy="add-client" color="primary" dark small fab @click="create">
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <base-dialog
            :dialog.sync="deleteDialog"
            title="Delete contact"
            :message="message"
            :method="contactDelete"
          ></base-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn fab dark small color="info" @click="goto(item)">
            <v-icon dark data-cy="edit-contact">fas fa-pencil</v-icon>
          </v-btn>
          <v-btn
            data-cy="delete-client-contact"
            :disabled="client.primary_id == item.id"
            fab
            dark
            small
            color="error"
            @click="openDelete(item)"
          >
            <v-icon dark>fas fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseDialog from "./BaseDialog.vue";

export default {
  components: {
    BaseDialog
  },
  mounted() {
    this.getContacts(this.$route.params.client);
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    deleteDialog: false,
    selected: null,
    search: null,
    message: null,
    headers: [
      { text: "Name", value: "full_name" },
      { text: "Phone", value: "phone" },
      { text: "email", value: "email" },
      { text: "Actions", value: "actions" }
    ]
  }),
  computed: {
    ...mapGetters(["contacts", "client"])
  },
  methods: {
    ...mapActions(["deleteContact", "getContacts"]),
    openDelete(contact) {
      this.selected = contact;
      this.message = `Are you sure you want to delete ${this.selected.full_name}?`;
      this.deleteDialog = true;
    },
    contactDelete() {
      this.deleteContact(this.selected).then(() => {
        this.getContacts(this.$route.params.client);
      });
    },
    goto(contact) {
      this.$router.push(`/client/${this.client.id}/contact/${contact.id}`);
    },
    create() {
      this.$router.push(`/client/${this.client.id}/contact`);
    }
  }
};
</script>
