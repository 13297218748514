<template>
  <v-dialog :value="show" persistent max-width="800px">
    <v-card>
      <v-card-text>
        <contact-create
          :dialog="true"
          :client="client"
          @close="close"
        ></contact-create>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactCreate from "./ContactCreate";
export default {
  props: ["show", "client"],
  components: {
    ContactCreate
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
