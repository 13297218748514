<template>
  <div>
    <h1>What are you doing?!</h1>
    <h2>You really shouldn't be here...</h2>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
