<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>fas fa-circle-xmark</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Survey Scope</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-spacer></v-spacer>
          <v-form>
            <tiptap-vuetify
              :extensions="extensions"
              :label="'Enter report content for \'Survey Scope\' here...'"
              v-model="atemplateContent"
              name="details"
              class="sec-div mt-3"
              :class="{ 'error-div': showWorkDetailsErr }"
              :placeholder="'Enter report content for \'Survey Scope\' here...'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" data-cy="save-note" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  CodeBlock,
} from "tiptap-vuetify";

export default {
  components: {
    TiptapVuetify,
  },
  props: ["building", "atemplateContent", "step", "dialog"],

  data: () => ({
    confirmDialog: false,
    templateContent: null,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      HorizontalRule,
      Paragraph,
      CodeBlock,
      HardBreak,
    ],
  }),
  computed: {
    ...mapGetters(["building", "is_reviewed"]),
  },
  mounted() {
    this.atemplateContent
      ? (this.templateContent = this.atemplateContent)
      : (this.templateContent = null);
  },
  methods: {
    ...mapActions(["editBuilding", "getBuilding"]),
    close() {
      this.$emit("close");
    },
    save() {
      if (this.step == 1) this.saveAsbestos();
      if (this.step == 2) this.saveLead();
    },
    saveAsbestos() {
      const building = this.$route.params.building;
      this.editBuilding({
        id: building,
        area_surveyed: this.templateContent,
      })
        .then(() => {
          this.$emit("updated");
        })
        .then(() => {
          this.close();
        });
    },
    saveLead() {
      const building = this.$route.params.building;
      this.editBuilding({
        id: building,
        lead_area_surveyed: this.templateContent,
      })
        .then(() => {
          this.$emit("updated");
        })
        .then(() => {
          this.close();
        });
    },
  },
  watch: {
    atemplateContent(updated) {
      this.templateContent = updated;
    },
  },
};
</script>
