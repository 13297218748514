import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"data-cy":"client-page","headers":_vm.headers,"items":_vm.contacts,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"spellcheck":"true","aria-autocomplete":"no","append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{attrs:{"data-cy":"add-client","color":"primary","dark":"","small":"","fab":""},on:{"click":_vm.create}},[_c(VIcon,[_vm._v("fas fa-plus")])],1)],1),_c('base-dialog',{attrs:{"dialog":_vm.deleteDialog,"title":"Delete contact","message":_vm.message,"method":_vm.contactDelete},on:{"update:dialog":function($event){_vm.deleteDialog=$event}}})]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"fab":"","dark":"","small":"","color":"info"},on:{"click":function($event){return _vm.goto(item)}}},[_c(VIcon,{attrs:{"dark":"","data-cy":"edit-contact"}},[_vm._v("fas fa-pencil")])],1),_c(VBtn,{attrs:{"data-cy":"delete-client-contact","disabled":_vm.client.primary_id == item.id,"fab":"","dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.openDelete(item)}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("fas fa-trash-alt")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }