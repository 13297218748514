<template>
  <v-row>
    <v-col class="py-0 my-0">
      <v-row >
        <v-col class="py-0 my-0">
          <h1>Browse Clients</h1>
          <p class="text--secondary">
            Search, edit, or remove a client. View client contacts or buildings
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 my-0">
          <v-data-table 
            data-cy="client-table"
            :headers="headers"
            :items="clients"
            :search="search"
            no-data-text="There are no clients"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="d-flex flex" align="center">
                  <v-col sm="6">
                    <v-text-field 
                      data-cy="search-clients"
                      spellcheck="true"
                      aria-autocomplete="no"
                      v-model.lazy="search"
                      append-icon="fas fa-search"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          data-cy="add-client"
                          v-on="on"
                          color="primary"
                          dark
                          small
                          fab
                          @click="create"
                          class="float-right"
                        >
                          <v-icon>fas fa-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Create Client</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-toolbar>
              <base-dialog
                :dialog.sync="deleteDialog"
                title="Delete Client"
                :message="message"
                :method="clientDelete"
              ></base-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn 
                    data-cy="view-client-building"
                    v-on="on"
                    fab
                    dark
                    x-small
                    class="mr-1"
                    color="secondary"
                    @click="goto(item, true)"
                  >
                    <v-icon dark>fal fa-city</v-icon>
                  </v-btn>
                </template>
                <span>View Buildings</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn 
                    data-cy="edit-client"
                    v-on="on"
                    fab
                    dark
                    x-small
                    color="info"
                    @click="goto(item)"
                    class="mx-1"
                  >
                    <v-icon dark>fas fa-pencil-alt</v-icon>
                  </v-btn>
                </template>
                <span>Edit Client</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn 
                    data-cy="view-client-contact"
                    v-on="on"
                    fab
                    dark
                    x-small
                    color="warning"
                    @click="viewContacts(item)"
                    class="mx-1"
                  >
                    <v-icon dark>fas fa-users</v-icon>
                  </v-btn>
                </template>
                <span>View Contacts</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="delete-client"
                    v-on="on"
                    fab
                    class="mx-1"
                    x-small
                    color="error"
                    :disabled="item.survey_count > 0"
                    @click="openDelete(item)"
                  >
                    <v-icon >fas fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>Delete Client</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseDialog from "./BaseDialog.vue";

export default {
  components: {
    BaseDialog
  },
  mounted() {
    this.getClients();
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    deleteDialog: false,
    selected: null,
    search: null,
    message: null,
    headers: [
      { text: "Name", value: "name" },
      { text: "Address", value: "full_address" },
      { text: "Actions", value: "actions" }
    ]
  }),
  computed: {
    ...mapGetters(["clients"])
  },
  methods: {
    ...mapActions(["deleteClient", "getClients"]),
    openDelete(client) {
      this.selected = client;
      this.message = `Are you sure you want to delete ${this.selected.name}?`;
      this.deleteDialog = true;
    },
    clientDelete() {
      this.deleteClient(this.selected).then(() => {
        this.getClients();
      })
    },
    goto(client, buildings = false) {
      this.$router.push(`/client/${client.id}${buildings ? "/buildings" : ""}`);
    },
    create() {
      this.$router.push(`/client`);
    },
    viewContacts(item) {
      this.$router.push(`/client/${item.id}/contacts`);
    }
  }
};
</script>
