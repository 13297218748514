<template>
  <v-row>
    <v-col class="pt-0 mt-0">
      <v-row>
        <v-col class="pt-0 mt-0" cols="12" md="6">
          <h1>{{ building.name }}</h1>
          <h4>Report Download</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="reports">
          <v-data-iterator :items="reports" :options="options">
            <template v-slot:item="{ item }">
              <v-row>
                <v-col class="py-1">
                  <v-btn
                    block
                    outlined
                    color="primary"
                    @click="downloadReport(item)"
                  >
                    {{ item.final_document_name }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    reports: null,
    options: {
      sortBy: "created_at",
      itemsPerPage: -1
    }
  }),
  mounted() {
    this.getReports({ id: this.$route.params.building, type: "building" }).then(
      response => {
        this.reports = response.data;
      }
    );
  },
  computed: {
    ...mapGetters(["building"])
  },
  methods: {
    ...mapActions(["getReports", "downloadReport"])
  }
};
</script>
