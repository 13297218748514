import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSpeedDial,{staticStyle:{"display":"inline"},attrs:{"direction":"top","disabled":_vm.building.asbestos_report_status != 'completed' ||
    _vm.building.lead_report_status != 'completed'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.building.is_asbestos || _vm.building.is_lead)?_c(VTooltip,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-1 download-report-pdf-step",attrs:{"fab":"","disabled":!_vm.building.asbestos_report_status && !_vm.building.lead_report_status}},on),[_c(VIcon,[_vm._v("fas fa-file-pdf")])],1)]}}],null,false,471484857)},[_c('span',[_vm._v("Download report")])]):_vm._e()]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(
      _vm.building.is_asbestos && _vm.building.asbestos_report_status == 'completed'
    )?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue"},on:{"click":function($event){return _vm.downloadReport(_vm.ASBESTOS)}}},on),[_c(VIcon,[_vm._v("fal fa-stroopwafel ")])],1)]}}],null,false,2365839448)},[_c('span',[_vm._v("Asbestos")])]):_vm._e(),(_vm.building.is_lead && _vm.building.lead_report_status == 'completed')?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.downloadReport(_vm.LEAD)}}},on),[_c(VIcon,[_vm._v("fas fa-fill-drip")])],1)]}}],null,false,3466709553)},[_c('span',[_vm._v("Lead")])]):_vm._e(),(_vm.building.asbestos_report_status || _vm.building.lead_report_status)?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"fab":"","dark":"","small":"","color":"primary","href":`#/survey/${_vm.building.survey_id}/building/${_vm.building.id}/reports`}},on),[_c(VIcon,[_vm._v("fas fa-history")])],1)]}}],null,false,3509091467)},[_c('span',[_vm._v("View all")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }