<template>
  <v-app-bar app clipped-left class="primary text--white above">
    <v-app-bar-nav-icon data-cy="menu" @click="draw = !draw"
      ><v-icon color="white">fas fa-bars</v-icon></v-app-bar-nav-icon
    >
    <a href="#/dashboard">
      <v-avatar>
        <img
          data-cy="ccs-logo"
          src="/img/icons_white/apple-touch-icon-120x120.png"
          alt="logo"
        />
      </v-avatar>
    </a>
    <v-spacer></v-spacer>
    <div class="float-right mr-12" v-if="show2fa">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="logout()">
            <v-icon color="white" info>fas fa-sign-out</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </div>
    <message-notification v-else></message-notification>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageNotification from "../components/MessageNotification.vue";
export default {
  props: ["drawer"],
  components: {
    MessageNotification,
  },
  methods: {
    ...mapActions(["reset2FA"]),
    logout() {
      window.$chatwoot.reset();
      this.reset2FA();
      this.$auth.logout();
    },
  },
  computed: {
    ...mapGetters(["show2fa"]),
    draw: {
      get() {
        return this.drawer;
      },
      set(update) {
        this.$emit("update:drawer", update);
      },
    },
  },
};
</script>

<style scoped>
.above {
  z-index: 11 !important;
}
</style>