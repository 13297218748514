<template>
  <v-row justify="center">
    <v-col v-if="theContact || !isEdit" md="10" sm="10">
      <v-row>
        <v-col class="p-0 m-0" justify="center" align="center">
          <p v-if="isEdit" class="display-2 p-0 m-0">
            Edit {{ theClient.name }} Contact
          </p>
          <p v-else class="display-2 p-0 m-0">
            Create {{ theClient.name }} Contact
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-form v-if="item" data-cy="contact-form">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="item.first_name"
              outlined
              v-validate="'required|max:60'"
              counter
              maxlength="60"
              :error-messages="errors.collect('first name')"
              label="First Name"
              data-vv-name="first name"
              required
            ></v-text-field>
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="item.last_name"
              outlined
              v-validate="'required|max:60'"
              counter
              maxlength="60"
              :error-messages="errors.collect('last name')"
              label="Last Name"
              data-vv-name="last name"
              required
            ></v-text-field>
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="item.phone"
              outlined
              v-validate="'required|max:80'"
              counter
              maxlength="80"
              :error-messages="errors.collect('phone')"
              label="Phone"
              data-vv-name="phone"
              required
            ></v-text-field>
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="item.email"
              outlined
              v-validate="'required|email|max:80'"
              counter
              maxlength="80"
              :error-messages="errors.collect('email')"
              label="Email"
              data-vv-name="email"
              required
            ></v-text-field>
            <v-checkbox 
              data-cy="set-primary-contact"
              v-model="item.primary"
              label="Set this person as the primary contact?"
            ></v-checkbox>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="dialog" color="warning" class="float-left" @click="back"
            >Close</v-btn
          >
          <v-btn data-cy="contact-update" v-if="isEdit" color="primary" @click="saveContact"
            >Update</v-btn
          >
          <v-btn v-else color="primary" data-cy="create-contact" class="float-right" @click="saveContact"
            >Create</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <loader :value="theContact"></loader>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader.vue";

export default {
  components: {
    Loader
  },
  props: ["client", "contact", "dialog"],
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    item: {
      first_name: null,
      last_name: null,
      phone: null,
      email: null,
      primary: false
    }
  }),
  mounted() {
    if (this.$route.params.contact) {
      this.item.primary = this.theClient.primary_id == this.item.id;
    }
  },
  methods: {
    ...mapActions(["createContact", "editContact"]),
    saveContact() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.item.client_id = this.client;
        if (!this.dialog && this.isEdit) {
          this.editContact(this.item).then(() => {
            this.back();
          });
        } else {
          this.createContact(this.item).then(() => {
            this.back();
          });
        }
      });
    },
    back() {
      if (!this.dialog) this.$router.push(`/client/${this.client}/contacts`);
      this.$emit("close");
    }
  },
  computed: {
    ...mapGetters({
      theContact: "contact",
      theClient: "client",
      invalidInput: "invalidInput"
    }),
    isEdit() {
      return this.contact;
    }
  },
  watch: {
    theContact(updated) {
      if (this.isEdit) {
        this.item = updated;
        this.item.primary = this.theClient.primary_id == this.item.id;
      }
    }
  }
};
</script>
