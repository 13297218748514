<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <h2 class="mb-2"><span class="pr-3 notes-step">Notes</span></h2>
        </v-col>
      </v-row>
      <v-sheet elevation="1" outlined rounded>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-tabs fixed-tabs>
                  <v-tab
                    data-cy="page-note"
                    v-for="type in pageNoteTypes"
                    :key="type.id"
                    @click="step = type.id"
                    class="mb-0 pt-0"
                  >
                    {{ type.name }}
                    <span
                      class="ml-3"
                      v-if="
                        !hasNoteTypeId(type.id) ||
                          !hasNoteTypeId(type.id).content
                      "
                      ><v-icon small color="error"
                        >fas fa-exclamation-circle</v-icon
                      ></span
                    >
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col class="max-note-height">
                <v-window v-model="step">
                  <v-window-item
                    data-cy="survey-notes"
                    v-for="n in 4"
                    :value="n"
                    :key="n"
                  >
                    <v-row>
                      <v-col data-cy="note-box" class="h-100">
                        <p class="preline body-1" v-if="hasNoteTypeId(n)">
                          {{ hasNoteTypeId(n).content }}
                        </p>
                        <div
                          v-else
                          class="h-100 pt-10"
                          justify="center"
                          align="center"
                        >
                          <v-icon class="mb-2">fas fa-exclamation</v-icon>
                          <p class="subtitle-2 text--secondary">
                            There is no note available
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <v-row>
        <v-col class="py-0 mb-0 mt-n12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                data-cy="edit-note"
                v-on="on"
                @click="dialog = true"
                fab
                small
                right
                color="info"
                v-if="!is_reviewed(building)"
                class="float-right elevation-0 mr-3"
              >
                <v-icon>fa fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Edit Note</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <note-edit
        :aNote="hasNoteTypeId(step)"
        :type="step"
        :dialog="dialog"
        v-on:close="dialog = false"
        @updated="$emit('updated')"
      ></note-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import NoteEdit from "./NoteEdit.vue";
export default {
  components: {
    NoteEdit
  },
  props: ["notes", "edits"],
  data: () => ({
    step: 2,
    dialog: false
  }),
  mounted() {
    if (this.is_needs_edits(this.building)) this.step = 1;
  },
  methods: {
    hasNoteTypeId(id) {
      if (this.notes) {
        return this.notes.find(aNote => aNote.type_id == id);
      }
    }
  },
  computed: {
    ...mapGetters(["note_types", "building", "is_reviewed", "is_needs_edits"]),
    pageNoteTypes() {
      if (Array.isArray(this.note_types)) {
        if (this.edits) {
          return this.note_types;
        }
        return this.note_types.filter(aType => aType.id != 1);
      }
      return null;
    }
  },
  watch: {
    'building.status_id'() {
      if(this.is_needs_edits(this.building)) this.step = 1;
    }
  },
};
</script>

<style scoped>
.preline {
  white-space: pre-line;
}

.max-note-height {
  height: 13em;
  overflow: auto;
}

.edit-btn {
  display: sticky;
  bottom: 5px;
}
</style>
