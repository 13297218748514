<template>
  <div>
    <v-select
      class="pt-5"
      :items="analysisTypesDrp"
      v-model="type"
      item-text="name"
      item-value="id"
    ></v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["item"],
  mounted() {
    this.type = this.item.sample.analysis_type_id;
  },
  data: () => ({
    type: null
  }),
  methods: {
    ...mapActions(["updateSample"])
  },
  computed: {
    ...mapGetters(["analysisTypes", "building"]),
    analysisTypesDrp() {
      let types = this.analysisTypes.filter((type) => {
        return type.contaminate_id == this.item.contaminate_id && !!type.name;
      });
      return types;
    }
  },
  watch: {
    type(updated, previous) {
      if (previous != null && updated != previous) {
        this.updateSample({
          data: {
            id: this.item.sample.id,
            analysis_type_id: updated
          },
          building_id: this.building.id
        });
      }
    }
  }
};
</script>
