<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <v-card>
      <v-card-title class="text-h5">
        Welcome to CCS
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-avatar>
              <img src="/img/icons/apple-touch-icon-180x180.png" alt="logo" />
            </v-avatar>
          </v-col>
          <v-col cols="9">
            Would you like to enable tutorials?
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="answer(1)">
          Yes
        </v-btn>
        <v-btn color="primary" text @click="answer(0)">
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => {
    return {
      dialog: false
    };
  },
  mounted() {
    this.dialog = true;
  },
  watch: {
    show() {
      this.dialog = this.show;
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapActions(["setTutorialsSeen", "refreshUser"]),
    answer(ans) {
      this.dialog = false;
      this.setTutorialsSeen({
        tutorials_enabled: ans
      }).then(response => {
        this.reloadUser();
      });
    },
    reloadUser() {
      this.$auth.fetch();
      this.refreshUser(this.user.id);
    }
  }
};
</script>
