import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Welcome to CCS ")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VAvatar,[_c('img',{attrs:{"src":"/img/icons/apple-touch-icon-180x180.png","alt":"logo"}})])],1),_c(VCol,{attrs:{"cols":"9"}},[_vm._v(" Would you like to enable tutorials? ")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.answer(1)}}},[_vm._v(" Yes ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.answer(0)}}},[_vm._v(" No ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }