<template>
  <v-menu bottom left v-if="messages" offset-y class="transparent">
    <template v-slot:activator="{ on }">
      <div class="float-right mr-12">
        <v-btn icon v-on="on" :disabled="messages.length == 0">
          <v-badge
            :content="messages.length"
            :value="messages.length"
            color="green"
            overlap
          >
            <v-icon color="white" info>fas fa-bell</v-icon>
          </v-badge>
        </v-btn>
      </div>
    </template>
    <v-list subheader v-if="messages.length > 0" two-line>
      <v-subheader inset
        >Notifications<v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" :attrs="attrs" icon @click="readAll()">
              <v-icon small color="red">fas fa-times</v-icon>
            </v-btn>
          </template>
          <span>Read all</span>
        </v-tooltip>
      </v-subheader>
      <v-list-item
        v-for="(message, i) in messages"
        :key="i"
        :color="message.success ? '' : 'warning'"
      >
        <v-list-item-icon class="mr-3 mb-0">
          <v-btn
            fab
            x-small
            color="success"
            v-if="message.event == 'report' && message.status == 'completed'"
            @click="downloadReportURL(message)"
          >
            <v-icon>fas fa-download</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            color="success"
            v-else-if="
              message.event == 'clearance' && message.status == 'completed'
            "
            @click="downloadClerancePDF(message.pdf_id)"
          >
            <v-icon>fas fa-download</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            v-else
            :to="message.to"
            :color="message.success ? 'success' : 'warning'"
          >
            <v-icon>{{ icon(message.event) }}</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="message.message"></v-list-item-title>
          <v-list-item-subtitle v-text="message.time"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="read(message.id)">
            <v-icon color="red">fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["messages"])
  },
  methods: {
    ...mapActions(["getMessages", "readMessage", "readAll", "downloadClerancePDF", "downloadReportURL"]),
    read(id) {
      this.readMessage(id).then(() => {
        this.getMessages();
      });
    },
    icon(event) {
      return {
        duplicate: "fas fa-copy",
        report: "fal fa-file-pdf",
        notification: "fal fa-envelope-square"
      }[event];
    }
  },
  mounted() {
    this.getMessages();
  }
};
</script>
