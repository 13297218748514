<template>
  <div
    v-if="tooltip"
    v-show="tooltip.placed"
    class="pop-tour-step"
    v-click-outside="closeIfOutsideClick"
  >
    <div class="popup-marker clickable" @click="() => (showPopup = !showPopup)">
      <div class="marker__circle"></div>
      <div class="marker__outline"></div>
    </div>

    <div
      class="tooltip-popup-container p-3"
      v-if="tooltip && showPopup"
      :class="tooltip.popup_placement"
    >
      <div class="tooltip-popup p-3 pb-5">
        <b class="d-block">
          {{ tooltip.title }}
        </b>
        <small class="d-block mb-2" v-html="tooltip.text"></small>
        <v-btn class="float-right" color="primary" @click="acknowledgeTooltip"
          >Dismiss</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { createPopper } from "@popperjs/core";

import ClickOutside from "vue-click-outside";

export default {
  name: "PopTourStep",
  props: {
    tooltip: Object,
    tourKey: String
  },
  directives: {
    ClickOutside
  },
  data: () => ({
    showPopup: false,
    startTime: null
  }),
  watch: {
    showPopup(val) {
      if (!val) {
        return;
      }

      this.startTime = new Date().getTime();
    }
  },
  methods: {
    acknowledgeTooltip(toSave = true) {
      this.showPopup = false;

      this.$emit("acknowledge", {
        tooltip: this.tooltip,
        timeTaken: new Date().getTime() - this.startTime,
        toSave: toSave
      });
    },
    closeIfOutsideClick() {
      if (!this.showPopup) {
        return;
      }

      this.showPopup = false;
    }
  }
};
</script>
<style scoped>
.pop-tour-step {
  z-index: 7;
}

.popup-marker {
  transform: translate(-12px, -12px);
}

.marker__circle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background: rgba(255, 193, 7, 1);
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 1);
  transition: background-color 200ms ease 0s, transform 200ms ease-in-out;
}

.popup-marker:hover .marker__circle {
  transform: scale(1.25);
}

.marker__outline {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  transition: border-color 200ms ease 0s;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(255, 193, 7, 1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
  }
}

.p-3 {
  padding: 10px !important;
}

.d-block {
  display: block !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.tooltip-popup {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.25rem 2rem 0px;
  background: rgb(254, 254, 254);
  border-width: 2px;
  border-style: solid;
  border-color: #1565c0;
  border-image: initial;
  border-radius: 10px;
  width: 260px;
}

.tooltip-popup-container {
  position: absolute;
  transform: translate(0px, 0px);
}

.tooltip-popup-container.top {
  bottom: 0;
}

.tooltip-popup-container.right {
  left: 0;
}

.tooltip-popup-container.bottom {
  top: 0;
}

.tooltip-popup-container.left {
  right: 0;
}
</style>
