<template>
  <v-main class="min-vh-100">
    <v-container fluid class="pa-0">
      <two-factor-authentication v-if="show2fa" />
      <router-view v-else></router-view>
      <loader :value="isLoading"></loader>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "./Loader.vue";
import TwoFactorAuthentication from "./TwoFactorAuthentication.vue";

export default {
  components: {
    Loader,
    TwoFactorAuthentication,
  },
  mounted() {
    this.setLoading();
    this.startRouteProcess();
  },
  watch: {
    user(updated) {
      if (!updated) {
        for (let key of Object.keys(window.Echo.connector.channels)) {
          window.Echo.leave(key);
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "setMessage",
      "setLoading",
      "getRelatedSurveyData",
      "getBuilding",
      "getBuildings",
      "getFloor",
      "getRoom",
      "get2FA",
      "set2faVerified",
      "show2faDiv",
    ]),
    startRouteProcess() {
      this.$nextTick(() => {
        this.setChannels();
        this.eventConnections(this.$route);
      });
      this.$router.beforeEach((to, from, next) => {
        this.getRelatedSurveyData();
        this.eventConnections(to);
        next();
      });

      if (this.verified2Fa !== true) {
        this.get2FA().then((response) => {
          if (response.data.auth === true) {
            this.set2faVerified();
            return;
          }
          this.show2faDiv();
        });
      } else {
        this.set2faVerified();
      }
    },
    setChannels() {
      const channels = Object.keys(window.Echo.connector.channels);
      if (this.user && this.user.company_id) {
        if (
          channels.includes(`private-${this.user.company_id}.reports`) ||
          channels.includes(`App.Models.User.${this.user.id}`)
        ) {
          return;
        }
        window.Echo.private(`App.Models.User.${this.user.id}`).notification(
          (notification) => {
            this.setMessage(notification);
          }
        );
      }
    },
    surveyEvent(route) {
      this.getBuildings(route.params.survey).finally(() => {
        this.setLoading(false);
      });
    },
    buildingEvent(route) {
      window.Echo.private(`building.${route.params.building}`).listen(
        "BuildingUpdate",
        (e) => {
          this.getBuilding(e.buildingId).finally(() => {
            this.setLoading(false);
          });
        }
      );
    },
    floorEvent(route) {
      window.Echo.private(`floor.${route.params.floor}`).listen(
        "FloorUpdate",
        (e) => {
          this.getFloor(e.floorId);
        }
      );
    },
    roomEvent(route) {
      window.Echo.private(`room.${route.params.room}`).listen(
        "RoomUpdate",
        (e) => {
          this.getRoom(e.roomId);
        }
      );
    },
    disconnectEventChannels(to) {
      const exceptions = ["reports", "duplicate", "user"];
      for (let key of Object.keys(window.Echo.connector.channels)) {
        if (
          exceptions.find((val) =>
            key.toLowerCase().includes(val.toLowerCase())
          ) != undefined
        ) {
          continue;
        }
        window.Echo.leave(key);
      }
    },
    eventConnections(route) {
      this.disconnectEventChannels(route);
      switch (route.name) {
        case "Survey":
          this.surveyEvent(route);
          break;
        case "Building":
          this.buildingEvent(route);
          break;
        case "Floor":
          this.floorEvent(route);
          break;
        case "Room":
          this.roomEvent(route);
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["user", "isLoading", "show2fa", "verified2Fa"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~vuejs-noty/dist/vuejs-noty.css";

.noty_body {
  font-family: "Roboto" !important;
}

.min-vh-100 {
  min-height: 90vh !important;
}
</style>
