<template>
  <div v-if="template">
    <v-dialog v-model="deleteFloorDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          Delete diagram
        </v-card-title>
        <v-card-text>Are you sure you want to delete diagram?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteFloorDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDeleteFloorPlan">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expansion-panels v-model="panel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(0) }"
          >Removal Work Details</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row>
            <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
              <v-text-field
                spellcheck="true"
                label="Clearance Title"
                outlined
                v-model.lazy="template.clearance.name"
                v-validate="'required|max:190'"
                counter
                maxlength="190"
                :error-messages="errors.collect('title')"
                data-vv-name="title"
                placeholder="Enter Title"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
            <v-col sm="2" cols="12" class="pt-0" data-cy="lead-link-word">
              <v-menu
                v-model="menu_from"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="template.clearance.work_date"
                    label="Date of removal work from"
                    prepend-icon="fas fa-calendar"
                    :error-messages="errors.collect('work date from')"
                    v-validate="'required|max:190'"
                    data-vv-name="work date from"
                    outlined
                    v-bind="attrs"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="template.clearance.work_date"
                  :max="today"
                  @input="menu_from = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="2" cols="12" class="pt-0" data-cy="lead-link-word">
              <v-menu
                v-model="menu_to"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="template.clearance.work_date_to"
                    label="Date of removal work to"
                    prepend-icon="fas fa-calendar"
                    :error-messages="errors.collect('work date to')"
                    v-validate="'required|max:190'"
                    data-vv-name="work date to"
                    outlined
                    v-bind="attrs"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="template.clearance.work_date_to"
                  :max="today"
                  @input="menu_to = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4" cols="12" class="pt-0" data-cy="lead-link-word">
              <v-select
                :items="[
                  { class: 'Class A', type: 1 },
                  { class: 'Class B', type: 2 }
                ]"
                item-text="class"
                item-value="type"
                spellcheck="true"
                label="Clearance Type*"
                outlined
                v-model="template.clearance.type"
                v-validate="'required|max:190'"
                data-vv-name="clearance type"
                :error-messages="errors.collect('clearance type')"
                placeholder="Clearance Type"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4" cols="12" class="pt-0" data-cy="lead-link-word">
              <br />
              <v-textarea
                autocomplete="off"
                spellcheck="true"
                label="Site address"
                v-model="template.clearance.site_address"
                v-validate="'required|max:190'"
                data-vv-name="site address"
                :error-messages="errors.collect('site address')"
                outlined
                placeholder="Site address where removal work is being carried out"
                rows="4"
              ></v-textarea>
            </v-col>
            <v-col sm="8" cols="12" class="pt-0 mb-2" data-cy="lead-location">
              <span class="grey--text">
                <small>
                  Details of the specific asbestos removal work area(s)
                </small>
              </span>
              <tiptap-vuetify
                :extensions="extensions"
                label="Details of the specific asbestos removal work area(s)"
                v-model="template.clearance.details"
                name="details"
                class="sec-div"
                :class="{ 'error-div': showWorkDetailsErr }"
                placeholder="Enter details of the specific asbestos removal work area(s)"
              />

              <div
                class="v-text-field__details mt-2 ml-2"
                v-if="showWorkDetailsErr"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      The work details is required
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" cols="12" class="pt-0">
              <v-autocomplete
                data-cy="surveyor"
                attach
                autocomplete="off"
                v-model="template.clearance.authors"
                outlined
                v-validate="'required'"
                :items="surveyors"
                item-text="full_name"
                item-value="id"
                :error-messages="errors.collect('author')"
                label="Authors"
                data-vv-name="author"
                persistent-hint
                required
                multiple
                small-chips
                chips
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col sm="3" cols="12" class="pt-0" data-cy="lead-name">
              <clearance-companies-option
                :clearance_class="template.clearance.type"
                :clearance_id="template.clearance.id"
                @saveSelectedCompanies="handleCompanies"
              />
              <clearance-removalists-option
                @saveSelectedRemovalists="handleRemovalists"
                @returnHandleCompany="companyTrigger = false"
                :selectedCompanies="selectedCompanies"
                :companyHandler="companyTrigger"
                :clearance_id="template.clearance.id"
              />
            </v-col>

            <v-col sm="6" cols="12" class="pt-2" data-cy="lead-name">
              <v-row
                v-for="(v, k) in template.clearance.inspection_dates"
                :key="k"
              >
                <v-col sm="5" cols="5" class="pt-0" data-cy="lead-name">
                  <v-menu
                    v-model="template.clearance.inspection_dates[k].d_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="
                          template.clearance.inspection_dates[k].inspect_date
                        "
                        label="Date of clearance inspection"
                        prepend-icon="fas fa-calendar"
                        outlined
                        v-bind="attrs"
                        readonly
                        v-on="on"
                        :error-messages="errors.collect('inspection date')"
                        v-validate="'required|max:190'"
                        data-vv-name="inspection date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="
                        template.clearance.inspection_dates[k].inspect_date
                      "
                      @input="
                        template.clearance.inspection_dates[k].d_menu = false
                      "
                      :max="today"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="5" cols="5" class="pt-0" data-cy="lead-link-word">
                  <v-text-field
                    prepend-icon="mdi-clock-time-four-outline"
                    label="Time of clearance inspection"
                    outlined
                    v-model="
                      template.clearance.inspection_dates[k].inspect_time
                    "
                    value="12:30"
                    type="time"
                    :error-messages="errors.collect('inspection time')"
                    v-validate="'required|max:190'"
                    data-vv-name="inspection time"
                  ></v-text-field>
                </v-col>
                <v-col
                  sm="2"
                  cols="2"
                  v-if="template.clearance.inspection_dates.length > 1"
                >
                  <v-btn text @click="removeInspection(k)">
                    <v-icon color="red">fas fa-trash</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    @click="addInspectionDate()"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>fas fa-calendar-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add inspection date/time</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="11" cols="12">
              <span class="grey--text">
                <small>
                  Declaration text
                </small>
              </span>
              <tiptap-vuetify
                :extensions="extensions"
                label="Declaration text"
                v-model="template.clearance.declaration_text"
                name="details"
                class="sec-div"
                :class="{ 'error-div': showDeclarationErr }"
                placeholder="Declaration text on the document"
              />

              <div
                class="v-text-field__details mt-2 ml-2"
                v-if="showDeclarationErr"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      Declaration is required
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col sm="1" cols="12" :align-self="'end'">
              <v-btn
                :loading="isSaving"
                v-if="!this.template.clearance.id"
                color="primary"
                class="float-right"
                @click="createNewClearance"
                :disabled="!isFinishAble"
              >
                Save
              </v-btn>
              <v-btn
                :loading="isSaving"
                v-if="this.template.clearance.id"
                color="primary"
                class="float-right"
                @click="saveNew(1)"
                :disabled="!isFinishAble"
              >
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        :disabled="checkIfNoClearance"
        v-show="template.clearance.type == 1"
      >
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(1) }"
          >Asbestos Removal Work Documentation</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <table class="clearance-table">
            <tbody>
              <tr>
                <td>
                  Do you have a copy of the asbestos removal control plan?
                </td>
                <td>
                  <v-radio-group
                    v-model="template.information.arwp_1"
                    row
                    mandatory
                  >
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  Have all copies of the asbestos removal control plan been
                  reviewed by a suitably qualified independent person / company?
                </td>
                <td>
                  <v-radio-group
                    v-model="template.information.arwp_2"
                    row
                    mandatory
                  >
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>Do you have a copy of the notification form?</td>
                <td>
                  <v-radio-group
                    v-model="template.information.arwp_3"
                    row
                    mandatory
                  >
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  Has the enclosure been inspected or tested prior to starting
                  work?
                </td>
                <td>
                  <v-radio-group
                    v-model="template.information.arwp_4"
                    row
                    mandatory
                  >
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  Is the removal work consistent with the control plan and the
                  notification form?<br />
                  (e.g. use of enclosures, decontamination facilities, waste
                  facilities)
                </td>
                <td>
                  <v-radio-group
                    v-model="template.information.arwp_5"
                    row
                    mandatory
                  >
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <tiptap-vuetify
                    :extensions="extensions"
                    v-model="template.information.arwp_comment"
                    class="sec-div"
                    placeholder="Additional comments"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-2 float-right"
            @click="saveNew(2)"
            :loading="isSaving"
          >
            Next
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        :disabled="checkIfNoClearance"
        v-show="template.clearance.type == 1"
      >
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(2) }"
          >Compliance during Removal Work</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <table class="clearance-table">
            <tr>
              <td colspan="2" class="tbl-header">
                Air Monitoring – Adjacent to Removal Area
              </td>
            </tr>
            <tr>
              <td>
                Air monitoring was carried out during the removal work, outside
                of the removal area.<br />
                The result was below 0.01 f/ml
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_1_1"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>
                Has the air monitoring sample been analysed by an
                IANZ-accredited laboratory?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_1_2"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>Is the air monitoring report attached?</td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_1_3"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>
                Was there a breach of New Zealand Workplace Exposure Standards?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_1_4"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <tiptap-vuetify
                  :extensions="extensions"
                  v-model="template.information.cdrw_1_comment"
                  class="sec-div"
                  placeholder="Additional comments"
                />
              </td>
            </tr>
          </table>

          <br />
          <table class="clearance-table">
            <tr>
              <td colspan="2" class="tbl-header">
                Air Monitoring – Within Removal Area
              </td>
            </tr>
            <tr>
              <td>
                Personal monitoring was carried out during the removal work,
                within the work area.
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_2_1"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>
                Has the air monitoring sample been analysed by an
                IANZ-accredited laboratory?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_2_2"
                  row
                  mandatory
                  v-show="template.information.cdrw_2_1 != '0'"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
                <span
                  class="grey--text"
                  v-show="template.information.cdrw_2_1 == '0'"
                >
                  N/A
                </span>
              </td>
            </tr>
            <tr>
              <td>Is the air monitoring report attached?</td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_2_3"
                  row
                  mandatory
                  v-show="template.information.cdrw_2_1 != '0'"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
                <span
                  class="grey--text"
                  v-show="template.information.cdrw_2_1 == '0'"
                >
                  N/A
                </span>
              </td>
            </tr>
            <tr>
              <td>Were there any unanticipated anomalies?</td>
              <td>
                <v-radio-group
                  v-model="template.information.cdrw_2_4"
                  row
                  mandatory
                  v-show="template.information.cdrw_2_1 != '0'"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
                <span
                  class="grey--text"
                  v-show="template.information.cdrw_2_1 == '0'"
                >
                  N/A
                </span>
              </td>
            </tr>
          </table>

          <table class="clearance-table">
            <tr>
              <td class="tbl-header">
                Diagram of air monitoring and swab approximate sample positions
              </td>
              <td v-if="!template.information.floor_plan">
                <v-file-input
                  show-size
                  v-model="fileUp"
                  @change="fileChange($event)"
                  accept="image/*"
                  v-validate="'size:20480'"
                  :error-messages="errors.collect('floor plan')"
                  :data-vv-name="'floor plan'"
                  :label="'Upload floor plan'"
                ></v-file-input>
              </td>
            </tr>
            <tr v-if="template.information.floor_plan">
              <td class="text-center">
                <img :src="diagram_path" style="max-height: 400px;" />
                <br />

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      text
                      @click="
                        () => {
                          deleteFloorDialog = true;
                        }
                      "
                    >
                      <v-icon color="error">fas fa-times</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete diagram</span>
                </v-tooltip>
              </td>
            </tr>
          </table>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-2 float-right"
            @click="saveNew(3)"
            :loading="isSaving"
          >
            Next
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        :disabled="checkIfNoClearance"
        v-show="template.clearance.type == 2"
      >
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(3) }"
          >Asbestos Removal Work Documentation</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <table class="clearance-table">
            <tr>
              <td>
                Do you have a copy of the asbestos removal control plan which
                meets the approved code
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.arwd_1"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>
                Has the asbestos removal control plan been approved by a
                suitably qualified independent person/company?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.arwd_2"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>Do you have a copy of the notification form?</td>
              <td>
                <v-radio-group
                  v-model="template.information.arwd_3"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
          </table>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-2 float-right"
            @click="saveNew(4)"
            :loading="isSaving"
          >
            Next
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="checkIfNoClearance">
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(4) }"
          >Clearance Assessment</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <table class="clearance-table">
            <tr>
              <td colspan="2" class="tbl-header">Visual Inspection</td>
            </tr>
            <tr>
              <td>
                As a result of the asbestos removal work carried out in the
                specific areas detailed above, was there visible asbestos
                containing material remaining?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.vi_1"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>Were swab samples collected within the removal area?</td>
              <td>
                <v-radio-group
                  v-model="template.information.vi_2"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>Is air monitoring required?</td>
              <td>
                <v-radio-group
                  v-model="template.information.vi_3"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td>
                Has additional information been attached (e.g. photos, drawings,
                plans)?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.vi_4"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr>
              <td class="tbl-header">
                Prior to dismantling the enclosure checklist
              </td>
              <td>
                <v-switch v-model="template.information.pde_on"></v-switch>
              </td>
            </tr>
            <tr v-show="template.information.pde_on">
              <td>
                Was visible asbestos found within the enclosure or in the area
                immediately surrounding the enclosure?
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.pde_1"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-show="template.information.pde_on">
              <td>
                Air monitoring was carried out as part of the clearance
                inspection.<br />
                The result was below 0.01 f/ml.
              </td>
              <td>
                <v-radio-group
                  v-model="template.information.pde_2"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-show="template.information.pde_on">
              <td>Was the air disturbed during sampling?</td>
              <td>
                <v-radio-group
                  v-model="template.information.pde_3"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-show="template.information.pde_on">
              <td>Is the air monitoring report attached?</td>
              <td>
                <v-radio-group
                  v-model="template.information.pde_4"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
            <tr v-show="template.information.pde_on">
              <td>Can the enclosure be dismantled?</td>
              <td>
                <v-radio-group
                  v-model="template.information.pde_5"
                  row
                  mandatory
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
          </table>

          <table class="clearance-table">
            <tr>
              <td>
                <tiptap-vuetify
                  :extensions="extensions"
                  v-model="template.information.additional_informations"
                  class="sec-div"
                  placeholder="Additional comments"
                />
              </td>
            </tr>
          </table>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-2 float-right"
            @click="saveNew(5)"
            :loading="isSaving"
          >
            Next
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :disabled="checkIfNoClearance">
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(5) }"
          >Lab Certificates</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <clearance-lab-cert
            :clearance_id="template.clearance.id"
            @airChanged="
              () => {
                triggerAirSwab = true;
              }
            "
          />
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-2 float-right"
            @click="saveNew(6)"
            :loading="isSaving"
          >
            Next
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        :disabled="checkIfNoClearance"
        v-show="template.clearance.type == 1"
      >
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(6) }"
          >Lab Results table</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <clearance-sample-collected
            :clearance_id="template.clearance.id"
            :trigger_save="isSaving"
            :trigger_air_changes="triggerAirSwab"
            @defaultAirChanges="
              () => {
                triggerAirSwab = false;
              }
            "
            @next="saveNew(7)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="checkIfNoClearance">
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(7) }"
          >Photo Log</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <clearance-photo-log
            :trigger_save="isSaving"
            @next="saveNew(8)"
            :clearance_id="template.clearance.id"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :disabled="checkIfNoClearance">
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(8) }"
          >Notifications to WorkSafe</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <clearance-work-safe :clearance_id="template.clearance.id" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel :disabled="checkIfNoClearance" class="mb-3">
        <v-expansion-panel-header
          :class="{ 'v-expansion-panel-header-active': panel.includes(9) }"
          >Additional documents</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <clearance-additional-document
            :clearance_id="template.clearance.id"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-speed-dial v-model="fab" bottom fixed right>
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          v-if="template.clearance.id"
          color="primary"
          class="float-right elevation-0"
          direction="top"
          :disabled="!isFinishAble"
          :loading="isSaving"
          dark
          fab
        >
          <v-icon v-if="fab">
            fas fa-times
          </v-icon>
          <v-icon v-else>
            fas fa-save
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="green"
            @click="save"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>far fa-save</v-icon>
          </v-btn>
        </template>
        <span>Save current changes</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            @click="setForReview"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>fas fa-check</v-icon>
          </v-btn>
        </template>
        <span>Finish</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ClearanceCompaniesOption from "../components/clearance/ClearanceCompaniesOption";
import ClearanceRemovalistsOption from "../components/clearance/ClearanceRemovalistsOption";
import ClearancePhotoLog from "../components/clearance/ClearancePhotoLog";
import ClearanceWorkSafe from "../components/clearance/ClearanceWorkSafe";
import ClearanceSampleCollected from "../components/clearance/ClearanceSampleCollected";
import ClearanceLabCert from "../components/clearance/ClearanceLabCert";
import ClearanceAdditionalDocument from "../components/clearance/ClearanceAdditionalDocument";
import { ValidationProvider } from "vee-validate";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  components: {
    ClearanceCompaniesOption,
    ClearanceRemovalistsOption,
    ClearancePhotoLog,
    ClearanceWorkSafe,
    ClearanceSampleCollected,
    ClearanceLabCert,
    ClearanceAdditionalDocument,
    TiptapVuetify,
    ValidationProvider,
    ClearanceSampleCollected
  },
  props: ["clearance_id"],
  data() {
    return {
      panel: [0],
      template: null,
      isSaving: false,
      selectedCompanies: [],
      selectedRemovalists: [],
      menu: false,
      menu2: false,
      menu3: false,
      menu_from: false,
      menu_to: false,
      clearanceCompanies: [],
      clearanceRemovalist: [],
      building_id: null,
      survey_id: null,
      companyTrigger: false,
      fab: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      showWorkDetailsErr: false,
      showDeclarationErr: false,
      triggerAirSwab: false,
      fileUp: null,
      diagram_path: null,
      deleteFloorDialog: false
    };
  },
  computed: {
    ...mapGetters(["building", "invalidInput", "surveyors"]),
    checkIfNoClearance() {
      return !this.template.clearance.id;
    },
    today() {
      let d = new Date();
      let datestring =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2);

      return datestring;
    },
    rightNow() {
      return new Date().toLocaleTimeString();
    },
    isFinishAble() {
      return (
        this.selectedCompanies.length > 0 && this.selectedRemovalists.length > 0
      );
    }
  },
  methods: {
    ...mapActions([
      "getClearanceDataById",
      "getClearanceTemplate",
      "insertUpdateClearance",
      "generateClearance",
      "setClearanceForReview",
      "uploadClearanceFloorPlan",
      "getClearanceFloorPlan",
      "deleteClearanceFloorPlan",
      "removeClearanceFloorPlan"
    ]),
    handleCompanies(cmp) {
      this.selectedCompanies = cmp;
      this.companyTrigger = true;
    },
    handleRemovalists(rmv) {
      this.selectedRemovalists = rmv;
    },
    addInspectionDate() {
      this.template.clearance.inspection_dates.push({
        inspect_date: null,
        inspect_time: null,
        d_menu: false
      });
    },
    handleTime(k) {
      let s = "menu_" + k;
      let a = this.$refs[s][0];
      a.save(this.template.clearance.inspection_dates[k].inspect_time);
    },
    confirmDeleteFloorPlan() {
      this.deleteClearanceFloorPlan(this.template.clearance.id).then(() => {
        this.template.information.floor_plan = null;
        this.deleteFloorDialog = false;
        this.diagram_path = null;
        this.fileUp = null;
      });
    },
    removeInspection(key) {
      return this.template.clearance.inspection_dates.splice(key, 1);
    },
    saveNew(next) {
      this.panel = this.panel.filter(item => item !== next - 1);

      if (next == 1 && this.template.clearance.type == 2) {
        next = 3;
      }

      if (next == 3 && this.template.clearance.type == 1) {
        next = 4;
      }

      if (next == 6 && this.template.clearance.type == 2) {
        next = 7;
      }

      this.panel.push(next);
    },
    getPostData() {
      return {
        building_id: this.building_id,
        survey_id: this.survey_id,
        clearance: this.template.clearance,
        information: this.template.information,
        companies: this.selectedCompanies,
        removalists: this.selectedRemovalists
      };
    },
    checkIfHasWorkDetails() {
      return (
        !this.template.clearance.details ||
        this.template.clearance.details == "<p></p>"
      );
    },
    checkIfHasDeclaration() {
      return (
        !this.template.clearance.declaration_text ||
        this.template.clearance.declaration_text == "<p></p>"
      );
    },
    createNewClearance() {
      this.showWorkDetailsErr = false;
      this.showDeclarationErr = false;
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }

        if (this.checkIfHasWorkDetails()) {
          this.showWorkDetailsErr = true;
          return;
        }

        if (this.checkIfHasDeclaration()) {
          this.showDeclarationErr = true;
          return;
        }

        this.insertUpdateClearance(this.getPostData()).then(response => {
          let clearance_id = response.data.clearance_id;
          this.$router.push(`clearance/${clearance_id}/edit`);
          this.template.clearance.id = clearance_id;
          this.saveNew(1);
        });
      });
    },
    refreshFloorPlan() {
      this.getClearanceFloorPlan(this.template.clearance.id).then(response => {
        this.diagram_path = response.data.link;
      });
    },
    save() {
      this.showWorkDetailsErr = false;
      this.showDeclarationErr = false;
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }

        if (this.checkIfHasWorkDetails()) {
          this.showWorkDetailsErr = true;
          return;
        }

        if (this.checkIfHasDeclaration()) {
          this.showDeclarationErr = true;
          return;
        }

        this.isSaving = true;
        this.insertUpdateClearance(this.getPostData())
          .then(() => {
            this.isSaving = false;
          })
          .catch(() => {
            this.isSaving = false;
          });
      });
    },
    setForReview() {
      this.save();
      this.setClearanceForReview({
        clearance_id: this.template.clearance.id
      });

      this.generateClearance({
        clearance_id: this.template.clearance.id
      }).then(() => {
        this.$router.push(
          `/survey/${this.survey_id}/building/${this.building_id}`
        );
      });
    },
    fileChange(event) {
      if (!this.fileUp) {
        return;
      }
      let title = event.name;
      this.uploadClearanceFloorPlan({
        clearance_id: this.template.clearance.id,
        title: title,
        fileUp: this.fileUp
      })
        .then(response => {
          this.template.information.floor_plan = response.data.title;
          this.refreshFloorPlan();
        })
        .catch(() => {
          this.isSaving = false;
        });
    }
  },
  mounted() {
    this.building_id = this.$route.params.building;
    this.survey_id = this.$route.params.survey;
    if (this.$route.params.clearance) {
      this.getClearanceDataById(this.$route.params.clearance).then(response => {
        this.template = response.data.template;
        this.selectedRemovalists = response.data.removalists;
        this.selectedCompanies = response.data.companies;
        this.refreshFloorPlan();
      });
    } else {
      this.getClearanceTemplate(this.building_id).then(response => {
        this.template = response.data.template;
        this.selectedRemovalists = [];
        this.selectedCompanies = [];
      });
    }
  }
};
</script>
<style scoped>
.v-expansion-panel-content {
  margin-top: 30px !important;
}

.clearance-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.clearance-table td,
.clearance-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.clearance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.clearance-table td:nth-child(1) {
  width: 80%;
}
.clearance-table td:nth-child(2) {
  text-align: center;
}
.tbl-header {
  color: #1565c0;
  font-weight: bold;
}

.clearance-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #94959c;
  color: white;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #1565c0 !important;
}

.v-expansion-panel-header-active {
  background: #1565c0;
  color: white;
}

.error-div {
  border: #ff5722 solid 2px !important;
}

.sec-div {
  border: #9e9e9e solid 1px;
  border-radius: 2px;
}
</style>
