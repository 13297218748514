<template>
  <v-row>
    <v-col>
      <tour tourKey="floor_create" />
      <v-row>
        <v-col class="py-0">
          <h1>
            <span class="floor-create-step pr-3">
              {{ $route.params.floor ? "Edit" : "Create" }}
              Floor
            </span>
          </h1>
          <p class="text--secondary pt-0 mt-0">
            Input related floor data as part of the building
          </p>
        </v-col>
      </v-row>
      <floor-form :aFloor.sync="floor"></floor-form>
      <v-row>
        <v-col>
          <v-btn
            class="float-left"
            color="greyed"
            depressed
            @click="$router.go(-1)"
            >Back</v-btn
          >
          <v-btn
            color="primary"
            class="float-right"
            depressed
            v-if="$route.params.floor"
            @click="updateFloor()"
            data-cy="update"
            >Update</v-btn
          >
          <v-btn
            color="primary"
            depressed
            data-cy="submit"
            class="float-right"
            v-else
            @click="submit()"
            >Create</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FloorForm from "./FloorForm";
import Tour from "./Tour";
export default {
  components: {
    FloorForm,
    Tour
  },
  computed: {
    ...mapGetters(["invalidInput"])
  },
  mounted() {
    if (this.$route.params.floor) {
      this.getFloor(this.$route.params.floor).then(response => {
        this.floor = response;
      });
    }
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    floor: {
      building_id: null,
      name: null,
      file: null,
      image: null,
      order: null
    }
  }),
  methods: {
    ...mapActions(["createFloor", "editFloor", "getFloor", "setLoading"]),
    updateFloor() {
      const params = this.$route.params;
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.editFloor(this.floor).then(() => {
          this.$validator.reset();
          this.$router.push(
            "/survey/" + params.survey + "/building/" + params.building
          );
        });
      });
    },
    submit() {
      this.setLoading();
      const params = this.$route.params;
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          this.setLoading(false);
          return;
        }
        this.floor.building_id = params.building;
        this.createFloor(this.floor)
          .then(() => {
            this.$router.push(
              "/survey/" + params.survey + "/building/" + params.building
            );
          })
          .finally(() => {
            this.setLoading(false);
            this.$validator.reset();
          });
      });
    }
  }
};
</script>
