<template>
  <v-row>
    <v-col v-if="buildings.length > 0">
      <v-row>
        <v-col><h2>Buildings</h2></v-col>
      </v-row>
      <v-row
        v-for="building in buildings"
        v-bind:key="building.id"
        class="mb-3"
      >
        <v-col cols="6">
          <v-row>
            <v-col class="mb-0 pb-0">
              <h1>
                <span data-cy="building-name">{{ building.name }}</span>
                <span data-cy="building-phase">{{
                  building.phase ? `- ${building.phase}` : ""
                }}</span>
                <v-chip
                  data-cy="building-status"
                  v-if="building.status_id"
                  :color="statusColors[building.status_id]"
                  :key="`building-status-${building.status_id}`"
                  dark
                  class="ml-4 mb-2"
                >
                  <span>
                    {{ building.status_name }}
                  </span>
                </v-chip>
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-0 pt-0">
              <v-tooltip top v-if="building.is_asbestos">
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="building.asbestos_approval ? 'blue' : 'gray'"
                    class="pr-2"
                    v-on="on"
                    >fal fa-stroopwafel</v-icon
                  >
                </template>
                <span v-if="building.asbestos_approval">Asbestos approved</span>
                <span v-if="!building.asbestos_approval"
                  >Asbestos needs review</span
                >
              </v-tooltip>
              <v-tooltip top v-if="building.is_lead">
                <template v-slot:activator="{ on }">
                  <v-icon
                    :color="building.lead_approval ? 'green' : 'gray'"
                    v-on="on"
                    >fas fa-fill-drip</v-icon
                  >
                </template>
                <span v-if="building.lead_approval">Lead approved</span>
                <span v-if="!building.lead_approval">Lead needs review</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="view-building"
                    class="mx-1"
                    fab
                    color="primary"
                    v-on="on"
                    @click="goto(building)"
                  >
                    <v-icon>fas fa-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>View building</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <h3 data-cy="building-full-add">
            {{ building.full_address }}
          </h3>
          <h4 class="ma-0 pa-0" data-cy="building-job-num">
            {{ building.job_number }}
          </h4>
          <p class="font-weight-light" data-cy="building-des">
            {{ building.description }}
          </p>
          <p
            class="font-weight-light"
            data-cy="building-des"
            v-if="!$route.params.building"
          >
            <strong style="font-size: 1.2em; color: #0058a1">{{
              building.report_type
            }}</strong>
          </p>
          <!-- <the-building class="pa-4" v-bind:aBuilding="building"></the-building> -->
        </v-col>
        <v-col cols="6">
          <a-img
            data-cy="image-display"
            :src="`/api/image/building/${building.id}?updated=${Math.random()}`"
          ></a-img>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-row>
        <v-col
          ><h2 data-cy="no-buildings" class="text-center sub-section-header">
            There are no buildings
          </h2></v-col
        >
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import TheBuilding from "./TheBuilding.vue";
import BuildingButtons from "./BuildingButtons.vue";

export default {
  components: {
    TheBuilding,
    BuildingButtons,
  },
  computed: {
    ...mapGetters([
      "surveys",
      "getUserById",
      "getOfficeById",
      "buildings",
      "statusColors",
    ]),
  },
  methods: {
    goto(building) {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${building.id}`
      );
    },
  },
};
</script>
