<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-window v-model="step" touchless>
            <v-window-item :value="1">
              <item-details
                ref="itemDetails"
                :aItem.sync="item"
                :buildingItems="buildingItems"
                :building="building"
              ></item-details>
            </v-window-item>
            <v-window-item :value="2">
              <polygon-menu
                :url="`/api/image/floor/${$route.params.floor}/image`"
                v-if="$route.params.floor"
                :aItem.sync="item"
                :single="true"
                :tools="[1]"
                :color="false"
              ></polygon-menu>
              <v-row v-if="polygonInvalid" class="mt-2">
                <v-col class="pa-2 warning center-text rounded">
                  <p class="pt-2" data-cy="warning-polygon-text">
                    Please place a polygon
                  </p>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="3" v-if="!skipScoring"
              ><item-survey
                @nextStep="step++"
                :aItem.sync="item"
                :sampledAsItem="sampledAsItem"
              ></item-survey
            ></v-window-item>
            <v-window-item :value="4" v-if="!skipRecommandation">
              <item-recommendation
                :aItem.sync="item"
                :sampledAsItem="sampledAsItem"
                @save="$emit('save')"
                @update="$emit('update')"
                ref="recommendations"
              ></item-recommendation>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            @click="back()"
            color="greyed"
            depressed
            data-cy="back"
            v-if="step != 1"
            class="float-left"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            v-if="
              step < 3 || (step == 3 && item.item_scores.every((score) => score != 0))
            "
            :disabled="nextDisabled"
            @click="next"
            data-cy="next"
            class="float-right"
            >Next</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-btn
            v-if="$route.params.item"
            :disabled="invalidSave"
            color="primary"
            depressed
            @click.native="update"
            data-cy="update"
            >Update</v-btn
          >
          <v-btn
            v-else
            color="primary"
            :disabled="saveDisabled"
            depressed
            @click.native="save"
            data-cy="submit"
            >Save</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ItemDetails from "./ItemDetails";
import PolygonMenu from "./PolygonDrawerMenu";
import ItemSurvey from "./ItemSurvey";
import ItemRecommendation from "./ItemRecommendation";
import ASSESSMENT_METHODS from "../constants/assessmentMethods";

export default {
  components: {
    ItemDetails,
    PolygonMenu,
    ItemSurvey,
    ItemRecommendation,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  created() {
    this.NEGATIVE = 2;
  },
  mounted() {
    if (
      this.item.assessment_method_id > ASSESSMENT_METHODS.PREVIOUSLY_SAMPLED ||
      !this.item.assessment_method_id
    ) {
      this.item.assessment_method_id = ASSESSMENT_METHODS.SAMPLED;
    }
  },
  props: ["aItem", "aStep", "buildingItems", "building"],
  data: () => ({
    polygonInvalid: false,
    invalidSave: true,
  }),
  computed: {
    ...mapGetters(["floor", "invalidInput"]),
    skipScoring() {
      return (
        this.item.presumed_sample.sample_status_id == 2 ||
        this.item.presumed_sample.sample_status_id == 13
      );
    },
    skipRecommandation() {
      return (
        this.item.presumed_sample.sample_status_id == 2 ||
        this.item.presumed_sample.sample_status_id == 13
      );
    },
    saveDisabled() {
      if (this.aStep == 1) return true;
      if (this.aStep == 2 && this.skipScoring && this.skipRecommandation) return false;
      if (
        this.aItem.item_recommendations.length == 0 &&
        this.aItem.assessment_method_id == 6 &&
        this.aItem.reported_sample.status_id == 2
      )
        return false;
      return this.aItem.item_recommendations.length == 0;
    },
    nextDisabled() {
      if (this.aStep == 1) return false;
      else if (this.aStep == 2 && this.skipScoring && this.skipRecommandation) {
        return true;
      } else
        return (
          this.aItem.reported_sample.status_id == 2 &&
          this.aItem.item_recommendations.length != 0
        );
    },
    step: {
      get() {
        return this.aStep;
      },
      set(updated) {
        this.$emit("update:aStep", updated);
      },
    },
    sampledAsItem() {
      if (!this.item || !this.item.sampled_as || !this.item.sampled_as.item_as_id) return;
      return this.buildingItems.find(
        (item) => item.id == this.item.sampled_as.item_as_id
      );
    },
    item: {
      get() {
        return this.aItem;
      },
      set(updated) {
        this.$emit("update:aItem", updated);
      },
    },
  },
  watch: {
    step(updated) {
      if (
        (this.item.assessment_method_id == ASSESSMENT_METHODS.INACCESSIBLE ||
          this.item.assessment_method_id == ASSESSMENT_METHODS.NOT_SUSPICIOUS) &&
        updated == 4
      ) {
        this.invalidSave = false;
      } else if (this.aStep == 2 && this.skipScoring && this.skipRecommandation) {
        this.invalidSave = false;
      }
    },
    "item.reported_sample.status_id"(updated, outdated) {
      if (updated == this.NEGATIVE) {
        this.item.item_scores = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45];
        this.item.amount = 0;
      } else if (outdated) {
        this.item.item_scores = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      }
    },
    "item.assessment_method_id"(updated, outdated) {
      if (
        outdated == ASSESSMENT_METHODS.INACCESSIBLE &&
        (this.item.item_scores.length != 12 ||
          this.item.item_scores.find((aItem) => aItem == 0) != undefined)
      ) {
        this.invalidSave = true;
        return;
      }
      this.invalidSave = false;
    },
    "item.item_scores"(updated) {
      if (
        this.item.assessment_method_id != ASSESSMENT_METHODS.INACCESSIBLE &&
        updated.length == 12 &&
        updated.find((aItem) => aItem == 0) == undefined
      ) {
        this.invalidSave = false;
        return;
      } else if (this.aStep == 2 && this.skipScoring && this.skipRecommandation) {
        this.invalidSave = false;
      } else {
        this.invalidSave = true;
      }
    },
  },
  methods: {
    update() {
      if (this.$route.params.item) {
        this.$emit("update");
        return;
      }
      this.noRecommendation =
        this.item.item_recommendations.length == 0 &&
        this.$refs.recommendations.getRiskRating() != "Negative" &&
        !this.room?.is_not_suspicious;
      if (this.noRecommendation) {
        return;
      }
      this.$emit("update");
    },
    save() {
      this.noRecommendation =
        this.skipRecommandation &&
        this.item.item_recommendations.length == 0 &&
        !this.room?.is_not_suspicious;
      if (this.noRecommendation && this.saveDisabled) {
        return;
      }
      this.$emit("save");
    },
    validationCheck() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        if (this.step == 2 && this.item.layer.polygons.length == 0) {
          this.polygonInvalid = true;
          return;
        }
        if (
          this.step == 2 &&
          (this.item.assessment_method_id == ASSESSMENT_METHODS.INACCESSIBLE ||
            this.item.assessment_method_id == ASSESSMENT_METHODS.NOT_SUSPICIOUS)
        ) {
          this.step = 4;
        } else {
          this.step++;
        }
        this.$vuetify.goTo(1);
      });
    },
    back() {
      if (
        this.step == 4 &&
        (this.item.assessment_method_id == ASSESSMENT_METHODS.INACCESSIBLE ||
          this.item.assessment_method_id == ASSESSMENT_METHODS.NOT_SUSPICIOUS)
      ) {
        this.step = 2;
      } else {
        this.step--;
      }
    },
    next() {
      this.$refs.itemDetails.validate().then((result) => {
        if (result) {
          this.validationCheck();
        }
      });
    },
  },
};
</script>
