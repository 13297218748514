import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px","transition":"fade-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Change Building Status")]),_c(VCardText,{attrs:{"data-cy":"building-statuses"}},[_c(VAutocomplete,{attrs:{"label":"Building statuses","items":_vm.buildingStatuses,"item-text":"name","item-value":"id","item-disabled":_vm.disableReviewed},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","text":"","data-cy":"confirm-status"},on:{"click":_vm.confirm}},[_vm._v("Confirm")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }