<template>
  <v-row v-if="room">
    <v-col>
      <tour tourKey="room" />
      <v-row>
        <v-col class="pt-0" cols="12" md="6">
          <h1>
            {{ room.name }}
            <v-tooltip
              bottom
              v-show="
                room.is_inaccessible ||
                room.is_not_suspicious ||
                !room.is_scoped
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-show="room.is_inaccessible"
                  fab
                  icon
                  small
                  color="blue lighten-1"
                  v-on="on"
                  class="white--text"
                  @click.stop
                >
                  <v-icon class="white--text" color="warning" :on="on"
                    >fas fa-exclamation-circle</v-icon
                  >
                </v-btn>
                <v-btn
                  v-show="room.is_not_suspicious"
                  fab
                  icon
                  small
                  color="blue lighten-1"
                  v-on="on"
                  class="white--text"
                  @click.stop
                >
                  <v-icon>fa fa-exclamation-circle</v-icon>
                </v-btn>
                <v-btn
                  v-show="!room.is_scoped"
                  fab
                  icon
                  small
                  color="purple lighten-3"
                  v-on="on"
                  class="white--text"
                  @click.stop
                >
                  <v-icon>fas fa-exclamation-circle</v-icon>
                </v-btn>
              </template>
              <span v-if="room.is_inaccessible">Inaccessible</span>
              <span v-if="room.is_not_suspicious">No PACM Observed</span>
              <span v-if="!room.is_scoped">Out of scope</span>
            </v-tooltip>
          </h1>
          <p class="preline">{{ room.note }}</p>
          <v-row>
            <v-col>
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="edit-room"
                    v-on="on"
                    fab
                    color="primary"
                    @click="editRoom()"
                    class="mr-1"
                  >
                    <v-icon>fas fa-pen</v-icon>
                  </v-btn>
                </template>
                <span>Edit Room</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="duplicate-room"
                    fab
                    v-on="on"
                    color="info"
                    @click="showDuplicateDialog()"
                    :disabled="duplicating"
                    class="mx-1"
                  >
                    <v-icon>fas fa-copy</v-icon>
                  </v-btn>
                </template>
                <span>Duplicate Room</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="delete-room"
                    v-on="on"
                    fab
                    color="warning"
                    @click="deleteDialogShow = true"
                    class="mx-1"
                  >
                    <v-icon>fas fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>Delete Room</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="survey && building">
            <v-col>
              <h2>Room information</h2>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td><p class="body-1 ma-0 pa-0">Survey</p></td>
                    <td>
                      <p class="body-1 ma-0 pa-0">{{ survey.name }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td><p class="body-1 ma-0 pa-0">Building</p></td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ building.name }}
                        {{ building.phase ? `- ${building.phase}` : "" }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Items</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ room.items_count }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-0 mt-n7" cols="12" md="6">
          <v-row v-if="room && !room.is_drawing">
            <v-col>
              <a-img
                style="max-width: 700px; margin: auto"
                :src="`/api/image/room/${$route.params.room}/image?updated=${room.updated_at}`"
              ></a-img>
            </v-col>
          </v-row>
          <v-row v-if="room && room.is_drawing">
            <v-col class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-progress-circular
                    v-on="on"
                    :attrs="attrs"
                    :size="80"
                    :width="5"
                    color="primary"
                    class="my-16 py-16"
                    indeterminate
                  ></v-progress-circular>
                </template>
                <span>Image generating... Please check again later</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <room-item-table
            :key="room.updated_at"
            v-on:itemduplicate="showDuplicateItem"
            :reloadItems.sync="reloadItems"
          ></room-item-table>
          <duplicate-dialog
            v-bind:dialog.sync="duplicateShow"
            :model="'Room'"
            :text="'name'"
            :items="floors"
            :name.sync="duplicateName"
            :value.sync="duplicateFloor"
            v-on:duplicate="duplicate"
            :label="'Floor'"
          ></duplicate-dialog>
          <duplicate-dialog
            v-bind:dialog.sync="duplicateItemDialog"
            :model="'Item'"
            :text="'floor_name'"
            :items="buildingRooms"
            :name.sync="duplicateItemName"
            :value.sync="duplicateItemRoom"
            v-on:duplicate="processDuplicateItem"
            :label="'Floor - Room'"
          ></duplicate-dialog>
          <delete-dialog
            :dialog.sync="deleteDialogShow"
            :model="'Room'"
            v-on:delete="deleteSelected()"
          ></delete-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RoomItemTable from "./RoomItemTable.vue";
import DeleteDialog from "./DeleteDialog.vue";
import DuplicateDialog from "./DuplicateDialog.vue";
import Tour from "./Tour.vue";
export default {
  components: {
    RoomItemTable,
    DeleteDialog,
    DuplicateDialog,
    Tour,
  },
  mounted() {
    this.setLoading();
    this.getRoom(this.$route.params.room).then(() => {
      this.setLoading(false);
    });
    this.getBuildingRooms(this.$route.params.building).then((res) => {
      this.buildingRooms = res.data;
    });
  },
  data: () => ({
    search: null,
    image: null,
    duplicating: false,
    duplicateShow: false,
    duplicateItemDialog: false,
    duplicateName: null,
    duplicateItemName: null,
    duplicateItemRoom: null,
    duplicateFloor: null,
    duplicateItemId: null,
    deleteDialogShow: false,
    buildingRooms: [],
    reloadItems: false,
  }),
  computed: {
    ...mapGetters([
      "room",
      "getItemTable",
      "item",
      "rooms",
      "floor",
      "floors",
      "building",
      "survey",
    ]),
  },
  watch: {
    room() {
      this.setLoading(false);
    },
  },
  methods: {
    ...mapActions([
      "getItem",
      "setItem",
      "deleteItem",
      "removeItem",
      "duplicateRoom",
      "duplicateItem",
      "deleteRoom",
      "checkDrawing",
      "getBuildingRooms",
      "setLoading",
      "getRoom",
    ]),
    showDuplicateDialog() {
      this.duplicateName = this.room.name;
      this.duplicateFloor = this.floor.id;
      this.duplicateShow = true;
    },
    deleteSelected() {
      const routes = this.$route.params;
      this.deleteRoom(routes.room).then(() => {
        this.$router.push(
          `/survey/${routes.survey}/building/${routes.building}/floor/${routes.floor}`
        );
      });
    },
    duplicate() {
      this.duplicating = true;
      this.duplicateRoom({
        id: this.$route.params.room,
        floor_id: this.duplicateFloor,
        name: this.duplicateName,
      }).then(() => {
        this.duplicating = false;
        this.duplicateShow = false;
      });
    },
    showDuplicateItem(item) {
      this.duplicateItemId = item.id;
      this.duplicateItemName = item.name;
      this.duplicateItemRoom = item.room_id;
      this.duplicateItemDialog = true;
    },
    processDuplicateItem() {
      this.duplicateItem({
        id: this.duplicateItemId,
        room_id: this.duplicateItemRoom,
        name: this.duplicateItemName,
      }).then(() => {
        this.duplicating = false;
        this.duplicateItemDialog = false;
        this.reloadItems = true;
      });
    },
    deleteSelectedItem(item) {
      this.deleteItem(item.id).then(() => {
        this.removeItem(item.id);
      });
    },
    editItem(item) {
      this.setItem({ ...item });
      this.$router.push({ path: `item/${item.id}`, append: true });
    },
    editRoom() {
      const routes = this.$route.params;
      this.$router.push(
        `/survey/${routes.survey}/building/${routes.building}/floor/${routes.floor}/room/${routes.room}/edit`
      );
    },
  },
};
</script>
