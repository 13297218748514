<template>
  <v-row justify="center">
    <v-col>
      <v-row>
        <v-col class="py-0 my-0">
          <h1>Account setting</h1>
        </v-col> </v-row
      ><br />
      <v-form data-cy="user-form" v-if="userJ.id">
        <v-row>
          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="userJ.first_name"
              outlined
              v-validate="'required|max:100'"
              :error-messages="errors.collect('first_name')"
              label="First Name"
              data-vv-name="first_name"
              required
            ></v-text-field>
          </v-col>

          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="userJ.last_name"
              outlined
              v-validate="'required|max:100'"
              :error-messages="errors.collect('last_name')"
              label="Last Name"
              data-vv-name="last_name"
              required
            ></v-text-field>
          </v-col>

          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              outlined
              v-model="userJ.email"
              v-validate="'required|email'"
              :error-messages="errors.collect('email')"
              label="E-mail"
              data-vv-name="email"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="userJ.phone"
              outlined
              v-validate="'required|max:100'"
              :error-messages="errors.collect('phone')"
              label="Phone"
              data-vv-name="phone"
              required
            ></v-text-field>
          </v-col>
          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="userJ.position"
              outlined
              v-validate="'required|max:100'"
              :error-messages="errors.collect('position')"
              label="Position"
              data-vv-name="position"
              required
            ></v-text-field>
          </v-col>

          <v-col sm="4" cols="12" class="pt-0" data-cy="lead-name">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="userJ.license_no"
              outlined
              label="License number"
            ></v-text-field>
          </v-col>
        </v-row>
                <v-row>
          <v-col cols="12" class="pt-0" data-cy="lead-name">
            <v-checkbox
              v-model="userJ.bohs_ip402_qualification"
              :label="`Qualification: BOHS IP402 - Surveying and Sampling Strategies for Asbestos in Buildings (or approved equivalent)`"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0" data-cy="lead-name">
            <v-textarea
              auto-grow
              rows="3"
              autocomplete="off"
              spellcheck="true"
              v-model="userJ.qualifications"
              outlined
              label="Additional Qualifications"
            ></v-textarea>
          </v-col>
          </v-row>
        <v-row>
          <v-col cols="12" class="pt-0" data-cy="lead-name">
            <v-file-input
              outlined
              v-model="file"
              :label="
                userJ.signature
                  ? `${userJ.first_name} ${userJ.last_name} signature`
                  : 'Upload signature'
              "
              data-cy="user-signature-upload"
            >
            </v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" cols="12" class="pt-0" data-cy="lead-name">
            <mail-settings :user_id="userJ.id" />
            <v-switch v-model="userJ.tutorials_enabled">
              <template v-slot:label> Enable tutorials </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row justify="center" style="max-width: 500px; margin: auto">
          <v-col>
            <a-img v-if="file && displayImage" :src="displayImage"></a-img>
            <a-img v-else :src="`/api/image/user/${userJ.id}/signature`"></a-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="greyed"
              depressed
              class="float-left"
              @click="$router.go(-1)"
              >Back</v-btn
            >
            <v-btn
              data-cy="user-update"
              color="primary"
              class="float-right"
              @click="userUpdate"
              >Update</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MailSettings from "./MailSettings.vue";
import Loader from "./Loader.vue";
export default {
  components: {
    Loader,
    MailSettings,
  },
  data: () => ({
    userJ: {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      position: null,
      qualifications: null,
      license_no: null,
      roles: [],
      signature: null,
      tutorials_enabled: 1,
    },
    reader: null,
    uploadReader: null,
    file: null,
  }),
  computed: {
    ...mapGetters(["invalidInput"]),
  },
  mounted() {
    if (this.user) {
      this.getUserById(this.user.id).then((response) => {
        this.userJ = response.data;
      });
    }
    this.reader = new FileReader();
    this.reader.onload = (e) => {
      this.uploadReader = e.target.result;
    };
  },
  methods: {
    ...mapActions(["updateUser", "getUserById", "setUser", "refreshUser"]),
    userUpdate() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.userJ.roles.forEach(this.checkRoleObject);
        this.updateUser({ ...this.userJ, signature: this.file }).then(
          (response) => {
            this.$auth.fetch();
            this.refreshUser(this.user.id);
          }
        );
      });
    },
    checkRoleObject(item, index) {
      if (item === Object(item)) {
        this.userJ.roles[index] = item.id;
      }
    },
  },
  computed: {
    ...mapGetters(["user", "roles"]),
    displayImage() {
      if (this.reader && this.file) {
        this.reader.readAsDataURL(this.file);
      }
      return this.uploadReader;
    },
  },
};
</script>
