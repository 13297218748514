<template>
  <loader :value="isLoading"></loader>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader.vue";
export default {
  name: "AuthCallback",
  components: {
    Loader,
  },
  data: () => ({
    isLoading: true,
  }),
  async mounted() {
    try {
      this.getCsrfCookie();
      const response = await this.handleCallback();
      if (response.data.error) {
        this.$router.push("/login");
        return;
      }
      this.resetState();
      // Set token
      const authToken = response.headers.get("Authorization").split(" ")[1];
      this.$auth.token(null, authToken);

      // Fetch user data and set user
      const userResponse = await this.$auth.fetch();
      this.setUser(userResponse.data);

      // Navigate to dashboard
      this.$router.push("/dashboard");
    } catch (error) {
      this.$router.push("/login");
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "resetState",
      "setUser",
      "getCsrfCookie",
      "getLoginData",
      "setLoading",
    ]),
    async handleCallback() {
      const response = this.axios.post("/api/auth/callback", {
        code: this.$route.query.code,
        state: this.$route.query.state,
      });
      return response;
    },
  },
};
</script>
