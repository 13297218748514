import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,[_c(VWindow,{attrs:{"touchless":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VWindowItem,{attrs:{"value":1}},[_c('item-details',{ref:"itemDetails",attrs:{"aItem":_vm.item,"buildingItems":_vm.buildingItems,"building":_vm.building},on:{"update:aItem":function($event){_vm.item=$event},"update:a-item":function($event){_vm.item=$event}}})],1),_c(VWindowItem,{attrs:{"value":2}},[(_vm.$route.params.floor)?_c('polygon-menu',{attrs:{"url":`/api/image/floor/${_vm.$route.params.floor}/image`,"aItem":_vm.item,"single":true,"tools":[1],"color":false},on:{"update:aItem":function($event){_vm.item=$event},"update:a-item":function($event){_vm.item=$event}}}):_vm._e(),(_vm.polygonInvalid)?_c(VRow,{staticClass:"mt-2"},[_c(VCol,{staticClass:"pa-2 warning center-text rounded"},[_c('p',{staticClass:"pt-2",attrs:{"data-cy":"warning-polygon-text"}},[_vm._v(" Please place a polygon ")])])],1):_vm._e()],1),(!_vm.skipScoring)?_c(VWindowItem,{attrs:{"value":3}},[_c('item-survey',{attrs:{"aItem":_vm.item,"sampledAsItem":_vm.sampledAsItem},on:{"nextStep":function($event){_vm.step++},"update:aItem":function($event){_vm.item=$event},"update:a-item":function($event){_vm.item=$event}}})],1):_vm._e(),(!_vm.skipRecommandation)?_c(VWindowItem,{attrs:{"value":4}},[_c('item-recommendation',{ref:"recommendations",attrs:{"aItem":_vm.item,"sampledAsItem":_vm.sampledAsItem},on:{"update:aItem":function($event){_vm.item=$event},"update:a-item":function($event){_vm.item=$event},"save":function($event){return _vm.$emit('save')},"update":function($event){return _vm.$emit('update')}}})],1):_vm._e()],1)],1)],1),_c(VRow,[_c(VCol,[(_vm.step != 1)?_c(VBtn,{staticClass:"float-left",attrs:{"color":"greyed","depressed":"","data-cy":"back"},on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")]):_vm._e(),_c(VSpacer),(
            _vm.step < 3 || (_vm.step == 3 && _vm.item.item_scores.every((score) => score != 0))
          )?_c(VBtn,{staticClass:"float-right",attrs:{"depressed":"","color":"primary","disabled":_vm.nextDisabled,"data-cy":"next"},on:{"click":_vm.next}},[_vm._v("Next")]):_vm._e()],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-center"},[(_vm.$route.params.item)?_c(VBtn,{attrs:{"disabled":_vm.invalidSave,"color":"primary","depressed":"","data-cy":"update"},nativeOn:{"click":function($event){return _vm.update.apply(null, arguments)}}},[_vm._v("Update")]):_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saveDisabled,"depressed":"","data-cy":"submit"},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }