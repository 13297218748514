<template>
  <div class="d-inline-block mx-1">
    <v-speed-dial v-model="fab">
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          direction="top"
          dark
          fab
        >
          <v-icon v-if="fab">
            fas fa-times
          </v-icon>
          <v-icon v-else>
            fas fa-file-download
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn fab v-on="on" color="primary" small @click="show = true">
          <v-icon>fas fa-file-import</v-icon>
        </v-btn>
      </template>
      <span>Request a Management Plan</span>
    </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
          fab
          dark
          small
          color="primary"
          :href="`#/survey/${building.survey_id}/building/${building.id}/management-plans`"
          v-on="on"
        >
            <v-icon>fas fa-history</v-icon>
          </v-btn>
        </template>
        <span>View all</span>
      </v-tooltip>
    </v-speed-dial>
    <v-dialog
      v-model="show"
      v-if="building"
      persistent
      max-width="900"
      scrollable
    >
      <v-card v-show="showTnC">
        <v-card-title
          >Terms and Conditions for the use of the CCS software</v-card-title
        >
        <v-card-text>
          <terms-conditions />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showTnC = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-show="!showTnC">
        <v-card-title>Request an Asbestos Management Plan</v-card-title>
        <v-card-text>
          <v-row>
            <v-col><strong>Job ID:</strong></v-col>
            <v-col>{{ survey.job_number }}</v-col>
            <v-col><strong>Price:</strong></v-col>
            <v-col> ${{ price }}</v-col>
          </v-row>
          <v-row>
            <v-col><strong>Building name:</strong></v-col>
            <v-col> {{ building.name }} </v-col>
            <v-col><strong>Type:</strong></v-col>
            <v-col>
              {{ building.is_residential ? "Residential" : "Commercial" }}
            </v-col>
          </v-row>

          <v-row>
            <v-col><strong>Client:</strong></v-col>

            <v-col v-if="survey && getClientById(survey.client_id)">
              {{ getClientById(survey.client_id).name }}</v-col
            >
            <v-col v-if="company"><strong>Company:</strong></v-col>
            <v-col v-if="company"> {{ company.name }}</v-col>
          </v-row>

          <v-row>
            <v-col><strong>PCBU</strong></v-col>

            <v-col>
              <v-text-field
                spellcheck="true"
                label="Name"
                outlined
                v-model.lazy="contacts.pcbu.name"
                v-validate="'required|max:190'"
                maxlength="190"
                :error-messages="errors.collect('pcbu name')"
                data-vv-name="pcbu name"
                placeholder="Enter name"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                spellcheck="true"
                label="Contact number"
                outlined
                v-model.lazy="contacts.pcbu.number"
                v-validate="'required|max:190'"
                maxlength="190"
                :error-messages="errors.collect('pcbu contact number')"
                data-vv-name="pcbu contact number"
                placeholder="Enter contact number"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                spellcheck="true"
                label="Email"
                outlined
                v-model.lazy="contacts.pcbu.email"
                v-validate="'required|email|max:190'"
                maxlength="190"
                :error-messages="errors.collect('pcbu email')"
                data-vv-name="pcbu email"
                placeholder="Enter email"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col><strong>Building Manager</strong></v-col>

            <v-col>
              <v-text-field
                spellcheck="true"
                label="Name"
                outlined
                v-model.lazy="contacts.bm.name"
                v-validate="'required|max:190'"
                maxlength="190"
                :error-messages="errors.collect('building manager name')"
                data-vv-name="building manager name"
                placeholder="Enter name"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                spellcheck="true"
                label="Contact number"
                outlined
                v-model.lazy="contacts.bm.number"
                v-validate="'required|max:190'"
                maxlength="190"
                :error-messages="
                  errors.collect('building manager contact number')
                "
                data-vv-name="building manager contact number"
                placeholder="Enter contact number"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                spellcheck="true"
                label="Email"
                outlined
                v-model.lazy="contacts.bm.email"
                v-validate="'required|email|max:190'"
                maxlength="190"
                :error-messages="errors.collect('building manager email')"
                data-vv-name="building manager email"
                placeholder="Enter email"
                messages="*Required"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <strong>
                Turnaround time
              </strong>
            </v-col>
            <v-col cols="8">
              <v-select
                v-model="immediate"
                :items="[
                  { caption: 'Next business day', value: 0 },
                  { caption: '2 hour', value: 1 }
                ]"
                item-text="caption"
                item-value="value"
                outlined
                style="max-width:200px"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox class="mt-0" v-model="rememberDetails">
                <template v-slot:label>
                  <div>
                    Remember contact information for this client.
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox class="mt-0" v-model="agree_tnc" :disabled="disable">
                <template v-slot:label>
                  <div>
                    I accept the fee of ${{ price }} and I agree with the
                    <a href="#" @click="showTnC = true" @click.stop
                      >terms and conditions.</a
                    >
                  </div>
                </template>
              </v-checkbox>
              <v-alert v-if="immediate" dense outlined type="info">
                Please note: 2 hour turnaround time are billed at double the
                standard rate.
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="disable" justify="center">
            <v-icon x-large class="fa-spin">fas fa-circle-notch</v-icon>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="disable || !agree_tnc"
            @click="sendRequest"
            >Submit</v-btn
          >
          <v-btn text @click="show = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TermsConditions from "./TermsConditions.vue";
export default {
  props: ["building"],
  components: { TermsConditions },
  data: () => ({
    fab: false,
    agree_tnc: false,
    price: 0,
    immediate: 0,
    disable: false,
    show: false,
    showTnC: false,
    rememberDetails: true,
    contacts: {
      pcbu: {
        name: null,
        number: null,
        email: null
      },
      bm: {
        name: null,
        number: null,
        email: null
      }
    }
  }),
  computed: {
    ...mapGetters([
      "survey",
      "client",
      "company",
      "getClientById",
      "invalidInput"
    ]),
    defaultPrice() {
      return this.building.is_residential ? 150 : 350;
    }
  },
  mounted() {
    this.price = this.defaultPrice;
    this.getContacts();
  },
  watch: {
    immediate(updated) {
      this.price = updated ? this.defaultPrice * 2 : this.defaultPrice;
    }
  },
  methods: {
    ...mapActions(["requestManagementPlan", "getManagementPlanContacts", "getManagementPlans"]),
    getContacts() {
      this.getManagementPlanContacts(this.building.id).then(result => {
        if (result.data.contacts) {
          this.contacts = result.data.contacts;
          this.rememberDetails = true;
        } else {
          this.rememberDetails = false;
        }
      });
    },
    sendRequest() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.disable = true;
        this.requestManagementPlan({
          is_immediate: this.immediate,
          building_id: this.building.id,
          contacts: this.contacts,
          remember_contacts: this.rememberDetails
        })
          .then(() => {
            this.disable = false;
            this.agree_tnc = false;
            this.immediate = false;
            this.show = false;
          })
          .catch(() => {
            this.disable = false;
          });
      });
    }
  }
};
</script>
