<template>
  <div>
    <v-btn color="primary" @click="show = true">Mail setting</v-btn>
    <v-dialog v-model="show" persistent max-width="400" scrollable>
      <v-card>
        <v-card-title>Mail setting</v-card-title>
        <v-card-text>
          <v-list-item
            v-for="(setting, skey) in user_mail_settings"
            :key="skey"
          >
            <v-switch
              v-model="user_mail_settings[skey].enabled"
              :label="setting.description"
            ></v-switch>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="greyed" @click="close">Close</v-btn>
          <v-btn color="primary" @click="update">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["user_id"],
  data: () => ({
    show: false,
    user_mail_settings: [],
  }),
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getUserMailSettings", "setUserMailSettings"]),
    close() {
      this.show = false;
    },
    load() {
      this.getUserMailSettings(this.user_id).then((response) => {
        this.user_mail_settings = response.data.user_mail_settings;
      });
    },
    update() {
      this.setUserMailSettings({
        user_id: this.user_id,
        settings: this.user_mail_settings,
      }).then(() => {
        this.load();
        this.close();
      });
    },
  },
};
</script>
  