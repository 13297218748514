import { render, staticRenderFns } from "./ReportTemplate.vue?vue&type=template&id=87c867fe&scoped=true"
import script from "./ReportTemplate.vue?vue&type=script&lang=js"
export * from "./ReportTemplate.vue?vue&type=script&lang=js"
import style0 from "./ReportTemplate.vue?vue&type=style&index=0&id=87c867fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.96.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87c867fe",
  null
  
)

export default component.exports