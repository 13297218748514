<template>
  <v-dialog :value="show" persistent max-width="800px">
    <v-card>
      <v-card-title
        ><v-col class="text-center">
          <h2 class="h2">Create Client</h2>
        </v-col>
      </v-card-title>
      <v-card-text>
        <client-update :dialog="true" @close="close"></client-update>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientUpdate from "./ClientUpdate";
export default {
  props: ["show"],
  components: {
    ClientUpdate
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
