<template>
  <v-row>
    <v-col class="py-0 my-0">
      <tour tourKey="building" />
      <the-building
        v-bind:aBuilding.sync="building"
        v-if="building"
        @updated="updated"
      ></the-building>
      <building-floors
        @updated="updated"
        v-if="building && building.report_type != 'Clearance Certificate'"
      ></building-floors>
      <clearance-certificates
        @updated="updated"
        v-if="building && building.report_type == 'Clearance Certificate'"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import TheBuilding from "../components/TheBuilding.vue";
import BuildingFloors from "../components/BuildingFloors.vue";
import ClearanceCertificates from "../components/clearance/ClearanceCertificates";
import Tour from "../components/Tour.vue";

export default {
  components: {
    TheBuilding,
    BuildingFloors,
    ClearanceCertificates,
    Tour,
  },
  data: () => ({
    building: null,
  }),
  mounted() {
    this.updated();
  },
  methods: {
    ...mapActions(["getBuilding"]),
    updated() {
      this.getBuilding(this.$route.params.building).then((aBuilding) => {
        this.building = aBuilding;
      });
    },
  },
};
</script>
