<template>
  <v-row class="pa-0 ma-0">
    <v-col class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0">
          <v-breadcrumbs
            large
            v-if="$route.name"
            :items="routes"
            data-cy="breadcrumbs-item"
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.to"
                :exact="item.exact"
                :disabled="item.disabled"
              >
                <v-tooltip bottom v-if="item.tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span data-cy="menu-clients" v-on="on" :attrs="attrs">{{
                      item.text
                    }}</span>
                  </template>
                  <span>{{ item.tooltip }}</span>
                </v-tooltip>
                <span data-cy="survey" v-else>{{ item.text }}</span>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col data-cy="building-info" class="px-9 py-0 my-0" justify="center">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  watch: {
    routes(to) {
      if (!this.$route.name) return;
      if (!to.find((route) => route.text == this.$route.name)) {
        to.push({
          text: this.$route.name,
          tooltip: null,
          exact: true,
          disabled: true,
          to: { name: this.$route.name },
        });
      }
    },
  },
  computed: {
    ...mapGetters(["drawer", "building", "survey", "floor", "room", "client"]),
    routes() {
      const items = [];
      for (let [key] of Object.entries(this.$route.params)) {
        switch (key) {
          case "survey":
            items.push({
              text: "Survey",
              tooltip: this.survey.name,
              exact: true,
              disabled: this.$route.name == "Survey",
              to: { name: "Survey" },
            });
            break;
          case "building":
            items.push({
              text: "Building",
              tooltip: this.building.name,
              exact: true,
              disabled: this.$route.name == "Building",
              to: { name: "Building" },
            });
            break;
          case "floor":
            items.push({
              text: "Floor",
              tooltip: this.floor.name,
              exact: true,
              disabled: this.$route.name == "Floor",
              to: { name: "Floor" },
            });
            break;
          case "room":
            items.push({
              text: "Room",
              tooltip: this.room.name,
              exact: true,
              disabled: this.$route.name == "Room",
              to: { name: "Room" },
            });
            break;
          case "item":
            items.push({
              text: "Item",
              exact: true,
              disabled: this.$route.name == "Item",
              to: { name: "Edit Item" },
            });
            break;
          case "client":
            items.push({
              text: "Clients",
              tooltip: this.client.name,
              exact: true,
              disabled: this.$route.name == "Client",
              to: { name: "Clients" },
            });
            break;
          case "clientExport":
            items.push({
              text: "Export",
              exact: true,
              disabled: this.$route.name == "Export",
              to: { name: "Export" },
            });
            break;
          case "buildingExport":
            items.push({
              text: "Client Building",
              exact: true,
              disabled: this.$route.name == "Client Building",
              to: { name: "Client Building" },
            });
            break;
          case "laboratory":
            items.push({
              text: "Laboratories",
              exact: true,
              disabled: this.$route.name == "Laboratories",
              to: { name: "Laboratories" },
            });
            items.push({
              text: "Edit Laboratory",
              exact: true,
              disabled: this.$route.name == "Edit Laboratory",
              to: { name: "Edit Laboratory" },
            });
            break;
          case "user":
            items.push({
              text: "Users",
              exact: true,
              disabled: this.$route.name == "Users",
              to: { name: "Users" },
            });
            items.push({
              text: "Edit User",
              exact: true,
              disabled: this.$route.name == "Edit User",
              to: { name: "Edit User" },
            });
            break;
          case "office":
            items.push({
              text: "Offices",
              exact: true,
              disabled: this.$route.name == "Offices",
              to: { name: "Offices" },
            });
            items.push({
              text: "Edit Office",
              exact: true,
              disabled: this.$route.name == "Edit Office",
              to: { name: "Edit Office" },
            });
            break;
          default:
            items.push({
              text: this.$route.name,
              exact: true,
              disabled: true,
              to: { name: this.$route.name },
            });
            break;
        }
      }

      return items;
    },
  },
};
</script>
