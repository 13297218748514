<template>
  <v-row justify="center">
    <v-col v-if="theClient || !isEdit" md="10" sm="10">
      <v-row>
        <v-col data-cy="client-form">
          <v-form v-if="client" data-cy="client-name">
            <v-text-field
              autocomplete="new-password"
              spellcheck="true"
              v-model="client.name"
              outlined
              v-validate="'required|max:100'"
              counter
              maxlength="100"
              :error-messages="errors.collect('name')"
              label="Name"
              data-vv-name="name"
              required
              class="mb-2"
            ></v-text-field>
            <address-form
              data-cy="client-address"
              :addresses="client.address"
              v-bind="client.address"
              v-on:update:city="setClientAddress($event, 'city')"
              v-on:update:suburb="setClientAddress($event, 'suburb')"
              v-on:update:postcode="setClientAddress($event, 'postcode')"
              v-on:update:address="setClientAddress($event, 'address')"
              v-on:update:address2="setClientAddress($event, 'address2')"
              v-bind:clearAddress.sync="clearAddress"
            ></address-form>
            <v-select
              v-if="isEdit"
              label="Primary contact"
              v-model="client.primary_id"
              :items="contacts"
              item-text="full_name"
              item-value="id"
              outlined
            ></v-select>
            <v-row>
              <v-col class="text-center " style="max-width:400px;margin:auto;">
                <a-img
                  class="border-1"
                  v-if="uploaded && displayImage"
                  :src="displayImage"
                ></a-img>
                <a-img
                  class="border-1"
                  v-else-if="client.image && client.id"
                  :src="
                    `/api/image/client/${client.id}?updated=${theClient.updated_at}`
                  "
                ></a-img>
              </v-col>
            </v-row>
            <v-file-input
              data-cy="client-image-upload"
              v-model="uploaded"
              label="Image"
            ></v-file-input>
          </v-form>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-btn color="warning" class="float-left" @click="back">Back</v-btn>
          <v-btn
            v-if="isEdit"
            color="primary"
            class="float-right"
            @click="updateClient"
            >Update</v-btn
          >
          <v-btn
            v-else
            color="primary"
            class="float-right"
            @click="updateClient"
            data-cy="client-create"
            >Create</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <loader :value="theClient"></loader>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddressForm from "./AddressForm.vue";
import Loader from "./Loader.vue";

export default {
  props: ["dialog"],
  components: {
    AddressForm,
    Loader
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  mounted() {
    this.getContacts(this.theClient.id);
    this.reader = new FileReader();
    this.reader.onload = e => {
      this.image = e.target.result;
    };
  },
  data: () => ({
    clearAddress: false,
    uploaded: null,
    image: null,
    client: {
      name: null,
      image: null,
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
        address2: null
      }
    }
  }),
  methods: {
    ...mapActions(["editClient", "createClient", "getContacts"]),
    setClientAddress(data, field) {
      this.client.address[field] = data;
    },
    updateClient() {
      this.client.image = this.uploaded || null;
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        if (this.isEdit) {
          this.editClient(this.client).then(() => {
            this.back();
          });
        } else {
          this.createClient(this.client).then(response => {
            if (!this.dialog) {
              this.createContact(response.data.id);
              this.back();
            } else {
              this.$emit("close");
            }
          });
        }
      });
    },
    back() {
      if (!this.dialog) this.$router.push(`/clients`);
      this.$emit("close");
    },
    createContact(id) {
      this.$router.push(`/client/${id}/contact`);
    }
  },
  computed: {
    ...mapGetters({
      theClient: "client",
      invalidInput: "invalidInput",
      contacts: "contacts"
    }),
    displayImage() {
      if (this.reader) {
        this.reader.readAsDataURL(this.uploaded);
      }
      return this.image;
    },
    isEdit() {
      return this.$route.params.client;
    }
  },
  watch: {
    theClient(updated) {
      if (this.isEdit) {
        this.getContacts(updated.id);
        this.client = updated;
      }
    }
  }
};
</script>
