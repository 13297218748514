<template>
  <div>
    <tour tourKey="room_create" />
    <v-row>
      <v-col>
        <v-row>
          <v-col class="py-0 my-0">
            <h1>
              <span class="room-create-step pr-3">
                {{ $route.params.room ? "Edit" : "Create" }} Room
              </span>
            </h1>
            <p class="text--secondary pt-0 mt-0">
              {{ $route.params.room ? "Edit" : "Set up" }} a room as part of the
              floor
            </p>
          </v-col>
        </v-row>
        <room-form
          :aRoom.sync="room"
          ref="roomForm"
          :building="building"
          @setValidForm="(check) => (validForm = check)"
        ></room-form>
        <v-row>
          <v-col>
            <v-btn
              class="float-left"
              color="greyed"
              depressed
              @click="$router.go(-1)"
              >Back</v-btn
            >
            <v-btn
              color="primary"
              class="float-right"
              :disabled="!validForm"
              @click="submit()"
              data-cy="submit"
              >{{ $route.params.room ? "Update" : "Create" }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RoomForm from "./RoomForm";
import Tour from "./Tour";
export default {
  components: {
    RoomForm,
    Tour,
  },
  mounted() {
    if (this.$route.params.room) {
      this.getRoom(this.$route.params.room).then((response) => {
        this.room = { ...response };
      });
    }
    this.room.floor_id = this.$route.params.floor;
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    room: {
      floor_id: null,
      file: null,
      order: null,
      name: null,
      polygon: null,
      image: null,
      note: "",
      is_inaccessible: false,
      is_not_suspicious: false,
      is_scoped: true,
      label: false,
      layer: {
        polygons: [],
        labels: [],
      },
    },
    validForm: false,
  }),
  computed: {
    ...mapGetters(["invalidInput", "building"]),
  },
  methods: {
    ...mapActions(["createRoom", "editRoom", "getRoom", "setLoading"]),
    goToFloor() {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${this.$route.params.building}/floor/${this.$route.params.floor}`
      );
    },
    submit() {
      this.setLoading();
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          this.setLoading(false);
          return;
        }
        if (this.room.label && this.room.layer.labels.length == 0) {
          this.$refs.roomForm.invalidLabel = true;
          this.setLoading(false);
          return;
        }
        if (this.room.is_inaccessible && this.room.layer.polygons.length == 0) {
          this.$refs.roomForm.invalidPolygon = true;
          this.setLoading(false);
          return;
        }
        this.room.floor_id = this.$route.params.floor;
        if (!this.$route.params.room) {
          this.createRoom(this.room)
            .then(() => {
              this.$validator.reset();
              this.goToFloor();
            })
            .finally(() => {
              this.setLoading(false);
            });
        }
        if (this.$route.params.room) {
          this.editRoom(this.room)
            .then(() => {
              this.$validator.reset();
              this.goToFloor();
            })
            .finally(() => {
              this.setLoading(false);
            });
        }
      });
    },
  },
};
</script>
