export default {
  methods: {
    addFileOptimization(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onerror = () => {
          URL.revokeObjectURL(this.src);
          console.error("Cannot load image");
          return reject;
        };
        img.onload = () => {
          URL.revokeObjectURL(this.src);
          const maxWidth = 1200;
          const maxHeight = 1200;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }
          const [newWidth, newHeight] = [width, height];
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          canvas.toBlob(
            blob => {
              blob.lastModified = file.lastModified;
              blob.name = file.name;
              let reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                return resolve(reader.result);
              }
            },
            "image/jpeg",
            0.7
          );
        };
        const blobURL = URL.createObjectURL(file);
        img.src = blobURL;
      });
    }
  }
};
