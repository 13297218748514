import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.show = true}}},[_vm._v("Mail setting")]),_c(VDialog,{attrs:{"persistent":"","max-width":"400","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Mail setting")]),_c(VCardText,_vm._l((_vm.user_mail_settings),function(setting,skey){return _c(VListItem,{key:skey},[_c(VSwitch,{attrs:{"label":setting.description},model:{value:(_vm.user_mail_settings[skey].enabled),callback:function ($$v) {_vm.$set(_vm.user_mail_settings[skey], "enabled", $$v)},expression:"user_mail_settings[skey].enabled"}})],1)}),1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"greyed"},on:{"click":_vm.close}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.update}},[_vm._v("Update")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }