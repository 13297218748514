<template>
  <div>
    <v-select
      v-if="item.contaminate_id == ASBESTOS"
      class="pt-5"
      :items="sampleStatuses.filter(aSample => aSample.contaminate_id == ASBESTOS)"
      v-model="sample"
      item-text="name"
      item-value="id"
      label="Status"
    ></v-select>
    <v-text-field
      v-if="item.contaminate_id == LEAD"
      spellcheck="true"
      @change.native="updateAnalysisField"
      :label="analysisProp.label"
      single-line
      :value="analysis"
      hide-details
      suffix="mg/kg"
      :disabled="analysisProp.disabled"
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ANALYSIS_TYPES from "../constants/analysisTypes";
export default {
  props: ["item"],
  created() {
    this.ASBESTOS = 1;
    this.LEAD = 2;
  },
  mounted() {
    this.sample = this.item.sample.status.id;
  },
  data: () => ({
    sample: null
  }),
  methods: {
    ...mapActions(["updateSample", "updateLabAnalysis"]),
    updateAnalysisField(input) {
      this.updateLabAnalysis({
        data: { id: this.item.lead.id, analysis:  input.target.value },
        building_id: this.building.id,
      });
    }
  },
  computed: {
    ...mapGetters(["sampleStatuses", "building"]),
    analysis() {
      let def_analysis = null;
      if (this.item.lead) {
        switch (this.item.sample.analysis_type_id) {
          case ANALYSIS_TYPES.XRF:
            def_analysis = this.item.lead.xrf_result;
          break;
          case ANALYSIS_TYPES.LABORATORY_RESULT:
            def_analysis = this.item.lead.laboratory_analysis;
          break;
        }
      }
      return def_analysis;
    },
    analysisProp() {
      let def = {
        disabled:false, label: 'Please select analysis type'
      };
      switch (this.item.sample.analysis_type_id) {
        case ANALYSIS_TYPES.XRF:
        case ANALYSIS_TYPES.LABORATORY_RESULT:
          def.label = 'Enter result in PPM';
        break;
        default:
          def.disabled = true;
        break;
      }
      return def;
    }
  },
  watch: {
    sample(updated, previous) {
      if (previous != null && updated != previous) {
        this.updateSample({
          data: { id: this.item.sample.id, status_id: updated },
          building_id: this.building.id,
        });
      }
    }
  },
};
</script>
