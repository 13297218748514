import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import "@fortawesome/fontawesome-pro/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    options: {
      customProperties: true,
      cspNonce: "bcd891a01e01f20cc198818bfbd12063",
    },
    themes: {
      light: {
        primary: {
          base: colors.blue.darken3,
          text: colors.shades.black,
          a: colors.shades.black,
        },
        secondary: colors.indigo.base,
        accent: colors.lightGreen.lighten,
        error: colors.deepOrange.base,
        warning: colors.amber.darken3,
        info: colors.teal.lighten1,
        success: colors.green.accent4,
        greyed: colors.grey.lighten2,
      },
      dark: {
        primary: {
          base: colors.blue.darken3,
          text: colors.shades.black,
          a: colors.shades.black,
        },
        secondary: "#FFFF00",
        accent: "#FFFF00",
        error: "#FFFF00",
        warning: "#FFFF00",
        info: "#FFFF00",
        success: "#FFFF00",
      },
    },
  },
});
