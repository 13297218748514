<template>
  <v-row class="my-0 py-0" v-if="aBuilding">
    <v-col cols="12" align="left" data-cy="actions">
      <v-speed-dial
        style="display: inline"
        v-model="fab"
        direction="top"
        v-if="!isClearanceCert && building.is_asbestos && building.is_lead"
      >
        <template v-slot:activator>
          <v-tooltip bottom v-if="generatedCheck">
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                color="red"
                class="mr-1 white--text generate-report-step"
                dark
                v-on="on"
                data-cy="generate-report"
              >
                <v-icon v-if="fab"> fas fa-times </v-icon>
                <v-icon v-else> fas fa-sync </v-icon>
              </v-btn>
            </template>
            <span>Generate report</span>
          </v-tooltip>
        </template>
        <v-tooltip right v-if="generatedCheck">
          <template v-slot:activator="{ on }">
            <v-btn
              data-cy="generate-asbestos"
              fab
              dark
              small
              color="blue"
              @click="generate"
              v-on="on"
            >
              <v-icon>fal fa-stroopwafel </v-icon>
            </v-btn>
          </template>
          <span>Asbestos</span>
        </v-tooltip>
        <v-tooltip right v-if="generatedCheck">
          <template v-slot:activator="{ on }">
            <v-btn
              data-cy="generate-lead"
              fab
              dark
              small
              color="green"
              @click="generateLead"
              v-on="on"
            >
              <v-icon>fas fa-fill-drip</v-icon>
            </v-btn>
          </template>
          <span>Lead</span>
        </v-tooltip>
      </v-speed-dial>
      <v-tooltip
        bottom
        v-if="generatedCheck && building.is_asbestos && !building.is_lead && building.report_type_id != CLEARANCE_REPORT"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            data-cy="generate-asbestos"
            fab
            dark
            color="red"
            @click="generate"
            v-on="on"
          >
            <v-icon>fas fa-sync</v-icon>
          </v-btn>
        </template>
        <span>Generate asbestos report</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="generatedCheck && !building.is_asbestos && building.is_lead && building.report_type_id != CLEARANCE_REPORT"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            data-cy="generate-lead"
            fab
            dark
            color="red"
            @click="generateLead"
            v-on="on"
          >
            <v-icon>fas fa-sync</v-icon>
          </v-btn>
        </template>
        <span>Generate lead report</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="
          aBuilding.asbestos_report_status == 'active' ||
          aBuilding.lead_report_status == 'active'
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn fab v-on="on" class="mr-1 report-generating-step">
            <v-icon class="fa-spin">fas fa-spinner-third</v-icon>
          </v-btn>
        </template>
        <span data-cy="generate-report">Report generating</span>
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="
          (aBuilding.asbestos_report_status == 'queued' ||
          aBuilding.lead_report_status == 'queued') && (
            aBuilding.asbestos_report_status != 'active' ||
          aBuilding.lead_report_status != 'active'
          )
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn
            data-cy="report-queued"
            fab
            v-on="on"
            class="mr-1 report-queued-step"
          >
            <v-icon>fas fa-th-list</v-icon>
          </v-btn>
        </template>
        <span>Report queued</span>
      </v-tooltip>

      <building-download-button
        :completed="
          aBuilding.asbestos_report_status == 'completed' &&
          aBuilding.lead_report_status == 'completed'
        "
        :building="$route.params.building ? building : the_building"
        v-if="aBuilding && !isClearanceCert"
      ></building-download-button>

      <management-plan-dialog
        :building="aBuilding"
        v-if="isReviewed && !isClearanceCert && aBuilding.is_asbestos"
      />

      <v-tooltip bottom v-if="$route.params.building && !isReviewed">
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            v-on="on"
            color="secondary"
            @click="$router.push(`${$route.params.building}/edit`)"
            data-cy="edit-building"
            class="mx-1 white--text edit-building-step"
          >
            <v-icon>fas fa-pen</v-icon>
          </v-btn>
        </template>
        <span>Edit building</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$route.params.building && !isClearanceCert">
        <template v-slot:activator="{ on }">
          <v-btn
            data-cy="building-duplicate"
            fab
            v-on="on"
            color="info"
            :disabled="duplicating"
            @click="showDuplicate()"
            class="mx-1 white--text duplicate-building-step"
          >
            <v-icon>fas fa-copy</v-icon>
          </v-btn>
        </template>
        <span>Duplicate building</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$route.params.building && !isReviewed">
        <template v-slot:activator="{ on }">
          <v-btn
            data-cy="delete-building"
            fab
            v-on="on"
            color="warning"
            @click="deleteDialogShow = true"
            class="mx-1 white--text"
          >
            <v-icon>fas fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <span>Delete building</span>
      </v-tooltip>
      <v-row>
        <duplicate-dialog
          v-if="!isClearanceCert"
          v-bind:dialog.sync="duplicateShow"
          :model="'Building'"
          :text="activeSurveyName"
          :items="activeSurveys"
          :name.sync="duplicateName"
          :value.sync="duplicateSurvey"
          v-on:duplicate="duplicate"
          :label="'Survey'"
        ></duplicate-dialog>
        <delete-dialog
          :dialog.sync="deleteDialogShow"
          :model="'Building'"
          v-on:delete="deleteSelected"
        ></delete-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DuplicateDialog from "./DuplicateDialog.vue";
import DeleteDialog from "./DeleteDialog.vue";
import ManagementPlanDialog from "./ManagementPlanDialog.vue";
import BuildingDownloadButton from "./BuildingDownloadButton";

export default {
  components: {
    DuplicateDialog,
    DeleteDialog,
    ManagementPlanDialog,
    BuildingDownloadButton,
  },
  created() {
    this.CLEARANCE_REPORT = 6;
  },
  data: () => ({
    aBuilding: null,
    duplicating: false,
    duplicateShow: false,
    duplicateName: null,
    duplicateSurvey: null,
    deleteDialogShow: false,
    activeSurveys: [],
    fab: false,
  }),
  props: {
    the_building: {
      required: false,
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.aBuilding = this.$route.params.building
      ? this.building
      : this.the_building;
  },
  methods: {
    ...mapActions([
      "generateReport",
      "getActiveSurveys",
      "deleteBuilding",
      "duplicateBuilding",
    ]),
    goto(building) {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${building.id}`
      );
    },
    generate() {
      this.generateReport({
        building: this.aBuilding.id,
        contaminate_id: 1,
      });
    },
    generateLead() {
      this.generateReport({
        building: this.aBuilding.id,
        contaminate_id: 2,
      });
    },
    showDuplicate() {
      this.getActiveSurveys().then((response) => {
        this.activeSurveys = [...response.data, { ...this.survey }];
        this.status = this.building.status_id;
        this.duplicateSurvey = this.$route.params.survey;
      });
      this.duplicateName = this.building.name;
      this.duplicateShow = true;
    },
    duplicate(survey = null) {
      this.duplicateShow = false;
      this.duplicating = true;
      const request = {
        id: this.$route.params.building,
        name: this.duplicateName,
        survey_id: this.duplicateSurvey,
        survey: { ...survey },
      };
      this.duplicateBuilding(request);
    },
    activeSurveyName(item) {
      return `${item.job_number} - ${item.name}`;
    },
    deleteSelected() {
      this.deleteBuilding(this.$route.params.building).then(() => {
        this.$router.push(`/survey/${this.$route.params.survey}`);
      });
    },
    deleteModal() {
      this.deleteDialog = true;
    },
  },
  computed: {
    ...mapGetters(["building"]),
    isClearanceCert() {
      return (
        this.aBuilding.report_type &&
        this.aBuilding.report_type == "Clearance Certificate"
      );
    },
    generatedCheck() {
      if (
        (this.aBuilding.asbestos_report_status &&
          this.aBuilding.asbestos_report_status == "active") ||
        this.aBuilding.asbestos_report_status == "queued" ||
        (this.aBuilding.lead_report_status &&
          this.aBuilding.lead_report_status == "active") ||
        this.aBuilding.lead_report_status == "queued"
      ) {
        return false;
      }
      return true;
    },
    isReviewed() {
      return this.aBuilding.status_name == "Reviewed";
    },
  },
};
</script>