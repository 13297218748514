import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Edit "+_vm._s(_vm.showType)+" Note")]),_c(VCardText,[_c(VForm,[_c(VTextarea,{attrs:{"data-cy":"note-field","autocomplete":"off"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","data-cy":"save-note","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }