import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VApp,[(!_vm.$auth.check() || _vm.$route.meta.noNav)?_c(VMain,[_c(VContainer,{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('router-view')],1)],1):_c('div',[(!_vm.show2fa)?_c('drawer',{attrs:{"drawer":_vm.drawer},on:{"update:drawer":function($event){_vm.drawer=$event}}}):_vm._e(),_c('app-bar',{attrs:{"drawer":_vm.drawer},on:{"update:drawer":function($event){_vm.drawer=$event}}}),_c('main-body'),_c(VFooter,{staticClass:"mt-10"},[_c(VRow,[_c(VCol,{staticClass:"text-center text--primary"},[_vm._v(" Copyright © "+_vm._s(new Date().getFullYear())+" Datanest Software Limited | "),_c('a',{staticClass:"text-decoration-none text--secondary",attrs:{"href":"#"},on:{"click":function($event){_vm.showTnC = true}}},[_vm._v("Terms and Conditions")]),_c('terms-conditions-dialog',{attrs:{"show":_vm.showTnC},on:{"closeDialog":() => (_vm.showTnC = false)}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }