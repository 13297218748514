<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="400px"
    transition="fade-transition"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text data-cy="approve-building-message">
        <p v-if="!status">{{ message }}</p>
        <v-textarea
          data-cy="deny-message"
          label="Needs edit message"
          v-if="deny"
          v-model="denyMessage"
        ></v-textarea>
        <v-autocomplete
          label="Building statuses"
          v-if="status"
          :items="buildingStatuses"
          item-text="name"
          item-value="id"
          v-model="buildingStatus"
        ></v-autocomplete>
        <v-checkbox
          v-model="asbestos"
          data-cy="checkbox-approve-asbestos"
          v-if="
            approve &&
              !deny &&
              model &&
              model.survey_id &&
              model.asbestos_count > 0
          "
          label="Approve asbestos"
          class="my-0"
        >
        </v-checkbox>
        <v-checkbox
          v-model="lead"
          v-if="
            approve && !deny && model && model.survey_id && model.lead_count > 0
          "
          label="Approve lead"
          class="my-0"
        >
        </v-checkbox>
      </v-card-text>
      <v-card-actions v-if="close">
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Close</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Cancel</v-btn>
        <v-btn color="primary" text @click="confirm" data-cy="confirm"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: [
    "dialog",
    "title",
    "message",
    "method",
    "deny",
    "status",
    "model",
    "close"
  ],
  data: () => ({
    approve: {
      asbestos: false,
      lead: false
    }
  }),
  methods: {
    confirm() {
      if (this.model) {
        if (this.model.survey_id) {
          this.method(this.model, { ...this.approve });
        } else {
          this.method(this.model);
        }
      } else {
        this.method();
      }
      this.$emit("confirm");
      this.show = false;
    }
  },
  computed: {
    ...mapGetters(["buildingStatuses"]),
    asbestos: {
      get() {
        return this.approve.asbestos;
      },
      set(updated) {
        this.approve.asbestos = updated;
      }
    },
    lead: {
      get() {
        return this.approve.lead;
      },
      set(updated) {
        this.approve.lead = updated;
      }
    },
    denyMessage: {
      get() {
        return this.deny;
      },
      set(updated) {
        this.$emit("update:deny", updated);
      }
    },
    show: {
      get() {
        return this.dialog;
      },
      set(updated) {
        this.$emit("update:dialog", updated);
        if (updated) {
          if (this.model && this.model.approval) {
            this.approve = this.model.approval;
          }
        }
      }
    },
    buildingStatus: {
      get() {
        return this.status;
      },
      set(updated) {
        this.$emit("update:status", updated);
      }
    }
  },
  watch: {
    dialog() {
      if (this.model && this.model.survey_id && this.model.approvals) {
        this.approve.asbestos = this.model.approvals.asbestos;
        this.approve.lead = this.model.approvals.lead;
      }
    }
  }
};
</script>
