import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Delete "+_vm._s(_vm.model))]),_c(VCardText,[_c('p',{attrs:{"data-cy":"delete-dialog"}},[_vm._v("Are you sure you want to delete this "+_vm._s(_vm.model)+"?")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","text":"","data-cy":"confirm"},on:{"click":_vm.deleteSelected}},[_vm._v("Confirm")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }