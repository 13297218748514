<template>
  <v-row v-cloak v-if="floor && floor.information">
    <v-col>
      <v-row>
        <v-col cols="12" md="6">
          <h1 data-cy="floor-name">{{ floor.name }}</h1>
          <p class="preline">{{ floor.description }}</p>
          <v-row>
            <v-col class="mt-0 pt-0">
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    color="secondary"
                    @click="$router.push(`${$route.params.floor}/edit`)"
                    data-cy="edit-floor-btn"
                    class="mr-1"
                  >
                    <v-icon>fas fa-pen</v-icon>
                  </v-btn>
                </template>
                <span>Edit Floor</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="duplicate-floor"
                    v-on="on"
                    fab
                    color="info"
                    :disabled="duplicating"
                    @click="showDuplicateDialog()"
                    class="mx-1"
                  >
                    <v-icon>fas fa-copy</v-icon>
                  </v-btn>
                </template>
                <span>Duplicate Floor</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="delete-floor"
                    v-on="on"
                    fab
                    color="warning"
                    @click="deleteDialog = true"
                    class="mx-1"
                  >
                    <v-icon>fas fa-trash-alt</v-icon>
                  </v-btn>
                </template>
                <span>Delete Floor</span>
              </v-tooltip>
            </v-col>
            <v-col cols="1" class="mt-0 pt-0">
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right redraw-floor-step"
                    v-if="
                      floor.information.items !== 0 ||
                      floor.information.rooms !== 0
                    "
                    v-on="on"
                    fab
                    :disabled="!!floor.is_drawing"
                    color="color"
                    @click="redrawFloorPolygons()"
                  >
                    <v-icon>fas fa-paint-roller</v-icon>
                  </v-btn>
                </template>
                <span>Redraw floor polygons</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="floor && floor.information">
            <v-col cols="12" v-if="floor.id">
              <h2 class="mb-0">Floor information</h2>
              <v-simple-table class="elevation-1">
                <tbody>
                  <tr>
                    <td><p class="body-1 ma-0 pa-0">Job Number</p></td>
                    <td>
                      <p class="body-1 ma-0 pa-0">{{ survey.job_number }}</p>
                    </td>
                    <td><p class="body-1 ma-0 pa-0">Building</p></td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ building.name }}
                        {{ building.phase ? `- ${building.phase}` : "" }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Rooms</p>
                    </td>
                    <td data-cy="count-room">
                      <p class="body-1 ma-0 pa-0">
                        {{ floor.information.rooms }}
                      </p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">Items</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ floor.information.items }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="building.is_asbestos">
                    <td>
                      <p class="body-1 ma-0 pa-0">Samples</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ floor.information.samples }}
                      </p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">Sampled As</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ floor.information.sampled_as }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="building.is_asbestos">
                    <td>
                      <p class="body-1 ma-0 pa-0">Presumed</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ floor.information.presumed }}
                      </p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">Inaccessible</p>
                    </td>
                    <td>
                      <p class="body-1 ma-0 pa-0">
                        {{ floor.information.inaccessible }}
                      </p>
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-0 mt-n7" cols="12" md="6">
          <div class="text-center">
            <a-img
              v-if="image && !floor.is_drawing"
              style="max-width: 700px; margin: auto"
              :src="image"
            ></a-img>
            <v-tooltip bottom v-if="floor.is_drawing">
              <template v-slot:activator="{ on, attrs }">
                <v-progress-circular
                  v-on="on"
                  :attrs="attrs"
                  :size="80"
                  :width="5"
                  color="primary"
                  class="my-16 py-16"
                  indeterminate
                ></v-progress-circular>
              </template>
              <span>Image generating... Please check again later</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="floor.id">
          <v-data-table
            v-if="rooms"
            :headers="headers"
            :items="rooms"
            :options.sync="options"
            :footer-props="rowOptions"
            @click:row="goto"
            :item-class="duplicateCheck"
            :search="search"
            sort-by="order"
            sort-desc
            class="elevation-1"
            data-cy="room-table"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6">
                    <h2><span class="rooms-step">Rooms</span></h2>
                  </v-col>
                  <v-col>
                    <delete-dialog
                      :dialog.sync="deleteDialog"
                      :model="'Floor'"
                      v-on:delete="deleteSelected"
                    ></delete-dialog>
                    <duplicate-dialog
                      v-bind:dialog.sync="duplicateShow"
                      :model="'Floor'"
                      :text="'name'"
                      :items="buildings"
                      :name.sync="duplicateName"
                      :value.sync="duplicateBuilding"
                      v-on:duplicate="duplicate()"
                      :label="'Building'"
                    ></duplicate-dialog>
                    <v-text-field
                      data-cy="search-room"
                      spellcheck="true"
                      aria-autocomplete="no"
                      v-model.lazy="search"
                      append-icon="fas fa-search"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-tooltip bottom open-delay="300">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        @click="createRoom"
                        dark
                        small
                        fab
                        v-on="on"
                        data-cy="create-new-room"
                      >
                        <v-icon>fas fa-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Create Room</span>
                  </v-tooltip>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="item.is_inaccessible"
                    fab
                    icon
                    x-small
                    color="warning"
                    v-on="on"
                    class="white--text float-right"
                    @click.stop
                  >
                    <v-icon>fas fa-exclamation-circle</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="item.is_not_suspicious"
                    fab
                    icon
                    x-small
                    color="blue lighten-1"
                    v-on="on"
                    class="white--text float-right"
                    @click.stop
                  >
                    <v-icon>fa fa-exclamation-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!item.is_scoped"
                    fab
                    icon
                    x-small
                    color="purple lighten-3"
                    v-on="on"
                    class="white--text float-right"
                    @click.stop
                  >
                    <v-icon>fas fa-exclamation-circle</v-icon>
                  </v-btn>
                </template>
                <span v-if="item.is_inaccessible">Inaccessible</span>
                <span v-if="item.is_not_suspicious">No PACM Observed</span>
                <span v-if="!item.is_scoped">Out of scope</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="view-room-info"
                    fab
                    color="primary"
                    x-small
                    v-on="on"
                    @click="goto(item)"
                    class="white--text mx-1 float-right"
                    @click.stop
                  >
                    <v-icon>fas fa-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>View Room</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="edit-room"
                    fab
                    color="info"
                    x-small
                    v-on="on"
                    @click="editRoom(item)"
                    class="white--text mx-1 float-right"
                    @click.stop
                  >
                    <v-icon>fas fa-pencil-alt</v-icon>
                  </v-btn>
                </template>
                <span>Edit Room</span>
              </v-tooltip>
            </template>
            <template v-slot:item.items_count="{ item }">
              <v-chip
                data-cy="view-room"
                absolute
                right
                :color="item.items_count > 0 ? 'primary' : 'warning'"
                >{{ item.items_count }}</v-chip
              >
            </template>
            <template v-slot:item.note="{ item }">
              <p v-if="item.note">{{ item.note }}</p>
              <v-sheet color="warning lighten-3" class="w-100 h-100" v-else>
                <p>No note</p>
              </v-sheet>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DuplicateDialog from "./DuplicateDialog.vue";
import DeleteDialog from "./DeleteDialog.vue";
export default {
  components: {
    DuplicateDialog,
    DeleteDialog,
  },
  data: () => ({
    step: 1,
    image: null,
    search: null,
    duplicating: false,
    duplicateShow: false,
    duplicateName: null,
    duplicateBuilding: null,
    deleteDialog: false,
    rooms: null,
    rowOptions: { "items-per-page-options": [10, 20, 30] },
    options: {},
    headers: [
      { text: "Name", value: "name" },
      { text: "Order", value: "order" },
      { text: "Item Count", value: "items_count" },
      { text: "Note", value: "note" },
      { text: "Actions", value: "actions" },
    ],
  }),
  props: {
    floor: {
      type: Object,
    },
  },
  mounted() {
    this.setLoading(false);
    this.getBuildings(this.$route.params.survey);
    this.duplicateName = this.floor.name;
    this.duplicateBuilding = this.building.id;
    this.options = this.tableOptions;
    this.image = `/api/image/floor/${this.$route.params.floor}?${this.floor.updated_at}`;
    this.getRooms(this.$route.params.floor).then((rooms) => {
      this.rooms = rooms;
    });
  },
  methods: {
    ...mapActions([
      "duplicateFloor",
      "getBuildings",
      "deleteFloor",
      "getBuilding",
      "removeFloor",
      "redrawPolygons",
      "setLoading",
      "setTableOptions",
      "getRooms",
    ]),
    roomCheck(room) {
      return !room.is_not_suspicious && !room.is_inaccessible && room.is_scoped;
    },
    redrawFloorPolygons() {
      this.redrawPolygons(this.floor.id).then(() => {
        this.$emit("updated");
      });
    },
    showDuplicateDialog() {
      this.duplicateName = this.floor.name;
      this.duplicateShow = true;
    },
    goto(room) {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${this.$route.params.building}/floor/${this.$route.params.floor}/room/${room.id}`
      );
    },
    editRoom(room) {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${this.$route.params.building}/floor/${this.$route.params.floor}/room/${room.id}/edit`
      );
    },
    createRoom() {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${this.$route.params.building}/floor/${this.$route.params.floor}/room`
      );
    },
    duplicate() {
      this.duplicateShow = false;
      this.duplicating = true;
      this.duplicateFloor({
        id: this.$route.params.floor,
        building_id: this.duplicateBuilding,
        name: this.duplicateName,
      }).then(() => {
        this.duplicating = false;
      });
    },
    deleteSelected() {
      this.deleteFloor(this.$route.params.floor).then(() => {
        this.removeFloor(this.$route.params.floor);
        this.getBuilding(this.$route.params.building).then(() => {
          this.$router.push(
            `/survey/${this.$route.params.survey}/building/${this.$route.params.building}`
          );
        });
      });
    },
  },
  watch: {
    floor() {
      this.setLoading(false);
    },
    options(updated) {
      this.setTableOptions(updated);
    },
  },
  computed: {
    ...mapGetters([
      "buildings",
      "building",
      "survey",
      "duplicateCheck",
      "tableOptions",
    ]),
  },
};
</script>
