<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>Edit {{ showType }} Note</v-card-title>
        <v-card-text>
          <v-form>
            <v-textarea
              data-cy="note-field"
              autocomplete="off"
              v-model="note"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" data-cy="save-note" text @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["dialog", "aNote", "type"],
  data: () => ({
    note: null
  }),
  mounted() {
    this.aNote ? (this.note = this.aNote.content) : (this.note = null);
  },
  computed: {
    showType() {
      if (this.aNote) {
        return this.aNote.type.name;
      }
      return null;
    }
  },
  methods: {
    ...mapActions(["setBuildingNote", "getBuilding"]),
    close() {
      this.$emit("close");
    },
    save() {
      const building = this.$route.params.building;
      this.setBuildingNote({
        id: building,
        note: { type_id: this.type, content: this.note }
      }).finally(() => {
        this.$emit("updated");
      });
      this.close();
    }
  },
  watch: {
    'aNote.content'(updated) {
      this.note = updated;
    }
  }
};
</script>
