<template>
  <div>
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          Delete document
        </v-card-title>
        <v-card-text
          >Are you sure you want to delete this document?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="6" v-if="additional_docs && additional_docs.length > 0">
        <div>
          <h3>
            Existing additional documents
          </h3>
          <v-row v-for="(f, k) in additional_docs" :key="k">
            <v-col cols="6">
              <v-icon>
                {{ f.type == 0 ? "fas fa-file-pdf" : "fas fa-image" }}
              </v-icon>
              {{ f.name }}
            </v-col>
            <v-col cols="6">
              <v-icon @click="downloadFile(f.id)" color="secondary" class="pr-2"
                >fas fa-arrow-down</v-icon
              >
              <v-icon @click="removeFile(f.id)" color="red"
                >fas fa-times</v-icon
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6">
        <h3>Upload an Additional document</h3>
        <br />
        <v-text-field
          spellcheck="true"
          label="Document title"
          outlined
          v-model.lazy="title"
          v-validate="'required|max:190'"
          maxlength="190"
          :error-messages="errors.collect('document name')"
          data-vv-name="document name"
          placeholder="Enter title"
          messages="*Required"
          required
        ></v-text-field
        ><br />
        <v-file-input
          v-model="fileUp"
          @change="onFileChange($event)"
          accept=".pdf,.jpg,.png,.jpeg"
          outlined
          v-validate="'size:20480'"
          :error-messages="errors.collect('document')"
          :data-vv-name="'document'"
          :disabled="!title"
          :label="!title ? 'Please add a title first' : 'Upload a document'"
        ></v-file-input>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    clearance_id: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      additional_docs: [],
      fileUp: [],
      title: "Untitled document",
      isSaving: false,
      selectedFile: null,
      deleteDialog: false
    };
  },
  methods: {
    ...mapActions([
      "getClearanceAdditionalDocs",
      "insertUpdateAdditionalDocs",
      "uploadAdditionalDocFile",
      "deleteAdditionalDocument"
    ]),
    removeFile(id) {
      this.selectedFile = id;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.deleteAdditionalDocument(this.selectedFile).then(() => {
        this.getClearanceAdditionalDocDocs();
        this.deleteDialog = false;
        this.selectedFile = null;
      });
    },
    downloadFile(id) {
      const fileLink = document.createElement("a");
      fileLink.href = `/clearance/additional-document/${id}`;
      const downloadAt = document.createAttribute("download");
      fileLink.setAttributeNode(downloadAt);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    onFileChange() {
      if (!this.fileUp) {
        return;
      }

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }

        this.isSaving = true;
        this.uploadAdditionalDocFile({
          clearance_id: this.clearance_id,
          title: this.title,
          fileUp: this.fileUp
        })
          .then(response => {
            this.isSaving = false;
            this.additional_docs = response.data.additional_docs;
            this.fileUp = null;
            this.title = "Untitled document";
          })
          .catch(() => {
            this.isSaving = false;
          });
      });
    },
    getClearanceAdditionalDocDocs() {
      this.getClearanceAdditionalDocs(this.clearance_id).then(response => {
        this.additional_docs = response.data.additional_docs;
      });
    },
    save() {
      this.isSaving = true;
      this.insertUpdateAdditionalDocs({
        additional_docs: this.additional_docs,
        clearance_id: this.clearance_id
      })
        .then(response => {
          this.additional_docs = response.data.additional_docs;
          this.isSaving = false;
        })
        .catch(() => {
          this.isSaving = false;
        });
    }
  },
  mounted() {
    this.getClearanceAdditionalDocDocs();
  }
};
</script>
