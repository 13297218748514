import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400px","transition":"fade-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardText,{attrs:{"data-cy":"approve-building-message"}},[(!_vm.status)?_c('p',[_vm._v(_vm._s(_vm.message))]):_vm._e(),(_vm.deny)?_c(VTextarea,{attrs:{"data-cy":"deny-message","label":"Needs edit message"},model:{value:(_vm.denyMessage),callback:function ($$v) {_vm.denyMessage=$$v},expression:"denyMessage"}}):_vm._e(),(_vm.status)?_c(VAutocomplete,{attrs:{"label":"Building statuses","items":_vm.buildingStatuses,"item-text":"name","item-value":"id"},model:{value:(_vm.buildingStatus),callback:function ($$v) {_vm.buildingStatus=$$v},expression:"buildingStatus"}}):_vm._e(),(
          _vm.approve &&
            !_vm.deny &&
            _vm.model &&
            _vm.model.survey_id &&
            _vm.model.asbestos_count > 0
        )?_c(VCheckbox,{staticClass:"my-0",attrs:{"data-cy":"checkbox-approve-asbestos","label":"Approve asbestos"},model:{value:(_vm.asbestos),callback:function ($$v) {_vm.asbestos=$$v},expression:"asbestos"}}):_vm._e(),(
          _vm.approve && !_vm.deny && _vm.model && _vm.model.survey_id && _vm.model.lead_count > 0
        )?_c(VCheckbox,{staticClass:"my-0",attrs:{"label":"Approve lead"},model:{value:(_vm.lead),callback:function ($$v) {_vm.lead=$$v},expression:"lead"}}):_vm._e()],1),(_vm.close)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Close")])],1):_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","text":"","data-cy":"confirm"},on:{"click":_vm.confirm}},[_vm._v("Confirm")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }