<template>
  <div>
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          Delete Worksafe document
        </v-card-title>
        <v-card-text
          >Are you sure you want to delete this document?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="6" v-if="worksafes && worksafes.length > 0">
        <div>
          <h3>
            Existing worksafe documents
          </h3>
          <v-row v-for="(f, k) in worksafes" :key="k">
            <v-col cols="6">
              <v-icon>fas fa-file-pdf</v-icon> {{ f.name }}
            </v-col>
            <v-col cols="6">
              <v-icon @click="downloadFile(f.id)" color="secondary" class="pr-2"
                >fas fa-arrow-down</v-icon
              >
              <v-icon @click="removeFile(f.id)" color="red"
                >fas fa-times</v-icon
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6">
        <h3>Upload a new worksafe Licensed Asbestos Removal document</h3>
        <br />
        <v-text-field
          spellcheck="true"
          label="Worksafe document title"
          outlined
          v-model.lazy="title"
          v-validate="'required|max:190'"
          maxlength="190"
          :error-messages="errors.collect('document name')"
          data-vv-name="document name"
          placeholder="Enter title"
          messages="*Required"
          required
        ></v-text-field
        ><br />
        <v-file-input
          v-model="fileUp"
          @change="onFileChange($event)"
          accept=".pdf"
          outlined
          v-validate="'size:20480'"
          :error-messages="errors.collect('document')"
          :data-vv-name="'document'"
          :disabled="!title"
          :label="!title ? 'Please add a title first' : 'Upload a document'"
        ></v-file-input>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["clearance_id", "trigger_save"],
  data() {
    return {
      worksafes: [],
      fileUp: [],
      title: "Notification of Licensed Asbestos Removal",
      isSaving: false,
      selectedFile: null,
      deleteDialog: false
    };
  },
  methods: {
    ...mapActions([
      "getClearanceWorksafes",
      "getWorksafes",
      "searchWorksafe",
      "insertUpdateWorkSafes",
      "uploadWorkSafeFile",
      "deleteWorksafeDocument"
    ]),
    removeFile(id) {
      this.selectedFile = id;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.deleteWorksafeDocument(this.selectedFile).then(() => {
        this.getClearanceWorkSafes();
        this.deleteDialog = false;
        this.selectedFile = null;
      });
    },
    downloadFile(id) {
      const fileLink = document.createElement("a");
      fileLink.href = `/api/clearance/worksafe/${id}`;
      const downloadAt = document.createAttribute("download");
      fileLink.setAttributeNode(downloadAt);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    onFileChange() {
      if (!this.fileUp) {
        return;
      }

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }

        this.isSaving = true;
        this.uploadWorkSafeFile({
          clearance_id: this.clearance_id,
          title: this.title,
          fileUp: this.fileUp
        })
          .then(response => {
            this.isSaving = false;
            this.worksafes = response.data.worksafes;
            this.fileUp = null;
            this.title = "Notification of Licensed Asbestos Removal";
          })
          .catch(() => {
            this.isSaving = false;
          });
      });
    },
    save() {},
    addNewWs() {
      let newWs = JSON.parse(JSON.stringify(this.defaultWs));
      this.newWorksafes.push(newWs);
    },
    getClearanceWorkSafeDocs() {
      this.getClearanceWorksafes(this.clearance_id).then(response => {
        this.worksafes = response.data.worksafes;
      });
    },
    save() {
      this.isSaving = true;
      this.insertUpdateWorkSafes({
        worksafes: this.worksafes,
        clearance_id: this.clearance_id
      })
        .then(response => {
          this.worksafes = response.data.worksafes;
          this.isSaving = false;
        })
        .catch(() => {
          this.isSaving = false;
        });
    }
  },
  mounted() {
    this.getClearanceWorkSafeDocs();
  }
};
</script>
<style scoped>
.clearance-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.clearance-table td,
.clearance-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.clearance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.clearance-table td:nth-child(1) {
  width: 80%;
}
.clearance-table td:nth-child(2) {
  text-align: center;
}

.clearance-table tr:hover {
  background-color: #ddd;
}

.clearance-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #94959c;
  color: white;
}
</style>
