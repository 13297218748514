import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";

const config = {
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    stores: ["storage", "cookie"],
    tokenDefaultKey: "ccs",
    tokenImpersonateKey: "ccs_impersonate",
    registerData: {
      url: "/api/auth/register",
      method: "POST",
      redirect: "/login",
    },
    loginData: {
      url: "/api/auth/login",
      method: "POST",
      fetchUser: true,
      redirect: "/dashboard",
    },
    fetchData: {
      url: "/api/auth/user",
      method: "GET",
      enabled: true,
    },
    logoutData: {
      url: "/api/auth/logout",
      method: "POST",
      redirect: "/login",
    },
    refreshData: {
      url: "/api/auth/refresh",
      method: "POST",
      enabled: true,
      interval: 600,
    },
    redirect: "/login",
    authRedirect: "/login",
  },
};

export default config;
