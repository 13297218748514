<template>
  <div>
    <v-row>
      <v-col class="py-0 my-0">
        <v-row>
          <v-col class="pb-2"
            ><h3 class="primary--text">
              <span class="project-information-step pr-3"
                >Project Information</span
              >
            </h3></v-col
          ></v-row
        >
        <v-row>
          <v-col cols="6" class="job-number-step">
            <div>
              <v-autocomplete
                v-if="!surveySet && !$route.params.survey"
                clearable
                :items="items"
                :loading="isLoading"
                :search-input.sync="autoSearch"
                @input="setSurvey"
                @click:clear="unsetSurvey"
                hide-selected
                item-text="job_number"
                item-value="id"
                label="Job Number*"
                placeholder="Create or find a project job number"
                messages="*Required"
                prepend-inner-icon="fas fa-search"
                return-object
                outlined
                autofocus
                data-vv-name="job number"
                v-validate="'required'"
                :error-messages="errors.collect('job number')"
                data-cy="job-number-input"
                required
              >
                <template v-slot:prepend-item>
                  <create-survey-modal
                    v-on:set-survey="setSurvey"
                  ></create-survey-modal>
                </template>
              </v-autocomplete>
              <v-text-field
                v-else
                :disabled="true"
                v-model="survey.job_number"
                outlined
              >
                <template v-slot:append
                  ><button
                    v-if="!$route.params.survey"
                    type="button"
                    aria-label="clear icon"
                    @click="resetSurvey"
                    class="
                      enable-disabled
                      v-icon
                      notranslate
                      v-icon--link
                      fas
                      fa-times-circle
                      theme--light
                      primary--text
                    "
                  ></button
                ></template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :disabled="true"
              data-cy="name-input"
              v-model="survey.name"
              data-vv-name="name update"
              :error-messages="errors.collect('name')"
              v-validate="'required|max:100'"
              outlined
              label="Name*"
              messages="*Required"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col data-cy="clients" sm="6" cols="12" class="my-0">
            <v-autocomplete
              data-cy="client"
              :disabled="true"
              autocomplete="off"
              v-model="survey.client_id"
              outlined
              v-validate="'required'"
              :items="extantClients"
              placeholder="Select the client"
              item-text="name"
              item-value="id"
              :error-messages="errors.collect('client')"
              label="Client*"
              data-vv-name="client"
              messages="*Required"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col sm="6" cols="12" class="my-0">
            <v-autocomplete
              data-cy="contact"
              :disabled="true"
              v-if="survey.client_id && survey.contact_id"
              autocomplete="off"
              v-model="survey.contact_id"
              outlined
              v-validate="'required'"
              :items="contacts"
              item-text="full_name"
              item-value="id"
              :error-messages="errors.collect('contact')"
              label="Contact*"
              data-vv-name="contact"
              messages="*Required"
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col data-cy="office" cols="12" class="my-0">
            <v-autocomplete
              :disabled="true"
              autocomplete="off"
              v-model="survey.office_id"
              data-cy="select-office"
              placeholder="Select the office"
              outlined
              v-validate="'required'"
              :items="extantOffices"
              item-text="name"
              item-value="id"
              :error-messages="errors.collect('office')"
              label="Office*"
              data-vv-name="office"
              messages="*Required"
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateSurveyModal from "./CreateSurveyModal.vue";
import axios from "axios";

export default {
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  mounted() {
    this.axiosSource = axios.CancelToken.source();
    this.cancelToken = this.axiosSource.token;
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  props: ["value"],
  components: {
    CreateSurveyModal,
  },
  data: () => ({
    searchTimer: null,
    axiosSource: null,
    cancelToken: null,
    surveySet: false,
    items: [],
    isLoading: false,
    model: {},
    search: null,
    result: true,
    modelCheck: false,
    surveyModal: false,
  }),
  computed: {
    ...mapGetters([
      "extantOffices",
      "extantClients",
      "extantLaboratories",
      "invalidInput",
      "report_types",
    ]),
    survey: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit("input", updated);
      },
    },
    autoSearch: {
      get() {
        return this.search;
      },
      set(updated) {
        if (!updated) return;
        this.search = updated;
      },
    },
    contacts() {
      if (!this.survey.client_id) return;
      return this.extantClients.find(
        (client) => client.id == this.survey.client_id
      ).contacts;
    },
  },
  methods: {
    ...mapActions(["getJobSearch"]),
    resetSurvey() {
      this.survey = {
        client_id: null,
        contact_id: null,
        office_id: null,
        name: null,
        job_number: null,
        note: null,
        image: null,
      };
      this.surveySet = false;
    },
    setSurvey(survey) {
      if (!survey) {
        this.surveySet = false;
        return;
      }
      this.survey = { ...survey };
      this.surveySet = true;
    },
    unsetSurvey() {
      this.surveySet = false;
    },
  },
  watch: {
    "survey.job_number"(updated) {
      if (this.search != updated && updated) this.search = updated;
    },
    search(updated, outdated) {
      this.axiosSource = axios.CancelToken.source();
      this.cancelToken = this.axiosSource.token;
      if (this.survey.job_number != updated && updated)
        this.survey.job_number = updated;
      this.isLoading = true;
      if (this.searchTimer) clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.getJobSearch({ search: updated, cancelToken: this.cancelToken })
          .then((res) => {
            this.items = res.data;
            if (res.data.length == 0) {
              this.result = false;
            } else {
              this.result = true;
            }
          })
          .finally(() => (this.isLoading = false));
      }, 300);
    },
  },
};
</script>

<style scoped>
.enable-disabled {
  pointer-events: auto;
}
</style>