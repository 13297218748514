import Vue from "vue";
import App from "./views/App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import VeeValidate from "vee-validate";
import "./window.js";
import VueKonva from "vue-konva";
import "./registerServiceWorker";
import VueNoty from "vuejs-noty";
import Image from "./components/Image.vue";
// import TheCanvas from "./components/TheCanvas.vue";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import "./chatwoot.js";

Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: true,
  layout: "bottomCenter",
  theme: "relax",
});

Vue.use(VueKonva, { prefix: "vk" });
Vue.use(VeeValidate);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "fa",
});
Vue.component("a-img", Image);
Vue.config.productionTip = false;

let loadedRoute = window.location.href.split("/#");
let previousRoute = '';
router.beforeEach((to, from, next) => {
  // Store previous route if not login page
  if (!from.path.includes("login") && from.path != "/") {
    previousRoute = from.path;
  }
  
  if (from.path === "/" && !!loadedRoute[1] && loadedRoute[1] != from.path) {
    previousRoute = loadedRoute[1];
  }
  if (
    from.path.includes("login") &&
    !to.path.includes("login") &&
    !previousRoute.includes("login") &&
    to.path != previousRoute &&
    !!previousRoute
  ) {
    next({ path: previousRoute });
  } else {
    next();
  }
});

Vue.router = router;

const vue = new Vue({
  router,
  store,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");

vue.axios.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      vue.$noty.success(response.data.message);
      return response;
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.resolve({ data: { data: [], message: true } });
    }
    if (error.response.status == 401) {
      vue.$auth.logout();
    }
    if (
      error.response.config.url !== "/api/broadcasting/auth" &&
      !error.response.config.url.includes("sample")
    ) {
      vue.$noty.error(error.response.data.message);
    }
    return Promise.reject(error);
  }
);
