<template>
  <v-row justify="center">
    <v-col md="10" sm="10">
      <v-row>
        <v-col>
          <v-form data-cy="office-form">
            <v-text-field
              data-cy="office-name"
              autocomplete="new-password"
              spellcheck="true"
              v-model="office.name"
              outlined
              v-validate="'required|max:60'"
              counter
              maxlength="60"
              :error-messages="errors.collect('name')"
              label="Name"
              data-vv-name="name"
              required
            ></v-text-field>
            <address-form
              data-cy="office-form"
              :addresses="office.address"
              v-bind="office.address"
              v-on:update:city="setOfficeAddress($event, 'city')"
              v-on:update:suburb="setOfficeAddress($event, 'suburb')"
              v-on:update:postcode="setOfficeAddress($event, 'postcode')"
              v-on:update:address="setOfficeAddress($event, 'address')"
              v-on:update:address2="setOfficeAddress($event, 'address2')"
              v-bind:clearAddress.sync="clearAddress"
            ></address-form>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="float-left"
            color="warning"
            data-cy="back"
            @click="back()"
            >{{ dialog ? "Close" : "Back" }}</v-btn
          >
          <v-btn data-cy="office-create" class="float-right" color="primary" @click="create">
            create</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddressForm from "./AddressForm.vue";
export default {
  props: ["dialog"],
  components: {
    AddressForm
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    clearAddress: false,
    office: {
      name: null,
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
        address2: null
      }
    }
  }),
  computed: {
    ...mapGetters(["invalidInput"])
  },
  methods: {
    ...mapActions(["createOffice", "getOffices"]),
    setOfficeAddress(data, field) {
      this.office.address[field] = data;
    },
    create() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.createOffice(this.office).then(() => {
          this.getOffices().then(() => {
            this.back();
          });
        });
      });
    },
    back() {
      if (!this.dialog) {
        this.$router.go(-1);
      }
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.clearAddress = true;
      this.office = {
        name: null,
        address: {
          city: null,
          suburb: null,
          postcode: null,
          address: null,
          address2: null
        }
      };
    }
  }
};
</script>
