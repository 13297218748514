import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"data-cy":"laboratory-files"}},[_vm._l((_vm.building.file),function(file){return _c(VRow,{key:file.id},[(file.contaminate_id == _vm.contaminate)?[_c(VCol,{attrs:{"cols":11}},[_c(VFileInput,{attrs:{"data-cy":"lab-return-file","label":file.name || 'Lab return file',"disabled":""}})],1),_c(VCol,{attrs:{"cols":1}},[_c(VIcon,{staticClass:"pr-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.downloadReturnFile(file.id)}}},[_vm._v("fas fa-arrow-down")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.removeFile(file.id)}}},[_vm._v("fas fa-times")])],1)]:_vm._e()],2)}),_c(VRow,[_c(VCol,{attrs:{"sm":"3"}},[_c(VSelect,{attrs:{"items":_vm.contaminates,"value":_vm.contaminate,"disabled":"","item-text":"name","item-value":"id","label":"Contaminant"}})],1),_c(VCol,[_c(VFileInput,{staticClass:"lab-return-file-step",attrs:{"disabled":!_vm.contaminate,"label":"Lab return file"},on:{"change":function($event){return _vm.upload(_vm.num)}},model:{value:(_vm.files[_vm.num]),callback:function ($$v) {_vm.$set(_vm.files, _vm.num, $$v)},expression:"files[num]"}})],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }