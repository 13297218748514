<template>
  <v-row>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        disable-sort
        disable-filtering
        mobile-breakpoint="1000"
        no-data-text="There are no buildings to display"
        @click:row="goto"
        class="elevation-1"
      >
        <template v-slot:item.status_id="{ item }">
          <div class="pa-0 ma-0 d-flex flex-row justify-start">
            <v-chip data-cy="status" :color="getColor(item.status_name)" dark>{{
              item.status_name
            }}</v-chip>
            <v-tooltip top v-if="item.is_asbestos">
              <template v-slot:activator="{ on }">
                <v-icon
                  data-cy="asbestos-review"
                  :color="item.asbestos_approval ? 'blue' : 'gray'"
                  class="pl-2"
                  v-on="on"
                  >fal fa-stroopwafel</v-icon
                >
              </template>
              <span v-if="item.asbestos_approval">Asbestos approved</span>
              <span v-if="!item.asbestos_approval"
                >Asbestos
                <span v-if="item.status_id == AWAITING_REVIEW"
                  >needs review</span
                ></span
              >
            </v-tooltip>
            <v-tooltip top v-if="item.is_lead">
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="item.lead_approval ? 'green' : 'gray'"
                  class="pl-2"
                  v-on="on"
                  >fas fa-fill-drip</v-icon
                >
              </template>
              <span v-if="item.lead_approval">Lead approved</span>
              <span v-if="!item.lead_approval">Lead needs review</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="pa-0 ma-0 d-flex flex-row justify-start">
            <v-tooltip bottom v-if="item.is_asbestos">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  color="red darken3"
                  x-small
                  v-on="on"
                  class="white--text mx-1"
                  @click="downloadReport(item, ASBESTOS)"
                  v-if="item.asbestos_report_status == 'completed'"
                  @click.stop
                >
                  <v-icon>fas fa-file-pdf</v-icon>
                </v-btn>
                <v-btn
                  color="grey"
                  data-cy="icon-report"
                  class="white--text no-pointer mx-1"
                  x-small
                  fab
                  @click.stop
                  v-on="on"
                  v-else-if="review"
                >
                  <v-icon>fas fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>{{
                item.asbestos_report_status == "completed" ||
                item.lead_report_status == "completed"
                  ? "Download asbestos report"
                  : "No report available"
              }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.is_lead">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  color="green darken3"
                  x-small
                  v-on="on"
                  class="white--text mx-1"
                  @click="downloadReport(item, LEAD)"
                  v-if="item.lead_report_status == 'completed'"
                  @click.stop
                >
                  <v-icon>fas fa-file-pdf</v-icon>
                </v-btn>
                <v-btn
                  color="grey"
                  data-cy="icon-report"
                  class="white--text no-pointer mx-1"
                  x-small
                  fab
                  @click.stop
                  v-on="on"
                  v-else-if="review"
                >
                  <v-icon>fas fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>{{
                item.lead_report_status == "completed"
                  ? "Download lead report"
                  : "No report available"
              }}</span>
            </v-tooltip>
            <review-buttons
              v-if="review && (is_reviewer || isRoot)"
              class="d-inline"
              :building_id="item.id"
              :type="'dashboard'"
              @load="reloadReviewRows"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  data-cy="icon-view-building"
                  fab
                  color="primary"
                  x-small
                  v-on="on"
                  @click="goto(item)"
                  class="white--text mx-1"
                  @click.stop
                >
                  <v-icon>fas fa-arrow-right</v-icon>
                </v-btn>
              </template>
              <span>View Building</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReviewButtons from "../components/ReviewButtons";
export default {
  components: {
    ReviewButtons,
  },
  props: ["items", "loading", "review"],
  created() {
    this.ASBESTOS = 1;
    this.LEAD = 2;
    this.AWAITING_REVIEW = 9;
  },
  data: () => ({
    statusColors: {
      null: "primary lighten-4",
      Active: "primary lighten-2",
      "Awaiting Result": "lime darken-3 lighten-2",
      "Needs Edits": "red accent-4",
      "Pre Review": "light-blue accent-2",
      "Awaiting Review": "orange",
      Reviewed: "green darken-2",
      Complete: "primary darken-4",
    },
    headers: [
      { text: "Name", value: "name" },
      { text: "Report Type", value: "report_type" },
      { text: "Address", value: "full_address" },
      { text: "Status", value: "status_id" },
      { text: "Actions", value: "actions" },
    ],
  }),
  computed: {
    ...mapGetters(["reportTypeById", "getStatusById", "is_reviewer", "isRoot"]),
  },
  methods: {
    ...mapActions(["approveReview", "denyReview", "downloadReportURL"]),
    downloadReport({id, updated_at}, contaminate_id) {
      this.downloadReportURL({
        contaminate_id,
        building_id: id,
        updated_at: updated_at,
      });
    },
    goto(building) {
      this.$router.push(
        `/survey/${building.survey_id}/building/${building.id}`
      );
    },
    reloadReviewRows() {
      this.$emit("load");
    },
    getColor(status) {
      return this.statusColors[status];
    },
  },
};
</script>

<style scoped>
.no-pointer {
  cursor: not-allowed;
}
</style>
