/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "context" }]*/
/* eslint-disable no-console */
import axios from "axios";
import { setTimeout } from "timers";
import {
  createFormData,
  convertToData,
  optimizeImage,
  downloadReportEvent,
} from "../helpers/actions.js";

let actions = {
  setTableOptions(context, value) {
    if (value.itemsPerPage) {
      context.commit("SET_TABLE_OPTIONS", { itemsPerPage: value.itemsPerPage });
    }
  },
  getImageData(context, url) {
    return axios.get(url).then((res) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", (err) => reject(err));
        img.src = res.data;
      });
    });
  },
  getUrl(context, url) {
    return axios.get(url);
  },
  resetAuthState(context) {
    context.commit("RESET_AUTH_STATE");
  },
  resetState(context) {
    context.commit("RESET_STATE");
  },
  setLoading(context, isLoading = true) {
    context.commit("SET_LOADING", isLoading);
  },
  setDark(context, data) {
    context.commit("SET_DARK", data);
  },
  approveReview(context, data) {
    context.commit("SET_LOADING", true);
    return axios.post("/api/building/approve-report", data).then((response) => {
      context.commit("SET_LOADING", false);
      context.dispatch("getBuilding", data.id);
      return response;
    });
  },
  approvePreReview(context, { id }) {
    return axios.put("/api/building", { id, status_id: 9 }).then(() => {
      return context.dispatch("getBuilding", id);
    });
  },
  getLoginData(context) {
    context.dispatch("resetState");
    return context.dispatch("getOfflineData");
  },
  getOfflineData(context) {
    return Promise.allSettled([
      context.dispatch("getContaminates"),
      context.dispatch("getClients"),
      context.dispatch("getCompany"),
      context.dispatch("getUsers"),
      context.dispatch("getLaboratories"),
      context.dispatch("getReportTypes"),
      context.dispatch("getSampleStatuses"),
      context.dispatch("getStatuses"),
      context.dispatch("getAnalysisTypes"),
      context.dispatch("getAsbestos"),
      context.dispatch("getAssessmentMethods"),
      context.dispatch("getAsbestosRecommendations"),
      context.dispatch("getNoteTypes"),
      context.dispatch("getRoles"),
      context.dispatch("getAmountTypes"),
      context.dispatch("getOffices"),
    ]).then(() => context.dispatch("setLoading", false));
  },
  async getRelatedSurveyData(context) {
    if (!context.getters.company) context.dispatch("getCompany");
    if (!context.getters.users) context.dispatch("getUsers");
    if (!context.getters.clients) context.dispatch("getClients");
    if (!context.getters.offices) context.dispatch("getOffices");
    if (!context.getters.laboratories) context.dispatch("getLaboratories");
    if (!context.getters.sampleStatuses) context.dispatch("getSampleStatuses");
    if (!context.getters.statuses) context.dispatch("getStatuses");
    if (
      !context.getters.analysisTypes ||
      (context.getters.analysisTypes[0] &&
        !context.getters.analysisTypes[0].contaminate_id)
    )
      context.dispatch("getAnalysisTypes");
    if (!context.getters.contaminateTypes) context.dispatch("getContaminates");
    if (!context.getters.roles) context.dispatch("getRoles");
    if (!context.getters.assessmentMethods)
      context.dispatch("getAssessmentMethods");
    if (!context.getters.asbestos) context.dispatch("getAsbestos");
    if (!context.getters.amountTypes) context.dispatch("getAmountTypes");
    if (!context.getters.note_types) context.dispatch("getNoteTypes");
    if (!context.getters.asbestosRecommendations)
      context.dispatch("getAsbestosRecommendations");
    if (!context.getters.report_types)
      return context.dispatch("getReportTypes");
  },
  getRouteIds(context, routes) {
    let keys = Object.keys(routes.params);
    if (
      routes.fullPath.includes("clients") ||
      routes.fullPath.includes("dashboard") ||
      routes.fullPath.includes("laboratories") ||
      routes.fullPath.includes("offices")
    ) {
      context.dispatch("getOffices");
      context.dispatch("getLaboratories");
      context.dispatch("getUsers");
      context.dispatch("getClients");
      context.dispatch("getRoles");
    }
    context.dispatch("clearSelected", keys);
    if (keys.length > 0) {
      keys.map((key) => {
        if (key.includes("clearance")) return;
        return {
          survey: () => {
            context.dispatch("getSurvey", routes.params[key]);
          },
          building: () => {
            context.dispatch("getBuilding", routes.params[key]);
            if (!routes.params["floor"]) {
              context.dispatch("getBuildingItems", routes.params[key]);
              context.dispatch("getFloors", routes.params[key]);
            }
          },
          buildingExport: () => {
            context.dispatch("getBuilding", routes.params[key]);
            context.dispatch("getFloors", routes.params[key]);
            context.dispatch("getRelatedSurveyData");
          },
          floor: () => {
            if (!routes.params["room"]) {
              context.dispatch("getRooms", routes.params[key]);
            }
            context.dispatch("getFloor", routes.params[key]);
          },
          room: () => {
            context.dispatch("getRoom", routes.params[key]);
            if (!routes.params["item"]) {
              context.dispatch("getItems", routes.params[key]);
            }
          },
          item: () => {
            context.dispatch("getItem", routes.params[key]);
            context.dispatch("getAssessmentMethods");
          },
          client: () => {
            context.dispatch("getClient", routes.params[key]);
          },
          clientExport: () => {
            context.dispatch("getClient", routes.params[key]);
          },
          contact: () => {
            context.dispatch("getContact", routes.params[key]);
          },
          office: () => {
            context.dispatch("getOffice", routes.params[key]);
          },
          laboratory: () => {
            context.dispatch("getLaboratory", routes.params[key]);
          },
          user: () => {
            context.dispatch("getUser", routes.params[key]);
          },
        }[key]();
      });
    }
    if (
      routes.path == "/browse/projects" ||
      routes.path == "/survey" ||
      routes.path == "/dashboard"
    ) {
      context.dispatch("getRelatedSurveyData");
    }
    if (routes.path == "/browse/buildings") {
      context.dispatch("getRelatedSurveyData");
    }
  },
  getReportTypes(context) {
    return axios.get("/api/report-types").then((response) => {
      context.commit("SET_REPORT_TYPES", response.data);
    });
  },
  reportQueue(context) {
    return axios.get("/api/report/queued");
  },
  generateReport(context, { building, contaminate_id }) {
    return axios
      .get(`/api/report/generate/${building}/${contaminate_id}`)
      .finally(() => {
        context.dispatch("getBuilding", building);
      });
  },
  getReport(context, building) {
    return axios.get(
      `/api/report/download/${building}?${Math.random()
        .toString(36)
        .substring(7)}`,
      {
        responseType: "blob",
      }
    );
  },
  downloadReportURL(context, { contaminate_id, building_id, updated_at }) {
    axios
      .get(
        `/api/report/download/${building_id}?contaminate=${contaminate_id}&updated_at=${updated_at}`
      )
      .then(({ data }) => {
        downloadReportEvent(data);
      });
  },
  downloadClerancePDF(context, id) {
    axios.get(`/api/clearance/pdf/download/${id}`).then(({ data }) => {
      downloadReportEvent(data);
    });
  },
  downloadReturnFile(context, id) {
    axios.get(`/api/file/${id}`).then(({ data }) => {
      downloadReportEvent(data);
    });
  },
  downloadClearanceReport(context, clearance_id) {
    axios.get(`/api/clearance/download/${clearance_id}`).then(({ data }) => {
      downloadReportEvent(data);
    });
  },
  downloadCOCURL(
    context,
    { laboratory_id, building_id, updated_at, contaminate_id = 1 }
  ) {
    axios
      .post(
        `/api/report/coc/download/${building_id}/${laboratory_id}/${contaminate_id}?updated_at=${updated_at}`
      )
      .then(({ data }) => {
        downloadReportEvent(data);
      });
  },
  downloadReport(context, { id }) {
    axios.get(`/api/report/${id}`).then(({ data }) => {
      downloadReportEvent(data);
    });
  },
  /**
   * Set notification type and message
   * @param {*} context
   * @param {type: [error, success], message: String} data
   */
  setNotification(context, { type, message }) {
    context.commit("SET_NOTIFICATION_TYPE", type);
    context.commit("SET_NOTIFICATION_MESSAGE", message);
    context.commit(
      "SET_NOTIFICATION_TIMEOUT",
      setTimeout(() => {
        context.commit("SET_NOTIFICATION_SHOW", false);
        context.commit("SET_NOTIFICATION_TYPE", "transparent");
      }, 3000)
    );
  },
  setNotificationType(context, data) {
    context.commit("SET_NOTIFICATION_TYPE", data);
  },
  setNotificationShow(context, data) {
    context.commit("SET_NOTIFICATION_SHOW", data);
  },
  setNotificationMessage(context, data) {
    context.commit("SET_NOTIFICATION_MESSAGE", data);
  },
  setNotificationTimeout(context, data) {
    context.commit("SET_NOTIFICATION_TIMEOUT", data);
  },
  setMessages(context, data) {
    context.commit("SET_MESSAGES", data);
  },
  setMessage(context, data) {
    context.commit("SET_MESSAGE", data);
  },
  clearNotification(context) {
    context.commit("CLEAR_NOTIFICATION");
  },
  /**
   * Create a user within user company
   * @param {Vue context} context
   * @param {User data} user
   */
  createUser(context, user) {
    return axios.post("/api/user", user).then((response) => {
      context.commit("SET_USER_COMPANY_USERS", { users: response.data });
    });
  },
  /**
   * Edit user
   * @param {*} context
   * @param {User data} user
   */
  async updateUser(context, user) {
    const result = await createFormData(user, "put");
    return axios.post(`/api/user/${user.id}`, result);
  },
  /**
   * Set user that is logged in
   * @param {Vue context} context
   * @param {User data} user
   */
  setUser(context, user) {
    context.commit("SET_USER", user);
  },
  /**
   * Get users using company ID
   * @param {*} context
   * @param {*} company_id
   */
  getUsers(context) {
    return axios.get(`/api/user`).then((response) => {
      if (response.data.message) return;
      context.commit("SET_COMPANY_USERS", response.data);
    });
  },
  getUser(context, id) {
    return axios.get(`/api/user/${id}`).then((response) => {
      if (response.data.message) return;
      context.commit("SET_SELECTED_USER", response.data);
    });
  },
  getUserById(context, id) {
    return axios.get(`/api/user/${id}`);
  },
  refreshUser(context, id) {
    return axios.get(`/api/user/${id}`).then((response) => {
      context.commit("SET_USER", response.data);
    });
  },
  async createProject(context, project) {
    const projectReq = await createFormData(project);
    return axios.post(`/api/project`, projectReq);
  },
  getJobSearch(context, { search, cancelToken }) {
    return axios.get(`/api/survey/job/search`, {
      params: { search },
      cancelToken: cancelToken,
    });
  },
  getMessages(context) {
    return axios
      .get(`/api/user/messages?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        context.commit("SET_MESSAGES", response.data);
      });
  },
  readMessage(context, id) {
    return axios.post(`/api/user/message/read`, { id });
  },
  readAll(context) {
    return axios.get(`/api/user/read`).then(() => {
      context.dispatch("getMessages");
    });
  },
  setSurveyActive(context, { id }) {
    return axios.put(`/api/survey/${id}/active`);
  },
  /**
   * Delete user request
   * @param {*} context
   * @param {User data} user
   */
  deleteUser(context, { id }) {
    return axios.delete("/api/user", { data: { id } });
  },
  /**
   * Create user request
   * @param {*} context
   * @param {Company data} company
   */
  async createCompany(context, company) {
    const result = await createFormData(company);
    return axios.post("/api/company", result);
  },
  /**
   * Edit company data
   * @param {*} context
   * @param {Company data} company
   */
  async editCompany(context, company) {
    const result = await createFormData(company, "put");
    return axios.post("/api/company", result);
  },
  /**
   * Get request for all company data
   * @param {*} context
   * @param {Search data} data
   */
  getCompanies(context, data) {
    let search =
      "/api/company/admin?page=" +
      data.page +
      (data.search ? "&search=" + data.search : "") +
      "&rows=" +
      data.rows;
    return axios.get(search).then((response) => {
      if (response.data.message) return;
      context.commit("SET_COMPANIES", response.data);
    });
  },
  /**
   * Get request for company data then set user company
   * @param {*} context
   * @param {Search data} data
   */
  async getCompany(context) {
    return axios.get("/api/company").then((response) => {
      if (response.data.message) return;
      context.commit("SET_USER_COMPANY", response.data);
    });
  },
  /**
   * Delete request for company
   * @param {*} context
   * @param {Company data} company
   */
  deleteCompany(context, company) {
    return axios.delete("/api/company", { data: { id: company.id } });
  },
  /**
   * Get users request for any company then set the companies users
   * @param {*} context
   * @param {Search data} data
   */
  adminGetCompanyUsers(context, data) {
    return axios
      .get(
        "/api/company/users?id=" +
        data.id +
        "&rows=" +
        data.rows +
        "&page=" +
        data.page
      )
      .then((response) => {
        if (response.data.message) return;
        context.commit("SET_SYSTEM_COMPANY_USERS", {
          id: data.id,
          users: response.data,
        });
      });
  },
  /**
   * Get request for company offices
   * @param {*} context
   * @param {} company_id
   */
  getOffices(context) {
    return axios.get("/api/office").then((response) => {
      context.commit("SET_COMPANY_OFFICES", response.data);
    });
  },
  getOffice(context, id) {
    return axios.get(`/api/office/${id}`).then((response) => {
      context.commit("SET_OFFICE", response.data);
    });
  },
  /**
   * Delete request for an office
   * @param {*} context
   * @param {*} office
   */
  deleteOffice(context, { id }) {
    return axios.delete("/api/office", { data: { id } });
  },
  /**
   * Edit request for an office
   * @param {*} context
   * @param {*} office
   */
  updateOffice(context, office) {
    return axios.put("/api/office", office);
  },
  /**
   * Post request to create an office
   * @param {*} context
   * @param {*} office
   */
  createOffice(context, office) {
    return axios.post("/api/office", office);
  },
  /**
   * Post request to create an office as admin
   * @param {*} context
   * @param {*} office
   */
  adminCreateOffice(context, office) {
    return axios.post("/api/office/admin", office);
  },
  /**
   * Get request for available roles
   * @param {*} context
   */
  getRoles(context) {
    return axios.get("/api/user/roles").then((response) => {
      if (response.data.message) return;
      context.commit("SET_ROLES", response.data);
    });
  },
  /**
   * Get request for laboratory data as admin
   * @param {*} context
   * @param {*} company_id
   */
  adminGetLaboratories(context, company_id) {
    return axios.get("/api/laboratory/" + company_id).then((response) => {
      if (response.data.message) return;
      context.commit("SET_SYSTEM_COMPANY_LABORATORIES", {
        id: company_id,
        laboratories: response.data,
      });
    });
  },
  /**
   * Get request for laboratory data
   * @param {*} context
   * @param {*} company_id
   */
  getLaboratories(context) {
    return axios
      .get("/api/laboratory")
      .then((response) => {
        context.commit("SET_COMPANY_LABORATORIES", response.data);
      })
      .then(() => context.dispatch("setLoading", false));
  },
  getLaboratory(context, id) {
    return axios
      .get(`/api/laboratory/${id}`)
      .then((response) => {
        context.commit("SET_LABORATORY", response.data);
        return response.data;
      })
      .finally(() => context.dispatch("setLoading", false));
  },
  /**
   * Delete request for laboratory
   * @param {*} context
   * @param {*} laboratory
   */
  deleteLaboratory(context, { id }) {
    return axios.delete("/api/laboratory", { data: { id } });
  },
  /**
   * Edit request for laboratory
   * @param {*} context
   * @param {*} data
   */
  laboratoryEdit(context, data) {
    return axios.put("/api/laboratory", data);
  },
  /**
   * Post request to create laboratory
   * @param {*} context
   * @param {*} data
   */
  createLaboratory(context, data) {
    return axios.post("/api/laboratory", data);
  },
  /**
   * Post request to create laboratory as admin
   * @param {*} context
   * @param {*} data
   */
  adminCreateLaboratory(context, data) {
    return axios.post("/api/laboratory/admin", data);
  },
  /**
   * Get request for client data
   * @param {*} context
   */
  getClients(context) {
    return axios
      .get("/api/client")
      .then((response) => {
        if (response.data.message) return;
        context.commit("SET_COMPANY_CLIENTS", response.data);
      })
      .then(() => context.dispatch("setLoading", false));
  },
  getClient(context, id) {
    if (!id) return;
    return axios.get(`/api/client/${id}`).then((response) => {
      context.commit("SET_CLIENT", response.data);
    });
  },
  getContacts(context, id) {
    if (!id) return;
    return axios
      .get(`/api/client/${id}/contacts`)
      .then((response) => {
        context.commit("SET_CLIENT_CONTACTS", response.data);
      })
      .then(() => context.dispatch("setLoading", false));
  },
  getContact(context, id) {
    return axios.get(`/api/contact/${id}`).then((response) => {
      context.commit("SET_CONTACT", response.data);
    });
  },
  createContact(context, data) {
    return axios.post(`/api/contact`, data).then(() => {
      return context.dispatch("getClients");
    });
  },
  editContact(context, data) {
    return axios.put(`/api/contact/${data.id}`, data).then(() => {
      context.dispatch("getClients");
    });
  },
  deleteContact(context, data) {
    return axios.delete(`/api/contact/${data.id}`, data);
  },
  approveResult(context, building) {
    return axios
      .put(`/api/building/${building.id}`, { status_id: 8 })
      .then((response) => {
        context.dispatch("getBuilding", building.id);
        return response;
      });
  },
  /**
   *
   * @param {*} context
   * @param {page, search, paginate} search
   */
  getClientsSearch(context, search) {
    return axios.get("/api/client/search", { params: search });
  },
  getStatuses(context) {
    return axios.get("/api/statuses").then((response) => {
      if (response.data.message) return;
      context.commit("SET_STATUSES", response.data);
    });
  },
  getAnalysisTypes(context) {
    return axios.get("/api/analysis-types").then((response) => {
      if (response.data.message) return;
      context.commit("SET_ANALYSIS_TYPES", response.data);
    });
  },
  getActiveSurveys(context) {
    return axios.get("/api/survey/active");
  },
  getAssessmentMethods(context) {
    return axios.get("/api/assessment-methods").then((response) => {
      if (response.data.message) return;
      context.commit("SET_ASSESSMENT_METHODS", response.data);
    });
  },
  async createClient(context, client) {
    const result = await createFormData(client);
    return axios.post("/api/client", result).then((response) => {
      return context.dispatch("getClients").then(() => {
        return response;
      });
    });
  },
  deleteClient(context, { id }) {
    return axios.delete("/api/client", { data: { id } });
  },
  async editClient(context, client) {
    const result = await createFormData(client, "put");
    return axios.post("/api/client", result).then((response) => {
      return context.dispatch("getClients").then(() => {
        return response;
      });
    });
  },
  async createSurvey(context, survey) {
    const result = await createFormData(survey);
    return axios.post("/api/survey", result);
  },
  createCSVSurvey(context, survey) {
    const aSurvey = {
      client_id: survey.client.id,
      surveyor_id: survey.surveyor.id,
      reviewer_id: survey.reviewer.id,
      office_id: survey.office.id,
      name: survey["survey name"],
      job_number: survey["job number"],
    };
    return axios.post("/api/survey", aSurvey);
  },
  deleteSurvey(context, id) {
    return axios.delete("/api/survey", { data: { id } });
  },
  deleteBuilding(context, id) {
    return axios.delete("/api/building", { data: { id } });
  },
  deleteFloor(context, id) {
    return axios.delete("/api/floor", { data: { id } });
  },
  deleteRoom(context, id) {
    return axios.delete("/api/room", { data: { id } });
  },
  /**
   * Duplicate a survey and optionally define a name or job number
   * @param {*} context
   * @param {id, name: null, job_number: null} data
   */
  duplicateSurvey(context, { id, name, job_number }) {
    return axios.post(`/api/survey/${id}/duplicate`, {
      name: name,
      job_number: job_number,
    });
  },
  leaveChannel(context, channel) {
    window.Echo.leave(channel);
  },
  broadcastNotification(context, { type, message }) {
    context.dispatch("setNotificationType", type);
    context.dispatch("setNotificationMessage", message);
    context.dispatch(
      "setNotificationTimeout",
      setTimeout(() => {
        context.dispatch("clearNotification");
      }, 3000)
    );
  },
  /**
   * Duplicate a building and optionally define a name or survey_id
   * @param {*} context
   * @param {id, name: null, survey_id: null} data
   */
  duplicateBuilding(context, { id, name, survey_id, survey }) {
    return axios
      .post(`/api/building/${id}/duplicate`, {
        name,
        survey_id,
        survey,
      })
      .then(() => {
        let company_id = context.getters.user.company_id;
        window.Echo.private(`${company_id}.duplicate.building.${id}`).listen(
          "BuildingDuplicating",
          (e) => {
            const name = e.name;
            const status = e.status;
            context.dispatch("broadcastNotification", {
              type: status,
              message: `Building ${name} ${status != "success" ? "was unable to be" : "has been"
                } duplicated`,
            });
            context.dispatch(
              "leaveChannel",
              `${company_id}.duplicate.building.${id}`
            );
          }
        );
      });
  },
  /**
   * Duplicate a Floor and optionally define a name or survey_id
   * @param {*} context
   * @param {id, name: null, building_id: null} data
   */
  duplicateFloor(context, { id, name, building_id }) {
    return axios
      .post(`/api/floor/${id}/duplicate`, {
        name: name,
        building_id: building_id,
      })
      .then(() => {
        let company_id = context.getters.user.company_id;
        window.Echo.private(`${company_id}.duplicate.floor.${id}`).listen(
          "FloorDuplicating",
          (e) => {
            const name = e.name;
            const status = e.status;
            context.dispatch("broadcastNotification", {
              type: status,
              message: `Floor ${name} ${status != "success" ? "was unable to be" : "has been"
                } duplicated`,
            });
            context.dispatch(
              "leaveChannel",
              `${company_id}.duplicate.floor.${id}`
            );
          }
        );
      });
  },
  /**
   * Duplicate a Room and optionally define a name or survey_id
   * @param {*} context
   * @param {id, name: null, floor_id: null} data
   */
  duplicateRoom(context, { id, name, floor_id }) {
    return axios
      .post(`/api/room/${id}/duplicate`, {
        name: name,
        floor_id: floor_id,
      })
      .then(() => {
        let company_id = context.getters.user.company_id;
        window.Echo.private(`${company_id}.duplicate.room.${id}`).listen(
          "RoomDuplicating",
          (e) => {
            const name = e.name;
            const status = e.status;
            context.dispatch("broadcastNotification", {
              type: status,
              message: `Room ${name} ${status != "success" ? "was unable to be" : "has been"
                } duplicated`,
            });
            context.dispatch(
              "leaveChannel",
              `${company_id}.duplicate.room.${id}`
            );
          }
        );
      });
  },
  /**
   * Duplicate an Item and optionally define a name or room_id
   * @param {*} context
   * @param {id, name: null, room_id: null} data
   */
  duplicateItem(context, { id, name, room_id }) {
    return axios
      .post(`/api/item/${id}/duplicate`, {
        name: name,
        room_id: room_id,
      })
      .then(() => {
        context.dispatch("getRoom", room_id);
        context.dispatch("getItems", room_id);
      });
  },
  createItem(context, item) {
    return axios.post("/api/item", item);
  },
  updateItem(context, item) {
    return axios.put(`/api/item/${item.id}`, item);
  },
  async editSurvey(context, survey) {
    const result = await createFormData(survey, "put");
    return axios.post("/api/survey", result);
  },
  getSurveys(context) {
    return axios.get("/api/survey").then((response) => {
      context.commit("SET_SURVEYS", response.data);
    });
  },
  getSurvey(context, id) {
    return axios
      .get(`/api/survey/${id}?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        if (!response.data.message) {
          context.commit("SET_SURVEY", response.data);
        }
      });
  },
  async createBuilding(context, building) {
    const result = await createFormData(building);
    return axios.post("/api/building", result);
  },
  async createCSVBuilding(context, building) {
    const aBuilding = {
      survey_id: building["survey id"],
      address: {
        city: building.city,
        suburb: building.suburb,
        postcode: building.postcode,
        address: building.address,
        address2: building.address2,
      },
      name: building.name,
      description: building.description,
      phase: building.phase,
      is_within_three_months: false,
      is_answering_questions: false,
      is_residential: building["residential?"],
      laboratory_id: null,
      report_type_id: context.getters.reportTypes.find(
        (type) => type.name == building["Report Type"]
      ).id,
    };
    const result = await createFormData(aBuilding);
    return axios.post("/api/building", result);
  },
  async editBuilding(context, building) {
    const result = await createFormData(building, "PUT");
    return axios.post("/api/building", result);
  },
  updateBuildingStatus(context, building) {
    return axios.put(`/api/building/${building}/status`);
  },
  updateBuildingStatusTo(context, { id, status }) {
    context.commit("SET_LOADING", true);
    return axios.put(`/api/building/${id}`, { status_id: status }).then(() => {
      context.dispatch("getBuilding", id);
    });
  },
  async createFloor(context, floor) {
    const result = await createFormData(floor);
    return axios.post("/api/floor", result);
  },
  createCSVFloor(context, { floor, image }) {
    const request = {
      building_id: floor["building id"],
      image: image,
      name: floor["floor name"],
      description: floor.description,
    };
    return axios.post("/api/floor", request);
  },
  async editFloor(context, floor) {
    const result = await createFormData(floor, "put");
    return axios.post("/api/floor", result);
  },
  getFloors(context, id) {
    return axios.get(`/api/building/${id}/floor`).then((response) => {
      if (!response.data.message) {
        context.commit("SET_FLOORS", response.data);
      }
      return response.data;
    });
  },
  getBuildingRooms(context, id) {
    return axios.get(`/api/building/${id}/rooms`);
  },
  getRooms(context, floor) {
    return axios.get("/api/floor/" + floor + "/room").then((response) => {
      if (!response.data.message) {
        context.commit("SET_ROOMS", response.data);
      }
      return response.data;
    });
  },
  getRoom(context, id) {
    return axios
      .get(`/api/room/${id}?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        context.dispatch("setRoom", response.data.data);
        return response.data.data;
      });
  },
  setRoom(context, data) {
    context.commit("SET_SELECTED_ROOM", data);
  },
  setBuilding(context, data) {
    context.commit("SET_BUILDING", data);
  },
  editRoom(context, room) {
    return axios.put("/api/room", room);
  },
  getSurveyItems(context, id) {
    return axios.get(`/api/survey/${id}/items`);
  },
  getItems(context, room) {
    return axios
      .get(`/api/room/${room}/item?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        context.commit("SET_ITEMS", response.data);
        return response.data;
      });
  },
  getItem(context, item) {
    return axios
      .get(`/api/item/${item}?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        if (!response.data.message) {
          context.commit("SET_ITEM", response.data);
        }
        return response;
      });
  },
  createRoom(context, room) {
    if (room.file) {
      return convertToData(room.file).then((result) => {
        return optimizeImage(result).then((roomFile) => {
          room.file = roomFile;
          return axios.post("/api/room", room);
        });
      });
    }
    return axios.post("/api/room", room);
  },
  createCSVRoom(context, room) {
    return axios.post("/api/room", {
      floor_id: room["floor id"],
      name: room["room name"],
      note: room.note,
    });
  },
  getBuilding(context, id) {
    if (!id) return;
    context.dispatch("setLoading");
    return axios
      .get(`/api/building/${id}?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        context.commit("SET_BUILDING", response.data);
        context.dispatch("setLoading", false);
        return response.data;
      });
  },
  getApprovals(context, building_id) {
    if (!building_id) return;
    return axios
      .get(`/api/building/get-approval-status/${building_id}`)
      .then((response) => {
        context.commit("SET_APPROVAL", response.data);
      });
  },
  getFloor(context, id) {
    context.dispatch("setLoading");
    return axios
      .get(`/api/floor/${id}?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        context.commit("SET_FLOOR", response.data);
        context.dispatch("setLoading", false);
        return response.data;
      });
  },
  setFloor(context, data) {
    context.commit("SET_FLOOR", data);
  },
  getBuildings(context, survey) {
    if (!survey) return;
    return axios.get(`/api/survey/${survey}/building`).then((response) => {
      context.commit("SET_BUILDINGS", response.data);
    });
  },
  downloadFile(context, id) {
    return axios.get(`/api/file/${id}`, { responseType: "blob" });
  },
  setSurveyStatus(context, status) {
    context.commit("SET_SURVEY_STATUS", status);
  },
  setItem(context, item) {
    context.commit("SET_ITEM", item);
  },
  updateItemProp(context, item) {
    context.commit("UPDATE_ITEM", item);
  },
  updateLeadItemProp(context, lead) {
    context.commit("UPDATE_LEAD_ITEM", lead);
  },
  setSelectedSampledAs(context, id) {
    return axios.get(`/api/item/${id}`).then((response) => {
      context.commit("SET_SELECTED_SAMPLED_AS", response.data);
    });
  },
  clearSampledAs(context) {
    context.commit("CLEAR_SAMPLED_AS");
  },
  /**
   * Removes any score already associated to the new score property
   * @param {*} context
   * @param {property, score} data
   */
  setItemScore(context, data) {
    context.commit("SET_ITEM_SCORE", data);
  },
  setItemRecommendation(context, item) {
    context.commit("SET_ITEM_RECOMMENDATION", item);
  },
  setSelectedProperty(context, item) {
    context.commit("SET_SELECTED_PROPERTY", item);
  },
  getAmountTypes(context) {
    return axios.get("/api/amount-types").then((response) => {
      context.commit("SET_AMOUNT_TYPES", response.data);
    });
  },
  getNoteTypes(context) {
    return axios.get("/api/note-types").then((response) => {
      context.commit("SET_NOTE_TYPES", response.data);
    });
  },
  getAsbestos(context) {
    return axios.get("/api/contaminate/asbestos").then((response) => {
      context.commit("SET_ASBESTOS", response.data);
    });
  },
  getAsbestosRecommendations(context) {
    return axios.get("/api/recommendations/asbestos").then((response) => {
      context.commit("SET_ASBESTOS_RECOMMENDATIONS", response.data);
    });
  },
  getContaminates(context) {
    return axios.get("/api/contaminates").then((response) => {
      context.commit("SET_CONTAMINATE_TYPE", response.data);
    });
  },
  getCompanyAsbestosRecommendations(context) {
    context.dispatch("getAsbestosRecommendations");
    return axios.get("/api/recommendation/get");
  },
  /**
   * Search for building based on defined parameters in the search object
   * @param {*} context
   * @param {page, search, status, paginate} search
   */
  async getBuildingSearch(context, search) {
    return context.dispatch("getRelatedSurveyData").then(() => {
      return axios
        .get("/api/building/search", {
          params: search,
          cancelToken: search.cancelToken,
        })
        .then((response) => {
          context.commit("SET_BUILDING_SEARCH", response.data);
        });
    });
  },
  getSurveySearch(context, search) {
    return axios
      .get("/api/survey/search", { params: search })
      .then((response) => {
        context.commit("SET_SURVEY_SEARCH", response.data);
      });
  },
  getBuildingItems(context, id) {
    return axios.get(`/api/building/${id}/items`).then((response) => {
      context.commit("SET_ITEMS", response.data);
      return response;
    });
  },
  getSampleStatuses(context) {
    return axios
      .get(`/api/sample/statuses/?${Math.random().toString(36).substring(7)}`)
      .then((response) => {
        context.commit("SET_SAMPLE_STATUSES", response.data);
      });
  },
  updateSample(context, { data, building_id }) {
    return axios.put(`/api/sample/${data.id}`, data).then(() => {
      context.dispatch("getBuildingItems", building_id);
    });
  },
  updateLabAnalysis(context, { data, building_id }) {
    return axios
      .put(`/api/item/lead/lab_analysis/${data.id}`, data)
      .then(() => {
        context.dispatch("getBuildingItems", building_id);
      });
  },
  checkBagCode(context, { bag_code, building_id, contaminate_id }) {
    return axios.get(
      `/api/sample/bag/${bag_code}/building/${building_id}/${contaminate_id}`
    );
  },
  clearBuilding(context) {
    context.commit("CLEAR_SELECTED_BUILDING");
  },
  clearFloor(context) {
    context.commit("CLEAR_SELECTED_FLOOR");
  },
  clearRoom(context, floor) {
    context.commit("CLEAR_SELECTED_ROOM", floor);
  },
  clearItem(context, room = null) {
    context.commit("CLEAR_SELECTED_ITEM", room);
  },
  clearSurvey(context) {
    context.commit("CLEAR_SELECTED_SURVEY");
  },
  /**
   *
   * @param {*} context
   * @param {prop, data} data
   */
  setSelectedBuildingProp(context, data) {
    context.commit("SET_BUILDING_PROPERTY", data);
  },
  /**
   *
   * @param {*} context
   * @param {prop, data} data
   */
  setSelectedFloorProp(context, data) {
    context.commit("SET_FLOOR_PROPERTY", data);
  },
  setSelectedRoomProp(context, data) {
    context.commit("SET_SELECTED_ROOM_PROPERTY", data);
  },
  deleteItem(context, id) {
    return axios.delete("/api/item", { data: { id: id } });
  },
  removeItem(context, id) {
    context.commit("REMOVE_ITEM", id);
  },
  removeBuilding(context, id) {
    context.commit("REMOVE_BUILDING", id);
  },
  removeFloor(context, id) {
    context.commit("REMOVE_FLOOR", id);
  },
  removeRoom(context, id) {
    context.commit("REMOVE_ROOM", id);
  },
  createFeedback(context, feedback) {
    return axios.post(`/api/feedback`, feedback);
  },
  /**
   *
   * @param {*} context
   * @param {id, date} data
   */
  setSurveyDate(context, data) {
    return axios
      .put(`/api/building/${data.id}`, {
        survey_date: data.date,
      })
      .then(() => {
        context.dispatch("getBuilding", data.id);
      });
  },
  /**
   * Create a building note and delete the old one for that type, user version control
   * @param {*} context
   * @param {id, note: {type_id, content}} data
   */
  setBuildingNote(context, { id, note }) {
    return axios.post(`/api/building/${id}/note`, note);
  },
  setReportTemplate(context, { id, template }) {
    return axios.post(`/api/building/${id}/report-template`, template);
  },
  deleteFile(context, id) {
    return axios.delete("/api/file", { data: { id } });
  },
  setBrowseSurveySearch(context, search) {
    context.commit("SET_BROWSE_SURVEY_SEARCH", search);
  },
  setBrowseBuildingSearch(context, search) {
    return context.commit("SET_BROWSE_BUILDING_SEARCH", search);
  },
  setBrowseSurveyStatus(context, search) {
    context.commit("SET_BROWSE_SURVEY_STATUS", search);
  },
  setBrowseBuildingStatus(context, search) {
    context.commit("SET_BROWSE_BUILDING_STATUS", search);
  },
  setBrowseBuildingReportType(context, search) {
    context.commit("SET_BROWSE_BUILDING_REPORT_TYPE", search);
  },
  sendReviewBuilding(context, { id }) {
    return axios.put(`/api/building/${id}`, { status_id: 9 }).then(() => {
      return context.dispatch("getBuilding", id);
    });
  },
  approveActiveBuilding(context, building) {
    if (building.samples_count > 0) {
      return axios
        .put(`/api/building/${building.id}`, { status_id: 6 })
        .then(() => {
          return context.dispatch("getBuilding", building.id);
        });
    }
    return axios
      .put(`/api/building/${building.id}`, { status_id: 8 })
      .then(() => {
        return context.dispatch("getBuilding", building.id);
      });
  },
  denyReview(context, { id, deny }) {
    context.dispatch("setBuildingToNeedsEdits", id);
    return context
      .dispatch("setBuildingNote", {
        id: id,
        note: { type_id: 1, content: deny },
      })
      .then(() => {
        return context.dispatch("getBuilding", id);
      });
  },
  setBuildingToNeedsEdits(context, id) {
    return axios.put(`/api/building/${id}`, { status_id: 7 });
  },
  exportClientSearch(context, { id, search }) {
    return axios.get(`/api/export/client/search`, { params: { id, search } });
  },
  matchClient(context, { client_id, customer_ref }) {
    return axios.post(`/api/export/client/match`, { client_id, customer_ref });
  },
  unlinkClient(context, client_id) {
    return axios.put(`/api/export/client/unlink`, { client_id });
  },
  async exportClient(
    context,
    {
      client_id,
      enad_ref,
      name,
      billing_address,
      billing_address2,
      billing_suburb,
      billing_city,
      billing_postcode,
      contact_name,
      contact_email,
      contact_phone,
      name_short,
    }
  ) {
    const result = {
      enad_ref,
      name,
      billing_address,
      billing_address2,
      billing_suburb,
      billing_city,
      billing_postcode,
      contact_name,
      contact_email,
      contact_phone,
      name_short,
    };
    return axios.post(`/api/export/client/${client_id}`, result);
  },
  exportBuildingSearch(context, { id, search }) {
    return axios.get(`/api/export/building/search`, { params: { id, search } });
  },
  matchBuilding(context, { building_id, building_ref }) {
    return axios.post(`/api/export/building/match`, {
      building_id,
      building_ref,
    });
  },
  unlinkBuilding(context, building_id) {
    return axios.put(`/api/export/building/unlink`, { building_id });
  },
  exportBuilding(context, building) {
    return axios.post(`/api/export/building/${building.building_id}`, building);
  },
  exportReport(context, report_id) {
    return axios.get(`/api/export/report/${report_id}`);
  },
  exportReports(context, building_id) {
    return Axios.get(`/api/export/building/${building_id}/reports`);
  },
  exportAllFloors(context, { id }) {
    context.dispatch("setLoading");
    return axios
      .post(`/api/export/floor/all`, { building_id: id })
      .then(() => {
        context.dispatch("getBuilding", id);
      })
      .finally(() => {
        context.dispatch("setLoading", false);
      });
  },
  /**
   *
   * @param {*} context
   * @param {id: building id, floors: array of id's} param1
   */
  exportFloors(context, { id, floors }) {
    context.dispatch("setLoading");
    return axios
      .post(`/api/export/floor/some`, {
        building_id: id,
        floors,
      })
      .then(() => {
        context.dispatch("getFloors", id);
        context.dispatch("setLoading", false);
      });
  },
  exportRoomNotes(context, { id }) {
    return axios.post(`/api/export/building/notes`, { building_id: id });
  },
  /**
   *
   * @param {*} context
   * @param {client_id, paginate = 15, page = 1} param
   */
  getClientBuildings(
    context,
    {
      client_id,
      search,
      paginate = 15,
      page = 1,
      type = "client",
      status = "All",
      cancelToken,
    }
  ) {
    return axios.get(`/api/client/${client_id}/buildings`, {
      params: { paginate, page, search, type, status },
      cancelToken,
    });
  },
  clearSelected(context, keys) {
    if (!keys.find((key) => key == "survey")) {
      context.commit("CLEAR_SELECTED_SURVEY");
    }
    if (!keys.find((key) => key == "building")) {
      context.commit("CLEAR_SELECTED_BUILDING");
    }
    if (!keys.find((key) => key == "floor")) {
      context.commit("CLEAR_SELECTED_FLOOR");
    }
    if (!keys.find((key) => key == "room")) {
      context.commit("CLEAR_SELECTED_ROOM");
    }
    if (!keys.find((key) => key == "item")) {
      context.commit("CLEAR_SELECTED_ITEM");
    }
    if (!keys.find((key) => key == "office")) {
      context.commit("CLEAR_SELECTED_OFFICE");
    }
    if (!keys.find((key) => key == "laboratory")) {
      context.commit("CLEAR_SELECTED_LABORATORY");
    }
    if (!keys.find((key) => key == "client")) {
      context.commit("CLEAR_SELECTED_CLIENT");
    }
    if (!keys.find((key) => key == "contact")) {
      context.commit("CLEAR_SELECTED_CONTACTS");
    }
  },
  getUserReview() {
    return axios.get(`/api/user/buildings/review?${new Date().getTime()}`);
  },
  getUserSurvey() {
    return axios.get(`/api/user/buildings/survey?${new Date().getTime()}`);
  },
  getUserEdits() {
    return axios.get(`/api/user/buildings/edits?${new Date().getTime()}`);
  },
  getUserSurveys() {
    return axios.get(`/api/user/surveys`);
  },
  getManagementPlanContacts(context, building_id) {
    return axios.get(`/api/building/${building_id}/plan-contacts`);
  },
  requestManagementPlan(context, data) {
    return axios.post(`/api/building/request-plan`, data);
  },
  createLeadItem(context, item) {
    return axios.post(`/api/item/lead/create`, item);
  },
  async uploadReturnFile(context, { building, file, contaminate_id }) {
    const result = await createFormData({ return: file, contaminate_id });
    return axios.post(`/api/building/${building}/file`, result);
  },
  deleteReturnFile(context, file) {
    return axios.delete(`/api/file/${file}`);
  },
  redrawPolygons(context, floor_id) {
    return axios.get(`/api/floor/${floor_id}/redraw`).then(() => {
      return context.dispatch("getFloor", floor_id);
    });
  },
  getCsrfCookie(context) {
    return axios.get("/sanctum/csrf-cookie");
  },
  createRecommendation(context, recommendation) {
    return axios.post("/api/recommendation", recommendation);
  },
  updateRecommendation(context, recommendation) {
    return axios.put("/api/recommendation", recommendation);
  },
  deleteRecommendation(context, { id }) {
    return axios.delete(`/api/recommendation/${id}`);
  },
  getReports(context, { id, type }) {
    return axios.get(`/api/report/building/${id}`, { params: { type } });
  },
  getManagementPlans(context, { id, type }) {
    return axios.get(`/api/management-plans/building/${id}`, {
      params: { type },
    });
  },
  getClearances(context, building_id) {
    return axios.get(`/api/building/${building_id}/clearances`);
  },
  deleteClearance(context, clearance_id) {
    return axios.get(`/api/clearance/delete/${clearance_id}`);
  },
  duplicateClearance(context, clearance_id) {
    return axios.get(`/api/clearance/duplicate/${clearance_id}`);
  },
  setClearanceToActive(context, clearance_id) {
    return axios.get(`/api/clearance/set-to-active/${clearance_id}`);
  },
  getClearanceDataById(context, clearance_id) {
    return axios.get(`/api/clearance/get-clearance-by-id/${clearance_id}`);
  },
  getClearanceTemplate(context, building_id) {
    return axios.get(`/api/clearance/get-template/${building_id}`);
  },
  getSelectedClearanceCompany(context, clearance_id) {
    return axios.get(`/api/clearance/get-selected-companies/${clearance_id}`);
  },
  getClearanceCompanies(context) {
    return axios.get(`/api/clearance/get-companies`);
  },
  getSelectedClearanceRemovalist(context, clearance_id) {
    return axios.get(`/api/clearance/get-selected-removalist/${clearance_id}`);
  },
  getClearanceRemovalists(context) {
    return axios.get(`/api/clearance/get-removalists`);
  },
  insertUpdateClearance(context, data) {
    return axios.post("/api/clearance/insert-update", data);
  },
  generateClearance(context, data) {
    return axios.post("/api/clearance/generate-pdf", data);
  },
  getClearancePhotoLogs(context, clearance_id) {
    return axios.get(`/api/clearance/get-photo-logs/${clearance_id}`);
  },
  insertUpdatePhotoLogs(context, data) {
    return axios.post("/api/clearance/photo-log/insert-update", data);
  },
  getClearanceWorksafes(context, clearance_id) {
    return axios.get(`/api/clearance/get-work-safe/${clearance_id}`);
  },
  insertUpdateWorkSafes(context, data) {
    return axios.post("/api/clearance/work-safe/insert-update", data, {
      header: { "Content-Type": "multipart/form-data" },
    });
  },
  async uploadWorkSafeFile(context, { clearance_id, title, fileUp }) {
    const result = await createFormData({ clearance_id, title, fileUp });
    return axios.post(`/api/clearance/work-safe/insert-update`, result);
  },
  searchWorksafe(context, data) {
    return axios.post(`/api/clearance/work-safe/search`, data);
  },
  deleteWorksafeDocument(context, pdf_id) {
    return axios.get(`/api/clearance/work-safe/delete/${pdf_id}`);
  },
  insertUpdateRemovalCompany(context, data) {
    return axios.post(`/api/clearance/company/insert-update`, data);
  },
  getRemovalCompanies(context) {
    return axios.get(`/api/clearance/companies/all`);
  },
  getRemovalCompaniesByClearance(context, clearance_id) {
    return axios.get(
      `/api/clearance/companies/get-by-clearance/${clearance_id}`
    );
  },
  deleteRemovalCompany(context, company_id) {
    return axios.get(`/api/clearance/companies/delete/${company_id}`);
  },
  insertUpdateRemovalist(context, data) {
    return axios.post(`/api/clearance/removalists/insert-update`, data);
  },
  getRemovalists(context) {
    return axios.get(`/api/clearance/removalists/all`);
  },
  getRemovalistsByClearance(context, clearance_id) {
    return axios.get(
      `/api/clearance/removalists/get-by-clearance/${clearance_id}`
    );
  },
  deleteRemovalist(context, removalist_id) {
    return axios.get(`/api/clearance/removalists/delete/${removalist_id}`);
  },
  getClearanceAirSwabSamples(context, clearance_id) {
    return axios.get(`/api/clearance/get-air-swab-samples/${clearance_id}`);
  },
  saveClearanceAirSwabSamples(context, data) {
    return axios.post(`/api/clearance/air-swab-samples-insert-update`, data);
  },
  getClearanceAirSwabResults(context, clearance_id) {
    return axios.get(`/api/clearance/get-air-swab-result/${clearance_id}`);
  },
  async uploadAirSwabResultFile(context, { clearance_id, title, fileUp }) {
    const result = await createFormData({ clearance_id, title, fileUp });
    return axios.post(`/api/clearance/air-swab-result/insert-update`, result);
  },
  async uploadClearanceFloorPlan(context, { clearance_id, title, fileUp }) {
    const result = await createFormData({ clearance_id, title, fileUp });
    return axios.post(`/api/clearance/upload-floor-plan`, result);
  },
  deleteClearanceFloorPlan(context, clearance_id) {
    return axios.get(`/api/clearance/delete-floor-plan/${clearance_id}`);
  },
  deleteAirSwabResultDocument(context, pdf_id) {
    return axios.get(`/api/clearance/air-swab-result/delete/${pdf_id}`);
  },
  getClearanceForReview(context) {
    return axios.get(`/api/clearance/get-clearance-dash`);
  },
  getClearanceFloorPlan(context, clearance_id) {
    return axios.get(`/api/clearance/get-floor-plan/${clearance_id}`);
  },
  approveClearance(context, data) {
    return axios.post(`/api/clearance/approve`, data);
  },
  reviewClearance(context, data) {
    return axios.post(`/api/clearance/review`, data);
  },
  denyClearance(context, data) {
    return axios.post(`/api/clearance/deny`, data);
  },
  setClearanceForReview(context, data) {
    return axios.post(`/api/clearance/set-for-review`, data);
  },
  getBuildingReportStats(context, building_id) {
    if (!building_id) return;
    return axios.get(`/api/building/get-report-stats/${building_id}`);
  },
  checkIfHasClearance(context) {
    return axios.get(`/api/clearance/get-clearance-stats`);
  },
  checkIfHasDashboardData(context) {
    return axios.get(`/api/dashboard-stats`);
  },
  getClearanceAdditionalDocs(context, clearance_id) {
    return axios.get(`/api/clearance/get-additional-docs/${clearance_id}`);
  },
  insertUpdateAdditionalDocs(context, data) {
    return axios.post("/api/clearance/additional-doc/insert-update", data, {
      header: { "Content-Type": "multipart/form-data" },
    });
  },
  async uploadAdditionalDocFile(context, { clearance_id, title, fileUp }) {
    const result = await createFormData({ clearance_id, title, fileUp });
    return axios.post(`/api/clearance/additional-doc/insert-update`, result);
  },

  deleteAdditionalDocument(context, pdf_id) {
    return axios.get(`/api/clearance/additional-doc/delete/${pdf_id}`);
  },
  uploadBuildingImage(context, data) {
    return axios.post("/api/building/upload-image", data);
  },
  getAllCompleted(context, key) {
    return axios.get(`/api/tour/all-completed`, {
      params: {
        key,
      },
    });
  },
  acknowledge(context, data) {
    return axios.post("/api/tour/acknowledge", data);
  },
  get2faInfo(context) {
    return axios.get("/api/2fa/get-info");
  },
  set2faVerified(context) {
    context.commit("SET_2FA_VERIFIED", true);
    context.commit("SET_2FA", false);
    context.commit("SET_2FA_LOADED", false);
    return;
  },
  show2faDiv(context) {
    context.commit("SET_2FA_VERIFIED", false);
    context.commit("SET_2FA", true);
    context.commit("SET_2FA_LOADED", true);
    return;
  },
  reset2FA(context) {
    context.commit("SET_2FA_LOADED", false);
    context.commit("SET_2FA_VERIFIED", false);
    context.commit("SET_2FA", false);
    return;
  },
  get2FA(context) {
    return axios.post("/api/2fa/check");
  },
  verify2fa(context, data) {
    return axios.post("/api/2fa/verify", data);
  },
  setTutorialsSeen(context, data) {
    return axios.post("/api/tour/tutorials-enabled", data);
  },
  checkJobNumber(context, job_number) {
    return axios
      .get("/api/survey/job-number", { params: { job_number } })
      .then((response) => {
        return response;
      });
  },
  is_reviewer(context) {
    return !context.state.auth.user
      ? false
      : context.state.auth.user.roles.find((role) => role.id == 2);
  },
  getSurveyStatistics(context) {
    return axios.get("/api/statistics");
  },
  getUserMailSettings(context, user_id) {
    return axios.get("/api/user/mail/settings");
  },
  setUserMailSettings(context, data) {
    return axios.post("/api/user/mail/update", data);
  },
  checkStatisticDate(context, { date, dataset }) {
    const datasetTitle = ["asbestos", "lead", "clearance"][dataset];
    return axios.get("/api/building/check", {
      params: { date: date, dataset: datasetTitle },
    });
  },
  getRecommendationClient(context, data) {
    return axios.post("/api/recommendation/clients", data);
  },
  updateRecommendationClients(context, data) {
    let rp = axios.post("/api/recommendation/set-recommendation-client", data);
    context.dispatch("getAsbestosRecommendations");
    return rp;
  },

  validateEmailDomain(context, email) {
    let rp = axios.post("/api/auth/validate-email", { email });
    return rp;
  },
  async handleAuthCallback(code, error) {
    let rp = axios.post("/api/auth/callback", { code, error });
    return rp;
  }
};

export default actions;
