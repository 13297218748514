<template>
  <div>
    <v-app>
      <v-main v-if="!$auth.check() || $route.meta.noNav">
        <v-container fluid class="pa-0 ma-0">
          <router-view></router-view>
        </v-container>
      </v-main>
      <div v-else>
        <drawer v-bind:drawer.sync="drawer" v-if="!show2fa"></drawer>
        <app-bar v-bind:drawer.sync="drawer"></app-bar>
        <main-body></main-body>
        <v-footer class="mt-10">
          <v-row>
            <v-col class="text-center text--primary">
              Copyright © {{ new Date().getFullYear() }} Datanest Software Limited |
              <a
                class="text-decoration-none text--secondary"
                @click="showTnC = true"
                href="#"
                >Terms and Conditions</a
              >
              <terms-conditions-dialog
                :show="showTnC"
                @closeDialog="() => (showTnC = false)"
              />
            </v-col>
          </v-row>
        </v-footer>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MainBody from "../components/Main";
import Drawer from "../components/Drawer";
import AppBar from "../components/AppBar";
import TermsConditionsDialog from "../components/TermsConditionsDialog.vue";

export default {
  components: {
    MainBody,
    Drawer,
    AppBar,
    TermsConditionsDialog,
  },
  data: () => ({
    drawer: true,
    showTnC: false,
  }),
  methods: {
    ...mapActions(["getRouteIds"]),
  },
  computed: {
    ...mapGetters(["isAdmin", "isRoot", "isPM", "isExporter", "show2fa"]),
  },
  watch: {
    $route(to, from) {
      this.getRouteIds(to);
      this.$nextTick(() => {
        if (
          (this.$route.meta.isRoot && !this.isRoot) ||
          (this.$route.meta.isExporter && !this.isExporter) ||
          (this.$route.meta.isAdmin && !(this.isAdmin || this.isRoot)) ||
          (this.$route.meta.isPM && !(this.isRoot || this.isAdmin || this.isPM))
        ) {
          return this.$router.push("/dashboard");
        }
        return this.$vuetify.goTo(to.hash != "" ? to.hash : 0);
      });
    },
  },
};
</script>
