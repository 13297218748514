<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>Edit Survey Note</v-card-title>
        <v-card-text>
          <v-form>
            <v-textarea
              v-model="note"
              autocomplete="off"
              data-cy="note-input"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Close</v-btn>
          <v-btn color="primary" data-cy="note-save-btn" text @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["dialog", "aNote"],
  data: () => ({
    note: null
  }),
  mounted() {
    this.note = this.aNote;
  },
  computed: {},
  methods: {
    ...mapActions(["editSurvey", "getSurvey"]),
    close() {
      this.$emit("close");
    },
    save() {
      const survey = this.$route.params.survey;
      this.editSurvey({ id: survey, note: this.note }).then(() => {
        this.getSurvey(survey);
      });
      this.close();
    }
  }
};
</script>
