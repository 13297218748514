import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.show,"persistent":"","max-width":"800px"}},[_c(VCard,[_c(VCardTitle,[_c(VCol,{staticClass:"text-center"},[_c('h2',{staticClass:"h2"},[_vm._v("Create Client")])])],1),_c(VCardText,[_c('client-update',{attrs:{"dialog":true},on:{"close":_vm.close}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }