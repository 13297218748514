import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.asbestosProperties)?_c('div',[_c('tour',{key:_vm.toggleStep,attrs:{"tourKey":"item_add_scores"}}),_c(VRow,[_c(VCol,[_c('h2',[_c('span',{staticClass:"score-desc-step"},[_vm._v(_vm._s(_vm.group.name))])])])],1),(_vm.aItem.assessment_method_id == 2 && _vm.sampledAsItem)?_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-6"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"raised":"","color":"warning"},on:{"click":_vm.setScores}},on),[_vm._v(" Set Scoring ")])]}}],null,false,1083484321)},[_c('span',[_vm._v("Set all scoring to sampled as scores")])])],1)],1):_vm._e(),_c(VWindow,{attrs:{"touchless":""},model:{value:(_vm.toggleStep),callback:function ($$v) {_vm.toggleStep=$$v},expression:"toggleStep"}},_vm._l((_vm.asbestosProperties),function(property,i){return _c(VWindowItem,{key:property.id,attrs:{"value":property.id}},[_c('item-properties',{attrs:{"aItem":_vm.item,"property":property,"group":`${property.id}-${i}`,"sampledAsItem":_vm.sampledAsItem},on:{"update:aItem":function($event){_vm.item=$event},"update:a-item":function($event){_vm.item=$event},"next-step":_vm.nextStep}})],1)}),1),_c(VRow,[_c(VCol,{staticClass:"justify-space-between"},[_c(VItemGroup,{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.toggleStep),callback:function ($$v) {_vm.toggleStep=$$v},expression:"toggleStep"}},_vm._l((_vm.asbestosProperties),function(n){return _c(VItem,{key:`btn-${n.id}`,attrs:{"value":n.id}},[_c(VBtn,{attrs:{"input-value":n.id == _vm.toggleStep,"icon":"","color":_vm.isItemPropertySet(n.id) ? 'primary' : 'warning'},on:{"click":function($event){return _vm.setToggle(n.id)}}},[_c(VIcon,[_vm._v(_vm._s(n.id == _vm.toggleStep ? "fas" : "fal")+" fa-circle")])],1)],1)}),1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }