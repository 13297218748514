/* eslint-disable no-console */
import axios from "axios";
import VueAxios from "vue-axios";
import config from "../auth.js";
import auth from "@websanova/vue-auth/dist/v2/vue-auth.common.js";

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomePage";
import Login from "../views/LoginPage";
import AuthCallback from "../views/AuthCallback";
import TheDashboard from "../views/TheDashboard";
import ClientBuildings from "../views/ClientBuildings";
import BrowseBuildingPage from "../views/BrowseBuildingPage";
import TheSurvey from "../components/TheSurvey";
import SurveyCreate from "../components/SurveyCreate";
import RoomCreate from "../components/RoomCreate";
import BuildingCreate from "../components/BuildingCreate";
import FloorCreate from "../components/FloorCreate";
import Building from "../views/Building.vue";
import TheRoom from "../components/TheRoom";
import ItemCreate from "../views/ItemCreate.vue";
import ItemEdit from "../views/ItemCreate";
import Reports from "../views/Reports";
import ManagementPlans from "../views/ManagementPlans";
import TheFloors from "../components/TheFloors";
import MissingPage from "../views/404Page";
import TheClients from "../components/TheClients.vue";
import ContactCreate from "../components/ContactCreate.vue";
import ClientContacts from "../components/ClientContacts.vue";
import ClientUpdate from "../components/ClientUpdate.vue";
import CreateProject from "../views/CreateProject";
import Clearance from "../views/Clearance";
import AccountSetting from "../components/AccountSetting";
const ExportPage = () =>
  import(/* webpackChunkName: "export" */ "../views/Export");
const ExportClient = () =>
  import(/* webpackChunkName: "export" */ "../views/ExportClient");
const ExportBuildingSearch = () =>
  import(/* webpackChunkName: "export" */ "../views/ExportBuildingSearch");
const ExportBuilding = () =>
  import(/* webpackChunkName: "export" */ "../views/ExportBuilding");
const AdminPage = () =>
  import(/* webpackChunkName: "group-admin" */ "../views/AdminPage");
const TheOffices = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/TheOffices");
const TheLabs = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/TheLabs");
const TheUsers = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/TheUsers");
const OfficeEdit = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/OfficeEdit");
const UserUpdate = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/UserUpdate");
const LaboratoryEdit = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/LaboratoryEdit");
const OfficeCreate = () =>
  import(/* webpackChunkName: "group-admin" */ "../components/OfficeCreate");
const CreateLaboratory = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../components/CreateLaboratory"
  );
const CSVUpload = () =>
  import(/* webpackChunkName: "group-admin" */ "../views/DynamicUpload");
const Statistics = () =>
  import(/* webpackChunkName: "group-admin" */ "../views/Statistics");
const RecommendationManagement = () =>
  import(
    /* webpackChunkName: "group-admin" */ "../views/RecommendationManagement.vue"
  );

const routes = [
  { path: "/", redirect: "/dashboard" },
  {
    path: '/callback',
    name: 'microsoft-callback',
    component: AuthCallback,
  },
  {
    path: "/404",
    component: MissingPage,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/home",
    component: Home,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          title: "Dashboard",
        },
        component: TheDashboard,
      },
      {
        path: "/project",
        name: "Create Project",
        component: CreateProject,
      },
      {
        path: "/project/:survey",
        name: "Create Building",
        component: CreateProject,
      },
      {
        path: "/export",
        name: "Export",
        component: ExportPage,
        meta: {
          isExporter: true,
        },
      },
      {
        path: "/export/:clientExport",
        name: "Client Export",
        component: ExportClient,
        meta: {
          isRoot: true,
        },
      },
      {
        path: "/export/:clientExport/building",
        name: "Export Building",
        component: ExportBuildingSearch,
        meta: {
          isExporter: true,
        },
      },
      {
        path: "/export/:clientExport/building/:buildingExport",
        name: "Client Building",
        component: ExportBuilding,
        meta: {
          isExporter: true,
        },
      },
      {
        path: "/survey/:survey",
        name: "Survey",
        component: TheSurvey,
      },
      {
        path: "/survey/:survey/edit",
        name: "Edit Survey",
        props: true,
        component: SurveyCreate,
      },
      {
        path: "/survey/:survey/building",
        name: "Create Survey Building",
        props: true,
        component: BuildingCreate,
      },
      {
        path: "/survey/:survey/building/:building",
        name: "Building",
        props: true,
        component: Building,
      },
      {
        path: "/survey/:survey/building/:building/edit",
        name: "Edit Building",
        component: BuildingCreate,
      },
      {
        path: "/survey/:survey/building/:building/floor",
        name: "Create Floor",
        props: true,
        component: FloorCreate,
      },
      {
        path: "/survey/:survey/building/:building/clearance",
        name: "Create Clearance",
        props: true,
        component: Clearance,
      },
      {
        path: "/survey/:survey/building/:building/reports",
        name: "Reports",
        props: true,
        component: Reports,
      },
      {
        path: "/survey/:survey/building/:building/management-plans",
        name: "Management Plans",
        props: true,
        component: ManagementPlans,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor",
        name: "Floor",
        component: TheFloors,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor/edit",
        name: "Edit Floor",
        props: true,
        component: FloorCreate,
      },
      {
        path: "/survey/:survey/building/:building/clearance/:clearance/edit",
        name: "Edit Clearance",
        props: true,
        meta: {
          reload: true,
        },
        component: Clearance,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor/room",
        name: "Create Room",
        props: true,
        component: RoomCreate,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor/room/:room/edit",
        name: "Edit Room",
        props: true,
        component: RoomCreate,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor/room/:room",
        name: "Room",
        props: true,
        component: TheRoom,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor/room/:room/item",
        name: "Create Item",
        props: true,
        component: ItemCreate,
      },
      {
        path: "/survey/:survey/building/:building/floor/:floor/room/:room/item/:item",
        name: "Edit Item",
        props: true,
        component: ItemEdit,
      },
      {
        path: "/browse",
        name: "Browse",
        component: BrowseBuildingPage,
      },
      {
        path: "/clients",
        name: "Clients",
        component: TheClients,
      },
      {
        path: "/client/:client/contacts",
        name: "Contacts",
        props: true,
        component: ClientContacts,
      },
      {
        path: "/client/:client/contact",
        name: "Create Contact",
        props: true,
        component: ContactCreate,
      },
      {
        path: "/client/:client/contact/:contact",
        name: "Contact",
        props: true,
        component: ContactCreate,
      },
      {
        path: "/client/:client",
        name: "Edit Client",
        component: ClientUpdate,
      },
      {
        path: "/client/:client/buildings",
        name: "Client Buildings",
        component: ClientBuildings,
      },
      {
        path: "/client",
        name: "Create Client",
        component: ClientUpdate,
      },
      {
        path: "/account",
        name: "Account setting",
        component: AccountSetting,
      },
      {
        path: "/admin",
        name: "Administration",
        component: AdminPage,
        children: [
          {
            path: "offices",
            name: "Offices",
            component: TheOffices,
            meta: {
              isPM: true,
            },
          },
          {
            path: "office/:office",
            name: "Edit Office",
            component: OfficeEdit,
            meta: {
              isPM: true,
            },
          },
          {
            path: "office",
            name: "Create Office",
            component: OfficeCreate,
            meta: {
              isPM: true,
            },
          },
          {
            path: "laboratories",
            name: "Laboratories",
            component: TheLabs,
            meta: {
              isPM: true,
            },
          },
          {
            path: "laboratory",
            name: "Create Laboratory",
            component: CreateLaboratory,
            meta: {
              isPM: true,
            },
          },
          {
            path: "laboratory/:laboratory",
            name: "Edit Laboratory",
            component: LaboratoryEdit,
            meta: {
              isPM: true,
            },
          },
          {
            path: "users",
            name: "Users",
            component: TheUsers,
            meta: {
              isAdmin: true,
            },
          },
          {
            path: "user/:user",
            name: "Edit User",
            component: UserUpdate,
            meta: {
              isAdmin: true,
            },
          },
          {
            path: "user",
            name: "Create User",
            component: UserUpdate,
            meta: {
              isAdmin: true,
            },
          },
          {
            path: "csv",
            name: "CSV Upload",
            component: CSVUpload,
            meta: {
              isPM: true,
            },
          },
          {
            path: "statistics",
            name: "Statistics",
            component: Statistics,
            meta: {
              isPM: true,
            },
          },
          {
            path: "recommendations",
            name: "Recommendation Management",
            component: RecommendationManagement,
            meta: {
              isAdmin: true,
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
axios.defaults.baseURL = `/`;
Vue.router = router;
(config.plugins = {
  router: Vue.router,
  http: Vue.axios,
}),
  Vue.use(auth, config);

export default router;
