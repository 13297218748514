import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticClass:"primary text--white above",attrs:{"app":"","clipped-left":""}},[_c(VAppBarNavIcon,{attrs:{"data-cy":"menu"},on:{"click":function($event){_vm.draw = !_vm.draw}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("fas fa-bars")])],1),_c('a',{attrs:{"href":"#/dashboard"}},[_c(VAvatar,[_c('img',{attrs:{"data-cy":"ccs-logo","src":"/img/icons_white/apple-touch-icon-120x120.png","alt":"logo"}})])],1),_c(VSpacer),(_vm.show2fa)?_c('div',{staticClass:"float-right mr-12"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.logout()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white","info":""}},[_vm._v("fas fa-sign-out")])],1)]}}],null,false,1789445249)},[_c('span',[_vm._v("Logout")])])],1):_c('message-notification')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }