<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col sm="6" cols="12" class="pb-0 mb-0">
          <v-text-field
            spellcheck="true"
            v-model="building.name"
            :placeholder="`Enter ${isNotClearance ? 'building' : 'clearance'} name`"
            outlined
            v-validate="'required|max:190'"
            counter
            maxlength="190"
            :error-messages="errors.collect('name')"
            label="Name*"
            messages="*Required"
            data-vv-name="name"
            required
          ></v-text-field>
        </v-col>
        <v-col sm="6" cols="12" class="pb-0 mb-0" v-if="report_types">
          <v-autocomplete
            autocomplete="off"
            v-model="building.report_type_id"
            placeholder="Select report type"
            outlined
            v-validate="'required'"
            :items="report_types"
            item-text="name"
            item-value="id"
            :error-messages="errors.collect('report type')"
            label="Report type*"
            data-vv-name="report type"
            data-cy="report-type"
            messages="*Required"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="6" cols="12" class="py-0 my-0">
          <v-text-field
            spellcheck="true"
            autocomplete="off"
            v-model="building.phase"
            :placeholder="`Enter an identifier for the ${
              isNotClearance ? 'building' : 'clearance'
            }`"
            outlined
            v-validate="'max:190'"
            counter
            maxlength="190"
            :error-messages="errors.collect('phase')"
            label="Identifier"
            data-vv-name="phase"
            data-cy="phase"
          ></v-text-field>
        </v-col>
        <v-col sm="6" cols="12" class="py-0 my-0">
          <v-autocomplete
            attach
            autocomplete="off"
            v-model="building.laboratory_id"
            outlined
            :items="laboratories"
            placeholder="Select the laboratory samples will be sent to"
            item-text="name"
            item-value="id"
            data-cy="laboratory"
            label="Laboratory"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-autocomplete
            data-cy="surveyor"
            attach
            autocomplete="off"
            v-model="building.surveyors"
            outlined
            v-validate="'required|length:1,5'"
            :items="surveyors"
            item-text="full_name"
            item-value="id"
            :error-messages="errors.collect('surveyor')"
            label="Surveyor"
            data-vv-name="surveyor"
            persistent-hint
            hint="First assigned surveyor will be the lead surveyor"
            required
            multiple
            small-chips
            chips
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            attach
            autocomplete="off"
            v-model="building.reviewer_id"
            outlined
            :items="reviewers"
            item-text="full_name"
            item-value="id"
            label="Reviewer"
            persistent-hint
            hint="Select the reviewer who will review the project"
            data-cy="reviewer"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            attach
            autocomplete="off"
            v-model="building.approver_id"
            outlined
            :items="approvers"
            item-text="full_name"
            item-value="id"
            label="Approver"
            persistent-hint
            hint="Select the approver who will approve the project"
            data-cy="approver"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 my-0">
          <v-textarea
            autocomplete="off"
            v-model="building.description"
            outlined
            :error-messages="errors.collect('description')"
            :placeholder="`Enter a ${
              isNotClearance ? 'building' : 'clearance'
            } description`"
            label="Description"
            data-vv-name="description"
            data-cy="description"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="my-0 py-0">
          <h3 class="primary--text">Address</h3>
          <br />
          <address-form
            :addresses="building.address"
            v-bind="building.address"
            v-on:update:city="setBuildingAddress($event, 'city')"
            v-on:update:suburb="setBuildingAddress($event, 'suburb')"
            v-on:update:postcode="setBuildingAddress($event, 'postcode')"
            v-on:update:address="setBuildingAddress($event, 'address')"
            v-on:update:address2="setBuildingAddress($event, 'address2')"
            v-on:update:geolocation="setBuildingAddress($event, 'geolocation')"
            v-on:update:latitude="setBuildingAddress($event, 'latitude')"
            v-on:update:longitude="setBuildingAddress($event, 'longitude')"
            v-bind:clearAddress.sync="clearAddress"
          ></address-form>
        </v-col>
      </v-row>

      <v-row v-show="isNotClearance">
        <v-col>
          <h3 class="primary--text">Additional details</h3>
          <v-row>
            <v-col
              v-if="
                building.report_type_id == DEMOLITION ||
                building.report_type_id == NZDF_REFURBISHMENT
              "
            >
              <v-checkbox
                v-model="building.is_answering_questions"
                label="Show priority scoring?"
                messages="Displays priority scoring in the report, material scoring is always displayed"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="building.is_residential"
                label="Is Residential?"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="building.has_multiple_images"
                label="Allow multiple images on items?"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="building.has_site_plan"
                label="Allow site plan upload?"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="building.has_sample_polygon"
                label="Show original sample polygons on sampled as items?"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :disabled="building.asbestos_count && building.asbestos_count > 0"
                v-model="building.is_asbestos"
                label="Allow collection of asbestos items?"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                :disabled="building.lead_count && building.lead_count > 0"
                v-model="building.is_lead"
                label="Allow collection of lead items?"
              ></v-checkbox>
            </v-col>
            <v-col v-if="building.company_id === 103">
              <v-checkbox
                v-model="building.summary_floor_plan"
                label="Summary Floor Plan?"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Label size"
                type="number"
                v-model="building.label_size"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3 class="primary--text">Images</h3>
          <v-file-input
            v-model="building.file"
            accept="image/*"
            label="Building Image"
            data-cy="image-upload"
          ></v-file-input>
          <v-row justify="center" style="max-width: 500px; margin: auto">
            <v-col>
              <a-img v-if="building.file" :src="building.file"></a-img>
              <a-img v-if="building.image && !building.file" :src="image"></a-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="isNotClearance && building.has_site_plan">
        <v-col>
          <v-file-input
            v-model="building.site_file"
            accept="image/*"
            label="Site Plan"
          ></v-file-input>
          <v-row justify="center" style="max-width: 500px; margin: auto">
            <v-col>
              <a-img v-if="building.site_file" :src="building.site_file"></a-img>
              <a-img
                v-if="building.site_plan && !building.site_file"
                :src="siteImage"
              ></a-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import AddressForm from "./AddressForm.vue";
import { NZDF_REFURBISHMENT, DEMOLITION } from "../constants/reportTypes";

export default {
  components: {
    AddressForm,
  },
  props: {
    aBuilding: {
      type: Object,
      required: true,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    clearAddress: false,
    reader: null,
    uploadedReader: null,
    uploadedImage: null,
    uploadedSiteImage: null,
    theImage: null,
    image: null,
    siteImage: null,
    edit: null,
  }),
  created() {
    this.NZDF_REFURBISHMENT = NZDF_REFURBISHMENT;
    this.DEMOLITION = DEMOLITION;
    this.$validator = this.validator;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.building.image && this.building.id) {
        this.image = `/api/image/building/${this.building.id}?updated=${this.building.updated_at}`;
      }

      if (this.building.site_plan && this.building.id) {
        this.siteImage = `/api/image/building/${this.building.id}/site_plan?updated=${this.building.updated_at}`;
      }
    });
  },
  methods: {
    setBuildingAddress(data, field) {
      this.building.address[field] = data;
    },
  },
  computed: {
    ...mapGetters({
      laboratories: "extantLaboratories",
      report_types: "report_types",
      selectedBuilding: "building",
      invalidInput: "invalidInput",
      surveyors: "surveyors",
      reviewers: "reviewers",
      approvers: "approvers",
    }),
    building: {
      get() {
        return this.aBuilding;
      },
      set(updated) {
        this.$emit("update:aBuilding", updated);
      },
    },
    isNotClearance() {
      return this.building.report_type_id != 6;
    },
  },
};
</script>
