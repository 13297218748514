import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-0 ma-0"},[_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-0 ma-0"},[(_vm.$route.name)?_c(VBreadcrumbs,{attrs:{"large":"","items":_vm.routes,"data-cy":"breadcrumbs-item"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VBreadcrumbsItem,{attrs:{"to":item.to,"exact":item.exact,"disabled":item.disabled}},[(item.tooltip)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g({attrs:{"data-cy":"menu-clients","attrs":attrs}},on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])]):_c('span',{attrs:{"data-cy":"survey"}},[_vm._v(_vm._s(item.text))])],1)]}}],null,false,2685793592)}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{staticClass:"px-9 py-0 my-0",attrs:{"data-cy":"building-info","justify":"center"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }