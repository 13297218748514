<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-btn
          color="primary"
          @click="dialog = true"
          :disabled="!clearance_class"
        >
          <span v-if="originalSelection.length < 1">
            <v-icon>
              fas fa-plus
            </v-icon>
            No companies selected
          </span>
          <span v-else>
            <v-icon> fas fa-building </v-icon>
            {{ originalSelection.length }}
            {{ originalSelection.length > 1 ? "companies" : "company" }}
            selected
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title color="primary" dark
          >Asbestos Removal companies</v-card-title
        >
        <v-card-text>
          <div v-show="showSelected">
            <table class="clearance-table">
              <th>&nbsp;</th>
              <th>Company</th>
              <th>Licenses</th>
              <th>Description</th>
              <th>Actions</th>
              <tr
                v-for="(company, ckey) in companyList"
                :key="ckey"
                :class="{
                  'text-error':
                    (clearance_class == 1 && !company.license_no_a) ||
                    (clearance_class == 2 && !company.license_no_b)
                }"
              >
                <td style="width:10%;">
                  <v-checkbox
                    v-model="selectedCompanies"
                    :value="company.id"
                  ></v-checkbox>
                </td>
                <td style="width:20%;">{{ company.name }}</td>
                <td style="width:20%;">{{ company.licenses }}</td>
                <td style="width:30%;">{{ company.description }}</td>
                <td style="width:20%;" class="text-center">
                  <v-btn
                    fab
                    small
                    class="mr-2"
                    color="warning"
                    @click="editCompany(company)"
                  >
                    <v-icon>
                      fas fa-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    color="error"
                    @click="deleteCompany(company.id)"
                  >
                    <v-icon>
                      fas fa-trash
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </table>

            <div class="text-center" v-if="hasHighlighted">
              Rows highlighted don’t have the nessessary clearance license
            </div>
          </div>
          <div v-show="showAddNew">
            <v-row>
              <v-col>
                <v-text-field
                  spellcheck="true"
                  label="Company name"
                  outlined
                  v-model.lazy="companyObj.name"
                  v-validate="'required|max:190'"
                  maxlength="190"
                  :error-messages="errors.collect('company name')"
                  data-vv-name="company name"
                  placeholder="Enter company name"
                  messages="*Required"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  spellcheck="true"
                  label="Class A license"
                  outlined
                  v-model.lazy="companyObj.license_no_a"
                  v-validate="'max:190'"
                  maxlength="190"
                  :error-messages="errors.collect('class A license number')"
                  data-vv-name="class A license number"
                  placeholder="Enter class A license number"
                  messages="Optional"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  spellcheck="true"
                  label="Class B license"
                  outlined
                  v-model.lazy="companyObj.license_no_b"
                  v-validate="'max:190'"
                  maxlength="190"
                  :error-messages="errors.collect('class B license number')"
                  data-vv-name="class B license number"
                  placeholder="Enter class B license number"
                  messages="Optional"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description"
                  placeholder="Please enter description"
                  v-model="companyObj.description"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn color="warning" v-if="showSelected" @click="addNewCompany"
                >New</v-btn
              >
            </v-col>
            <v-col>
              <div class="float-right">
                <v-btn
                  class="mr-1 "
                  color="success"
                  v-if="showAddNew"
                  @click="saveChanges"
                  >Save changes</v-btn
                >
                <v-btn
                  class="mr-1 "
                  v-if="showSelected"
                  color="primary"
                  @click="saveSelected"
                  >Save selected</v-btn
                >
                <v-btn color="error" v-if="showSelected" @click="close"
                  >Close</v-btn
                >
                <v-btn
                  @click="(showAddNew = false), (showSelected = true)"
                  color="error"
                  v-if="showAddNew"
                  >Cancel</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this company?</v-card-title
        >
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(dialogDelete = false), (selectedCompany = false)"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="confirmDelete"
            >Confirm</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["clearance_id", "clearance_class"],
  data() {
    return {
      pagination: {
        sortBy: "name"
      },
      companyList: [],
      originalSelection: [],
      selectedCompanies: [],
      showSelected: true,
      showAddNew: false,
      dialog: false,
      dialogDelete: false,
      selectedCompany: null,
      companyObj: {
        id: null,
        name: null,
        license_no: null,
        description: null
      },
      defaultCompanyObj: {
        id: null,
        name: null,
        license_no_a: null,
        license_no_b: null,
        description: null
      },
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name"
        },
        { text: "Licenses", value: "licenses" },
        { text: "Description", value: "description" }
      ]
    };
  },
  computed: {
    ...mapGetters({ invalidInput: "invalidInput" }),
    hasHighlighted() {
      let hasNoLicense = false;
      this.companyList.forEach(company => {
        if (
          (this.clearance_class == 1 && !company.license_no_a) ||
          (this.clearance_class == 2 && !company.license_no_b)
        ) {
          hasNoLicense = true;
          return;
        }
      });

      return hasNoLicense;
    }
  },
  methods: {
    ...mapActions([
      "getRemovalCompanies",
      "getRemovalCompaniesByClearance",
      "insertUpdateRemovalCompany",
      "deleteRemovalCompany"
    ]),

    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    addNewCompany() {
      this.showAddNew = true;
      this.showSelected = false;
      this.companyObj = JSON.parse(JSON.stringify(this.defaultCompanyObj));
    },
    editCompany(cmp) {
      this.showAddNew = true;
      this.showSelected = false;
      this.companyObj = JSON.parse(JSON.stringify(cmp));
    },
    deleteCompany(cmp_id) {
      this.selectedCompany = cmp_id;
      this.dialogDelete = true;
    },
    confirmDelete() {
      this.deleteRemovalCompany(this.selectedCompany).then(() => {
        this.dialogDelete = false;
        var index = this.selectedCompanies.indexOf(this.selectedCompany);
        if (index !== -1) {
          this.selectedCompanies.splice(index, 1);
        }
        var index = this.originalSelection.indexOf(this.selectedCompany);
        if (index !== -1) {
          this.originalSelection.splice(index, 1);
        }
        this.getCompanies();
      });
    },
    saveChanges() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.insertUpdateRemovalCompany({
          company: this.companyObj
        }).then(() => {
          this.showAddNew = false;
          this.showSelected = true;
          this.getCompanies();
        });
      });
    },
    getCompanies() {
      this.getRemovalCompanies().then(response => {
        this.companyList = response.data.companies;
      });
    },
    getClearanceCompanySelected() {
      if (this.clearance_id) {
        this.getRemovalCompaniesByClearance(this.clearance_id).then(
          response => {
            this.selectedCompanies = response.data.companies;
            this.originalSelection = response.data.companies;
          }
        );
      } else {
        this.selectedCompanies = [];
        this.originalSelection = [];
      }
    },
    initialise() {
      this.getCompanies();
      this.getClearanceCompanySelected();
    },
    saveSelected() {
      this.originalSelection = JSON.parse(
        JSON.stringify(this.selectedCompanies)
      );
      this.close();
    },
    close() {
      this.dialog = false;
      this.$emit("saveSelectedCompanies", this.originalSelection);
    }
  },
  mounted() {
    this.initialise();
  }
};
</script>
<style scoped>
.clearance-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.clearance-table td,
.clearance-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.clearance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.clearance-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #94959c;
  color: white;
}

.text-error {
  background-color: #ff8181 !important;
  color: white;
}
</style>
