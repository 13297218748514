export default Object.freeze({
    SAMPLED: 1,
    SAMPLED_AS: 2,
    STRONGLY_PRESUMED: 3,
    PRESUMED: 4,
    INACCESSIBLE: 5,
    PREVIOUSLY_SAMPLED: 6,
    LEAD_SAMPLED: 7,
    LEAD_PREVIOUSLY_SAMPLED: 8,
    LEAD_XRF: 9,
    LEAD_INACCESSIBLE: 10,
    BMIS_ASBESTOS: 11,
    BMIS_LEAD: 12,
    NOT_SUSPICIOUS: 13,
});