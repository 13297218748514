<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          @click="show = true"
          color="primary"
          class="elevation-0"
        >
          <span class="body-1">{{ building.items_count }}</span>
        </v-btn>
      </template>
      <span>View building items</span>
    </v-tooltip>
    <v-dialog v-model="show" persistent scrollable max-width="1200px">
      <v-card>
        <v-card-title
          >{{ building.name }} Items <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="fas fa-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field
        ></v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="items" :search="search">
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    show: false,
    search: null,
    headers: [
      { text: "Floor", value: "floor_name" },
      { text: "Room", value: "room_name" },
      {
        text: "Item",
        value: "full_name"
      },
      { text: "Contaminant", value: "contaminate_name" },
      { text: "Assessment Method", value: "assessment_name" },
      { text: "Status", value: "sample_status" },
      { text: "Sample ID", value: "bag_code" }
    ]
  }),
  computed: {
    ...mapGetters(["items", "building", "floors", "getAssessmentMethodById"])
  }
};
</script>
