<template>
  <v-dialog v-model="display" scrollable max-width="700px">
    <tour tourStep="item_view" />
    <v-card v-if="item">
      <v-card-title data-cy="item-details">{{ item.full_name }}</v-card-title>
      <v-card-text>
        <div class="asbestos-risks-step" v-if="item.contaminate_id == 1">
          <v-row v-if="!isInaccessible && item.item_scores.length == 12">
            <v-col class="center-table">
              <v-simple-table class="table-width mx-auto" data-cy="item-table-details">
                <template v-slot:default>
                  <thead class="primary">
                    <tr>
                      <th class="table-col-width">
                        <span class="white--text"></span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text"> Material Assessment Score </span>
                      </th>
                      <th
                        class="table-col-width"
                        :class="demolitionScoring() ? 'text-decoration-line-through' : ''"
                      >
                        <span class="white--text"> Priority Assessment Score </span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text"> Total Score </span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text"> Risk </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="table-col-width">
                        <strong>UK HSE</strong>
                      </td>
                      <td class="table-col-width">
                        {{ getMaterialScore("UK HSE").toFixed(2) }}
                      </td>
                      <td
                        class="table-col-width"
                        :class="demolitionScoring() ? 'text-decoration-line-through' : ''"
                      >
                        {{ Math.ceil(priorityScore).toFixed(2) }}
                      </td>
                      <td class="table-col-width" data-cy="uk-total-score">
                        {{ getTotalScore("UK HSE").toFixed(2) }}
                      </td>
                      <td>
                        {{ getRiskRating("UK HSE") }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-col-width">
                        <strong>NZ</strong>
                      </td>
                      <td class="table-col-width">
                        {{ getMaterialScore("NZ").toFixed(2) }}
                      </td>
                      <td
                        class="table-col-width"
                        :class="demolitionScoring() ? 'text-decoration-line-through' : ''"
                      >
                        {{ Math.ceil(priorityScore).toFixed(2) }}
                      </td>
                      <td class="table-col-width" data-cy="nz-total-score">
                        {{ getTotalScore("NZ").toFixed(2) }}
                      </td>
                      <td>
                        {{ getRiskRating("NZ") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="center-table">
              <v-simple-table class="table-width mx-auto" data-cy="item-table-details">
                <template v-slot:default>
                  <thead class="primary">
                    <tr>
                      <th class="table-col-width">
                        <span class="white--text">Assessment Method</span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text">Sample Status</span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text">Amount</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="table-col-width">
                        {{ item.assessment_name }}
                      </td>
                      <td class="table-col-width" data-cy="item-sample-status">
                        <p>
                          {{ item.sample_status }}
                        </p>
                      </td>
                      <td class="table-col-width" data-cy="item-amount">
                        <p v-if="item.amount_type_id">
                          {{ item.amount || 0 }}
                          <span v-html="amountTypeById(item.amount_type_id).name"></span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="body-1">
                <strong>{{ item.comment ? "Comment" : "No Comment" }}</strong
                ><br />
                <span v-if="item.comment">{{ item.comment }}</span>
              </p>
              <p class="body-1">
                <strong>
                  {{
                    item.item_recommendations.length > 0
                      ? "Recommendations"
                      : "No recommendations"
                  }}</strong
                >
              </p>
              <ul v-if="item.item_recommendations.length > 0">
                <li
                  v-for="recommendation in item.item_recommendations"
                  :key="recommendation"
                >
                  {{
                    asbestosRecommendations.find((rec) => rec.id == recommendation).name
                  }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
        <div v-if="item.contaminate_id == 2">
          <v-row>
            <v-col class="center-table">
              <v-simple-table class="table-width mx-auto">
                <template v-slot:default>
                  <thead class="primary">
                    <tr>
                      <th class="table-col-width">
                        <span class="white--text">Colour</span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text">Paint condition</span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text">Lab Analysis</span>
                      </th>
                      <th class="table-col-width">
                        <span class="white--text">XRF</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="table-col-width" data-cy="item-colour">
                        {{ item.lead.colour || "N/A" }}
                      </td>
                      <td class="table-col-width" data-cy="paint-condition">
                        {{ item.lead.paint_condition || "N/A" }}
                      </td>
                      <td class="table-col-width" data-cy="item-analysis">
                        <span
                          v-if="
                            item.lead.laboratory_analysis &&
                            item.assessment_method_id != LEAD_INACCESSIBLE
                          "
                          :class="
                            item.lead.laboratory_analysis >= 1000
                              ? 'red--text'
                              : 'green--text'
                          "
                        >
                          {{ item.lead.laboratory_analysis }} mg/kg
                        </span>
                        <span v-else>N/A</span>
                      </td>
                      <td class="table-col-width" data-cy="item-analysis">
                        <span
                          v-if="item.lead.xrf_result"
                          :class="
                            item.lead.xrf_result >= 1000 ? 'red--text' : 'green--text'
                          "
                        >
                          {{ item.lead.xrf_result }} ppm
                        </span>
                        <span v-else>N/A</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p class="body-1">
                <strong>{{ item.comment ? "Comment" : "No Comment" }}</strong
                ><br />
                <span v-if="item.comment">{{ item.comment }}</span>
              </p>
            </v-col>
          </v-row>
        </div>
        <v-row v-if="item.polygon_image" justify="center" style="margin: auto">
          <v-col>
            <a-img
              style="max-width: 600px"
              :src="`/api/image/item/${item.id}/polygon_image?${Math.random()}`"
            ></a-img>
          </v-col>
        </v-row>
        <v-row v-if="item.image" justify="center" style="margin: auto">
          <v-col>
            <a-img
              style="max-width: 600px"
              :src="`/api/image/item/${item.id}?${Math.random()}`"
            ></a-img>
          </v-col>
        </v-row>
        <v-row v-if="item.image_alt" justify="center" style="margin: auto">
          <v-col>
            <a-img
              style="max-width: 600px"
              :src="`/api/image/item/${item.id}/image_alt?${Math.random()}`"
            ></a-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Tour from "./Tour";
import ASSESSMENT_METHODS from "../constants/assessmentMethods";

export default {
  props: ["show", "item"],
  components: { Tour },
  created() {
    this.DEMOLITION = 2;
    this.NZDF_REFURBISHMENT = 5;
    this.INACCESSIBLE = 5;
    this.LEAD_XRF = ASSESSMENT_METHODS.LEAD_XRF;
    this.LEAD_INACCESSIBLE = ASSESSMENT_METHODS.LEAD_INACCESSIBLE;
  },
  computed: {
    ...mapGetters([
      "getAssessmentMethodById",
      "sampleStatusById",
      "amountTypeById",
      "asbestosRecommendations",
      "asbestos",
      "building",
    ]),
    display: {
      get() {
        return this.show;
      },
      set(updated) {
        this.$emit("update:show", updated);
      },
    },
    isInaccessible() {
      return this.item.assessment_method_id == this.INACCESSIBLE;
    },
    itemRecommendations: {
      get() {
        return this.item.item_recommendations;
      },
      set(values) {
        this.noRecommendation = false;
        this.setItemRecommendation(values);
      },
    },
    materialScore() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return 0;
      return this.getMaterialScore();
    },
    priorityScore() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return 0;
      return this.getPriorityScore();
    },
    totalScore() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return 0;
      return this.getTotalScore();
    },
    riskRating() {
      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return "Negative";
      return this.getRiskRating();
    },
  },
  methods: {
    demolitionScoring() {
      return (
        (this.building.report_type_id == this.DEMOLITION ||
          this.building.report_type_id == this.NZDF_REFURBISHMENT) &&
        !this.building.is_answering_questions
      );
    },
    getAmountScore() {
      switch (this.item.amount_type_id) {
        case 1:
        case 3:
          if (this.item.amount <= 0.5) return 0;
          else if (this.item.amount <= 10) return 1;
          else if (this.item.amount <= 50) return 2;
          return 3;
        case 4:
          return 3;
        case 2:
          return this.item.amount > 0 ? 1 : 0;
      }
    },
    getLikelihoodScore() {
      let likelihoodGroup = this.asbestos.find((group) => group.id == 3);
      let location = likelihoodGroup.properties.find((property) => property.id == 6);
      let locationScore = location.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let accessibility = likelihoodGroup.properties.find((property) => property.id == 7);
      let accessibilityScore = accessibility.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let amount = this.getAmountScore();
      return (locationScore[0].score + accessibilityScore[0].score + amount) / 3;
    },
    getActivityScore() {
      let activityGroup = this.asbestos.find((group) => group.id == 2);
      let activityProperty = activityGroup.properties.find(
        (property) => property.id == 5
      );
      let activityScore = activityProperty.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      return activityScore[0].score;
    },
    getExposureScore() {
      let exposureGroup = this.asbestos.find((group) => group.id == 4);
      let occupancy = exposureGroup.properties.find((property) => property.id == 8);
      let occupancyScore = occupancy.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let frequency = exposureGroup.properties.find((property) => property.id == 9);
      let frequencyScore = frequency.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let usage = exposureGroup.properties.find((property) => property.id == 10);
      let usageScore = usage.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      return (
        (occupancyScore[0].score + frequencyScore[0].score + usageScore[0].score) / 3
      );
    },
    getMaintenanceScore() {
      let maintenanceGroup = this.asbestos.find((group) => group.id == 5);
      let type = maintenanceGroup.properties.find((property) => property.id == 11);
      let typeScore = type.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let activity = maintenanceGroup.properties.find((property) => property.id == 12);
      let activityScore = activity.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      return (typeScore[0].score + activityScore[0].score) / 2;
    },
    getPriorityScore() {
      return (
        this.getActivityScore() +
        this.getLikelihoodScore() +
        this.getExposureScore() +
        this.getMaintenanceScore()
      );
    },
    getMaterialScore(scoringType = "UK HSE") {
      const SAMPLED = 1;
      const SAMPLED_AS = 2;
      const STRONGLY_PRESUMED = 3;
      const PRESUMED = 4;
      const PREVIOUSLY_SAMPLED = 6;
      if (this.isInaccessible || this.item.is_negative) return 0;
      let priorityGroup = this.asbestos.find((group) => group.id == 1);
      let productType = priorityGroup.properties.find((property) => property.id == 1);
      let productScore = productType.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let extentDamage = priorityGroup.properties.find((property) => property.id == 2);
      let extentScore = extentDamage.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let surfaceTreatment = priorityGroup.properties.find(
        (property) => property.id == 3
      );
      let surfaceScore = surfaceTreatment.scores.filter((score) =>
        this.item.item_scores.includes(score.id)
      );
      let statusId = null;
      let sampleScore = 2;
      switch (this.item.assessment_method_id) {
        case SAMPLED_AS:
          statusId = this.item.sampled_as.item_as.sample.status_id;
          break;
        case PREVIOUSLY_SAMPLED:
          statusId = this.item.reported_sample.status_id;
          break;
        case PRESUMED:
        case STRONGLY_PRESUMED:
          statusId = this.item.presumed_sample.sample_status_id;
          break;
        case SAMPLED:
          statusId = this.item.sample.status_id;
      }
      sampleScore = scoringType == "UK HSE" ? this.sampleStatusById(statusId).score : 0;
      return (
        productScore[0].score + extentScore[0].score + surfaceScore[0].score + sampleScore
      );
    },
    getTotalScore(scoringType = "UK HSE") {
      if (this.isInaccessible || this.item.is_negative) return 0;
      if (this.demolitionScoring()) return Math.ceil(this.getMaterialScore(scoringType));
      return Math.ceil(this.getMaterialScore(scoringType) + this.getPriorityScore());
    },
    getRiskRating(scoringType = "UK HSE") {
      const DEMOLITION_REPORT = 2;
      const INACCESSIBLE = 5;
      const NEGATIVE = 2;
      const RISK_VERY_LOW = 7;
      const RISK_LOW = 12;
      const RISK_NZ_LOW = 7;
      const RISK_NZ_MEDIUM = 14;
      const RISK_MODERATE = 18;
      const DEMOLITION_RISK_NZ_LOW = 3;
      const DEMOLITION_RISK_NZ_MEDIUM = 6;
      const DEMOLITION_RISK_VERY_LOW = 4;
      const DEMOLITION_RISK_LOW = 6;
      const DEMOLITION_RISK_MODERATE = 9;
      let demolition =
        !this.building.is_answering_questions &&
        this.building.report_type_id == DEMOLITION_REPORT;

      if (this.isInaccessible) return 0;
      if (this.item.is_negative) return "Negative";

      if (this.item.assessment_method_id == INACCESSIBLE) {
        return "Inaccessible";
      }
      if (
        this.item.sample &&
        this.item.sample.status &&
        this.item.sample.status.id == NEGATIVE
      ) {
        return "Negative";
      }
      let totalScore = this.getTotalScore(scoringType);
      if (totalScore == 0) {
        return "Negative";
      }
      if (scoringType == "NZ") {
        if (demolition) {
          return totalScore <= DEMOLITION_RISK_NZ_LOW
            ? "Low"
            : totalScore <= DEMOLITION_RISK_NZ_MEDIUM
            ? "Medium"
            : "High";
        }
        return totalScore <= RISK_NZ_LOW
          ? "Low"
          : totalScore <= RISK_NZ_MEDIUM
          ? "Medium"
          : "High";
      }
      if (totalScore <= (demolition ? DEMOLITION_RISK_VERY_LOW : RISK_VERY_LOW))
        return "Very Low";
      if (totalScore <= (demolition ? DEMOLITION_RISK_LOW : RISK_LOW)) return "Low";
      if (totalScore <= (demolition ? DEMOLITION_RISK_MODERATE : RISK_MODERATE))
        return "Moderate";
      return "High";
    },
  },
};
</script>

<style scoped>
.table-width {
  width: 60em;
}

.table-col-width {
  width: 20em;
}
</style>
