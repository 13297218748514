<template>
  <v-row>
    <v-col>
      <v-data-table
        :items="items"
        :headers="headers"
        :options.sync="options"
        :server-items-length="results.total"
        :loading="loading"
        disable-sort
        disable-filtering
        @click:row="goto"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat class="pt-6">
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  props: ["client_id"],
  data: () => ({
    searchTimer: null,
    cancelToken: null,
    axiosSource: null,
    items: [],
    results: { total: null },
    search: null,
    options: {},
    loading: false,
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name",
      },
      {
        text: "Report Type",
        align: "left",
        value: "report_type",
      },
      {
        text: "Survey",
        align: "left",
        value: "survey_name",
      },
      {
        text: "Identifier",
        align: "left",
        value: "phase",
      },
      {
        text: "Address",
        align: "left",
        value: "full_address",
      },
    ],
  }),
  mounted() {
    this.options = this.tableOptions;
    this.axiosSource = axios.CancelToken.source();
    this.cancelToken = this.axiosSource.token;
  },
  methods: {
    ...mapActions(["getClientBuildings", "setTableOptions"]),
    goto(building) {
      this.$router.push(
        `/survey/${building.survey_id}/building/${building.id}`
      );
    },
    clientBuildings() {
      this.getClientBuildings({
        client_id: this.client_id,
        search: this.search,
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        cancelToken: this.cancelToken,
      }).then((response) => {
        this.options.page = response.data.meta.current_page;
        this.results = response.data.meta;
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters(["tableOptions"]),
  },
  watch: {
    search() {
      this.loading = true;
      this.axiosSource.cancel();
      this.axiosSource = axios.CancelToken.source();
      this.cancelToken = this.axiosSource.token;
      this.options.page = 1;
      if (this.searchTimer) clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.clientBuildings();
      }, 300);
    },
    options(updated) {
      this.clientBuildings();
      this.setTableOptions(updated);
      this.$forceUpdate();
    },
  },
};
</script>
