<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-on="on" v-bind="attrs">
        <template v-slot:activator="{ on }">
          <v-btn fab v-on="on" @click="dialog = !dialog" class="mx-1">
              <v-icon>fas fa-text</v-icon>
          </v-btn>
        </template>
        <span>Add Label</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>Options</v-card-title>
      <v-card-text>
        <v-row class="px-4" v-if="labelOptions">
          <v-col>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  outlined
                  label="Text"
                  v-model="label.text"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row >
              <v-col class="py-0">
                <v-text-field
                  outlined
                  label="Font Size"
                  type="number"
                  v-model="label.size"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="mt-0 pt-0">
            <v-btn text class="float-right" @click="dialog = false"
              >UPDATE</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LABEL, POLYGON } from "../constants/drawing";
export default {
  props: ["labelOptions", "polygonOptions", "canvasOptions", "tool"],
  data: () => ({
    dialog: false
  }),
  created() {
    this.LABEL = LABEL;
    this.POLYGON = POLYGON;
  },
  computed: {
    label: {
      get() {
        return this.labelOptions;
      },
      set(updated) {
        this.$emit("update:labelOptions", updated);
      }
    },
    polygons: {
      get() {
        return this.polygonOptions;
      },
      set(updated) {
        this.$emit("update:polygonOptions", updated);
      }
    },
    options: {
      get() {
        return this.canvasOptions;
      },
      set(updated) {
        this.$emit("update:canvasOptions", updated);
      }
    }
  }
};
</script>
