<style scoped>
.text-white {
  color: white;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #919295;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4e97d1;
}

input:focus + .slider {
  box-shadow: 0 0 1px#4e97d1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.bg-light {
  border: 4px solid #4e97d1 !important;
  border-radius: 6px;
}

.bmis-link,
.bmis-link:link,
.bmis-link:active,
.bmis-link:visited {
  color: #4e97d1 !important;
}

[type="submit"],
[type="submit"]:hover,
[type="submit"]:active {
  background-color: #4e97d1 !important;
  border-color: #4e97d1 !important;
}

.container {
  max-width: 100% !important;
}

.top-bar {
  background-color: #1d4162;
  height: 50px;
}
</style>
<template>
  <v-container>
    <div class="top-bar"></div>
    <v-row class="text-center bg-white">
      <v-col>
        <img
          :src="`/img/marketing/ccs_logo.png`"
          rel="image:CCS Logo"
          style="width: 10em"
          class="my-5"
        />
      </v-col>
    </v-row>
    <v-row justify="center" style="margin: 0px 3px">
      <v-col md="7" sm="10" xs="12" class="border bg-light">
        <v-row>
          <v-col class="text-center">
            <h2>CCS Login</h2>
            <small>
              <a
                :href="portal_url"
                target="_blank"
                style="text-decoration: none"
                class="bmis-link"
                >Click here to log into Datanest Admin</a
              >.
            </small>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form method="POST" ref="form" @submit.prevent="">
              <v-row class="m-2" justify="center" v-if="!basic_auth">
                <v-col md="8" sm="10" xs="12" class="text-center">
                  <v-text-field
                    prepend-icon="fad fa-user-circle"
                    type="email"
                    v-model="email"
                    v-validate="'required|email'"
                    :error-messages="errors.collect('email')"
                    autofocus
                    label="E-mail"
                    data-vv-name="email"
                    @keyup.native.enter="login"
                    light="light"
                    data-cy="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="m-0" justify="center" v-else>
                <v-col md="8" sm="10" xs="12" class="text-center">
                  <v-text-field
                    prepend-icon="fad fa-user-circle"
                    append-outer-icon="fas fa-rotate-left"
                    type="email"
                    v-model="email"
                    v-validate="'required|email'"
                    :error-messages="errors.collect('email')"
                    autofocus
                    label="E-mail"
                    data-vv-name="email"
                    light="light"
                    data-cy="email"
                    readonly
                    @click:append-outer="basic_auth = false"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="m-2" justify="center" v-if="basic_auth">
                <v-col md="8" sm="10" xs="12" class="text-center">
                  <v-text-field
                    prepend-icon="far fa-shield-check"
                    type="password"
                    v-model="password"
                    v-validate="'required|min:6|max:30'"
                    :error-messages="errors.collect('password')"
                    label="Password"
                    @keyup.native.enter="login"
                    data-vv-name="password"
                    light="light"
                    data-cy="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="row mx-2 my-2" v-if="basic_auth">
                <div class="col"></div>
                <div class="align-self-center mr-2">Keep me signed in?</div>
                <div class>
                  <label class="switch my-2">
                    <input type="checkbox" name="remember" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="row mx-2 mt-2 mb-2">
                <div class="col mb-3">
                  <a
                    :href="`${portal_url}/forgot`"
                    style="text-decoration: none"
                    target="_blank"
                    v-if="basic_auth"
                    >Forgot your password?</a
                  >
                </div>
                <div class>
                  <v-btn
                    style="color: #fff"
                    block="block"
                    v-bind:class="[{ disabled: !email || !password }]"
                    color="primary"
                    class="mr-4 w-100"
                    @keyup.native.enter="login"
                    @click="login"
                    data-cy="submit"
                  >
                    <span v-if="basic_auth">Login</span><span v-else>Next</span>
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="m-2 mt-3 text-center" justify="center">
      <v-col md="2">
        <small>Copyright &copy; Datanest Software Limited</small>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "login",
  mounted() {
    this.portal_url = process.env.VUE_APP_PORTAL_URL;
    if (this.$auth.user()) {
      this.$router.push("/dashboard");
      return;
    }
    this.reset2FA();
  },
  data: () => ({
    basic_auth: false,
    portal_url: null,
    remember: true,
    email: "",
    password: "",
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "resetState",
      "setUser",
      "getCsrfCookie",
      "getLoginData",
      "setLoading",
      "reset2FA",
      "validateEmailDomain",
    ]),
    login() {
      let hashPassword = this.password;
      this.setLoading();
      this.getCsrfCookie().then(() => {
        if (this.basic_auth) {
          this.$auth
            .login({
              data: { email: this.email, password: hashPassword },
            })
            .then((response) => {
              if (response.data.error) return;
              this.resetState();
              this.setUser(response.data).then(() => {
                this.getLoginData();
              });
            })
            .finally(() => {
              this.setLoading(false);
            });
        } else {
          this.validateEmailDomain(this.email)
            .then((response) => {
              if (response.data.error) {
                return;
              }
              if (response.data.oauth_url) {
                window.location.href = response.data.oauth_url;
                return;
              } else {
                this.basic_auth = true;
              }
            })
            .catch((error) => {
              this.basic_auth = true;
            })
            .finally(() => {
              this.setLoading(false);
            });
        }
      });
    },
    async handleCallback() {
      const response = this.axios.post("/api/auth/callback", {
        code: this.$route.query.code,
        state: this.$route.query.state,
      });
      return response;
    },
  },
};
</script>
