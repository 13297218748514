<template>
  <div class="tour-container">
    <step
      v-for="(tooltip, tooltipIndex) in tooltips"
      :id="tourKey + '-tooltip-' + tooltipIndex"
      :tooltip="tooltip"
      :key="tooltipIndex"
      :tourKey="tourKey"
      @acknowledge="acknowledgeTooltip"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Tour from "../helpers/tour.js";
import { createPopper } from "@popperjs/core";
import Step from "./Step";

export default {
  name: "Tour",
  mixins: [Tour],
  props: {
    tourKey: String
  },
  data: () => ({
    shouldShowTour: false,
    tooltips: [],
    completedSteps: []
  }),
  watch: {
    "user.tutorials_enabled"(updated) {
      if (updated) {
        this.updateAllPositions();
      }
    }
  },
  components: {
    Step
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapActions(["acknowledge", "getAllCompleted"]),
    acknowledgeTooltip({ tooltip, timeTaken, toSave }) {
      tooltip.popper.destroy();

      tooltip.placed = false;

      if (!toSave) {
        return;
      }

      this.completedSteps.push(tooltip.key);

      this.acknowledge({
        tour_key: this.tourKey,
        step_key: tooltip.key,
        time_taken: timeTaken
      })
        .then(response => {})
        .catch(err => {
          throw err;
        });
    },
    updateAllPositions(destroy = false) {
      if (!this.shouldShowTour) {
        return;
      }

      if (destroy) {
        this.tooltips.forEach((tooltip, tooltipIndex) => {
          if (tooltip.popper) {
            tooltip.popper.destroy();
          }
        });

        this.addTourSteps();
        return;
      }

      setTimeout(() => {
        this.tooltips.forEach((tooltip, tooltipIndex) => {
          if (tooltip.popper) {
            tooltip.popper.forceUpdate();
          }
        });
      }, 300);
    },
    addTourSteps() {
      this.tooltips = this.steps[this.tourKey].filter(s => {
        return this.completedSteps.length > 0
          ? !new RegExp(this.completedSteps.join("|")).test(s.key)
          : true;
      });

      this.tooltips.forEach((tooltip, tooltipIndex) => {
        this.$set(tooltip, "placed", false);
        this.$set(tooltip, "popper", null);

        let checkExist = setInterval(() => {
          let element = document.querySelector(tooltip.element);

          if (element) {
            let popup = document.querySelector(
              `#${this.tourKey}-tooltip-${tooltipIndex}`
            );

            if (!popup) {
              return;
            }

            tooltip.placed = true;

            tooltip.popper = createPopper(element, popup, {
              placement: tooltip.placement
            });

            clearInterval(checkExist);
          } else {
            this.$set(
              tooltip,
              "failed",
              tooltip.failed ? (tooltip.failed += 1) : 1
            );

            if (tooltip.failed >= 10) {
              clearInterval(checkExist);
            }
          }
        }, 500);
      });
    }
  },
  mounted() {
    this.getAllCompleted(this.tourKey).then(response => {
      if (response.data.tutorials_off) {
        return;
      }

      this.completedSteps = response.data.completed;

      this.addTourSteps();
    });

    // this.$root.on("updateAllTourPositions", this.updateAllPositions);

    // this.$root.$emit("updateAllTourPositions");
  }
};
</script>
