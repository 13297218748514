<template>
  <v-row>
    <v-col class="pt-0 mt-0">
      <v-row>
        <v-col class="pt-0 mt-0" cols="12" md="6">
          <h1>{{ building.name }}</h1>
          <h4>Management Plans</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="plans">
          <v-data-iterator :items="plans" :options="options">
            <template v-slot:item="{ item }">
              <v-row>
                <v-col class="py-1">
                  <v-btn
                    block
                    outlined
                    color="primary"
                    target="_blank"
                    :href="`/plan/download/${item.download_hash}`"
                  >
                   <v-icon class="mr-1">far fa-file-pdf</v-icon> {{ item.file_name }} / {{ item.date_requested }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
        <v-col v-else>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    plans: null,
    options: {
      sortBy: "created_at",
      itemsPerPage: -1
    }
  }),
  mounted() {
    this.getManagementPlans({ id: this.$route.params.building, type: "building" }).then(
      response => {
        this.plans = response.data.plans;
      }
    );
  },
  computed: {
    ...mapGetters(["building"])
  },
  methods: {
    ...mapActions(["getManagementPlans"])
  }
};
</script>
