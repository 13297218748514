<template>
  <v-row>
    <v-col>
      <tour tourKey="item_create" />
      <v-row>
        <v-col class="pt-0">
          <h1>{{ $route.params.item ? "Edit" : "Create" }} Item</h1>
          <p class="text--secondary pt-0 mt-0">
            Set item details, location, and scoring within a room
          </p>
        </v-col>
      </v-row>
      <v-row v-if="step == 1 && !$route.params.item && building.is_lead && building.is_asbestos">
        <v-col sm="4" cols="12" class="py-0" data-cy="contaminate">
          <v-select
            label="Contaminant"
            v-model="item.contaminate_id"
            :items="contaminateTypes"
            item-text="name"
            item-value="id"
            outlined
            placeholder="Contaminant"
            required
            :disabled="!!room.is_inaccessible"
          ></v-select>
        </v-col>
      </v-row>
      <asbestos-form
        data-cy="asbestos"
        :aItem.sync="item"
        :aStep.sync="step"
        :buildingItems="buildingItems"
        v-if="item.contaminate_id == ASBESTOS && building"
        @save="save"
        @update="update"
        :building="building"
      ></asbestos-form>
      <lead-form
        data-cy="lead"
        :aItem.sync="item"
        v-if="item.contaminate_id == LEAD"
        @save="save"
        @update="update"
      ></lead-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AsbestosForm from "../components/ItemAsbestosForm";
import LeadForm from "../components/LeadForm";
import ASSESSMENT_METHODS from "../constants/assessmentMethods";
import Tour from "../components/Tour";
export default {
  components: {
    AsbestosForm,
    LeadForm,
    Tour
  },
  computed: {
    ...mapGetters(["contaminateTypes", "building", "room"])
  },
  created() {
    this.ASBESTOS = 1;
    this.LEAD = 2;
  },
  mounted() {
    if (this.$route.params.item) {
      this.getItem(this.$route.params.item).then(response => {
        this.item = { ...this.item, ...response.data };
        this.setMissing();
      });
    }
    this.item.room_id = this.$route.params.room;
    this.getBuildingItems(this.$route.params.building).then(response => {
      this.buildingItems = response.data.filter(
        item => item.contaminate_id == this.ASBESTOS
      );
    });
    if (
      this.room.is_inaccessible &&
      this.item.contaminate_id == this.ASBESTOS
    ) {
      this.item.assessment_method_id = ASSESSMENT_METHODS.INACCESSIBLE;
    }
    if(!this.building.is_lead) this.item.contaminate_id = this.ASBESTOS;
    if(!this.building.is_asbestos) this.item.contaminate_id = this.LEAD;
  },
  watch: {
    item(updated) {
      if (
        this.room.is_inaccessible &&
        updated.contaminate_id == this.ASBESTOS
      ) {
        this.item.assessment_method_id = ASSESSMENT_METHODS.INACCESSIBLE;
      }
    }
  },
  methods: {
    ...mapActions([
      "createItem",
      "updateItem",
      "getItems",
      "getBuildingItems",
      "getItem",
      "setLoading"
    ]),
    setMissing() {
      if (!this.item.presumed_sample) {
        this.item.presumed_sample = {
          sample_status_id: null
        };
      }
      if (!this.item.reported_sample) {
        this.item.reported_sample = {
          name: null,
          sample_number: null,
          status_id: null
        };
      }
      if (!this.item.sampled_as) {
        this.item.sampled_as = {
          item_id: null,
          status_id: 1
        };
      }
      if (!this.item.sample) {
        this.item.sample = {
          analysis_type_id: 1,
          bag_code: null,
          is_urgent: false,
          status_id: 1
        };
      }
    },
    save() {
      this.setLoading();
      this.createItem({ ...this.item, room_id: this.$route.params.room })
        .then(response => {
          if (response.status == 200) {
            return this.getItems(this.$route.params.room).then(() => {
              this.$router.push(this.$route.path.replace(`/item`, ""));
            });
          }
        })
        .finally(() => {
          this.setLoading(false);
        });
    },
    update() {
      this.setLoading(false);
      this.updateItem(this.item)
        .then(response => {
          if (response.status == 200) {
            this.getItems(this.$route.params.room).then(() => {
              return this.$router.push(
                this.$route.path.replace(`/item/${this.$route.params.item}`, "")
              );
            });
          }
        })
        .finally(() => {
          this.setLoading(false);
        });
    }
  },
  data: () => ({
    buildingItems: [],
    step: 1,
    item: {
      file: null,
      file_alt: null,
      name: null,
      contaminate_id: 1,
      link: null,
      description: null,
      polygon: null,
      amount: null,
      removal_class_id: null,
      amount_type_id: 1,
      assessment_method_id: 1,
      sampled_as_scores: null,
      layer: {
        polygons: [],
        labels: []
      },
      lead: {
        building_material: null,
        colour: null,
        paint_condition: null,
        laboratory_analysis: null,
        colour_metric: null,
        xrf_result: null
      },
      room_id: null,
      item_scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      item_recommendations: [],
      presumed_sample: {
        sample_status_id: null
      },
      reported_sample: {
        name: null,
        sample_number: null,
        status_id: null
      },
      sampled_as: {
        item_id: null,
        status_id: 1
      },
      sample: {
        analysis_type_id: 1,
        bag_code: null,
        is_urgent: false,
        status_id: 1
      }
    }
  })
};
</script>
