<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <div v-if="companies && companies.length < 1">
        <span>
          <v-icon> fas fa-exclamation </v-icon>
        </span>
      </div>
      <div v-else>
        <v-btn
          color="primary"
          @click="dialog = true"
          :disabled="selectedCompanies.length == 0"
        >
          <span v-if="selectedRemovalists.length < 1">
            <v-icon> fas fa-plus </v-icon>
            No removalist selected
          </span>
          <span v-else>
            <v-icon> fas fa-user-hard-hat </v-icon>
            {{ selectedRemovalists.length }}
            {{ selectedRemovalists.length > 1 ? "removalists" : "removalist" }}
            selected
          </span>
        </v-btn>
      </div>
      <template>
        <v-dialog v-model="dialog" max-width="800px">
          <v-card>
            <v-card-title color="primary" dark
              >Clearance Removalists</v-card-title
            >
            <v-card-text>
              <div v-show="showSelected">
                <table
                  class="table"
                  :key="'removalist-table-' + removalistList.length"
                >
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Contact number</th>
                  <th>Company</th>
                  <th>Actions</th>
                  <tr v-for="(removalist, ckey) in removalistList" :key="ckey">
                    <td style="width: 10%">
                      <v-checkbox
                        :value="removalist"
                        :value-comparator="
                          () =>
                            selectedRemovalists.some(
                              (aRemovalist) => aRemovalist.id === removalist.id
                            )
                        "
                        @change="addRemovalist(removalist)"
                      ></v-checkbox>
                    </td>
                    <td style="width: 20%">{{ removalist.name }}</td>
                    <td style="width: 20%">{{ removalist.position }}</td>
                    <td style="width: 10%">{{ removalist.contact_no }}</td>
                    <td style="width: 20%">{{ removalist.company.name }}</td>
                    <td style="width: 20%" class="text-center">
                      <v-btn
                        fab
                        small
                        class="mr-2"
                        color="warning"
                        @click="editRemovalist(removalist)"
                      >
                        <v-icon> fas fa-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        small
                        color="error"
                        @click="deleteRemovalistFn(removalist.id)"
                      >
                        <v-icon> fas fa-trash </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </div>
              <div v-show="showAddNew">
                <v-row>
                  <v-col>
                    <v-select
                      v-validate="'required|max:190'"
                      :error-messages="errors.collect('company')"
                      data-vv-name="company"
                      v-model="removalistObj.removal_company_id"
                      :items="companies"
                      item-text="name"
                      item-value="id"
                      label="Company"
                      placeholder="Company"
                      outlined
                      persistent-hint
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      spellcheck="true"
                      label="Name"
                      outlined
                      v-model.lazy="removalistObj.name"
                      v-validate="'required|max:190'"
                      maxlength="190"
                      :error-messages="errors.collect('name')"
                      data-vv-name="name"
                      placeholder="Enter name"
                      messages="*Required"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      spellcheck="true"
                      label="Position"
                      outlined
                      v-model.lazy="removalistObj.position"
                      v-validate="'required|max:190'"
                      maxlength="190"
                      :error-messages="errors.collect('position')"
                      data-vv-name="position"
                      placeholder="Enter position"
                      messages="*Required"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      spellcheck="true"
                      label="Qualifications"
                      outlined
                      v-model.lazy="removalistObj.qualifications"
                      maxlength="190"
                      v-validate="'max:190'"
                      :error-messages="errors.collect('qualifications')"
                      data-vv-name="qualifications"
                      placeholder="Enter qualifications"
                      messages="Optional"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      spellcheck="true"
                      label="Contact no"
                      outlined
                      v-model.lazy="removalistObj.contact_no"
                      maxlength="190"
                      v-validate="'required|max:190'"
                      :error-messages="errors.collect('contact number')"
                      data-vv-name="contact number"
                      placeholder="Enter Contact number"
                      messages="*Required"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-row>
                <v-col>
                  <v-btn
                    color="warning"
                    v-if="showSelected"
                    @click="addNewRemovalist"
                    >New</v-btn
                  >
                </v-col>
                <v-col>
                  <div class="float-right">
                    <v-btn
                      class="mr-1"
                      color="success"
                      v-if="showAddNew"
                      @click="saveChanges"
                      >Save changes</v-btn
                    >
                    <v-btn
                      class="mr-1"
                      v-if="showSelected"
                      color="primary"
                      @click="saveSelected"
                      >Save selected</v-btn
                    >
                    <v-btn
                      color="error
"
                      class=""
                      v-if="showSelected"
                      @click="dialog = false"
                      >Close</v-btn
                    >
                    <v-btn
                      @click="(showAddNew = false), (showSelected = true)"
                      color="error"
                      v-if="showAddNew"
                      >Cancel</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this removalist?</v-card-title
          >
          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="(dialogDelete = false), (selectedRemovalist = false)"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="confirmDelete"
              >Confirm</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["clearance_id", "companyHandler", "selectedCompanies"],
  data() {
    return {
      pagination: {
        sortBy: "name",
      },
      removalistList: [],
      companies: [],
      originalSelection: [],
      selectedRemovalists: [],
      showSelected: true,
      showAddNew: false,
      dialog: false,
      dialogDelete: false,
      selectedRemovalist: null,
      removalistObj: {
        id: null,
        name: null,
        position: null,
        qualifications: null,
        contact_no: null,
      },
      defaultRemovalistObj: {
        id: null,
        name: null,
        position: null,
        qualifications: null,
        contact_no: null,
      },
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
        },
        { text: "Qualifications ", value: "qualifications" },
        { text: "Description", value: "description" },
      ],
    };
  },
  computed: {
    ...mapGetters({ invalidInput: "invalidInput" }),
  },
  watch: {
    companyHandler() {
      if (this.companyHandler === true) {
        // this.triggerFilter();
      }
      this.$emit("returnHandleCompany", false);
    },
    dialog() {
      if (this.dialog) {
        this.getCompanies();
        this.getRemovalistsList();
      }
    },
    selectedCompanies(updated, outdated) {
      if (!this.selectedRemovalists) return;
      const check = this.selectedRemovalists.filter((removalist) => {
        return updated.some(
          (company) => company === removalist.removal_company_id
        );
      });
      this.selectedRemovalists = check;
      this.$emit(
        "saveSelectedRemovalists",
        JSON.stringify(this.selectedRemovalists)
      );
    },
  },
  methods: {
    ...mapActions([
      "getRemovalCompanies",
      "getRemovalists",
      "getRemovalistsByClearance",
      "insertUpdateRemovalist",
      "deleteRemovalist",
    ]),
    addRemovalist(e) {
      if (
        this.selectedRemovalists.some((removalist) => removalist.id === e.id)
      ) {
        const index = this.selectedRemovalists.findIndex(
          (aRemovalist) => aRemovalist.id === e.id
        );
        this.selectedRemovalists.splice(index, 1);
        return;
      }
      this.selectedRemovalists.push(e);
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    addNewRemovalist() {
      this.showAddNew = true;
      this.showSelected = false;
      this.removalistObj = JSON.parse(
        JSON.stringify(this.defaultRemovalistObj)
      );
    },
    editRemovalist(cmp) {
      this.showAddNew = true;
      this.showSelected = false;
      this.removalistObj = JSON.parse(JSON.stringify(cmp));
    },
    deleteRemovalistFn(cmp_id) {
      this.selectedRemovalist = cmp_id;
      this.dialogDelete = true;
    },
    confirmDelete() {
      this.deleteRemovalist(this.selectedRemovalist).then(() => {
        this.dialogDelete = false;
        var index = this.selectedRemovalists.indexOf(this.selectedRemovalist);
        if (index !== -1) {
          this.selectedRemovalists.splice(index, 1);
        }
        this.initialise();
      });
    },
    saveChanges() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }

        this.insertUpdateRemovalist({
          removalist: this.removalistObj,
        }).then(() => {
          this.showAddNew = false;
          this.showSelected = true;
          this.getRemovalistsList();
        });
      });
    },
    getRemovalistsList() {
      this.getRemovalists().then((response) => {
        let rms = response.data.removalists;
        this.removalistList = [];
        rms.forEach((element) => {
          if (
            this.selectedCompanies.some(
              (aCompany) => aCompany === element.removal_company_id
            )
          ) {
            this.removalistList.push(element);
          }
        });
      });
    },
    getCompanies() {
      this.getRemovalCompanies().then((response) => {
        this.companies = response.data.companies;
      });
    },
    getRemovalistSelected() {
      if (this.clearance_id) {
        this.getRemovalistsByClearance(this.clearance_id).then((response) => {
          const data = response.data.removalists;
          if (Array.isArray(data)) {
            this.selectedRemovalists = data;
          }
        });
      } else {
        this.selectedRemovalists = [];
      }
    },
    initialise() {
      this.getCompanies();
      this.getRemovalistsList();
      this.getRemovalistSelected();
    },
    saveSelected() {
      this.dialog = false;
      this.$emit(
        "saveSelectedRemovalists",
        JSON.stringify(this.selectedRemovalists)
      );
    },
  },
  mounted() {
    this.initialise();
  },
};
</script>
<style scoped>
table {
  width: 100%;
}

.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #94959c;
  color: white;
}
</style>
