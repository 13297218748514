<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>fas fa-circle-xmark</v-icon>
          </v-btn>
          <v-toolbar-title>Edit {{ templates[aTemplateType] }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-spacer></v-spacer>
          <v-form>
            <tiptap-vuetify
              :extensions="extensions"
              :label="'Enter report content for ' + aTemplateType + ' here...'"
              v-model="templateContent"
              name="details"
              class="sec-div mt-3"
              :class="{ 'error-div': showWorkDetailsErr }"
              :placeholder="'Enter report content for ' + aTemplateType + ' here...'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" text @click="confirmDialog = true"
            >Reset to Default</v-btn
          >
          <v-btn color="primary" data-cy="save-note" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card max-width="500">
        <v-card-title class="text-h6 primary">
          <span class="white--text">Are you sure?</span>
        </v-card-title>

        <v-card-text class="text-subtitle-1">
          {{ "This will reset the content to the default for this template." }}
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialog = false">Cancel</v-btn>
          <v-btn color="primary" data-cy="save-note" text @click="resetToDefault"
            >Reset</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  CodeBlock,
} from "tiptap-vuetify";
export default {
  components: {
    TiptapVuetify,
  },
  props: ["building", "dialog", "templates", "aTemplateTxt", "aTemplateType"],
  data: () => ({
    confirmDialog: false,
    templateContent: null,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      HorizontalRule,
      Paragraph,
      CodeBlock,
      HardBreak,
    ],
  }),
  mounted() {
    this.aTemplateTxt
      ? (this.templateContent = this.aTemplateTxt)
      : (this.templateContent = null);
  },
  methods: {
    ...mapActions(["setReportTemplate", "getBuilding"]),
    close() {
      this.$emit("close");
    },
    resetToDefault() {
      this.templateContent = this.building[this.aTemplateType + "_default"];
      this.confirmDialog = false;
    },
    save() {
      const building = this.$route.params.building;
      this.setReportTemplate({
        id: building,
        template: { type: this.aTemplateType, content: this.templateContent },
      }).finally(() => {
        this.$emit("updated");
      });
      this.close();
    },
  },
  watch: {
    aTemplateTxt(updated) {
      this.templateContent = updated;
    },
  },
};
</script>
