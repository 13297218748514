import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"10","sm":"10"}},[_c(VRow,[_c(VCol,[_c(VForm,{attrs:{"data-cy":"office-form"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|max:60'),expression:"'required|max:60'"}],attrs:{"data-cy":"office-name","autocomplete":"new-password","spellcheck":"true","outlined":"","counter":"","maxlength":"60","error-messages":_vm.errors.collect('name'),"label":"Name","data-vv-name":"name","required":""},model:{value:(_vm.office.name),callback:function ($$v) {_vm.$set(_vm.office, "name", $$v)},expression:"office.name"}}),_c('address-form',_vm._b({attrs:{"data-cy":"office-form","addresses":_vm.office.address,"clearAddress":_vm.clearAddress},on:{"update:city":function($event){return _vm.setOfficeAddress($event, 'city')},"update:suburb":function($event){return _vm.setOfficeAddress($event, 'suburb')},"update:postcode":function($event){return _vm.setOfficeAddress($event, 'postcode')},"update:address":function($event){return _vm.setOfficeAddress($event, 'address')},"update:address2":function($event){return _vm.setOfficeAddress($event, 'address2')},"update:clearAddress":function($event){_vm.clearAddress=$event},"update:clear-address":function($event){_vm.clearAddress=$event}}},'address-form',_vm.office.address,false))],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"float-left",attrs:{"color":"warning","data-cy":"back"},on:{"click":function($event){return _vm.back()}}},[_vm._v(_vm._s(_vm.dialog ? "Close" : "Back"))]),_c(VBtn,{staticClass:"float-right",attrs:{"data-cy":"office-create","color":"primary"},on:{"click":_vm.create}},[_vm._v(" create")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }