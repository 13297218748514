<template>
  <v-img :src="href" eager contain :id="src" align="center" @click="$emit('click')">
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
import axios from "axios";
export default {
  props: ["src"],
  data: () => ({
    href: null
  }),
  mounted() {
    this.setImage();
  },
  methods: {
    setImage() {
      if ((typeof this.src === 'string' || this.src instanceof String) && this.src.includes("data:image")) {
        this.href = this.src;
      } else if(this.src instanceof Blob) {
        this.href= URL.createObjectURL(this.src);
      } else {
        axios.get(this.src).then(res => {
          this.href = res.data;
        });
      }
      if(!this.src) return;
      const img = document.getElementById(this.src);
      if (!img) return;
      img.addEventListener("contextmenu", e => {
        e.preventDefault();
        const link = document.createElement("a");
        link.href = this.href;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  },
  watch: {
    src() {
      this.setImage();
    }
  }
};
</script>
