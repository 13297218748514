<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-row>
            <v-col class="py-0 my-0">
              <h1>{{ edit ? "Edit" : "Create" }} Building</h1>
              <p class="text--secondary pt-0 mt-0" v-if="edit">
                Edit the details on an existing building
              </p>
              <p class="text--secondary pt-0 mt-0" v-else>
                Create and set the details for new building
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0"
              ><h3 class="primary--text">Management</h3></v-col
            >
          </v-row>
          <building-form :aBuilding="building" @update="updateBuilding" v-if="building" :validator="$validator"></building-form>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-btn class="float-left" color="greyed" depressed @click="back()"
            >Back</v-btn
          >
          <v-btn
            class="float-right"
            color="primary"
            v-if="building.id"
            data-cy="update"
            depressed
            @click="update()"
            >Update</v-btn
          >
          <v-btn
            class="float-right"
            color="primary"
            v-else
            depressed
            data-cy="submit"
            @click="submit()"
            >Create</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BuildingForm from "./BuildingForm.vue";

export default {
  props: ["survey"],
  components: {
    BuildingForm,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  mounted() {
    this.edit = this.$route.matched.some(({ path }) => path.includes("edit"));
    if (!this.$route.params.building) {
      this.clear();
    } else {
      this.building = {...this.selectedBuilding, file: null, site_file: null};
    }
  },
  data: () => ({
    edit: null,
    building: {
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
        address2: null,
        geolocation: null,
        longitude: null,
        longitude: null,
      },
      name: null,
      description: null,
      phase: null,
      image: null,
      file: null,
      site_plan: null,
      site_file:null,
      is_within_three_months: false,
      is_answering_questions: false,
      is_residential: false,
      is_urgent_transfer: false,
      has_site_plan: false,
      has_sample_polygon: true,
      has_multiple_images: false,
      laboratory_id: null,
      report_type_id: null,
      label_size: null,
      is_lead: null,
      is_asbestos: null,
    },
  }),
  computed: {
    ...mapGetters({
      selectedBuilding: "building",
    }),
  },
  methods: {
    ...mapActions([
      "getBuilding",
      "createBuilding",
      "clearBuilding",
      "editBuilding",
      "setbuildingProp",
    ]),
    updateBuilding(aBuilding) {
      this.building = {...aBuilding}
    },
    clear() {
      this.clearBuilding();
      this.clearAddress = true;
      this.$validator.reset();
      this.$forceUpdate();
    },
    setBuildingProp(data, prop) {
      this.setbuildingProp({ prop: prop, data: data });
    },
    update() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        if (this.theImage) {
          this.building.image = this.theImage;
        }
        this.editBuilding(this.building).then(() => {
          this.clear();
          this.$validator.reset();
          this.back();
        });
      });
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.building.survey_id = this.survey;
        if (this.theImage) {
          this.building.image = this.theImage;
        }
        this.createBuilding(this.building).then((response) => {
          if (response.data.building_id)
            this.getBuilding(response.data.building_id);
          this.$emit("created-building", true);
          this.$router.push(
            `/survey/${this.$route.params.survey}/building/${response.data.building_id}`
          );
        });
      });
    },
    back() {
      if (this.$route.params.building) {
        this.$router.push(
          `/survey/${this.$route.params.survey}/building/${this.$route.params.building}`
        );
      } else {
        this.$router.push(`/survey/${this.$route.params.survey}`);
      }
    },
  },
};
</script>
