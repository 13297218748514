<template>
  <v-row class="grey lighten-1 pa-3 ma-0">
    <v-col class="pa-0 ma-0">
      <v-skeleton-loader
        class="mx-auto"
        height="50em"
        width="60em"
        type="image"
        :loading="loading"
      >
        <span></span>
      </v-skeleton-loader>
      <div
        ref="canvasParent"
        v-show="!loading"
        class="white rounded canvas-overflow justify-center"
      ></div>
    </v-col>
  </v-row>
</template>

<script>
import { POLYGON, LABEL } from "../constants/drawing";
import { FabricDrawing } from "fabric-tool";

export default {
  props: ["drawOptions", "itemPolygons", "imageData", "tool", "name", "single"],
  data: () => ({
    loading: true,
    canvas: null,
    width: 0,
    height: 0,
    highlights: [],
    selectedPoint: null,
    moving: false,
    shifting: false,
    previous: [0, 0],
    completed: false,
    image: null,
    shapes: [],
    config: {
      canvasEl: "edit-canvas",
      viewOnly: false,
      maxShapes: 1,
      cornerColor: "red",
      cornerStyle: "circle",
      fill: "transparent",
      stroke: "red",
      polygonFill: "rgba(255, 99, 71, 0.5)",
      cornerSize: 16,
      hasPolygon: true,
      hasLine: true,
      hasPoint: true,
      backgroundImg: "floor.png",
      zoom: true,
      radius: 8,
      strokeWidth: 2,
    },
    fabricDrawing: null,
    itemPolygons: [],
  }),
  mounted() {
    if (this.imageData) {
      this.image = this.imageData;
      this.setCanvas();
      this.draw();
    }
  },
  watch: {
    "options.fill"() {
      this.draw();
    },
    "options.stroke"() {
      this.draw();
    },
    "options.lineWidth"() {
      this.draw();
    },
    "label.text"() {
      this.draw();
    },
    "label.size"() {
      this.draw();
    },
    imageData() {
      this.image = this.imageData;
      if (!this.canvas) {
        this.setCanvas();
      }
      this.clear();
    },
  },
  computed: {
    options: {
      get() {
        return this.drawOptions;
      },
      set(updated) {
        this.$emit("update:drawOptions", updated);
      },
    },
    polygons: {
      get() {
        return this.itemPolygons;
      },
      set(updated) {
        this.$emit("update:itemPolygons", updated);
      },
    },
    stroke() {
      return `rgba(${this.options.stroke.r}, ${this.options.stroke.g}, ${this.options.stroke.b}, ${this.options.stroke.a})`;
    },
    fill() {
      return `rgba(${this.options.fill.r}, ${this.options.fill.g}, ${this.options.fill.b}, ${this.options.fill.a})`;
    },
  },
  methods: {
    updateView() {
      this.$emit("canvasUpdate", this.canvas);
    },
    undo() {
      //call fabric undo
    },
    clear() {
      this.reset();
      this.$nextTick(() => {
        this.draw();
      });
    },
    reset() {
      this.polygon = {
        points: [],
        fill: { r: 255, g: 99, b: 71, a: 1 },
        stroke: { r: 255, g: 0, b: 0, a: 1 },
        lineWidth: 10,
        name: null,
      };
    },
    getRGBA(color) {
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    },
    setStroke({ r, g, b, a }) {
      return `rgba(${r || this.options.stroke.r}, ${g || this.options.stroke.g}, ${
        b || this.options.stroke.b
      }, ${a || this.options.stroke.a})`;
    },
    setFill({ r, g, b, a }) {
      return `rgba(${r || this.options.fill.r}, ${g || this.options.fill.g}, ${
        b || this.options.fill.b
      }, ${a || this.options.fill.a})`;
    },
    setCanvas() {
      const parent = this.$refs.canvasParent;
      this.canvas = document.createElement("canvas");
      this.canvas.setAttribute("id", "edit-canvas");
      parent.appendChild(this.canvas);
      this.fabricDrawing = new FabricDrawing(this.config);
      this.loading = false;
    },

    draw() {
      this.fabricDrawing.setBackgroundImgUrl(this.image.src).then(() => {
        this.updateCanvas();
        if (this.polygons) {
          this.drawShapes();
        }
      });
    },
    updateStyle() {
      this.config.fill = this.getRGBA(this.options.fill);
      this.config.stroke = this.getRGBA(this.options.stroke);
      this.config.polygonFill = this.getRGBA(this.options.fill);
      this.config.cornerColor = this.getRGBA(this.options.stroke);
      this.config.maxShapes = this.single ? 1 : 20;
      this.config.polyLineWidth = 3;
      this.config.strokeWidth = 3;
    },

    updateCanvas() {
      this.updateStyle();
      this.fabricDrawing.updateStyle(this.config);
    },
    drawShapes() {
      this.shapes = [];
      for (let polygon of this.polygons) {
        let points = polygon.points.map((point) => {
          return {
            x: Number(point[0]),
            y: Number(point[1]),
          };
        });
        let shape = {
          type: polygon.type ? polygon.type : "polygon",
          points: points,
        };
        this.shapes.push(shape);
      }
      if (this.shapes.length > 0) {
        this.fabricDrawing.completeObjects(JSON.stringify(this.shapes));
      }
    },

    completePolygon() {
      this.fabricDrawing
        .savePoints()
        .then((shapes) => {
          let shapedata = JSON.parse(shapes);

          let finalShapes = [];
          shapedata.forEach((shape) => {
            let points = shape.points.map((point) => {
              return [point.x, point.y];
            });

            let type = shape.type;

            finalShapes.push({
              type: type,
              points: points,
              fill: this.options.fill,
              stroke: this.options.stroke,
              lineWidth: this.options.lineWidth,
            });
          });

          this.polygons = finalShapes;
        })
        .catch((err) => {
          console.error("Error occurred:", err);
          this.polygons = [];
        });
    },
  },
};
</script>

<style scoped>
.canvas-overflow {
  overflow: auto;
}
</style>
