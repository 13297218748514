<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="pb-0 mb-0">
          <v-text-field
            spellcheck="true"
            v-model.lazy="room.name"
            outlined
            v-validate="'required|max:190'"
            placeholder="Enter a room name"
            counter
            maxlength="190"
            :error-messages="errors.collect('name')"
            label="Name*"
            data-vv-name="name"
            messages="*Required"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 my-0">
          <v-text-field
            spellcheck="true"
            autocomplete="off"
            label="Order*"
            messages="*Required"
            v-model="room.order"
            type="number"
            v-validate="'required|numeric'"
            :error-messages="errors.collect('order')"
            data-vv-name="order"
            placeholder="Enter room order"
            required
            v-if="roomId"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            autocomplete="off"
            spellcheck="true"
            v-model.lazy="room.note"
            outlined
            label="Note"
            data-cy="note"
            placeholder="Enter room note"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            class="room-inaccessible-step"
            dense
            v-model="room.is_inaccessible"
            label="Mark room as inaccessible?"
            :disabled="!!room.is_not_suspicious || !!scope || !!room.label"
            hint="An 'Inaccessible' item will be created for this room"
            persistent-hint
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            class="room-not-suspicious-step"
            dense
            v-model="room.is_not_suspicious"
            :disabled="!!room.is_inaccessible || !!scope || !!room.label"
            label="No PACM Observed?"
            persistent-hint
          ></v-checkbox>
        </v-col>
        <!-- <v-col>
          <v-checkbox
            dense
            v-model="room.label"
            label="Add room label?"
            :disabled="
              scope || !!room.is_inaccessible || !!room.is_not_suspicious
            "
          ></v-checkbox>
        </v-col> -->
        <v-col>
          <v-checkbox
            dense
            v-model="scope"
            :disabled="
              !!room.is_not_suspicious || !!room.is_inaccessible || !!room.label
            "
            label="Out of scope?"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="room.is_inaccessible">
        <v-col>
          <v-row>
            <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
              <v-file-input
                outlined
                v-model="file"
                label="Upload inaccessible item image"
                accept="image/*"
                v-validate="'image'"
                data-vv-name="image"
                :error-messages="errors.collect('image')"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row justify="center">
                <v-col>
                  <a-img
                    :src="displayImage"
                    v-if="room.file && displayImage"
                    outlined
                    class="img-size"
                  ></a-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="room.is_inaccessible">
        <v-col class="text-center"> Please mark 'Inaccessible' area </v-col>
      </v-row>
      <v-row v-if="room.is_not_suspicious">
        <v-col class="text-center"> Please draw room polygon </v-col>
      </v-row>
      <v-row v-if="requiresPolygon && url">
        <v-col>
          <polygon-menu
            :url="url"
            :selectedTool="selectedTool"
            :aItem.sync="room"
            :tools="tools"
            :setColor="polygonColors"
            @polygons="checkInvalidPolygon"
            :single="true"
            :is_room="true"
            :color="false"
            :buildingSize="building.label_size"
          ></polygon-menu>
        </v-col>
      </v-row>
      <v-row class="warning rounded" v-if="room.label && invalidLabel">
        <v-col class="text-center pb-0">
          <p>Please place a label on the floor plan</p>
        </v-col>
      </v-row>
      <v-row
        class="warning rounded"
        v-if="
          !submissionCheck && (room.is_inaccessible || room.is_not_suspicious)
        "
      >
        <v-col class="text-center pb-0">
          <p>Please place a polygon on the floor plan</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PolygonMenu from "./PolygonDrawerMenu";
import { POLYGON, LABEL, LINE } from "../constants/drawing";

import { NZDF_REFURBISHMENT } from "../constants/reportTypes";
export default {
  components: {
    PolygonMenu,
  },
  props: ["aRoom", "building"],
  data: () => ({
    reader: null,
    displayImage: null,
    invalidLabel: false,
    url: null,
    file: null,
    invalidPolygon: true,
    polygonColors: {},
  }),
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
    this.NZDF_REFURBISHMENT = NZDF_REFURBISHMENT;
    this.LABEL = LABEL;
    this.POLYGON = POLYGON;
    this.LINE = LINE;
  },
  mounted() {
    if (this.$route.params.floor) {
      this.url = `/api/image/floor/${this.$route.params.floor}/image`;
    }
    this.polygonColors = this.setPolygonColor();
    this.reader = new FileReader();
    this.reader.onload = (e) => {
      this.resizeImage(e.target.result);
    };
    this.invalidPolygon = !!this.room.layer.polygons.length;
  },
  methods: {
    setPolygonColor() {
      if (this.scope) {
        return {
          fill: { r: 139, g: 120, b: 139, a: 0.3 },
          stroke: { r: 139, g: 0, b: 139, a: 0.6 },
        };
      }
      if (this.room.is_not_suspicious)
        return {
          fill: { r: 25, g: 120, b: 225, a: 0.2 },
          stroke: { r: 17, g: 85, b: 255, a: 0.6 },
        };
      return {
        fill: { r: 255, g: 165, b: 0, a: 0.1 },
        stroke: { r: 255, g: 165, b: 0, a: 0.6 },
      };
    },
    addDropFile(e) {
      this.reader.readAsDataURL(e.dataTransfer.files[0]);
    },
    checkInvalidPolygon(polygons) {
      this.invalidPolygon = !!!polygons?.length;
    },
    resizeImage(file) {
      const maxSize = 1000;
      const image = new Image();
      image.onload = () => {
        if (image.width < maxSize && image.height < maxSize) {
          this.displayImage = file;
        }
        const canvas = document.createElement("canvas");
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        canvas.toBlob((blob) => {
          this.room.file = new File([blob], "inaccessible-image.png");
        });
        const resizedImage = canvas.toDataURL("image/png", 1);
        this.displayImage = resizedImage;
      };
      image.src = file;
    },
    setRoomNote(note, updated) {
      if (!updated)
        if (`${this.room.note}`.includes(note)) {
          const replacement = this.room.note.replace(note, "");
          this.room.note = replacement;
          return;
        }
      if (updated) if (`${this.room.note}`.includes(note)) return;
      this.room.note = note.concat(this.room.note);
    },
  },
  computed: {
    requiresPolygon() {
      return (
        (this.room.layer && this.room.floor_id && this.room.label) ||
        this.scope ||
        this.room.is_inaccessible ||
        this.room.is_not_suspicious
      );
    },
    submissionCheck() {
      const requiresPolygon =
        this.room.is_inaccessible || this.room.is_not_suspicious;
      const check = requiresPolygon && !this.invalidPolygon;
      const valid = (requiresPolygon ? check : true) && this.room.name;
      this.$emit("setValidForm", valid);
      return valid;
    },
    selectedTool() {
      return this.aRoom.label ? LABEL : POLYGON;
    },
    tools() {
      return this.aRoom.label ? [LABEL] : [POLYGON];
    },
    scope: {
      get() {
        return this.room ? !this.room.is_scoped : !this.aRoom.is_scoped;
      },
      set(updated) {
        this.room.is_scoped = !updated;
      },
    },
    room: {
      get() {
        return this.aRoom;
      },
      set(updated) {
        this.$emit("update:aRoom", updated);
      },
    },
    roomId() {
      return this.$route.params.room;
    },
  },
  watch: {
    "room.layer.labels"() {
      this.invalidLabel = false;
    },
    "room.is_not_suspicious"(updated, outdated) {
      if (outdated === 0 || outdated === 1) return;
      if (updated === 0 || updated === 1) return;
      this.polygonColors = this.setPolygonColor();
      const note = "The room was visually assessed during the asbestos management survey and no visible potentially asbestos containing materials were identified. ";
      this.setRoomNote(note, updated);
    },
    "room.is_scoped"(updated, outdated) {
      if (outdated === 0 || outdated === 1) return;
      if (updated === 0 || updated === 1) return;
      this.polygonColors = this.setPolygonColor();
      const note = "Out of scope. ";
      this.setRoomNote(note, !updated);
    },
    file(updated) {
      if (updated) {
        this.reader.readAsDataURL(updated);
      }
    },
  },
};
</script>

<style scoped>
.img-size {
  max-width: 500px;
  margin: auto;
}
</style>
