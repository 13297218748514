<template>
  <div>
    <v-row>
      <v-col class="py-0 my-0">
        <h1 data-cy="dashboard-label">
          <span class="dashboard-step pr-3">Dashboard</span>
        </h1>
        <p class="text--secondary">
          View {{ mode }} which require your attention
        </p>
      </v-col>
      <v-col>
        <v-select
          :items="drp"
          label="Type"
          item-text="key"
          item-value="value"
          v-model="mode"
          style="width: 150px; margin-right: 15px"
          class="float-right"
          outlined
          v-if="showClearance"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-show="mode == 'surveys'">
      <v-col class="py-0 my-0" v-if="roles && statuses">
        <v-row>
          <v-col>
            <v-row v-if="review.data.length > 0">
              <v-col>
                <h4>
                  <span class="awaiting-review-step pr-3">Awaiting Review</span>
                </h4>
                <user-building-card
                  data-cy="awaiting-review-table"
                  :review="true"
                  :items="review.data"
                  :loading="review.loading"
                  v-on:load="load()"
                ></user-building-card>
              </v-col>
            </v-row>
            <v-row v-if="edits.data.length > 0">
              <v-col data-cy="change-required-table">
                <h4>
                  <span class="pr-3 needs-edit-step">Change Required</span>
                </h4>
                <user-building-card
                  data-cy="change-required-table"
                  :review="false"
                  :items="edits.data"
                  :loading="edits.loading"
                  v-on:load="load()"
                ></user-building-card>
              </v-col>
            </v-row>
            <v-row v-if="survey.data.length > 0">
              <v-col data-cy="active-buildings-table">
                <h4>
                  <span class="pr-3 active-bldg-step">Active Buildings</span>
                </h4>
                <user-building-card
                  :review="false"
                  :items="survey.data"
                  :loading="survey.loading"
                  v-on:load="load()"
                ></user-building-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-show="mode == 'clearances'">
      <v-col>
        <clearance-dashboard @reloadChecker="checkClearanceDrp" />
      </v-col>
    </v-row>
    <tour tourKey="dashboard" v-if="user" :key="user.tutorials_enabled" />
    <ask-tutorial v-if="user && user.tutorials_seen == 1 && start" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserBuildingCard from "../components/UserBuildingCard.vue";
import ClearanceDashboard from "../components/ClearanceDashboard.vue";
import Tour from "../components/Tour";
import AskTutorial from "../components/AskTutorials.vue";
export default {
  components: {
    UserBuildingCard,
    ClearanceDashboard,
    Tour,
    AskTutorial,
  },
  data: () => ({
    start: false,
    survey: {
      data: [],
      loading: true,
    },
    review: {
      data: [],
      loading: true,
    },
    edits: {
      data: [],
      loading: true,
    },
    drp: [
      { key: "Survey", value: "surveys" },
      { key: "Clearance", value: "clearances" },
    ],
    mode: "surveys",
    showClearance: false,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "getUserSurvey",
      "getUserReview",
      "getUserEdits",
      "roles",
      "statuses",
      "setLoading",
      "checkIfHasClearance",
      "refreshUser",
      "checkIfHasDashboardData",
    ]),
    load() {
      this.clear();
      const loading = [];

      if (this.user && !this.user.hasOwnProperty("tutorials_seen")) {
        this.refreshUser(this.user.id);
      }
      if (this.mode == "surveys") {
        const surveyLoading = this.getUserSurvey()
          .then((result) => {
            this.survey.data = result.data;
          })
          .catch(() => {
            this.survey.loading = false;
          })
          .finally(() => {
            this.survey.loading = false;
          });
        loading.push(surveyLoading);
        const reviewLoading = this.getUserReview()
          .then((result) => {
            this.review.data = result.data;
          })
          .catch(() => {
            this.review.loading = false;
          })
          .finally(() => {
            this.review.loading = false;
          });
        loading.push(reviewLoading);
        const editLoading = this.getUserEdits()
          .then((result) => {
            this.edits.data = result.data;
          })
          .catch(() => {
            this.edits.loading = false;
          })
          .finally(() => {
            this.edits.loading = false;
          });
        loading.push(editLoading);
      }

      Promise.all(loading)
        .catch(() => {
          this.setLoading(false);
          this.start = true;
        })
        .finally(() => {
          this.setLoading(false);
          this.start = true;
        });

      this.checkClearanceDrp();
    },
    checkClearanceDrp() {
      this.checkIfHasClearance().then((response) => {
        this.showClearance = response.data.has_clearance;
        if (!this.showClearance) {
          this.mode = "surveys";
        }
      });
    },
    clear() {
      this.survey.data = [];
      this.review.data = [];
      this.edits.data = [];
      this.survey.loading = true;
      this.review.loading = true;
      this.edits.loading = true;
    },
  },
  mounted() {
    this.setLoading(false);
    this.load();
  },
};
</script>
