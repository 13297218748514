export default {
  data: () => ({
    steps: {
      dashboard: [
        {
          element: ".dashboard-step",
          key: "dashboard",
          placement: "right",
          title: "Dashboard",
          text:
            "The dashboard shows buildings grouped by status, awaiting review, needing update or active."
        },
        {
          element: ".awaiting-review-step",
          key: "awaiting-review",
          placement: "right",
          title: "Awaiting Review",
          text: "List of building reports that are awaiting review."
        },
        {
          element: ".needs-edit-step",
          key: "needs-edit",
          placement: "right",
          title: "Needs Edit",
          text:
            "List of building reports that have been reviewed and declined, and need updating."
        },
        {
          element: ".active-bldg-step",
          key: "active-bldg",
          placement: "right",
          title: "Active buildings",
          text: "Buildings that are assigned to you that are currently active."
        }
      ],
      create_project: [
        {
          element: ".project-information-step",
          key: "project-information",
          placement: "right",
          popup_placement: "right",
          title: "Project Information",
          text:
            "Configure your survey details by assigning an office, surveyor, client and contact."
        },
        {
          element: ".job-number-step",
          key: "job-number",
          placement: "top",
          popup_placement: "right",
          title: "Job number",
          text:
            "Set the job number or unique identifier for your project. Existing job numbers would show up while typing and will pre-fill the project information when clicked."
        },
        {
          element: ".building-information-step",
          key: "building-information",
          placement: "right",
          popup_placement: "right",
          title: "Building Information",
          text:
            "Fill in the building details and set the laboratory and type of report to be used."
        },
        {
          element: ".address-step",
          key: "address",
          placement: "right",
          title: "Address",
          text:
            "Set the site address for building. Address will be printed in the report."
        },
        {
          element: ".building-identifier-step",
          key: "building-information",
          placement: "left",
          popup_placement: "right",
          title: "Building Identifier",
          text:
            "Identifier or Phase would be appended to the job number when the report is printed."
        },
        {
          element: ".additional-details-step",
          key: "additional-details",
          placement: "left",
          title: "Additional Details",
          text:
            "Configure additional details for the building. Depending on the report type, options may vary."
        },
        {
          element: ".report-type-step",
          key: "manage-guidelines",
          placement: "left",
          title: "Report types",
          text:
            "Set the survey report type that will be performed on this building.<ul><li><b>Management</b> -This report type is used for an asbestos management survey with meeting the duties 7, 9 and 10 of the Health and Safety at Work (Asbestos) Regulations 2016.</li><li><b>Demolition</b> - This report type is used to manage a demolition survey.</li><li><b>Refurbishment</b> - This report type is used to manage refurbishment work at the site.</li><li><b>Clearance certificate</b> - This report type is used to create clearance certificates for a building.</li>"
        },
        {
          element: ".demolition-step",
          key: "manage-guidelines",
          placement: "left",
          title: "Demolition",
          text:
            "For cases where management and material assessment of asbestos items is not required due to impending demolition."
        },
        {
          element: ".show-scoring-step",
          key: "manage-guidelines",
          placement: "left",
          title: "Report Scoring",
          text: "Displays the assessment scoring on printed report."
        },
        {
          element: ".is-residential-step",
          key: "manage-guidelines",
          placement: "left",
          title: "Residential",
          text: "Set to identify if the building is a residential site."
        },
        {
          element: ".multiple-step",
          key: "manage-guidelines",
          placement: "left",
          title: "Multiple image on items",
          text: "Tick this to enable secondary images for items."
        },
        {
          element: ".urgent-step",
          key: "manage-guidelines",
          placement: "left",
          title: "Urgent transfer",
          text:
            "Urgent transfer defines the turnaround time on Lab CoC as urgent."
        }
      ],
      drawer: [
        {
          element: ".create-project-step",
          key: "create-project",
          placement: "right",
          popup_placement: "right",
          title: "New Project",
          text: "Start a new survey project."
        },
        {
          element: ".browse-step",
          key: "browse",
          placement: "right",
          popup_placement: "right",
          title: "Browse Buildings",
          text: "Display list of buildings relevant to you."
        },
        {
          element: ".clients-step",
          key: "clients",
          placement: "right",
          popup_placement: "right",
          title: "Clients",
          text: "Manage and view existing clients and associated buildings."
        },
        {
          element: ".offices-step",
          key: "create-project",
          placement: "right",
          popup_placement: "right",
          title: "Offices",
          text: "Create or manage offices."
        },
        {
          element: ".laboratories-step",
          key: "laboratories",
          placement: "right",
          popup_placement: "right",
          title: "Laboratories",
          text: "Create or Manage Laboratories."
        },
        {
          element: ".users-step",
          key: "create-project",
          placement: "right",
          popup_placement: "right",
          title: "User management",
          text: "Manage individual users and their roles."
        },
        {
          element: ".user-setting-step",
          key: "create-project",
          placement: "right",
          popup_placement: "top",
          title: "Account Setting",
          text:
            "Manage your account and set display information about yourself."
        }
      ],
      building: [
        {
          element: ".building-information-step",
          key: "building-information",
          placement: "left",
          popup_placement: "right",
          title: "Building Information",
          text:
            "Shows statistics of current building. This displays floor, room and item count."
        },
        {
          element: ".survey-date-step",
          key: "survey-date",
          placement: "left",
          popup_placement: "right",
          title: "Survey Date",
          text: "Survey date will show on the browse building page."
        },
        {
          element: ".notes-step",
          key: "notes",
          placement: "left-start",
          popup_placement: "right",
          title: "Notes",
          text:
            "Building notes will be shown in the printed report.<ul><li><b>Survey</b> - Summary of the survey</li><li><b>Interior</b> - Surveyor comments about the interior of the site.</li><li><b>Exterior</b> - Surveyor comments about the exterior of the site.</li><li><b>Deny</b> - Reviewer's feedback if the report is declined.</li></ul>"
        },
        {
          element: ".area-surveyed-step",
          key: "area-surveyed",
          placement: "left-start",
          popup_placement: "bottom",
          title: "Area Surveyed",
          text:
            "Comments about the area survey for different contaminants. This is shown in the survey report."
        },
        {
          element: ".review-step",
          key: "review-step",
          placement: "right",
          popup_placement: "bottom",
          title: "Review",
          text:
            "Approve or Decline a report, This feature is only visible to reviewers. When declined, the decline note is shown to the surveyor."
        },
        {
          element: ".floors-step",
          key: "floors",
          placement: "left",
          popup_placement: "right",
          title: "Floors",
          text: "Displays the list of floors with statistics."
        },
        {
          element: ".generate-report-step",
          key: "floors",
          placement: "left",
          popup_placement: "right",
          title: "Generate Report",
          text: "Generate a printable survey report."
        },
        {
          element: ".report-generating-step",
          key: "floors",
          placement: "left",
          popup_placement: "right",
          title: "Report is generating",
          text:
            "Your report is being generated and CCS will notify you when it's ready."
        },
        {
          element: ".report-queued-step",
          key: "floors",
          placement: "left",
          popup_placement: "right",
          title: "Report is generating",
          text:
            "Request to print report is in queue. CCS will notify you when complete."
        },
        {
          element: ".download-report-pdf-step",
          key: "download-report-pdf-step",
          placement: "left",
          popup_placement: "right",
          title: "Download report.",
          text: "Download the printable survey report."
        },
        {
          element: ".management-plan-pdf-step",
          key: "download-report-pdf-step",
          placement: "left",
          popup_placement: "right",
          title: "Management Plan",
          text: "Request to generate a management plan (extra fees apply)."
        },
        {
          element: ".edit-building-step",
          key: "download-report-pdf-step",
          placement: "left",
          popup_placement: "right",
          title: "Edit Building Information",
          text:
            "Allows the user to change the current building information and report type."
        },
        {
          element: ".duplicate-building-step",
          key: "download-report-pdf-step",
          placement: "left",
          popup_placement: "right",
          title: "Report is generating",
          text:
            "Allows the user to duplicate the current building into an existing survey or use it to start a new survey."
        },
        {
          element: ".bldg-img-step",
          key: "download-report-pdf-step",
          placement: "top",
          popup_placement: "bottom",
          title: "Building Image",
          text:
            "Displays the photo of the current building. Click image to replace."
        },
        {
          element: ".send-to-awaiting-review-step",
          key: "download-report-pdf-step",
          placement: "left-start",
          popup_placement: "left",
          title: "Send for Review",
          text: "Finalises the current building and send for review."
        },
        {
          element: ".finalise-building-step",
          key: "finalise-building",
          placement: "left",
          popup_placement: "left",
          title: "Finalise Building",
          text:
            "This completes the surveying phase and moves to status: awaiting results"
        }
      ],
      chain_of_custody: [
        {
          element: ".coc-step",
          key: "floor-create",
          placement: "right",
          popup_placement: "right",
          title: "Chain of custody",
          text:
            "Individually set the lab results of the sampled items and upload lab certificate."
        },
        {
          element: ".coc-step",
          key: "floor-create",
          placement: "right",
          popup_placement: "right",
          title: "Chain of custody",
          text:
            "Select required options then download chain of Custody form for lab."
        },
        {
          element: ".coc-results-table-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "right",
          title: "Lab Results",
          text:
            "Set the result of each samples based on the Laboratory results."
        },
        {
          element: ".sample-status-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "left",
          title: "Create Floor in Building",
          text: ""
        },
        {
          element: ".lab-return-file-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "top",
          title: "Lab return upload",
          text: "Upload lab certificate"
        },
        {
          element: ".turnaround-step",
          key: "floor-create",
          placement: "left-start",
          popup_placement: "right",
          title: "Turn around",
          text: "Select turnaround requirements from lab for sample testing."
        },
        {
          element: ".laboratory-step",
          key: "floor-create",
          placement: "left-start",
          popup_placement: "right",
          title: "Laboratory",
          text: "Set the laboratory where the samples will be sent."
        },
        {
          element: ".contaminant-step",
          key: "floor-create",
          placement: "left-start",
          popup_placement: "right",
          title: "Contaminant",
          text: "Select contaminant to generate CoC for."
        },
        {
          element: ".pre-review-step",
          key: "floor-create",
          placement: "left-start",
          popup_placement: "left",
          title: "Send to Pre-review",
          text: "Perform a final check of the date before sending for review"
        }
      ],
      floor_create: [
        {
          element: ".floor-create-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "right",
          title: "Create floor in building",
          text: ""
        },
        {
          element: ".floor-upload-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "right",
          title: "Upload floor plan",
          text:
            "Floor plan is required to draw on location of surveyed materials."
        }
      ],
      floor: [
        {
          element: ".redraw-floor-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "right",
          title: "Redraw Image",
          text:
            "Click this button to redraw the floor plan image with all the corresponding polygons (Automatically happens upon updating floor, room, and item)."
        },
        {
          element: ".rooms-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "right",
          title: "Rooms",
          text: ""
        }
      ],
      room: [
        {
          element: ".items-step",
          key: "floor-create",
          placement: "left",
          popup_placement: "right",
          title: "Room items",
          text: "Items included in surey for this room"
        }
      ],
      room_create: [
        {
          element: ".room-create-step",
          key: "room-create",
          placement: "right",
          popup_placement: "right",
          title: "Room create",
          text: ""
        },
        {
          element: ".room-inaccessible-step",
          key: "room-inaccessible",
          placement: "left-start",
          popup_placement: "right",
          title: "Inaccesible",
          text: "Room and items in the room will be marked inaccessible."
        }
      ],
      item_view: [
        {
          element: ".asbestos-risks-step",
          key: "room-create",
          placement: "left",
          popup_placement: "right",
          title: "Item summary",
          text:
            "Displays the summary of the item and information including the risk rating, location on the floor plan and an image."
        }
      ],
      asbestos_create: [
        // Asbestos form
        {
          element: ".assessment-method-step",
          key: "item-create",
          placement: "top",
          popup_placement: "right",
          title: "Assessment Method",
          text:
            "Method for assessing potential Asbestos Containing Materials.<ul><li><b>Sampled</b> - Sample taken for lab testing</li><li><b>Sampled As</b> - Sample not taken because material is same as another sample already taken for lab testing.</li><li><b>Strongly Presumed</b> - Material strongly assumed to contain asbestos based on surveyor experience and judgement.</li><li><b>Presumed</b> - Material assumed to contain asbestos based on surveyor experience and judgement.</li><li><b>Inaccessible</b> - Item not ale to be accessed for inspection or sampling.</li><li><b>Previously Sampled</b> - Material has been previously tested in the lab and results are known and can be recorded instantly.</li></ul>"
        },
        {
          element: ".sample-id-step",
          key: "item-create",
          placement: "top",
          popup_placement: "right",
          title: "Sample ID",
          text:
            "Sample ID refers to a unique identifier that will be sent to the laboratory."
        },
        {
          element: ".analysis-step",
          key: "item-create",
          placement: "top",
          popup_placement: "right",
          title: "Analysis",
          text: "Asbestos sample type."
        }
      ],
      lead_create: [
        // Lead form
        {
          element: ".lead-assessment-method-step",
          key: "item-create",
          placement: "top",
          popup_placement: "right",
          title: "Assessment Method",
          text:
            "Method for assessing potential Lead based paint.<ul><li><b>Sampled</b> - Sample taken for lab testing</li><li><b>Previously Sampled</b> - Material has been previously tested in the lab and results are known and can be recorded instantly.</li></ul>"
        },
        {
          element: ".lead-sample-id-step",
          key: "item-create",
          placement: "top",
          popup_placement: "right",
          title: "Sample ID",
          text:
            "Sample ID refers to a unique identifier that will be sent to the laboratory."
        },
        {
          element: ".xrf-result-step",
          key: "item-create",
          placement: "top",
          popup_placement: "right",
          title: "XRF Result",
          text: "Enter XRF result if available, in Parts per Million (PPM)."
        },
        {
          element: ".colour-metric-step",
          key: "item-create",
          placement: "top",
          popup_placement: "left",
          title: "Colour Metric",
          text: "Enter colour metric result from lead testing, if available."
        }
      ],
      item_create: [
        // Main create
        {
          element: ".item-create-step",
          key: "item-create",
          placement: "left",
          popup_placement: "right",
          title: "Item create",
          text: "Create an item "
        }
      ],
      item_add_scores: [
        {
          element: ".score-desc-step",
          key: "item-create",
          placement: "right",
          popup_placement: "right",
          title: "Item scoring",
          text:
            "Choose the appropriate answer to determine the risk result of the sample."
        }
      ],
      item_add_recommendation: [
        {
          element: ".recommendation-step",
          key: "item-create",
          placement: "right",
          popup_placement: "right",
          title: "Add recommendation",
          text:
            "Select the appropriate recommendation on how best to manage the item."
        }
      ],
      polygon: [
        {
          element: ".polygon-step",
          key: "item-create",
          placement: "left",
          popup_placement: "right",
          title: "Polygon create",
          text: "Draw the item location using a polygon over the floor image."
        }
      ]
    }
  })
};
