import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{staticClass:"pt-0 mt-0"},[_c(VRow,[_c(VCol,{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v(_vm._s(_vm.building.name))]),_c('h4',[_vm._v("Management Plans")])])],1),_c(VRow,[(_vm.plans)?_c(VCol,[_c(VDataIterator,{attrs:{"items":_vm.plans,"options":_vm.options},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VRow,[_c(VCol,{staticClass:"py-1"},[_c(VBtn,{attrs:{"block":"","outlined":"","color":"primary","target":"_blank","href":`/plan/download/${item.download_hash}`}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("far fa-file-pdf")]),_vm._v(" "+_vm._s(item.file_name)+" / "+_vm._s(item.date_requested)+" ")],1)],1)],1)]}}],null,false,3048454952)})],1):_c(VCol)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }