<template>
  <v-row>
    <v-col class="pt-3">
      <v-row>
        <v-col
          lg="6"
          md="6"
          class="hidden-sm-and-down py-0 my-0"
          v-if="survey"
        >
          <h1 data-cy="survey-name" v-if="survey.name">{{ survey.name }}</h1>
        </v-col>
        <v-col lg="12" md="12" sm="12" cols="12">
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                data-cy="edit-survey"
                color="primary"
                v-on="on"
                @click="edit()"
                class="mr-1"
              >
                <v-icon>fas fa-pen</v-icon>
              </v-btn>
            </template>
            <span>Edit survey</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                data-cy="duplicate-survey"
                fab
                color="info"
                v-on="on"
                :disabled="duplicating"
                @click="showDuplicateDialog()"
                class="mx-1"
              >
                <v-icon>fas fa-copy</v-icon>
              </v-btn>
            </template>
            <span>Duplicate survey</span>
          </v-tooltip>
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                data-cy="delete-survey"
                fab
                v-on="on"
                color="warning"
                @click="deleteDialog = true"
                class="mx-1"
              >
                <v-icon>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>
            <span>Delete survey</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="survey">
        <v-col v-if="survey.id" lg="6" md="6" sm="12" cols="12" class="mb-6">
          <h2 class="mb-0">Survey information</h2>
          <v-row>
            <v-col>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Client</p>
                    </td>
                    <td>
                      <p data-cy="survey-client" class="body-1 ma-0 pa-0">
                        {{ survey.client_name }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Job Number</p>
                    </td>
                    <td>
                      <p data-cy="survey-job-number" class="body-1 ma-0 pa-0">
                        {{ survey.job_number }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="body-1 ma-0 pa-0">Office</p>
                    </td>
                    <td>
                      <p data-cy="office" class="body-1 ma-0 pa-0">
                        {{ survey.office_name || "N/A" }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Note</h2>
              <v-sheet elevation="1" rounded outlined>
                <v-row class="max-note-height">
                  <v-col v-if="survey.note" class="h-100 px-6">
                    <p class="body-1" data-cy="survey-notes">{{ survey.note }}</p>
                  </v-col>
                  <v-col v-else class="mt-15 pt-6" align="center">
                    <v-icon class="mb-2" color="error"
                      >fas fa-exclamation-circle</v-icon
                    >
                    <p class="subtitle-2 text--secondary" data-cy="survey-text">
                      Survey note has not been set
                    </p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 mb-0 mt-n14">
              <v-btn
                data-cy="note-edit-btn"
                small
                fab
                class="float-right"
                color="info"
                @click="noteDialog = true"
              >
                <v-icon>fas fa-pen</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="6" md="6" sm="12" cols="12">
          <a-img
            class="pa-0 ma-0"
            v-if="clientImage"
            :src="clientImage"
          ></a-img>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-dialog v-model="duplicateDialog" persistent max-width="400px">
        <v-card>
          <v-card-title>Duplicate Survey</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <span class="sub-header">
                  All duplicated items will be set to "Previously Sampled"
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  spellcheck="true"
                  label="Name"
                  v-model="duplicateName"
                  v-validate="'required'"
                  :error-messages="errors.collect('name')"
                  data-vv-name="name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  spellcheck="true"
                  label="Job Number"
                  v-model="duplicateJob"
                  v-validate="'required'"
                  :error-messages="errors.collect('job')"
                  data-vv-name="job"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="greyed" depressed @click="duplicateDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              data-cy="confirm"
              color="primary"
              depressed
              @click="duplicate"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <delete-dialog
        :dialog.sync="deleteDialog"
        :model="'Survey'"
        v-on:delete="deleteSelected"
      ></delete-dialog>
      <edit-survey-note
        :dialog.sync="noteDialog"
        :aNote="survey.note"
        v-on:close="noteDialog = false"
      ></edit-survey-note>
      <survey-buildings class="mt-6"></survey-buildings>
      <v-tooltip bottom open-delay="300">
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            v-on="on"
            @click="createBuilding"
            dark
            bottom
            fixed
            right
            fab
            data-cy="create-building-btn"
            class="float-right"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Create building</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SurveyBuildings from "./SurveyBuildings.vue";
import DeleteDialog from "./DeleteDialog.vue";
import EditSurveyNote from "./EditSurveyNote.vue";

export default {
  components: {
    SurveyBuildings,
    DeleteDialog,
    EditSurveyNote,
  },
  mounted() {
    if (!this.survey.name) {
      this.setLoading();
    }
    if (this.survey.client_id) {
      this.clientImage = `/api/image/client/${this.survey.client_id}?${this.survey.updated_at}`;
    }
    this.getBuildings(this.$route.params.survey);
    this.duplicateName = this.survey.name;
    this.duplicateJob = this.survey.job_number;
  },
  data: () => ({
    clientImage: null,
    image: null,
    deleteDialog: false,
    duplicating: false,
    duplicateName: null,
    duplicateJob: null,
    duplicateDialog: false,
    noteDialog: false,
  }),
  watch: {
    survey(updated) {
      if (updated.client_id) {
        this.clientImage = `/api/image/client/${updated.client_id}?${this.survey.updated_at}`;
      }
      this.setLoading(false);
    },
  },
  methods: {
    ...mapActions([
      "setNotification",
      "getBuildings",
      "duplicateSurvey",
      "deleteSurvey",
      "resetSurvey",
      "getSurvey",
      "setSurveyActive",
      "setLoading",
    ]),
    duplicate() {
      this.duplicating = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          this.duplicating = false;
          return;
        }
        this.duplicateDialog = false;
        const survey = {
          id: this.$route.params.survey,
          name: this.duplicateName,
          job_number: this.duplicateJob,
        };
        this.duplicateSurvey(survey).then(() => {
          this.duplicating = false;
        });
      });
    },
    deleteSelected() {
      this.deleteSurvey(this.$route.params.survey).then(() => {
        this.$router.push(`/browse`);
      });
    },
    edit() {
      this.$router.push(`/survey/${this.$route.params.survey}/edit`);
    },
    createBuilding() {
      this.$router.push("/project/" + this.$route.params.survey);
    },
    setActive() {
      this.setSurveyActive(this.survey).then(() => {
        this.getSurvey(this.survey.id);
      });
    },
    showDuplicateDialog() {
      this.duplicateName = this.survey.name;
      this.duplicateJob = this.survey.job_number;
      this.duplicateDialog = true;
    },
  },
  computed: {
    ...mapGetters([
      "survey",
    ]),
  },
};
</script>

<style scoped>
.max-note-height {
  height: 16em;
  overflow: auto;
}
</style>
