import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.results.total,"loading":_vm.loading,"disable-sort":"","disable-filtering":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goto},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{staticClass:"pt-6",attrs:{"flat":""}},[_c(VTextField,{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }