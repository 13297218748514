<template>
  <div v-if="!isLoading">
    <div v-for="(status, skey) in statuses" :key="skey">
      <v-row v-if="clearances[status].length > 0">
        <v-col>
          <h4>
            {{ getTitle(status) }}
          </h4>
          <br />
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="clearances[status]"
                :loading="loading"
                disable-sort
                disable-filtering
                mobile-breakpoint="1000"
                no-data-text="There are no clearances to display"
                @click:row="goto"
                class="elevation-1"
              >
                <template v-slot:item.stat_circ="{ item }">
                  <v-chip
                    data-cy="status"
                    :color="getColor(item.status)"
                    dark
                    >{{ item.readable_status }}</v-chip
                  >
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="pa-0 ma-0 d-flex flex-row justify-start">
                    <span v-if="status == 'awaiting_review'">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            color="red darken3"
                            x-small
                            v-on="on"
                            class="white--text mx-1"
                            :href="`/clearance/download/${item.id}`"
                            v-if="item.has_report"
                            @click.stop
                          >
                            <v-icon>fas fa-file-pdf</v-icon>
                          </v-btn>
                          <v-btn
                            color="grey"
                            data-cy="icon-report"
                            class="white--text no-pointer mx-1"
                            x-small
                            fab
                            @click.stop
                            v-on="on"
                            v-if="!item.has_report"
                          >
                            <v-icon>fas fa-file-pdf</v-icon>
                          </v-btn>
                        </template>
                        <span>{{
                          item.has_report
                            ? "Download report"
                            : "No report available"
                        }}</span>
                      </v-tooltip>
                    </span>
                    <span
                      v-if="status == 'awaiting_review' || status == 'declined'"
                    >
                      <clearance-review-button
                        :clearance="item"
                        :type="'dashboard'"
                        @load="getList()"
                      />
                    </span>
                    <span v-if="status != 'awaiting_review'">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            data-cy="approve-report"
                            v-on="on"
                            fab
                            x-small
                            color="primary"
                            class="mx-1"
                            @click.stop="edit(item)"
                          >
                            <v-icon>fas fa-arrow-right</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Clearance</span>
                      </v-tooltip>
                    </span>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClearanceReviewButton from "./clearance/ClearanceReviewButton";

export default {
  components: {
    ClearanceReviewButton,
  },
  data: () => ({
    loading: false,
    clearances: {
      awaiting_review: [],
      declined: [],
      active: [],
    },
    headers: [
      {
        text: "Clearance",
        align: "start",
        value: "name",
      },
      { text: "Site address", value: "site_address" },
      { text: "Building", value: "building.name" },
      { text: "Type", value: "class" },
      { text: "Inspection date", value: "inspection_date_time" },
      { text: "Status", value: "stat_circ", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    title: null,
    message: null,
    deny: null,
    status: null,
    method: null,
    dialog: null,
    model: null,
    close: false,
    statuses: ["awaiting_review", "declined", "active"],
  }),
  computed: {
    ...mapGetters(["user", "isLoading"]),
  },
  methods: {
    ...mapActions([
      "getClearanceForReview",
      "approveClearance",
      "setLoading",
      "denyClearance",
      "generateClearance",
    ]),
    getTitle(string) {
      let status = string.charAt(0).toUpperCase() + string.slice(1);

      return status.split("_").join(" ");
    },
    goto(clearance) {
      this.$router.push(
        `/survey/${clearance.building.survey_id}/building/${clearance.building.id}`
      );
    },
    edit(clearance) {
      this.$router.push(
        `/survey/${clearance.building.survey_id}/building/${clearance.building.id}/clearance/${clearance.id}/edit`
      );
    },
    approveReviewerShow(clearance) {
      this.title = "Approve Clearance Review";
      this.message = `Are you sure you want to approve ${clearance.name}?`;
      this.deny = false;
      this.status = false;
      this.method = this.reviewerApprove;
      this.close = false;
      this.dialog = true;
      this.model = clearance;
    },
    reviewerApprove(clearance) {
      this.approveClearance(clearance).then(() => {
        this.getList();
      });
      this.generateClearance({
        clearance_id: clearance.id,
      });
    },
    reviewerDeny(clearance) {
      this.denyClearance({ id: clearance.id, deny: this.deny }).then(() => {
        this.getList();
      });
    },
    denyReviewerShow(clearance) {
      this.title = "Deny Clearance Review";
      this.message = `Are you sure you want to deny ${clearance.name}?`;
      this.deny = " ";
      this.status = false;
      this.method = this.reviewerDeny;
      this.dialog = true;
      this.close = false;
      this.model = clearance;
    },
    showMessage(clearance) {
      this.title = "Declination message";
      this.message = clearance.message;
      this.deny = "";
      this.status = false;
      this.method = this.closeMessage;
      this.close = true;
      this.dialog = true;
      this.close = true;
      this.model = clearance;
    },
    closeMessage() {
      this.dialog = false;
    },

    getList() {
      this.loading = true;
      this.getClearanceForReview({
        status: "review",
      })
        .then((response) => {
          this.clearances = response.data.clearances;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.setLoading(false);
        });

      this.$emit("reloadChecker");
    },
    getColor(status) {
      let statusColors = {
        active: "primary lighten-2",
        needs_edit: "red accent-4",
        awaiting_review: "orange",
        reviewed: "green darken-2",
        complete: "primary darken-4",
      };

      return statusColors[status];
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.no-pointer {
  cursor: not-allowed;
}
</style>
