<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="py-0 my-0">
          <h1>Browse Buildings</h1>
          <p class="text--secondary">
            Search job number, name, or address to find a building
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="statuses" class="py-0 my-0">
          <v-data-table
            :headers="headers"
            :items="buildingSearch.data"
            :options.sync="options"
            :server-items-length="
              buildingSearch.meta ? buildingSearch.meta.total : 0
            "
            :footer-props="rowOptions"
            :loading="loading"
            disable-sort
            disable-filtering
            @click:row="goto"
            :search="search"
            data-cy="reports-table"
            class="elevation-1"
            mobile-breakpoint="1000"
          >
            <template v-slot:no-results>
              <div>
                <p>No buildings could be found</p>
              </div>
            </template>
            <template v-slot:no-data>
              <div>
                <p>No buildings could be found</p>
              </div>
            </template>
            <template v-slot:item.status_name="{ item }">
              <v-chip
                data-cy="building-status"
                :color="getColor(item.status_name)"
                dark
                >{{ item.status_name }}</v-chip
              >
            </template>
            <template v-slot:item.survey_date="{ item }">
              {{ formatDate(item.survey_date) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="pa-0 ma-0 d-flex flex-row justify-end">
                <v-tooltip bottom v-if="item.is_asbestos">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      data-cy="status-btn"
                      fab
                      color="red darken3"
                      x-small
                      v-on="on"
                      @click="downloadReport(item, ASBESTOS)"
                      v-if="item.asbestos_report_status == 'completed'"
                      class="white--text mr-1"
                      @click.stop
                    >
                      <v-icon data-cy="file-pdf">fas fa-file-pdf</v-icon>
                    </v-btn>
                  </template>
                  <span>Download asbestos report</span>
                </v-tooltip>
                <v-tooltip bottom  v-if="item.is_lead">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      data-cy="status-btn"
                      fab
                      color="green darken3"
                      x-small
                      v-on="on"
                      @click="downloadReport(item, LEAD)"
                      v-if="item.lead_report_status == 'completed'"
                      class="white--text mr-1"
                      @click.stop
                    >
                      <v-icon data-cy="file-pdf">fas fa-file-pdf</v-icon>
                    </v-btn>
                  </template>
                  <span>Download lead report</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      data-cy="view-building"
                      fab
                      color="primary"
                      x-small
                      v-on="on"
                      @click="goto(item)"
                      class="white--text mx-1"
                      @click.stop
                    >
                      <v-icon data-cy="view-building"
                        >fas fa-arrow-right</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>View Building</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="d-flex flex" align="center">
                  <v-col sm="4" data-cy="search-building">
                    <v-text-field
                      v-model.lazy="search"
                      clearable
                      append-icon="fas fa-search"
                      label="Search"
                      single-line
                      hide-details
                      data-cy="search"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="4" data-cy="search-status" class="mt-5">
                    <v-select
                      clearable
                      :items="buildingStatuses"
                      v-model="status"
                      item-text="name"
                      item-value="id"
                      label="Status"
                    ></v-select>
                  </v-col>
                  <v-col sm="4" data-cy="search-report-type" class="mt-5">
                    <v-select
                      clearable
                      :items="reportTypes"
                      v-model="reportType"
                      item-text="name"
                      item-value="id"
                      label="Report type"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    this.ASBESTOS = 1;
    this.LEAD = 2;
  },
  mounted() {
    this.options = this.tableOptions;
    this.axiosSource = axios.CancelToken.source();
    this.cancelToken = this.axiosSource.token;
    this.getSearch();
    this.getOfflineData().finally(() => {
      this.setLoading(false);
    })
  },
  data: () => ({
    loading: false,
    rowOptions: { "items-per-page-options": [10, 20, 30] },
    statusColors: {
      null: "primary lighten-4",
      Active: "primary lighten-2",
      "Awaiting Result": "lime darken-3 lighten-2",
      "Needs Edits": "red accent-4",
      "Pre Review": "light-blue accent-2",
      "Awaiting Review": "orange",
      Reviewed: "green darken-2",
      Complete: "primary darken-4"
    },
    options: {},
    searchTimer: null,
    cancelToken: null,
    axiosSource: null,
    headers: [
      { text: "Job Number", value: "job_number" },
      { text: "Name", value: "name" },
      { text: "Client", value: "client_name" },
      { text: "Report Type", value: "report_type" },
      { text: "Address", value: "full_address" },
      { text: "Start Date", value: "survey_date" },
      { text: "Status", value: "status_name" },
      { text: "Actions", value: "actions" }
    ]
  }),
  computed: {
    ...mapGetters([
      "buildingSearch",
      "buildingStatuses",
      "laboratories",
      "reportTypes",
      "statuses",
      "browseBuildingSearch",
      "browseBuildingStatus",
      "browseBuildingReportType",
      "getClientById",
      "tableOptions"
    ]),
    search: {
      get() {
        return this.browseBuildingSearch;
      },
      set(updated) {
        this.setBrowseBuildingSearch(updated);
      }
    },
    status: {
      get() {
        return this.browseBuildingStatus;
      },
      set(updated) {
        this.setBrowseBuildingStatus(updated);
      }
    },
    reportType: {
      get() {
        return this.browseBuildingReportType;
      },
      set(updated) {
        this.setBrowseBuildingReportType(updated);
      }
    }
  },
  methods: {
    ...mapActions([
      "getBuildingSearch",
      "setBrowseBuildingSearch",
      "setBrowseBuildingStatus",
      "setBrowseBuildingReportType",
      "setTableOptions",
      "getOfflineData",
      "setLoading",
      "downloadReportURL"
    ]),
    downloadReport({id, updated_at}, contaminate_id) {
      this.downloadReportURL({
        contaminate_id,
        building_id: id,
        updated_at: updated_at,
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    goto(building) {
      this.$router.push(
        `/survey/${building.survey_id}/building/${building.id}`
      );
    },
    getColor(status) {
      return this.statusColors[status];
    },
    getSearch() {
      this.loading = true;
      if (this.searchTimer) clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.getBuildingSearch({
          page: this.options.page,
          search: this.search,
          status: this.status,
          report_type: this.reportType,
          paginate: this.options.itemsPerPage,
          cancelToken: this.cancelToken
        }).then(() => (this.loading = false));
      }, 300);
    }
  },
  watch: {
    search() {
      this.options.page = 1;
      this.axiosSource.cancel();
      this.axiosSource = axios.CancelToken.source();
      this.cancelToken = this.axiosSource.token;
      this.getSearch();
    },
    status() {
      this.options.page = 1;
      this.getSearch();
    },
    reportType() {
      this.options.page = 1;
      this.getSearch();
    },
    options(updated) {
      this.getSearch();
      this.setTableOptions(updated);
    }
  }
};
</script>
