<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title>Feedback</v-card-title>
      <v-card-text>
        <p>Enter feedback or suggestions:</p>
        <v-textarea v-model="feedback" rows="3"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Close</v-btn>
        <v-btn color="primary" text @click="save">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["dialog"],
  data: () => ({
    feedback: null
  }),
  methods: {
    ...mapActions(["createFeedback"]),
    close() {
      this.$emit("close");
    },
    save() {
      this.createFeedback({
        feedback: this.feedback,
        route: window.location.href
      }).then(() => {
        this.close();
      });
    }
  }
};
</script>
