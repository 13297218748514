<template>
  <div class="d-inline">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          :color="isDashboard ? (isAwaitingReview ? 'indigo' : 'error') : ''"
          class="mr-1"
          :class="{ 'white--text': isDashboard }"
          :fab="isDashboard"
          :text="!isDashboard"
          x-small
          v-on="on"
          data-cy="generate-report"
          @click.stop="dialog = true"
          v-if="canViewButton()"
        >
          <v-icon :small="!isDashboard">
            {{ isAwaitingReview ? "fas fa-vote-yea" : "fas fa-comment-edit" }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ isAwaitingReview ? "Review" : "View message" }}</span>
    </v-tooltip>
    <template>
      <v-dialog v-model="dialog" max-width="600px" transition="fade-transition">
        <v-card v-if="clearance && clearance.id">
          <v-card-title>
            <small>
              Review Certificate
              <span class="ml-1" v-if="clearance">
                {{ clearance.name }}</span
              ></small
            ></v-card-title
          >
          <v-card-text>
            <div v-if="isAwaitingReview">
              <v-radio-group v-model="status" row>
                <v-radio label="Approve" :value="'reviewed'"></v-radio>
                <v-radio label="Deny" :value="'declined'"></v-radio>
              </v-radio-group>
              <div v-if="isAwaitingReview && status == 'declined'">
                <v-textarea
                  autocomplete="off"
                  spellcheck="true"
                  label="Reason for denying the clearance report"
                  outlined
                  v-model="message"
                  placeholder="Enter details"
                ></v-textarea>
                <span v-if="denyWarn" class="deny-warn">
                  Please fill in details on why you're denying this report.
                </span>
              </div>
            </div>
            <div v-if="!isAwaitingReview">
              {{ clearance.message }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">Close</v-btn>
            <v-btn
              color="primary"
              text
              v-if="isAwaitingReview"
              @click="confirm"
              data-cy="confirm"
              :disabled="denyWarn || reviewing"
              >
              <span v-if="reviewing">
                <v-icon class="mr-2">fas fa-spinner fa-spin</v-icon> Submitting...
              </span>
              <span v-else>
                Confirm
              </span>
              
              </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["clearance", "type"],
  data: () => {
    return {
      dialog: false,
      status: null,
      message: "",
      reviewing: false,
    };
  },
  computed: {
    ...mapGetters(["is_reviewer", "user"]),
    denyWarn() {
      let d = this.message ? this.message.trim() : "";
      return (this.status == "declined" && d == "");
    },
    isDashboard() {
      return this.type == "dashboard";
    },
    hovPosition() {
      return this.isDashboard ? "left" : "right";
    },
    isAwaitingReview() {
      return this.clearance && this.clearance.status == "awaiting_review";
    }
  },
  methods: {
    ...mapActions(["reviewClearance"]),
    canViewButton() {
      if (
        this.is_reviewer ||
        (!this.isAwaitingReview &&
          this.clearance.authors_list.indexOf(this.user.id) >= 0)
      ) {
        return true;
      }

      return false;
    },
    confirm() {
      if (this.denyWarn) {
        return;
      }
      this.reviewing = true;
      this.reviewClearance({
        clearance_id: this.clearance.id,
        status: this.status,
        message: this.message
      }).then(response => {
        this.reviewing = false;
        this.$emit("load");
        this.dialog = false;
      });
    }
  }
};
</script>
<style scoped>
.deny-warn,
.deny-warn i {
  color: rgb(204, 57, 57) !important;
}
</style>
