<template>
  <div>
    <v-dialog v-model="deleteDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          Delete Photo
        </v-card-title>
        <v-card-text>Are you sure you want to delete this photo?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteLogDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          Delete log
        </v-card-title>
        <v-card-text>Are you sure you want to delete this log?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteLogDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDeleteLog">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <table class="clearance-table">
      <tr v-for="(log, lkey) in photoLogs" :key="lkey">
        <td class="w-50" style="vertical-align:top">
          <div>
            <div v-for="(photo, pkey) in log.photos" :key="pkey">
              <v-row>
                <v-col cols="10">
                  <v-file-input
                    show-size
                    @change="fileChange($event, lkey, pkey)"
                    accept="image/*"
                    v-validate="'size:20480'"
                    :error-messages="errors.collect('image-' + pkey)"
                    :data-vv-name="'image-' + pkey"
                    :label="photo.name ? photo.name : 'Upload a photo'"
                  ></v-file-input>
                </v-col>
                <v-col cols="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        rounded
                        color="error"
                        v-on="on"
                        @click="showDelete(lkey, pkey)"
                      >
                        <v-icon>fas fa-times</v-icon>
                      </v-btn>
                    </template>
                    <span>Remove photo</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </div>
          <div
            :class="{
              'text-right': log.photos.length > 0,
              'text-center': log.photos.length == 0
            }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="addNewPhoto(lkey)"
                  color="primary"
                  :loading="isSaving"
                >
                  <v-icon class="mr-1">
                    fas fa-image
                  </v-icon>
                </v-btn>
              </template>
              <span>Add photo</span>
            </v-tooltip>
          </div>
        </td>
        <td class="w-50" style="vertical-align:top">
          <v-row>
            <v-col cols="10">
              <v-textarea
                label="Description"
                placeholder="Please enter description of the photos"
                v-model="log.description"
              >
              </v-textarea>
            </v-col>
            <v-col cols="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    rounded
                    color="error"
                    @click="showDeleteLog(lkey)"
                  >
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </template>
                <span>Remove Log row</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </td>
      </tr>
    </table>

    <div class="text-center mt-2">
      <v-btn @click="addNewLog" color="primary" :loading="isSaving">
        <v-icon class="mr-1">
          fas fa-plus
        </v-icon>
        Add New photo log
      </v-btn>
    </div>
    <div class="float-right">
      <v-btn @click="save" color="primary" :loading="isSaving" class="mr-2">
        <v-icon class="mr-1">
          fas fa-save
        </v-icon>

        Save
      </v-btn>
      <v-btn @click="$emit('next', true)" color="primary" :loading="isSaving">
        Next
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["clearance_id", "trigger_save"],
  data() {
    return {
      photoLogs: [],
      isSaving: false,
      defaultLog: {
        id: null,
        description: null,
        photos: []
      },
      defaultPhoto: {
        id: null,
        name: null,
        path: null,
        deleted: null
      },
      deleteDialog: false,
      deleteLogDialog: false,
      selectedLog: null,
      selectedPhoto: null
    };
  },
  watch: {
    trigger_save() {
      if (this.trigger_save == true) {
        this.save(false);
        return;
      }
    }
  },
  methods: {
    ...mapActions(["getClearancePhotoLogs", "insertUpdatePhotoLogs"]),
    confirmDelete() {
      this.photoLogs[this.selectedLog].photos.splice(this.selectedPhoto, 1);
      this.deleteDialog = false;
    },
    showDelete(logKey, photoKey) {
      this.selectedLog = logKey;
      this.selectedPhoto = photoKey;
      if (
        this.photoLogs[this.selectedLog].photos[this.selectedPhoto].id == null
      ) {
        this.photoLogs[this.selectedLog].photos.splice(this.selectedPhoto, 1);
        return;
      }
      this.deleteDialog = true;
    },
    confirmDeleteLog() {
      this.photoLogs.splice(this.selectedLog, 1);
      this.deleteLogDialog = false;
    },
    showDeleteLog(logKey) {
      this.selectedLog = logKey;
      if (this.photoLogs[this.selectedLog].id == null) {
        this.photoLogs.splice(this.selectedLog, 1);
        return;
      }
      this.deleteLogDialog = true;
    },
    addNewLog() {
      let newLog = JSON.parse(JSON.stringify(this.defaultLog));
      let newPhoto = JSON.parse(JSON.stringify(this.defaultPhoto));
      newLog.photos.push(newPhoto);
      this.photoLogs.push(newLog);
    },
    addNewPhoto(lkey) {
      let newPhoto = JSON.parse(JSON.stringify(this.defaultPhoto));
      this.photoLogs[lkey].photos.push(newPhoto);
    },
    fileChange(file, lkey, pkey) {
      if (!file) {
        this.photoLogs[lkey].photos[pkey].path = null;
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", e => {
        this.photoLogs[lkey].photos[pkey].path = e.target.result;
        this.photoLogs[lkey].photos[pkey].name = file.name;
      });
      reader.addEventListener(
        "error",
        e => (this.photoLogs[lkey].photos[pkey].path = null)
      );
      reader.readAsDataURL(file);
    },
    getPhotoLogs() {
      this.getClearancePhotoLogs(this.clearance_id).then(response => {
        this.photoLogs = response.data.photo_logs;
      });
    },
    save(withMsg = true) {
      this.isSaving = true;
      this.insertUpdatePhotoLogs({
        photo_logs: this.photoLogs,
        clearance_id: this.clearance_id,
        with_message: withMsg
      })
        .then(response => {
          this.photoLogs = response.data.photo_logs;
          this.isSaving = false;
        })
        .catch(() => {
          this.isSaving = false;
        });
    }
  },
  mounted() {
    this.getPhotoLogs();
  }
};
</script>
<style scoped>
.clearance-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.clearance-table td,
.clearance-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.clearance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.clearance-table td:nth-child(1) {
  width: 80%;
}
.clearance-table td:nth-child(2) {
  text-align: center;
}

.clearance-table tr:hover {
  background-color: #ddd;
}

.clearance-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #94959c;
  color: white;
}

.w-50 {
  width: 50% !important;
}
</style>
