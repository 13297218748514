function optimizeImage(data) {
  return new Promise((resolve) => {
    const maxSize = 1000;
    const image = new Image();
    image.onload = function (e) {
      const canvas = document.createElement("canvas");
      let width = image.width;
      let height = image.height;
      if (width > height && width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(image, 0, 0, width, height);
      resolve(canvas.toDataURL("image/jpeg", 0.7));
    };
    image.src = data;
  });
}

async function convertToData(file) {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

async function createFormData(data, type = null) {
  let request = new FormData();
  if (type) request.append("_method", type);
  if (typeof data.survey !== "undefined") {
    const survey = JSON.stringify(data.survey);
    request.append("survey", survey);
    data = data.building;
  }
  for (let item of Object.keys(data)) {
    if (
      item === "address" ||
      item === "sample" ||
      item === "presumed_sample" ||
      item === "sampled_as" ||
      item === "reported_sample" ||
      item === "lead" ||
      item === "layer"
    ) {
      request.append(item, JSON.stringify(data[item]));
      continue;
    } else if (data[item] && data[item] instanceof File) {
      if (item != "return" && item != "fileUp") {
        const fileData = await convertToData(data[item]);
        const result = await optimizeImage(fileData);
        if (result) request.append(item, result);
      } else {
        request.append(item, data[item]);
      }
      continue;
    } else if (data[item] && data[item] && data[item] instanceof Blob) {
      const result = await optimizeImage(data[item]);
      if (result) request.append(item, result);
      continue;
    } else {
      request.append(item, data[item] === null ? "" : data[item]);
    }
  }
  return request;
}

function downloadReportEvent(data) {
  const link = document.createElement("a");
  link.href = data.href;
  link.download = data.name;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export { createFormData, optimizeImage, convertToData, downloadReportEvent };
