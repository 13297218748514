<template>
  <v-dialog v-model="show" persistent max-width="900" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-col
        class="text-center mt-n2 pa-1 blue darken-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              color="white"
              class="w-100 h-100 ma-0 pa-0 bg-primary"
            >
              <v-icon>fas fa-plus</v-icon></v-btn
            >
          </template>
          <span>Create new survey</span>
        </v-tooltip>
      </v-col>
    </template>
    <v-card>
      <v-card-title> Create Survey </v-card-title>
      <v-card-text>
        <v-col>
          <v-row>
            <v-col cols="6" class="job-number-step">
              <v-text-field
                spellcheck="true"
                autocomplete="off"
                v-model="survey.job_number"
                item-value="id"
                item-text="job_number"
                outlined
                label="Job Number"
                counter
                maxlength="100"
                hint="Enter a job number for the project"
                autofocus
                v-validate="'required|max:100'"
                data-vv-name="job number"
                :error-messages="surveyMessages"
                required
                @change.native="jobNumberCheck"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="survey.name"
                outlined
                label="Name*"
                data-vv-name="name"
                v-validate="'required|max:100'"
                :error-messages="errors.collect('name')"
                messages="*Required"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" cols="12" class="my-0">
              <v-autocomplete
                autocomplete="off"
                v-model="survey.client_id"
                outlined
                v-validate="'required'"
                :items="extantClients"
                placeholder="Select the client"
                item-text="name"
                item-value="id"
                :error-messages="errors.collect('client')"
                label="Client*"
                data-vv-name="client"
                messages="*Required"
                required
                ><template v-slot:prepend-item>
                  <v-col class="text-center mt-n2 pa-1 blue darken-3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          color="white"
                          class="w-100 h-100 ma-0 pa-0 bg-primary"
                          @click="clientDialog = true"
                        >
                          <v-icon
                            >fas fa-plus</v-icon
                          ></v-btn
                        >
                      </template>
                      <span>Create Client</span>
                    </v-tooltip>
                  </v-col>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="6" cols="12" class="my-0">
              <v-autocomplete
                autocomplete="off"
                v-model="survey.contact_id"
                :disabled="!survey.client_id"
                outlined
                v-validate="'required'"
                :items="contacts"
                item-text="full_name"
                item-value="id"
                :error-messages="errors.collect('contact')"
                label="Contact*"
                data-vv-name="contact"
                messages="*Required"
                required
              >
                <template v-slot:prepend-item>
                  <v-col class="text-center mt-n2 pa-1 blue darken-3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          color="white"
                          class="w-100 h-100 ma-0 pa-0 bg-primary"
                          @click="contactDialog = true"
                        >
                          <v-icon
                            >fas fa-plus</v-icon
                          ></v-btn
                        >
                      </template>
                      <span>Create Client Contact</span>
                    </v-tooltip>
                  </v-col>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="my-0">
              <v-autocomplete
                autocomplete="off"
                v-model="survey.office_id"
                placeholder="Select the office"
                outlined
                v-validate="'required'"
                :items="extantOffices"
                item-text="name"
                item-value="id"
                :error-messages="errors.collect('office')"
                label="Office*"
                data-vv-name="office"
                messages="*Required"
                required
              >
                <template v-slot:prepend-item>
                  <v-col class="text-center mt-n2 pa-1 blue darken-3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          color="white"
                          class="w-100 h-100 ma-0 pa-0 bg-primary"
                          @click="officeDialog = true"
                        >
                          <v-icon
                            >fas fa-plus</v-icon
                          ></v-btn
                        >
                      </template>
                      <span>Create Office</span>
                    </v-tooltip>
                  </v-col>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" depressed @click="setSurvey">Create</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
    <office-create-dialog
      :show="officeDialog"
      v-on:close="closeOfficeDialog"
    ></office-create-dialog>
    <client-create-dialog
      :show="clientDialog"
      v-on:close="closeClientDialog"
    ></client-create-dialog>
    <contact-create-dialog
      :show="contactDialog"
      v-on:close="closeContactDialog"
      :client="survey.client_id"
    ></contact-create-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OfficeCreateDialog from "./OfficeCreateDialog.vue";
import ClientCreateDialog from "./ClientCreateDialog.vue";
import ContactCreateDialog from "./ContactCreateDialog.vue";

export default {
  components: {
    OfficeCreateDialog,
    ClientCreateDialog,
    ContactCreateDialog,
  },
  data: () => ({
    show: false,
    jobCheck: true,
    clientDialog: false,
    contactDialog: false,
    officeDialog: false,
    survey: {
      client_id: null,
      contact_id: null,
      office_id: null,
      name: null,
      job_number: null,
      note: null,
    },
  }),
  methods: {
    ...mapActions(["getClient", "checkJobNumber"]),
    jobNumberCheck() {
      if(!this.survey.job_number) return false;
      this.checkJobNumber(this.survey.job_number).then((response) => {
        this.jobCheck = !response.data.exists;
      });
    },
    setSurvey() {
      this.$validator.validateAll().then((result) => {
        if (!result || !this.jobCheck) {
          return;
        }
        this.$emit("set-survey", this.survey);
        this.show = false;
      });
    },
    closeOfficeDialog() {
      this.officeDialog = false;
      const lastOffice = this.extantOffices.reduce((prev, cur) => {
        return cur.id > prev ? cur.id : prev;
      }, 0);
      this.survey.office_id = lastOffice;
    },
    closeContactDialog() {
      this.contactDialog = false;
      const contacts = this.extantClients.find(
        (client) => client.id == this.survey.client_id
      ).contacts;
      const lastContact = contacts.reduce((prev, cur) => {
        return cur.id > prev ? cur.id : prev;
      }, 0);
      this.survey.contact_id = lastContact;
    },
    closeClientDialog() {
      this.clientDialog = false;
      const lastClient = this.extantClients.reduce((prev, cur) => {
        return cur.id > prev ? cur.id : prev;
      }, 0);
      this.survey.client_id = lastClient;
      if (this.survey.client_id) this.getClient(this.survey.client_id);
    },
  },
  watch: {
    "survey.client_id"(updated) {
      if (updated != null) {
        const contact = this.extantClients.find(
          (client) => client.id == updated
        ).primary_id;
        this.survey.contact_id = contact;
      }
    },
  },
  computed: {
    ...mapGetters(["extantOffices", "extantClients", "extantLaboratories"]),
    contacts() {
      if (!this.survey.client_id) return;
      return this.extantClients.find(
        (client) => client.id == this.survey.client_id
      ).contacts;
    },
    surveyMessages() {
      if (!this.jobCheck) {
        return [
          ...this.errors.collect("job number"),
          "Job Number is already in use",
        ];
      }
      return this.errors.collect("job number");
    },
  },
};
</script>