import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user)?_c('tour',{key:_vm.user.tutorials_enabled,attrs:{"tourKey":"drawer"}}):_vm._e(),(_vm.user)?_c(VNavigationDrawer,{staticClass:"accent",attrs:{"app":"","clipped":""},model:{value:(_vm.draw),callback:function ($$v) {_vm.draw=$$v},expression:"draw"}},[_c(VRow,[_c(VCol,[_c('a-img',{staticClass:"mx-4 my-2",attrs:{"src":`/api/image/company/${_vm.user.company_id}`}})],1)],1),_c(VDivider,{staticClass:"my-1",attrs:{"inset":""}}),_c(VList,_vm._l((_vm.items),function(item){return _c('div',{key:item.title},[(
            (item.if == 'root' && _vm.isRoot) ||
            (item.if == 'admin' && (_vm.isAdmin || _vm.isRoot)) ||
            (item.if == 'pm' && (_vm.isPM || _vm.isAdmin || _vm.isRoot)) ||
            (_vm.company && _vm.isAdmin && item.if == 'hasRec') ||
            (item.if == 'isExporter' &&
              _vm.company &&
              _vm.company.features.find((feature) => feature.id == _vm.EXPORTER_FEATURE) &&
              _vm.isExporter) ||
            !item.if
          )?_c(VListItem,{attrs:{"exact":"","data-cy":item.test,"href":item.href,"target":item.href ? '_blank' : '',"to":item.to,"active-class":"primary--text","link":""},on:{"click":function($event){item.title == 'Feedback' ? (_vm.feedbackDialog = true) : null}}},[_c(VListItemIcon,{staticClass:"text-center"},[_c(VIcon,{staticClass:"fa-fw"},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{class:item.class || '',attrs:{"data-cy":"new-project-title"}},[_vm._v(_vm._s(item.title))])],1)],1):_vm._e(),(item.divider)?_c(VDivider,{staticClass:"my-4"}):_vm._e()],1)}),0),(_vm.isAdmin)?_c(VDivider,{staticClass:"my-4"}):_vm._e(),_c(VListItem,{on:{"click":function($event){_vm.feedbackDialog = true}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("fas fa-phone")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Feedback ")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.showChat()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("fas fa-comment-dots")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Chat Support ")])],1)],1),_c(VDivider,{staticClass:"my-4"}),(_vm.user)?_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("fas fa-user")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('i',[_vm._v(_vm._s(_vm.user.full_name))])])],1),_c(VListItemIcon,[_c('button',{staticClass:"user-setting-step",attrs:{"disabled":_vm.$route && _vm.$route.path == '/account'},on:{"click":function($event){return _vm.gotoAccount()}}},[_c(VIcon,[_vm._v("fas fa-cog")])],1)])],1):_vm._e(),_c(VListItem,{attrs:{"exact":"","active-class":"primary white--text","link":""},on:{"click":function($event){return _vm.logout()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("fas fa-sign-out")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Logout")])],1)],1),_c('feedback-dialog',{attrs:{"dialog":_vm.feedbackDialog},on:{"update:dialog":function($event){_vm.feedbackDialog=$event},"close":function($event){_vm.feedbackDialog = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }