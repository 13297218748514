<template>
  <div>
    <tour tourKey="drawer" v-if="user" :key="user.tutorials_enabled" />
    <v-navigation-drawer v-model="draw" v-if="user" app clipped class="accent">
      <v-row>
        <v-col>
          <a-img class="mx-4 my-2" :src="`/api/image/company/${user.company_id}`" />
        </v-col>
      </v-row>
      <v-divider inset class="my-1"></v-divider>
      <v-list>
        <div v-for="item in items" :key="item.title">
          <v-list-item
            exact
            :data-cy="item.test"
            :href="item.href"
            :target="item.href ? '_blank' : ''"
            :to="item.to"
            @click="item.title == 'Feedback' ? (feedbackDialog = true) : null"
            v-if="
              (item.if == 'root' && isRoot) ||
              (item.if == 'admin' && (isAdmin || isRoot)) ||
              (item.if == 'pm' && (isPM || isAdmin || isRoot)) ||
              (company && isAdmin && item.if == 'hasRec') ||
              (item.if == 'isExporter' &&
                company &&
                company.features.find((feature) => feature.id == EXPORTER_FEATURE) &&
                isExporter) ||
              !item.if
            "
            active-class="primary--text"
            link
          >
            <v-list-item-icon class="text-center">
              <v-icon class="fa-fw">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :class="item.class || ''" data-cy="new-project-title">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="item.divider" class="my-4"></v-divider>
        </div>
      </v-list>
      <v-divider v-if="isAdmin" class="my-4"></v-divider>
      <v-list-item @click="feedbackDialog = true">
        <v-list-item-icon>
          <v-icon>fas fa-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Feedback </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="showChat()">
        <v-list-item-icon>
          <v-icon>fas fa-comment-dots</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Chat Support </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-4"></v-divider>
      <v-list-item v-if="user">
        <v-list-item-icon>
          <v-icon>fas fa-user</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <i>{{ user.full_name }}</i>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <button
            @click="gotoAccount()"
            :disabled="$route && $route.path == '/account'"
            class="user-setting-step"
          >
            <v-icon>fas fa-cog</v-icon>
          </button>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item exact @click="logout()" active-class="primary white--text" link>
        <v-list-item-icon>
          <v-icon>fas fa-sign-out</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <feedback-dialog
        :dialog.sync="feedbackDialog"
        v-on:close="feedbackDialog = false"
      ></feedback-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FeedbackDialog from "./FeedbackDialog.vue";
import Tour from "./Tour.vue";

export default {
  components: {
    FeedbackDialog,
    Tour,
  },
  props: ["drawer"],
  data: () => ({
    RECOMMENDATION_FEATURE: 2,
    EXPORTER_FEATURE: 4,
    remaining: null,
    used: null,
    items: [
      {
        title: "New Project",
        icon: "fal fa-plus",
        to: "/project",
        test: "new-project-btn",
        class: "create-project-step",
      },
      {
        title: "Browse",
        icon: "fas fa-building",
        to: "/browse",
        test: "reports-btn",
        class: "browse-step",
      },
      {
        title: "Clients",
        icon: "fas fa-poll-people",
        to: "/clients",
        test: "clients-btn",
        class: "clients-step",
        divider: true,
      },
      {
        title: "Offices",
        if: "pm",
        icon: "fas fa-phone-office",
        to: "/admin/offices",
        test: "offices-btn",
        class: "offices-step",
        label: "Admin",
      },
      {
        title: "Laboratories",
        if: "pm",
        icon: "fas fa-flask",
        to: "/admin/laboratories",
        class: "laboratories-step",
        test: "laboratories-btn",
      },
      {
        title: "Users",
        if: "admin",
        icon: "fas fa-users",
        to: "/admin/users",
        class: "users-step",
        test: "users-btn",
      },
      {
        title: "CSV Upload",
        if: "pm",
        icon: "fas fa-file-csv",
        to: "/admin/csv",
        test: "csv-btn",
      },
      {
        title: "Statistics",
        if: "pm",
        icon: "fas fa-chart-line",
        to: "/admin/statistics",
      },
      {
        title: "Export",
        if: "isExporter",
        icon: "fas fa-file-export",
        to: "/export",
        test: "export-btn",
      },
      {
        title: "Recommendations",
        if: "hasRec",
        icon: "fas fa-cog",
        to: "/admin/recommendations",
        test: "settings-btn",
      },
    ],
    feedbackDialog: false,
  }),
  mounted() {
    this.storageCheck();
  },
  methods: {
    ...mapActions(["setDark", "reset2FA"]),
    logout() {
      window.$chatwoot.reset();
      this.reset2FA();
      this.$auth.logout();
    },
    showChat() {
      window.$chatwoot.setUser(this.user.id, {
        email: this.user.email,
        name: this.user.full_name,
      });
      window.$chatwoot.toggle();
    },
    gotoAccount() {
      this.$router.push("/account");
    },
    async storageCheck() {
      if (navigator.storage && navigator.storage.estimate) {
        const quota = await navigator.storage.estimate();
        // quota.usage -> Number of bytes used.
        // quota.quota -> Maximum number of bytes available.
        this.used = (quota.usage / quota.quota) * 100;
        // Converted bytes to mb
        this.remaining = ((quota.quota - quota.usage) / 1000000).toFixed(2);
      }
    },
  },
  computed: {
    ...mapGetters(["dark", "isAdmin", "user", "company", "isRoot", "isPM", "isExporter"]),
    theme: {
      get() {
        return this.dark;
      },
      set(value) {
        this.setDark(value);
      },
    },
    draw: {
      get() {
        return this.drawer;
      },
      set(update) {
        this.$emit("update:drawer", update);
      },
    },
  },
  watch: {
    theme(newval) {
      this.$vuetify.theme.dark = newval;
    },
  },
};
</script>

<style lang="sass" scoped>
.title:hover
  cursor: pointer !important

a
  color: white
</style>
