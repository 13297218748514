<template>
  <div>
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">
          Delete document
        </v-card-title>
        <v-card-text
          >Are you sure you want to delete this document?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4">
        <h3>Upload a new document</h3>
        <br />
        <v-text-field
          spellcheck="true"
          label="Certificate title"
          outlined
          v-model.lazy="title"
          v-validate="'required|max:190'"
          :error-messages="errors.collect('certificate title')"
          data-vv-name="Certificate title"
          placeholder="Enter title"
          messages="*Required"
          required
        ></v-text-field>
        <br />
        <v-file-input
          v-model="fileUp"
          :label="!title ? 'Please add a title first' : 'Upload a document'"
          :disabled="!title"
          @change="onFileChange($event)"
          v-validate="'size:20480'"
          :error-messages="errors.collect('document')"
          :data-vv-name="'document'"
          accept=".pdf"
          outlined
        ></v-file-input>
      </v-col>
      <v-col cols="8" v-if="documents && documents.length > 0">
        <div>
          <h3>
            Existing documents
          </h3>

          <v-row v-for="(f, k) in documents" :key="k">
            <v-col cols="6">
              <v-icon>fas fa-file-pdf</v-icon> {{ f.title }}
            </v-col>
            <v-col cols="6">
              <v-icon @click="downloadFile(f.id)" color="secondary" class="pr-2"
                >fas fa-arrow-down</v-icon
              >
              <v-icon @click="removeFile(f)" color="red">fas fa-times</v-icon>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["clearance_id", "trigger_save"],
  data() {
    return {
      documents: [],
      fileUp: [],
      title: "",
      isSaving: false,
      selectedFile: null,
      deleteDialog: false
    };
  },
  computed: {
    ...mapGetters({ invalidInput: "invalidInput" })
  },
  methods: {
    ...mapActions([
      "getClearanceAirSwabResults",
      "insertUpdateAirSwabResults",
      "uploadAirSwabResultFile",
      "deleteAirSwabResultDocument"
    ]),
    removeFile(file) {
      this.selectedFile = file;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.deleteAirSwabResultDocument(this.selectedFile.id).then(() => {
        this.getClearanceAirSwabResultDocuments();
        this.deleteDialog = false;
        this.selectedFile = null;
        this.returnEmit();
      });
    },
    returnEmit() {
      this.$emit("airChanged");
    },
    downloadFile(id) {
      const fileLink = document.createElement("a");
      fileLink.href = `/api/clearance/air-swab-results/${id}`;
      const downloadAt = document.createAttribute("download");
      fileLink.setAttributeNode(downloadAt);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    onFileChange(event) {
      if (!this.fileUp) {
        return;
      }

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.isSaving = true;
        this.uploadAirSwabResultFile({
          clearance_id: this.clearance_id,
          title: this.title,
          fileUp: this.fileUp
        })
          .then(response => {
            this.isSaving = false;
            this.fileUp = null;
            this.title = null;
            this.getClearanceAirSwabResultDocuments();
            this.returnEmit();
          })
          .catch(() => {
            this.isSaving = false;
          });
      });
    },
    getClearanceAirSwabResultDocuments() {
      this.getClearanceAirSwabResults(this.clearance_id).then(response => {
        this.documents = response.data.documents;
      });
    }
  },
  mounted() {
    this.getClearanceAirSwabResultDocuments();
  }
};
</script>
