<template>
  <v-row justify="center">
    <v-col md="10" sm="10">
      <v-row v-if="extantUsers">
        <v-col>
          <form v-if="survey" data-cy="edit-survey-form">
            <v-text-field
              spellcheck="true"
              autocomplete="off"
              v-model="job_number"
              outlined
              label="Job Number"
              v-validate="'required|max:100'"
              counter
              maxlength="100"
              hint="Enter an identifier for the project"
              data-cy="job-number"
            ></v-text-field>

            <v-text-field
              spellcheck="true"
              autocomplete="off"
              v-model="name"
              outlined
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('name')"
              label="Name"
              data-vv-name="name"
              hint="Enter the name of the project"
              required
              data-cy="name"
            ></v-text-field>

            <v-autocomplete
              autocomplete="off"
              v-model="office_id"
              outlined
              v-validate="'required'"
              :items="extantOffices"
              item-text="name"
              item-value="id"
              :error-messages="errors.collect('office')"
              label="Office"
              data-vv-name="office"
              hint="Select the office which will complete the project"
              required
            >
              <template v-slot:prepend-item>
                <v-col class="text-center mt-n2 pa-1 blue darken-3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        color="white"
                        class="w-100 h-100 ma-0 pa-0 bg-primary"
                        @click="officeDialog = true"
                      >
                        <v-icon>fas fa-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Create Office</span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-autocomplete>

            <v-autocomplete
              attach
              autocomplete="off"
              v-model="client_id"
              outlined
              v-validate="'required'"
              :items="extantClients"
              item-text="name"
              item-value="id"
              :error-messages="errors.collect('client')"
              label="Client"
              data-vv-name="client"
              hint="Select the project client"
              required
            >
              <template v-slot:prepend-item>
                <v-col class="text-center mt-n2 pa-1 blue darken-3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        color="white"
                        class="w-100 h-100 ma-0 pa-0 bg-primary"
                        @click="clientDialog = true"
                      >
                        <v-icon>fas fa-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Create Client</span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-autocomplete>

            <v-autocomplete
              attach
              autocomplete="off"
              v-if="client_id"
              v-model="contact_id"
              outlined
              v-validate="'required'"
              :items="contacts"
              item-text="full_name"
              item-value="id"
              :error-messages="errors.collect('client contact')"
              label="Contact"
              data-vv-name="contact"
              hint="Select the contact"
              required
            >
              <template v-slot:prepend-item>
                <v-col class="text-center mt-n2 pa-1 blue darken-3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        color="white"
                        class="w-100 h-100 ma-0 pa-0 bg-primary"
                        @click="contactDialog = true"
                      >
                        <v-icon>fas fa-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Create Client Contact</span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-autocomplete>
          </form>
        </v-col>
      </v-row>
      <v-row v-if="!survey.id">
        <v-col justify="center" align="center">
          <v-btn class="mr-4" color="primary" @click="create()" data-cy="submit"
            >Create</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-btn
            class="float-left"
            color="warning"
            data-cy="back"
            @click="$router.go(-1)"
            >Back</v-btn
          >
          <v-btn
            class="float-right"
            color="primary"
            data-cy="update"
            @click="update()"
            >Update</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <office-create-dialog
      :show="officeDialog"
      v-on:close="closeOfficeDialog"
    ></office-create-dialog>
    <client-create-dialog
      :show="clientDialog"
      v-on:close="closeClientDialog"
    ></client-create-dialog>
    <contact-create-dialog
      :show="contactDialog"
      v-on:close="closeContactDialog"
      :client="client_id"
    ></contact-create-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OfficeCreateDialog from "./OfficeCreateDialog.vue";
import ClientCreateDialog from "./ClientCreateDialog.vue";
import ContactCreateDialog from "./ContactCreateDialog.vue";
export default {
  components: {
    OfficeCreateDialog,
    ClientCreateDialog,
    ContactCreateDialog
  },
  mounted() {
    if (!this.$route.params.survey) {
      this.clear();
    }
    this.getClients();
    this.setComponentValues();
    this.$validator.reset();
  },
  data: () => ({
    reader: null,
    id: null,
    client_id: null,
    contact_id: null,
    office_id: null,
    name: null,
    job_number: null,
    note: null,
    officeDialog: false,
    clientDialog: false,
    contactDialog: false
  }),
  methods: {
    ...mapActions([
      "getUsers",
      "createSurvey",
      "editSurvey",
      "clearSurvey",
      "getClients",
      "getClient"
    ]),
    closeOfficeDialog() {
      this.officeDialog = false;
      const lastOffice = this.extantOffices.reduce((prev, cur) => {
        return cur.id > prev ? cur.id : prev;
      }, 0);
      this.office_id = lastOffice;
    },
    closeContactDialog() {
      this.contactDialog = false;
      const contacts = this.extantClients.find(
        client => client.id == this.client_id
      ).contacts;
      const lastContact = contacts.reduce((prev, cur) => {
        return cur.id > prev ? cur.id : prev;
      }, 0);
      this.contact_id = lastContact;
    },
    closeClientDialog() {
      this.clientDialog = false;
      const lastClient = this.extantClients.reduce((prev, cur) => {
        return cur.id > prev ? cur.id : prev;
      }, 0);
      this.client_id = lastClient;
      this.getClient(this.client_id);
    },
    create() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.createSurvey(this.sendSurvey).then(response => {
          this.$router.push("/survey/" + response.data.survey_id);
        });
      });
    },
    update() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.editSurvey(this.editedSurvey).then(response => {
          this.$router.push("/survey/" + response.data.survey_id);
        });
      });
    },
    clear() {
      this.clearSurvey();
      this.reader = null;
      this.id = null;
      this.client_id = null;
      this.contact_id = null;
      this.office_id = null;
      this.name = null;
      this.job_number = null;
      this.note = null;
      this.$validator.reset();
    },
    setComponentValues() {
      this.id = this.survey.id;
      this.client_id = this.survey.client_id;
      this.contact_id = this.survey.contact_id;
      this.office_id = this.survey.office_id;
      this.name = this.survey.name;
      this.job_number = this.survey.job_number;
    }
  },
  computed: {
    ...mapGetters([
      "company",
      "extantOffices",
      "user",
      "extantUsers",
      "extantClients",
      "admins",
      "survey",
      "invalidInput"
    ]),
    editedSurvey() {
      let survey = {
        id: this.id,
        client_id: this.client_id,
        contact_id: this.contact_id,
        office_id: this.office_id,
        name: this.name,
        job_number: this.job_number
      };
      return survey;
    },
    sendSurvey() {
      let survey = {
        client_id: this.client_id,
        contact_id: this.contact_id,
        office_id: this.office_id,
        name: this.name,
        job_number: this.job_number
      };
      return survey;
    },
    contacts() {
      if (!this.client_id) return;
      return this.extantClients.find(client => client.id == this.client_id)
        .contacts;
    }
  },
  watch: {
    "$route.params.survey": function(val) {
      if (!val) {
        this.clear();
      } else {
        this.setComponentValues();
      }
    }
  }
};
</script>
