<template>
  <v-row v-if="building">
    <v-col class="py-0 my-0">
      <tour tourKey="create_project" />
      <v-row>
        <v-col class="py-0 my-0">
          <h1>Create Project</h1>
          <p class="text--secondary pt-0 mt-0">
            Set up a survey and add a
            {{ isNotClearance ? "building" : "clearance" }}, or add a
            {{ isNotClearance ? "building" : "clearance" }} to an existing
            survey
          </p>
        </v-col>
      </v-row>
      <create-survey v-model="survey"></create-survey>
      <v-row>
        <v-col
          ><h3 class="primary--text">
            <span class="building-information-step pr-3">
              {{ isNotClearance ? "Building" : "Clearance" }} Information</span
            >
          </h3></v-col
        >
      </v-row>
      <building-form
        :aBuilding="building"
        @update="updateBuilding"
        v-if="building"
        :validator="$validator"
      ></building-form>
      <v-row>
        <v-col>
          <v-btn
            data-cy="create-project"
            class="float-right"
            color="primary"
            depressed
            @click="save"
            >Create</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddressForm from "../components/AddressForm.vue";
import CreateSurvey from "../components/CreateSurvey.vue";
import Tour from "../components/Tour.vue";
import BuildingForm from "../components/BuildingForm.vue";

export default {
  components: {
    CreateSurvey,
    AddressForm,
    Tour,
    BuildingForm,
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  data: () => ({
    clearAddress: false,
    survey: {
      id: null,
      job_number: null,
      name: null,
      office_id: null,
      client_id: null,
      contact_id: null,
    },
    building: {
      address: {
        city: null,
        suburb: null,
        postcode: null,
        address: null,
        address2: null,
        latitude: null,
        longitude: null,
        geolocation: false,
      },
      surveyor_id: null,
      surveyors: [],
      reviewer_id: null,
      name: null,
      description: null,
      phase: null,
      image: null,
      file: null,
      site_plan: null,
      site_file: null,
      is_within_three_months: false,
      is_answering_questions: false,
      is_residential: false,
      is_urgent_transfer: false,
      has_multiple_images: false,
      has_site_plan: false,
      has_sample_polygon: true,
      laboratory_id: null,
      report_type_id: null,
      label_size: 10,
      is_lead: null,
      is_asbestos: null,
    },
  }),
  mounted() {
    this.setLoading(false);
    this.getReportTypes();
    this.getLaboratories();
    if (this.$route.params.survey) {
      this.survey = this.aSurvey;
    }
    this.$validator.reset();
  },
  computed: {
    ...mapGetters([
      "extantOffices",
      "extantUsers",
      "extantClients",
      "extantLaboratories",
      "surveyors",
      "invalidInput",
      "report_types",
      "reviewers",
    ]),
    ...mapGetters({
      aSurvey: "survey",
    }),
    isNotClearance() {
      return this.building.report_type_id != 6;
    },
  },
  methods: {
    ...mapActions(["createProject", "setLoading", "getReportTypes", "getLaboratories"]),
    updateBuilding(aBuilding) {
      this.building = { ...aBuilding };
    },
    setBuildingAddress(data, field) {
      this.building.address[field] = data;
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }
        this.setLoading();
        this.createProject({
          survey: { ...this.survey },
          building: {
            ...this.building,
          },
        })
          .then((response) => {
            this.$router.push(response.data.to);
          })
          .finally(() => {
            this.setLoading(false);
          });
      });
    },
  },
};
</script>
