<template>
  <div>
    <v-row v-if="approvals">
      <v-col align="left">
        <h1 data-cy="project-context">
          <span data-cy="building-name">{{ building.name }}</span>
          <span data-cy="building-phase">{{
            building.phase ? `- ${building.phase}` : ""
          }}</span>

          <span v-if="!isClearanceCert">
            <v-chip
              data-cy="building-status"
              v-if="building.status_id"
              :color="statusColors[building.status_id]"
              :key="`building-status-${building.status_id}`"
              dark
              class="ml-4 mb-2"
            >
              <span v-if="buildingStatuses">
                {{
                  buildingStatuses.find(
                    (status) => status.id == building.status_id
                  ).name
                }}
              </span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="status-edit"
                    v-if="$route.params.building"
                    v-on="on"
                    icon
                    color="white"
                    @click="dialog = true"
                    :disabled="
                      floors.length == 0 ||
                      building.rooms_count == 0 ||
                      !building.survey_date
                    "
                  >
                    <v-icon>fas fa-edit</v-icon>
                  </v-btn>
                </template>
                <span>Change status</span>
              </v-tooltip>
            </v-chip>
          </span>
          <span v-else>
            <v-chip
              data-cy="building-status"
              v-if="building.status_id"
              :color="statusColors[building.status_id]"
              dark
              class="ml-4 mb-2"
            >
              <span v-if="buildingStatuses">
                {{
                  buildingStatuses.find(
                    (status) => status.id == building.status_id
                  ).name
                }}
              </span>
            </v-chip>
          </span>
        </h1>
        <v-row>
          <v-col>
            <v-tooltip top v-if="building.is_asbestos">
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="approvals.asbestos ? 'blue' : 'gray'"
                  class="pr-2"
                  v-on="on"
                  >fal fa-stroopwafel</v-icon
                >
              </template>
              <span>
                Asbestos
                {{
                  approvals.asbestos && building.status_id != AWAITING_REVIEW
                    ? "approved"
                    : ""
                }}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="building.is_lead">
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="approvals.lead ? 'green' : 'gray'"
                  class="pr-2"
                  v-on="on"
                  >fas fa-fill-drip</v-icon
                >
              </template>
              <span>
                Lead
                {{
                  approvals.lead && building.status_id != AWAITING_REVIEW
                    ? "approved"
                    : ""
                }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <h3 data-cy="building-full-add">
          {{ building.full_address }}
        </h3>
        <h4 class="ma-0 pa-0" data-cy="building-job-num">
          {{ building.job_number }}
        </h4>
        <p class="font-weight-light" data-cy="building-des">
          {{ building.description }}
        </p>
        <p
          class="font-weight-light"
          data-cy="building-des"
          v-if="!$route.params.building"
        >
          <strong style="font-size: 1.2em; color: #0058a1">{{
            building.report_type
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row
      class="warning mb-4 rounded"
      v-if="!notBuilding && building.surveyors.length == 0"
    >
      <v-col class="pb-0">
        <p class="text-center text-h5 white--text">Surveyor has not been set</p>
      </v-col>
    </v-row>
    <div class="mb-6">
      <building-buttons
        :the_building="building"
        class="d-inline-block"
      ></building-buttons>
      <review-buttons
        v-if="isAwaitingReview && (is_reviewer || isRoot)"
        class="d-inline review-step"
        :building_id="building.id"
        :type="'building'"
        v-on:load="$emit('load')"
      />
    </div>
    <building-status-dialog
      v-bind:dialog.sync="dialog"
    ></building-status-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BuildingButtons from "./BuildingButtons.vue";
import BuildingStatusDialog from "./BuildingStatusDialog.vue";
import ReviewButtons from "./ReviewButtons.vue";

export default {
  components: {
    BuildingButtons,
    BuildingStatusDialog,
    ReviewButtons,
  },
  created() {
    this.AWAITING_REVIEW = 9;
    this.REVIEWED = 10;
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters([
      "building",
      "approvals",
      "buildingStatuses",
      "is_reviewer",
      "isRoot",
      "floors",
      "isClearanceCert",
      "statusColors",
    ]),
    isAwaitingReview() {
      return (
        this.building.status_name == "Awaiting Review" && !this.isClearanceCert
      );
    },
  },
  methods: {
    notBuilding() {
      return !this.$route.matched.some(({ path }) => path.includes("building"));
    },
  },
};
</script>