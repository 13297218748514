<template>
  <v-dialog v-model="show" persistent max-width="900" scrollable>
    <v-card>
      <v-card-title
        >Terms and Conditions for the use of the CCS software</v-card-title
      >
      <v-card-text>
        <terms-conditions />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TermsConditions from "./TermsConditions.vue";
export default {
  components: { TermsConditions },
  props: ["show"],
  methods: {
    close() {
      this.$emit("closeDialog");
    }
  }
};
</script>
