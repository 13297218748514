<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="400px"
    transition="fade-transition"
  >
    <v-card>
      <v-card-title>Change Building Status</v-card-title>
      <v-card-text data-cy="building-statuses">
        <v-autocomplete
          label="Building statuses"
          :items="buildingStatuses"
          item-text="name"
          item-value="id"
          v-model="status"
          :item-disabled="disableReviewed"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Cancel</v-btn>
        <v-btn color="primary" text @click="confirm" data-cy="confirm-status"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["dialog"],
  data: () => ({
    status: null,
  }),
  mounted() {
    this.$nextTick(() => {
      this.status = this.building.status_id;
    });
  },
  methods: {
    ...mapActions(["updateBuildingStatusTo", "setLoading"]),
    confirm() {
      this.updateBuildingStatusTo({
        id: this.building.id,
        status: this.status,
      }).then(() => {
        this.$emit("confirm");
        this.show = false;
      }).catch((error) => {
        this.denyMessage = error.message;
        this.show = false;
        this.setLoading(false);
      })
    },
    disableReviewed(item) {
      return item.name == "Reviewed";
    },
  },
  computed: {
    ...mapGetters(["buildingStatuses", "building"]),
    denyMessage: {
      get() {
        return this.deny;
      },
      set(updated) {
        this.$emit("update:deny", updated);
      },
    },
    show: {
      get() {
        return this.dialog;
      },
      set(updated) {
        this.$emit("update:dialog", updated);
      },
    },
  },
  watch: {
    "building.status_id"(updated) {
      this.status = updated;
    },
  },
};
</script>
