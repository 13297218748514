<template>
  <v-row>
    <v-col v-if="client.id" class="py-0 my-0">
      <v-row>
        <v-col class="py-0 my-0">
          <h2 data-cy="client-name">{{ client.name }}</h2>
          <h3 data-cy="client-full-add">{{ client.full_address }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center justify-center">
          <a-img
            style="max-width:600px;margin:auto;"
            :src="`/api/image/client/${client.id}?${client.updated_at}`"
          ></a-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <client-building-table :client_id="client.id"></client-building-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ClientBuildingTable from "../components/ClientBuildingTable";
export default {
  components: {
    ClientBuildingTable
  },
  computed: {
    ...mapGetters(["client"])
  },
};
</script>
