<template>
  <v-dialog v-model="duplicateDialog" persistent max-width="400px">
    <v-card>
      <v-card-title>Duplicate {{ model }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="sub-header" v-if="model == 'Building'">
              All duplicated items will be set to "Previously Sampled"
            </span>
            <span class="sub-header" v-else-if="model != 'Item'">
              All duplicated items will be set to "Sampled as"
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              spellcheck="true"
              label="Name"
              v-model="duplicateName"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="!newSurvey">
          <v-col>
            <v-autocomplete
              v-model="duplicateValue"
              :label="label"
              :items="items"
              :item-text="text"
              item-value="id"
              v-validate="'required'"
              :error-messages="errors.collect(label)"
              :data-vv-name="label"
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="model == 'Building'">
          <v-col>
            <v-checkbox
              v-model="newSurvey"
              label="Create new survey?"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="newSurvey">
          <v-col>
            <v-text-field
              spellcheck="true"
              label="Survey Name"
              v-model="survey.name"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('survey name')"
              data-vv-name="survey name"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="newSurvey">
          <v-col>
            <v-text-field
              spellcheck="true"
              label="Job Number"
              v-model="survey.job_number"
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="surveyMessages"
              data-vv-name="job number"
              @change.native="jobNumberCheck"
              :error="!jobCheck"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="newSurvey">
          <v-col>
            <v-autocomplete
              autocomplete="off"
              v-model="survey.office_id"
              data-cy="select-office"
              placeholder="Select the office"
              outlined
              v-validate="'required'"
              :items="extantOffices"
              item-text="name"
              item-value="id"
              :error-messages="errors.collect('office')"
              label="Office*"
              data-vv-name="office"
              messages="*Required"
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="newSurvey">
          <v-col>
            <v-autocomplete
              data-cy="client"
              autocomplete="off"
              v-model="survey.client_id"
              outlined
              v-validate="'required'"
              :items="extantClients"
              placeholder="Select the client"
              item-text="name"
              item-value="id"
              :error-messages="errors.collect('client')"
              label="Client*"
              data-vv-name="client"
              messages="*Required"
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="newSurvey">
          <v-col>
            <v-autocomplete
              data-cy="contact"
              autocomplete="off"
              v-model="survey.contact_id"
              outlined
              v-validate="'required'"
              :disabled="contacts.length == 0"
              :items="contacts"
              item-text="full_name"
              item-value="id"
              :error-messages="errors.collect('contact')"
              label="Contact*"
              data-vv-name="contact"
              messages="*Required"
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="duplicateDialog = false">Cancel</v-btn>
        <v-btn
          data-cy="confirm-duplicate"
          color="primary"
          text
          :disabled="!jobCheck || (!survey.contact_id && newSurvey)"
          @click="duplicate"
          >Confirm</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["dialog", "model", "label", "name", "items", "value", "text"],
  data: () => ({
    newSurvey: false,
    jobCheck: true,
    survey: {
      name: null,
      job_number: null,
      office_id: null,
      client_id: null,
      contact_id: null,
      note: "Duplicated building"
    }
  }),
  methods: {
    ...mapActions(["checkJobNumber"]),
    duplicate() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }
        this.$emit("duplicate", this.survey);
      });
    },
    jobNumberCheck() {
      this.checkJobNumber(this.survey.job_number).then(response => {
        this.jobCheck = !response.data.exists
      })
    }
  },
  computed: {
    ...mapGetters(["extantOffices", "extantClients"]),
    surveyMessages() {
      if(!this.jobCheck) {
        return [...this.errors.collect('job number'), 'Job Number is already in use'];
      }
      return this.errors.collect('job number');
    },
    contacts() {
      if (!this.survey.client_id) return [];
      return this.extantClients.find(
        client => client.id == this.survey.client_id
      ).contacts;
    },
    duplicateDialog: {
      get() {
        return this.dialog;
      },
      set(updated) {
        this.$emit("update:dialog", updated);
      }
    },
    duplicateName: {
      get() {
        return this.name;
      },
      set(updated) {
        this.$emit("update:name", updated);
      }
    },
    duplicateValue: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit("update:value", updated);
      }
    }
  }
};
</script>
