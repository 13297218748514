<template>
  <v-container v-if="loaded2fa">
    <div class="top-bar"></div>
    <v-row class="text-center bg-white">
      <v-col>
        <img :src="`/img/marketing/ccs_logo.png`" rel="image:CCS Logo" />
      </v-col>
    </v-row>
    <v-row justify="center" style="margin: 0px 3px">
      <v-col md="3" sm="1" xs="0"></v-col>
      <v-col md="6" sm="10" xs="12">
        <h2 class="text-center">
          {{
            two_fa.has_2fa_activated
              ? "Login using your Authenticator"
              : "Set up Authenticator"
          }}
        </h2>
      </v-col>
      <v-col md="3" sm="1" xs="0"> </v-col>
    </v-row>
    <v-row>
      <v-col md="1"></v-col>
      <v-col md="5" v-if="!two_fa.has_2fa_activated">
        <div class="text-center">
          <img :src="two_fa.qr_code" alt="" contain height="200" width="200" />
        </div>
        <p>
          Set up your two factor authentication by scanning the qr-code above.
          Alternatively, you can use the code
          <strong>{{ two_fa.secret_key }}</strong>
        </p>
        <p>Don't have an Authenticator app? Download one using the links below.</p>
        <div>
          <v-row>
            <v-col class="text-right google-col">
              <a
                target="_blank"
                href="https://play.google.com/store/search?q=Authenticator&c=apps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                ><img
                  alt="Get it on Google Play"
                  class="google-badge"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              /></a>
            </v-col>
            <v-col class="apple-col">
              <a target="_blank" href="https://apple.co/3DfXOLO"
                ><img
                  class="apple-badge"
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1284940800&h=288c84cc3566737997a818b26a76c159"
                  alt="Download on the App Store"
              /></a>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col :md="two_fa.has_2fa_activated ? '10' : '5'">
        <v-form method="POST" ref="form" @submit.prevent>
          <v-row class="m-2" justify="center">
            <v-col md="8" sm="10" xs="12" class="text-center">
              <p v-if="two_fa.has_2fa_activated">
                Please use your Authenticator app to continue logging in.
              </p>
              <v-text-field
                prepend-icon="fas fa-qrcode"
                type="text"
                v-model="code"
                v-validate="'required'"
                :error-messages="errors.collect('code')"
                autofocus
                label="Authenticator Code"
                data-vv-name="code"
                @keyup.native.enter="verify"
                light="light"
              ></v-text-field>
              <br />
              <v-btn
                style="color: #fff"
                block="block"
                v-bind:class="[{ disabled: !code }]"
                color="primary"
                class="mr-4"
                @keyup.native.enter="verify"
                @click="verify"
                data-cy="submit"
                >{{ two_fa.has_2fa_activated ? "Sign-in" : "Setup 2FA" }}</v-btn
              >

              <div
                v-if="two_fa.enable_recovery && !two_fa.has_2fa_activated"
                class="text-left"
              >
                <br />
                <p>
                  Your backup codes are displayed below. Copy them and store in a safe
                  place. The backup code can be used to reset your two-factor
                  authentication.
                </p>
                <ul>
                  <li v-for="(codes, i) in two_fa.recovery_codes" :key="i">
                    {{ codes }}
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col md="1"></v-col>
    </v-row>
    <v-row class="m-2 mt-3 text-center" justify="center" v-if="two_fa.has_2fa_activated">
      <v-col md="2">
        <img
          class="mt-3"
          style="width: 150px"
          :src="`/images/datanest.png`"
          rel="image:Datanest Logo"
          alt="Datanest Admin Logo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    code: null,
    portal_url: process.env.VUE_APP_PORTAL_URL,
    two_fa: {
      qr_code: null,
      recovery_codes: [],
      has_2fa_activated: false,
      secret_key: null,
    },
  }),
  computed: {
    ...mapGetters(["user", "loaded2fa"]),
  },
  mounted() {
    this.load2FA();
  },
  methods: {
    ...mapActions(["get2faInfo", "verify2fa", "set2faVerified", "get2FA"]),
    verify() {
      this.verify2fa({
        one_time_password: this.code,
      }).then((response) => {
        if (response.data.reset) {
          this.load2FA();
          return;
        }

        if (response.data.success) {
          this.set2faVerified();
          this.$router.push("/dashboard");
          return;
        }
      });
    },
    load2FA() {
      this.get2faInfo().then((response) => {
        if (response.data.error) return;
        this.two_fa = response.data.two_fa;
        this.code = null;
      });
    },
  },
};
</script>
<style>
.google-badge {
  width: 200px !important;
  height: 70px !important;
  border-radius: 13px;
  display: inline-block;
}
.apple-badge {
  width: 220px !important;
  height: 50px !important;
  border-radius: 13px;
  display: inline-block;
}
.apple-col {
  padding-top: 20px !important;
  padding-left: 0px !important;
}
.google-col {
  padding-right: 0px !important;
}
</style>
