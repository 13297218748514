<template>
  <v-row>
    <v-col>
      <v-row v-if="!is_reviewed(building)">
        <v-col class="py-0 my-0" v-if="!is_awaiting_result(building)">
          <v-row>
            <v-col v-if="floors && floors.length > 0">
              <v-data-table
                :headers="headers"
                :items="floors"
                :options.sync="options"
                :footer-props="rowOptions"
                :search="search"
                :item-class="duplicateCheck"
                class="elevation-1"
                sort-by="order"
                :sort-desc="false"
                data-cy="floor-table"
                @click:row="goto"
              >
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        data-cy="view-room"
                        fab
                        color="primary"
                        x-small
                        v-on="on"
                        @click="goto(item)"
                        class="white--text mx-1 float-right"
                        @click.stop
                      >
                        <v-icon>fas fa-arrow-right</v-icon>
                      </v-btn>
                    </template>
                    <span>View Floor</span>
                  </v-tooltip>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="6">
                        <h2 class="floors-step">Floors</h2></v-col
                      >
                      <v-col
                        ><v-text-field
                          data-cy="search-floor"
                          spellcheck="true"
                          aria-autocomplete="no"
                          v-model.lazy="search"
                          append-icon="fas fa-search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field
                      ></v-col>
                      <v-tooltip
                        left
                        open-delay="300"
                        v-if="!is_awaiting_result(building)"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="primary"
                            @click="createFloor"
                            dark
                            small
                            class="float-right"
                            fab
                            data-cy="create-floor-btn"
                          >
                            <v-icon>fas fa-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Create Floor</span>
                      </v-tooltip>
                    </v-row>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-col>
            <v-col v-else>
              <v-row>
                <v-col>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="primary"
                        @click="createFloor"
                        dark
                        small
                        class="float-right"
                        fab
                        data-cy="create-floor-btn"
                      >
                        <v-icon>fas fa-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Create Floor</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="text-center">
                    <strong>There are no floors</strong>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-2">
              <review-buttons
                class="float-right"
                @load="$emit('updated')"
                :building_id="building.id"
                v-if="is_awaiting_review(building) && (is_reviewer || isRoot)"
              />

              <v-tooltip bottom v-if="is_pre_review(building)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="send-for-review"
                    v-on="on"
                    fab
                    color="success"
                    class="float-right send-to-awaiting-review"
                    @click="approvePreReviewShow"
                    small
                  >
                    <v-icon>fas fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Send for review</span>
              </v-tooltip>
              <v-tooltip bottom v-if="is_active(building)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="finalise-building"
                    v-on="on"
                    fab
                    color="success"
                    class="float-right finalise-building-step"
                    small
                    :disabled="
                      floors.length == 0 ||
                      building.rooms_count == 0 ||
                      !building.survey_date
                    "
                    @click="approveActiveShow"
                  >
                    <v-icon>fas fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Finalise building</span>
              </v-tooltip>

              <v-tooltip bottom v-if="is_needs_edits(building)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-cy="finalise-needs-edits"
                    v-on="on"
                    fab
                    small
                    color="success"
                    class="float-right send-to-awaiting-review-step"
                    @click="sendReviewShow"
                  >
                    <v-icon>fas fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Send for Review</span>
              </v-tooltip>

              <base-dialog
                :dialog.sync="dialog"
                :title="title"
                :message="message"
                :method="method"
                :deny.sync="deny"
              ></base-dialog>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <v-sheet width="100%">
            <h2>Lab Results</h2>
            <chain-of-custody
              :building="building"
              @updated="$emit('updated')"
            ></chain-of-custody>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseDialog from "./BaseDialog.vue";
import ChainOfCustody from "./ChainOfCustody.vue";
import ReviewButtons from "./ReviewButtons.vue";

export default {
  components: {
    BaseDialog,
    ChainOfCustody,
    ReviewButtons,
  },
  data: () => ({
    dialog: false,
    deny: false,
    status: false,
    title: null,
    message: null,
    method: null,
    image: null,
    filesUploads: 1,
    search: null,
    rowOptions: { "items-per-page-options": [10, 20, 30] },
    options: {},
    headers: [
      { text: "Name", value: "name" },
      { text: "Order", value: "order" },
      { text: "Rooms", value: "information.rooms" },
      { text: "Items", value: "information.items" },
      { text: "Samples", value: "information.samples" },
      { text: "Sampled As", value: "information.sampled_as" },
      { text: "Presumed", value: "information.presumed" },
      { text: "Inaccessible", value: "information.inaccessible" },
      { text: "Actions", value: "actions" },
    ],
  }),
  mounted() {
    this.options = this.tableOptions;
  },
  methods: {
    ...mapActions([
      "getBuilding",
      "getRooms",
      "approveReview",
      "approvePreReview",
      "denyReview",
      "approveActiveBuilding",
      "sendReviewBuilding",
      "setTableOptions",
    ]),
    goto(floor) {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${this.$route.params.building}/floor/${floor.id}`
      );
    },
    approveActiveShow() {
      this.title = "Finalise Building";
      this.message = `Are you sure you want to finalise ${this.building.name} info?`;
      this.deny = false;
      this.status = false;
      this.method = this.updateStatus;
      this.dialog = true;
    },
    sendReviewShow() {
      this.title = "Send for review?";
      this.message = `Are you sure you want ${this.building.name} sent to review?`;
      this.deny = false;
      this.status = false;
      this.method = this.sendToReview;
      this.dialog = true;
    },
    sendToReview() {
      this.sendReviewBuilding(this.building).finally(() => {
        this.$emit("updated");
      });
    },
    updateStatus() {
      this.approveActiveBuilding(this.building).finally(() => {
        this.$emit("updated");
      });
    },
    approveReviewerShow() {
      this.title = "Approve Building Review";
      this.message = `Are you sure you want to approve ${this.building.name}?`;
      this.deny = false;
      this.status = false;
      this.method = this.reviewerApprove;
      this.dialog = true;
    },
    reviewerApprove() {
      this.approveReview(this.building);
      this.$emit("updated");
    },
    denyReviewerShow() {
      this.title = "Deny Building Review";
      this.message = `Are you sure you want to deny ${this.building.name}?`;
      this.deny = " ";
      this.status = false;
      this.method = this.reviewerDeny;
      this.dialog = true;
    },
    reviewerDeny() {
      this.denyReview({ id: this.building.id, deny: this.deny });
      this.$emit("updated");
    },
    approvePreReviewShow() {
      this.title = "Approve Pre Review";
      this.message = `Are you sure you want to approve pre-review of ${this.building.name}?`;
      this.deny = false;
      this.status = false;
      this.method = this.surveyorPreReview;
      this.dialog = true;
    },
    surveyorPreReview() {
      this.approvePreReview(this.building);
      this.$emit("updated");
    },
    createFloor() {
      this.$router.push(
        "/survey/" +
          this.$route.params.survey +
          "/building/" +
          this.$route.params.building +
          "/floor"
      );
    },
    complete() {
      this.$router.push(
        `/survey/${this.$route.params.survey}/building/${this.$route.params.building}`
      );
    },
  },
  computed: {
    ...mapGetters([
      "isRoot",
      "building",
      "getRoomsByFloor",
      "buildings",
      "floors",
      "rooms",
      "is_reviewer",
      "is_pre_review",
      "is_awaiting_review",
      "is_awaiting_result",
      "is_reviewed",
      "is_active",
      "is_needs_edits",
      "duplicateCheck",
      "tableOptions",
    ]),
  },
  watch: {
    building() {
      this.image = `/api/image/building/${this.$route.params.building}?${this.building.updated_at}`;
    },
    options(updated) {
      this.setTableOptions(updated);
    },
  },
};
</script>
