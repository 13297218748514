<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="getItemTable"
    item-key="id"
    :item-class="duplicateCheck"
    :search="search"
    :loading="loading"
    :options.sync="options"
    :footer-props="rowOptions"
    sort-by="bag_code"
    data-cy="item-table"
  >
    <template v-slot:top>
      <v-toolbar flat class="pt-6">
        <v-row>
          <v-col cols="6">
            <h2 class="ma-0 pa-0">
              <span class="pr-3 items-step">Items</span>
            </h2>
          </v-col>
          <v-col>
            <v-text-field
              data-cy="search-item"
              spellcheck="true"
              v-model="search"
              label="Search"
              append-icon="fas fa-search"
            ></v-text-field>
          </v-col>
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                @click="createItem"
                :disabled="!createCheck"
                :dark="!!createCheck"
                small
                fab
                data-cy="create-item-btn"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </template>
            <span>Create Item</span>
          </v-tooltip>
        </v-row>
      </v-toolbar>
      <delete-dialog
        data-cy="item-confirm-delete"
        :dialog.sync="deleteDialog"
        :model="'Item'"
        v-on:delete="confirmDeleteItem()"
      ></delete-dialog>
      <item-view-dialog :show.sync="show" :item="item"></item-view-dialog>
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom open-delay="100">
        <template v-slot:activator="{ on }">
          <v-btn
            data-cy="view-item"
            v-on="on"
            color="primary"
            fab
            x-small
            class="mr-1"
            @click="viewItem(item)"
          >
            <v-icon>fas fa-eye</v-icon>
          </v-btn>
        </template>
        <span>View Item</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            data-cy="edit-item"
            x-small
            color="info"
            v-on="on"
            :attrs="attrs"
            class="mx-1"
            fab
            @click="editItem(item)"
          >
            <v-icon>fas fa-pencil-alt</v-icon>
          </v-btn>
        </template>
        <span>Edit Item</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!item.is_duplicated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            data-cy="duplicate-item"
            x-small
            color="warning"
            v-on="on"
            :attrs="attrs"
            class="mx-1"
            fab
            @click="showDuplicateItem(item)"
          >
            <v-icon>fas fa-copy</v-icon>
          </v-btn>
        </template>
        <span>Duplicate Item</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            data-cy="delete-item"
            x-small
            color="error"
            v-on="on"
            :attrs="attrs"
            class="mx-1"
            fab
            @click="openDelete(item)"
          >
            <v-icon>fas fa-trash-alt</v-icon>
          </v-btn>
        </template>
        <span>Delete Item</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ItemViewDialog from "./ItemViewDialog";
import DeleteDialog from "./DeleteDialog.vue";
export default {
  components: {
    ItemViewDialog,
    DeleteDialog,
  },
  props: {
    reloadItems: {
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      "assessmentMethods",
      "room",
      "duplicateCheck",
      "tableOptions",
      "contaminateTypes",
    ]),
    getItemTable() {
      return this.items;
    },
    createCheck() {
      return (
        !this.room.is_not_suspicious && !this.room.is_inaccessible && this.room.is_scoped
      );
    },
  },
  mounted() {
    this.loading = true;
    this.options = this.tableOptions;
    this.getItems(this.$route.params.room).then((items) => {
      this.items = items;
      this.loading = false;
    });
    this.duplicateFloor = this.$route.params.floor;
    this.duplicateName = this.room.name;
  },
  data: () => ({
    show: false,
    message: null,
    loading: false,
    options: {},
    rowOptions: { "items-per-page-options": [10, 20, 30] },
    items: [],
    item: null,
    deleteDialog: false,
    search: null,
    headings: ["Name", "Assessment Method", "Sample ID", "Actions"],
    headers: [
      {
        text: "Name",
        align: "left",
        value: "full_name",
      },
      { text: "Contaminant", value: "contaminate_name" },
      { text: "Assessment Method", value: "assessment_name" },
      { text: "Sample ID", value: "bag_code" },
      { text: "Actions", value: "action" },
    ],
  }),
  methods: {
    ...mapActions(["removeItem", "deleteItem", "getItems", "setTableOptions"]),
    createItem() {
      this.$router.push(this.$route.params.room + "/item");
    },
    viewItem(item) {
      this.item = item;
      this.show = true;
    },
    openDelete(item) {
      this.selected = item;
      this.message = `Are you sure you want to delete ${this.selected.name}?`;
      this.deleteDialog = true;
    },
    editItem(item) {
      this.$router.push({ path: `item/${item.id}`, append: true });
    },
    deleteSelectedItem(item) {
      this.deleteItem(item.id).then(() => {
        this.getItems(this.$route.params.room).then((items) => {
          this.items = items;
          this.loading = false;
        });
      });
    },
    confirmDeleteItem() {
      this.deleteSelectedItem(this.selected);
      this.deleteDialog = false;
    },
    showDuplicateItem(item) {
      this.$emit("itemduplicate", item);
    },
  },
  watch: {
    options(updated) {
      this.setTableOptions(updated);
    },
    reloadItems(updated) {
      if (updated) {
        this.getItems(this.$route.params.room).then((items) => {
          this.items = items;
          this.loading = false;
        });
      } else {
        this.$emit("update:reloadItems", false);
      }
    },
  },
};
</script>
