<template>
  <v-speed-dial
    style="display: inline"
    v-model="fab"
    direction="top"
    :disabled="
      building.asbestos_report_status != 'completed' ||
      building.lead_report_status != 'completed'
    "
  >
    <template v-slot:activator>
      <v-tooltip bottom right v-if="building.is_asbestos || building.is_lead">
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            v-on="on"
            class="mx-1 download-report-pdf-step"
            :disabled="
              !building.asbestos_report_status && !building.lead_report_status
            "
          >
            <v-icon>fas fa-file-pdf</v-icon>
          </v-btn>
        </template>
        <span>Download report</span>
      </v-tooltip>
    </template>
    <v-tooltip
      right
      v-if="
        building.is_asbestos && building.asbestos_report_status == 'completed'
      "
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          small
          color="blue"
          v-on="on"
          @click="downloadReport(ASBESTOS)"
        >
          <v-icon>fal fa-stroopwafel </v-icon>
        </v-btn>
      </template>
      <span>Asbestos</span>
    </v-tooltip>
    <v-tooltip
      right
      v-if="building.is_lead && building.lead_report_status == 'completed'"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          small
          color="green"
          @click="downloadReport(LEAD)"
          v-on="on"
        >
          <v-icon>fas fa-fill-drip</v-icon>
        </v-btn>
      </template>
      <span>Lead</span>
    </v-tooltip>
    <v-tooltip
      right
      v-if="building.asbestos_report_status || building.lead_report_status"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          small
          color="primary"
          :href="`#/survey/${building.survey_id}/building/${building.id}/reports`"
          v-on="on"
        >
          <v-icon>fas fa-history</v-icon>
        </v-btn>
      </template>
      <span>View all</span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["completed", "building"],
  created() {
    this.ASBESTOS = 1;
    this.LEAD = 2;
  },
  data: () => ({
    fab: false,
  }),
  methods: {
    ...mapActions(["downloadReportURL"]),
    downloadReport(contaminate_id) {
      this.downloadReportURL({
        contaminate_id,
        building_id: this.building.id,
        updated_at: this.building.updated_at,
      });
    },
  },
};
</script>
