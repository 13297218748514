<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h3>
          Air monitoring results
        </h3>
        <table class="clearance-table">
          <tr>
            <td class="tbl-header">Samples</td>
            <td class="tbl-header">Result</td>
            <td class="tbl-header">Certificate</td>
            <td>&nbsp;</td>
          </tr>
          <tr
            v-for="(air_monitoring, akey) in samples.air_monitorings"
            :key="akey"
          >
            <td>
              <v-text-field
                spellcheck="true"
                label="Sample name"
                outlined
                v-model.lazy="air_monitoring.sample"
                placeholder="Sample name"
                v-validate="'required'"
                :error-messages="
                  errors.collect('air monitoring sample ' + (akey + 1))
                "
                :data-vv-name="'air monitoring sample ' + (akey + 1)"
                required
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                spellcheck="true"
                label="Result"
                outlined
                v-model.lazy="air_monitoring.result"
                placeholder="Sample name"
                v-validate="'required'"
                :error-messages="
                  errors.collect('air monitoring result ' + (akey + 1))
                "
                :data-vv-name="'air monitoring result ' + (akey + 1)"
                required
              ></v-text-field>
            </td>
            <td>
              <v-select
                :items="certificates"
                item-text="title"
                item-value="id"
                spellcheck="true"
                label="Certificate"
                outlined
                v-model="air_monitoring.air_swab_result_id"
                placeholder="Certificate"
                required
              ></v-select>
            </td>
            <td>
              <v-icon
                class="mr-1"
                @click="
                  () => {
                    samples.air_monitorings.splice(akey, 1);
                  }
                "
                color="error"
              >
                fas fa-times
              </v-icon>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-center">
              <v-btn @click="insertAirMonitoring()" color="primary">
                <v-icon class="mr-1">
                  fas fa-plus
                </v-icon>
                Add new sample
              </v-btn>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="6">
        <h3>
          Swab Results
        </h3>
        <table class="clearance-table">
          <tr>
            <td class="tbl-header">Samples</td>
            <td class="tbl-header">Result</td>
            <td class="tbl-header">Certificate</td>
            <td>&nbsp;</td>
          </tr>
          <tr v-for="(surface_dust, skey) in samples.surface_dusts" :key="skey">
            <td>
              <v-text-field
                spellcheck="true"
                label="Sample name"
                outlined
                v-model.lazy="surface_dust.sample"
                placeholder="Sample name"
                v-validate="'required'"
                :error-messages="
                  errors.collect('surface dust sample ' + (skey + 1))
                "
                :data-vv-name="'surface dust sample ' + (skey + 1)"
                required
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                spellcheck="true"
                label="Result"
                outlined
                v-model.lazy="surface_dust.result"
                placeholder="Sample name"
                v-validate="'required'"
                :error-messages="
                  errors.collect('surface dust result ' + (skey + 1))
                "
                :data-vv-name="'surface dust result ' + (skey + 1)"
                required
              ></v-text-field>
            </td>
            <td>
              <v-select
                :items="certificates"
                item-text="title"
                item-value="id"
                spellcheck="true"
                label="Certificate"
                outlined
                v-model="surface_dust.air_swab_result_id"
                placeholder="Certificate"
                required
              ></v-select>
            </td>
            <td>
              <v-icon
                class="mr-1"
                @click="
                  () => {
                    samples.surface_dusts.splice(akey, 1);
                  }
                "
                color="error"
              >
                fas fa-times
              </v-icon>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-center">
              <v-btn @click="insertSurfaceDust()" color="primary">
                <v-icon class="mr-1">
                  fas fa-plus
                </v-icon>
                Add new sample
              </v-btn>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col>
        <div class="float-right">
          <v-btn @click="save" color="primary" :loading="isSaving" class="mr-2">
            <v-icon class="mr-1">
              fas fa-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            @click="$emit('next', true)"
            color="primary"
            :loading="isSaving"
          >
            Next
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["clearance_id", "trigger_save", "trigger_air_changes"],
  data() {
    return {
      isSaving: false,
      certificates: [],
      samples: {
        air_monitorings: [],
        surface_dusts: []
      },
      defaultAirMonitoring: {
        type: 1,
        sample: "",
        result: "",
        air_swab_result_id: null
      },
      defaultDustCollected: {
        type: 2,
        sample: "",
        result: "",
        air_swab_result_id: null
      }
    };
  },
  computed: {
    ...mapGetters({ invalidInput: "invalidInput" })
  },
  watch: {
    trigger_save() {
      if (this.trigger_save == true) {
        this.save();
        return;
      }
    },
    trigger_air_changes() {
      if (this.trigger_air_changes == true) {
        this.getClearanceAirSwabResultDocuments();
        return;
      }
    }
  },
  methods: {
    ...mapActions([
      "getClearanceAirSwabSamples",
      "saveClearanceAirSwabSamples",
      "getClearanceAirSwabResults"
    ]),
    insertAirMonitoring() {
      this.samples.air_monitorings.push(
        JSON.parse(JSON.stringify(this.defaultAirMonitoring))
      );
    },
    insertSurfaceDust() {
      this.samples.surface_dusts.push(
        JSON.parse(JSON.stringify(this.defaultDustCollected))
      );
    },
    getClearanceAirSamples() {
      this.getClearanceAirSwabSamples(this.clearance_id).then(response => {
        this.samples = response.data.samples;
      });
    },
    getClearanceAirSwabResultDocuments() {
      this.getClearanceAirSwabResults(this.clearance_id).then(response => {
        this.certificates = response.data.documents;
        this.$emit("defaultAirChanges");
      });
    },
    save() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          this.$vuetify.goTo(this.invalidInput(this.$validator));
          return;
        }

        this.isSaving = true;
        this.saveClearanceAirSwabSamples({
          clearance_id: this.clearance_id,
          samples: this.samples
        }).then(response => {
          this.isSaving = false;
          this.getClearanceAirSamples();
        });
      });
    }
  },
  mounted() {
    this.getClearanceAirSamples();
    this.getClearanceAirSwabResultDocuments();
  }
};
</script>
<style scoped>
.clearance-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.clearance-table td,
.clearance-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.clearance-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.clearance-table td:nth-child(1) {
  width: 40%;
  vertical-align: middle;
}
.clearance-table td:nth-child(2) {
  width: 25%;
  vertical-align: middle;
}

.clearance-table td:nth-child(3) {
  width: 25% !important;
  text-align: center;
}

.clearance-table td:nth-child(4) {
  width: 10%;
  text-align: center;
}

.clearance-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #94959c;
  color: white;
}

.tbl-header {
  color: #1565c0;
  font-weight: bold;
  text-align: center;
}
</style>
