import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"mb-2"},[_c('span',{staticClass:"pr-3 notes-step"},[_vm._v("Notes")])])])],1),_c(VSheet,{attrs:{"elevation":"1","outlined":"","rounded":""}},[_c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,[_c(VTabs,{attrs:{"fixed-tabs":""}},_vm._l((_vm.pageNoteTypes),function(type){return _c(VTab,{key:type.id,staticClass:"mb-0 pt-0",attrs:{"data-cy":"page-note"},on:{"click":function($event){_vm.step = type.id}}},[_vm._v(" "+_vm._s(type.name)+" "),(
                      !_vm.hasNoteTypeId(type.id) ||
                        !_vm.hasNoteTypeId(type.id).content
                    )?_c('span',{staticClass:"ml-3"},[_c(VIcon,{attrs:{"small":"","color":"error"}},[_vm._v("fas fa-exclamation-circle")])],1):_vm._e()])}),1)],1)],1),_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"max-note-height"},[_c(VWindow,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},_vm._l((4),function(n){return _c(VWindowItem,{key:n,attrs:{"data-cy":"survey-notes","value":n}},[_c(VRow,[_c(VCol,{staticClass:"h-100",attrs:{"data-cy":"note-box"}},[(_vm.hasNoteTypeId(n))?_c('p',{staticClass:"preline body-1"},[_vm._v(" "+_vm._s(_vm.hasNoteTypeId(n).content)+" ")]):_c('div',{staticClass:"h-100 pt-10",attrs:{"justify":"center","align":"center"}},[_c(VIcon,{staticClass:"mb-2"},[_vm._v("fas fa-exclamation")]),_c('p',{staticClass:"subtitle-2 text--secondary"},[_vm._v(" There is no note available ")])],1)])],1)],1)}),1)],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"py-0 mb-0 mt-n12"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(!_vm.is_reviewed(_vm.building))?_c(VBtn,_vm._g({staticClass:"float-right elevation-0 mr-3",attrs:{"data-cy":"edit-note","fab":"","small":"","right":"","color":"info"},on:{"click":function($event){_vm.dialog = true}}},on),[_c(VIcon,[_vm._v("fa fa-pen")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Edit Note")])])],1)],1),_c('note-edit',{attrs:{"aNote":_vm.hasNoteTypeId(_vm.step),"type":_vm.step,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"updated":function($event){return _vm.$emit('updated')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }