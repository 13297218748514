import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTooltip,_vm._g(_vm._b({attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mx-1",attrs:{"fab":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_c(VIcon,[_vm._v("fas fa-text")])],1)]}}],null,true)},'v-tooltip',attrs,false),on),[_c('span',[_vm._v("Add Label")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Options")]),_c(VCardText,[(_vm.labelOptions)?_c(VRow,{staticClass:"px-4"},[_c(VCol,[_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c(VTextField,{attrs:{"outlined":"","label":"Text"},model:{value:(_vm.label.text),callback:function ($$v) {_vm.$set(_vm.label, "text", $$v)},expression:"label.text"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c(VTextField,{attrs:{"outlined":"","label":"Font Size","type":"number"},model:{value:(_vm.label.size),callback:function ($$v) {_vm.$set(_vm.label, "size", $$v)},expression:"label.size"}})],1)],1)],1)],1):_vm._e()],1),_c(VCardActions,[_c(VRow,[_c(VCol,{staticClass:"mt-0 pt-0"},[_c(VBtn,{staticClass:"float-right",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("UPDATE")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }