<template>
  <v-dialog :value="show" persistent max-width="800px">
    <v-card>
      <v-card-title
        ><v-col class="text-center">
          <h2 class="h2">Create Office</h2>
        </v-col>
      </v-card-title>
      <v-card-text>
        <office-create data-cy="close-office" :dialog="true" @close="close"></office-create>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OfficeCreate from "./OfficeCreate";
export default {
  props: ["show"],
  components: {
    OfficeCreate
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
