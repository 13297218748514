<template>
  <div ref="displayCanvas" class="text-center justify-center"></div>
</template>

<script>
import { FabricDrawing } from "fabric-tool";

export default {
  props: ["image", "updated", "polygons"],
  data: () => ({
    context: null,
    canvas: null,
    parent: null,
    scale: null,
    polygon: null,
    fill: { r: 255, g: 99, b: 71, a: 0.2 },
    stroke: { r: 255, g: 99, b: 71, a: 0.6 },
    config: {
      canvasEl: "canvas",
      viewOnly: true,
      maxShapes: 1,
      cornerColor: "red",
      cornerStyle: "circle",
      fill: "transparent",
      stroke: "red",
      polygonFill: "rgba(255, 0, 25, 0.1)",
      cornerSize: 16,
      hasPolygon: true,
      hasLine: true,
      hasPoint: true,
      backgroundImg: "floor.png",
      zoom: true,
      radius: 8,
      strokeWidth: 2,
    },
    fabricDrawing: null,
  }),
  mounted() {
    this.parent = this.$refs.displayCanvas;
    this.scale =
      this.image.width > window.innerWidth
        ? window.innerWidth / this.image.width
        : 1;
    this.setCanvas();
    this.initialDraw();
  },
  methods: {
    draw() {
      if (this.polygons && this.polygons.length > 0) {
        this.updateCanvas();
        this.drawShapes();
      }
    },
    updateStyle() {
      if (this.polygons && this.polygons.length > 0) {
        this.config.fill = this.getRGBA(this.polygons[0].fill);
        this.config.stroke = this.getRGBA(this.polygons[0].stroke);
        this.config.polygonFill = this.getRGBA(this.polygons[0].fill);
        this.config.cornerColor = this.getRGBA(this.polygons[0].stroke);
      }
    },
    updateCanvas() {
      this.updateStyle();
      this.fabricDrawing.updateStyle(this.config);
    },
    drawShapes() {
      this.shapes = [];
      for (let polygon of this.polygons) {
        let points = polygon.points.map((point) => {
          return {
            x: Number(point[0]),
            y: Number(point[1]),
          };
        });
        let shape = {
          type: polygon.type ? polygon.type : "polygon",
          points: points,
        };
        this.shapes.push(shape);
      }
      if (this.shapes.length > 0) {
        this.fabricDrawing.completeObjects(JSON.stringify(this.shapes));
      }
    },
    getRGBA(color) {
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a - 0.01})`;
    },
    setCanvas() {
      this.canvas = document.createElement("canvas");
      this.canvas.setAttribute("id", "canvas");
      this.parent.appendChild(this.canvas);
      this.fabricDrawing = new FabricDrawing(this.config);
    },

    initialDraw() {
      if (this.image.width == 0) {
        setTimeout(this.initialDraw, 1000);
      }
      this.fabricDrawing
        .setBackgroundImgUrl(this.image.src)
        .then(() => {
          this.draw();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetCanvas() {
      this.shapes = [];
      this.fabricDrawing.clearCanvas();
    },
  },
  watch: {
    updated() {
      this.resetCanvas();
      this.draw();
    }
  },
};
</script>
<style scoped>
.canvas-parent {
  overflow-x: auto;
  overflow-y: auto;
}
</style>
