<template>
  <div class="d-inline">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          :disabled="!is_reviewer()"
          color="indigo"
          class="mr-1 white--text"
          dark
          :x-small="isDashboard"
          v-on="on"
          data-cy="generate-report"
          @click.stop="dialog = true"
        >
          <v-icon v-if="fab"> fas fa-times </v-icon>
          <v-icon v-else> fas fa-vote-yea </v-icon>
        </v-btn>
      </template>
      <span>Review</span>
    </v-tooltip>
    <template>
      <v-dialog v-model="dialog" max-width="600px" transition="fade-transition">
        <v-card v-if="theApprovals">
          <v-card-title>
            <small>
              Review building
              <span class="ml-1" v-if="aBuilding">
                {{ aBuilding.name }}</span
              ></small
            ></v-card-title
          >
          <v-card-text>
            <v-tabs vertical>
              <v-tab
                :class="{ 'deny-warn': asbestosWarning }"
                href="#asbestos"
                v-if="aBuilding.is_asbestos"
              >
                <v-icon class="mr-2">
                  {{
                    asbestosWarning
                      ? "fas fa-exclamation-triangle"
                      : "fal fa-stroopwafel"
                  }}
                </v-icon>
                Asbestos
              </v-tab>

              <v-tab
                :class="{ 'deny-warn': leadWarning }"
                href="#lead"
                v-if="aBuilding.is_lead"
              >
                <v-icon class="mr-2">
                  {{
                    leadWarning
                      ? "fas fa-exclamation-triangle"
                      : "fas fa-fill-drip"
                  }}
                </v-icon>
                Lead
              </v-tab>
              <v-tab-item value="asbestos" v-if="aBuilding.is_asbestos">
                <v-container>
                  <div v-if="theApprovals.asbestos_approved != 1">
                    <v-radio-group
                      v-model="theApprovals.asbestos"
                      row
                      :error-messages="errors.collect('lead approval')"
                    >
                      <v-radio label="Approve" :value="1"></v-radio>
                      <v-radio label="Deny" :value="0"></v-radio>
                    </v-radio-group>
                    <v-textarea
                      v-show="theApprovals.asbestos == 0"
                      autocomplete="off"
                      spellcheck="true"
                      label="Reason for denying the asbestos report"
                      outlined
                      v-model="theApprovals.asbestos_deny"
                      placeholder="Enter details"
                      :disabled="theApprovals.asbestos == 1 ? true : false"
                    ></v-textarea>
                    <span v-if="asbestosWarning" class="deny-warn">
                      Please fill in details on why you're denying this report.
                    </span>
                  </div>
                  <div
                    v-if="
                      theApprovals.asbestos_approved == 1 &&
                      theApprovals.asbestos_reviewer_id
                    "
                  >
                    <v-alert type="success">
                      Approved by:<br />
                      {{ theApprovals.asbestos_reviewer_name }}
                      <span v-if="theApprovals.asbestos_approval_date">
                        / {{ theApprovals.asb_date_approved }}
                      </span>
                    </v-alert>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item value="lead" v-if="aBuilding.is_lead">
                <v-container>
                  <div v-if="theApprovals.lead_approved != 1">
                    <v-radio-group v-model="theApprovals.lead" row>
                      <v-radio label="Approve" :value="1"></v-radio>
                      <v-radio label="Deny" :value="0"></v-radio>
                    </v-radio-group>
                    <v-textarea
                      v-show="theApprovals.lead == 0"
                      autocomplete="off"
                      spellcheck="true"
                      label="Reason for denying the lead report"
                      outlined
                      v-model="theApprovals.lead_deny"
                      placeholder="Enter details"
                      :disabled="theApprovals.lead == 1 ? true : false"
                    ></v-textarea>
                    <span v-if="leadWarning" class="deny-warn">
                      Please fill in details on why you're denying this report.
                    </span>
                  </div>
                  <div
                    v-if="
                      theApprovals.lead_approved == 1 &&
                      theApprovals.lead_reviewer_id
                    "
                  >
                    <v-alert type="success">
                      Approved by:<br />
                      {{ theApprovals.lead_reviewer_name }}
                      <span v-if="theApprovals.lead_approval_date">
                        / {{ theApprovals.lead_date_approved }}
                      </span>
                    </v-alert>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              :disabled="
                leadWarning ||
                asbestosWarning ||
                (theApprovals.asbestos == null && theApprovals.lead == null)
              "
              text
              @click="confirm"
              data-cy="confirm"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["building_id", "type"],
  data: () => ({
    dialog: null,
    fab: false,
    asbFab: false,
    leadFab: false,
    tab: false,
    aBuilding: null,
    theApprovals: null,
  }),
  watch: {
    dialog(value) {
      if (value) {
        this.initialise();
      }
    },
  },
  computed: {
    ...mapGetters(["approvals", "building"]),
    isDashboard() {
      return this.type == "dashboard";
    },
    hovPosition() {
      return this.isDashboard ? "left" : "right";
    },
    leadWarning() {
      return this.theApprovals.lead == 0 && !this.leadDenyMessage();
    },
    asbestosWarning() {
      return this.theApprovals.asbestos == 0 && !this.asbestosDenyMessage();
    },
  },
  methods: {
    ...mapActions([
      "approveReview",
      "denyReview",
      "getApprovals",
      "getBuilding",
      "reviewReport",
      "is_reviewer",
    ]),
    leadDenyMessage() {
      return this.theApprovals.lead_deny
        ? this.theApprovals.lead_deny.trim()
        : false;
    },
    asbestosDenyMessage() {
      return this.theApprovals.asbestos_deny
        ? this.theApprovals.asbestos_deny.trim()
        : false;
    },
    confirm() {
      this.approveReview({
        id: this.building_id,
        status_id: 10,
        approvals: this.theApprovals,
      }).then(() => {
        this.$emit("load");
        this.getApprovals(this.building_id);
        this.dialog = false;
      });
    },
    initialise() {
      this.getApprovals(this.building_id).then(() => {
        this.theApprovals = { ...this.approvals };
      });
      this.getBuilding(this.building_id).then(() => {
        this.aBuilding = { ...this.building };
      });
    },
  },
};
</script>
<style scoped>
.deny-warn,
.deny-warn i {
  color: rgb(204, 57, 57) !important;
}
</style>
