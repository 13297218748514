<template>
  <div>
    <v-row data-cy="address-form" v-if="addresses">
      <v-col>
        <v-row v-if="showAddress">
          <v-col
            class="pt-0 my-0"
            data-cy="address-field"
            :cols="has_location ? 10 : 12"
          >
            <v-text-field
              spellcheck="true"
              autocomplete="off"
              v-model="address"
              outlined
              v-validate="'required|max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('address')"
              placeholder="Enter an address"
              label="Address*"
              data-vv-name="address"
              data-cy="address"
              messages="*Required"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="2" v-if="has_location">
            <v-btn @click="locatorButtonPressed" color="primary">
              <v-icon class="mr-1">
                fas fa-location
              </v-icon>
              Use GPS
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="predictions">
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="prediction in predictions"
                    :key="prediction.place_id"
                  >
                    <td @click="setAddress(prediction)">
                      {{ prediction.description }}
                    </td>
                  </tr>
                  <tr>
                    <td @click="addressNotFound()">
                      <i class="error--text">Address not in result?</i>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row v-if="showAddress">
          <v-col class="my-0" sm="6" cols="12">
            <v-text-field
              spellcheck="true"
              autocomplete="new-password"
              placeholder="Enter an address"
              v-model="address2"
              outlined
              v-validate="'max:190'"
              counter
              maxlength="190"
              :error-messages="errors.collect('address2')"
              label="Address 2"
              data-vv-name="address2"
              data-cy="address2"
            ></v-text-field>
          </v-col>
          <v-col class="my-0" sm="6" cols="12">
            <v-text-field
              spellcheck="true"
              v-model="suburb"
              autocomplete="off"
              placeholder="Enter the suburb"
              outlined
              v-validate="'max:75'"
              counter
              maxlength="75"
              :error-messages="errors.collect('suburb')"
              label="Suburb"
              data-vv-name="suburb"
              data-cy="suburb"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showAddress">
          <v-col class="my-0" sm="6" cols="12" data-cy="city-field">
            <v-text-field
              spellcheck="true"
              v-model="city"
              autocomplete="off"
              outlined
              v-validate="'required|max:190'"
              placeholder="Enter the city"
              counter
              maxlength="190"
              :error-messages="errors.collect('city')"
              label="City*"
              messages="*Required"
              data-vv-name="city"
              data-cy="city"
              required
            ></v-text-field>
          </v-col>
          <v-col class="my-0" sm="6" cols="12" data-cy="postcode-field">
            <v-text-field
              spellcheck="true"
              v-model="postcode"
              autocomplete="off"
              placeholder="Enter the postcode"
              messages="*Required"
              outlined
              v-validate="'required|max:6|min:4|numeric'"
              counter
              maxlength="6"
              minlength="4"
              :error-messages="errors.collect('postcode')"
              label="Postcode*"
              data-vv-name="postcode"
              type="number"
              data-cy="postcode"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="has_location && geolocation">
          <v-col class="my-0" sm="6" cols="12">
            <v-text-field
              readonly
              v-model="latitude"
              outlined
              placeholder="Latitude"
              label="Latitude"
            ></v-text-field>
          </v-col>
          <v-col class="my-0" sm="6" cols="12">
            <v-text-field
              readonly
              v-model="longitude"
              outlined
              placeholder="Longitude"
              label="Longitude"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    addresses: Object,
    has_location: Boolean,
    clearAddress: Boolean
  },
  inject: ["parentValidator"],
  created() {
    this.$validator = this.parentValidator;
  },
  data: () => ({
    predictions: null,
    aAddress: null,
    boundary: null,
    service: null,
    showAddress: true,
  }),
  mounted() {
    this.service = new window.google.maps.places.AutocompleteService();
    this.boundary = new window.google.maps.LatLngBounds(
      { lat: -47.445921, lng: -198.193359 },
      { lat: -33.424106, lng: -179.165039 }
    );
    this.predictions = false;
  },
  methods: {
    clear() {
      this.city = null;
      this.suburb = null;
      this.postcode = null;
      this.address = null;
      this.address2 = null;
      this.geolocation = false;
      this.latitude = null;
      this.longitude = null;
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.geolocation = true;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getStreetAddressFrom(this.latitude, this.longitude);
        },
        error => {
          console.error(error.message);
        }
      );
    },
    addressNotFound() {
      this.showAddress = false,
      this.predictions = false;
    },
    setAddress(address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: address.place_id }, (results, status) => {
        if (status == "OK") {
          for (let type of results[0].address_components) {
            const streetNumber = type.types.find(
              aType => aType == "street_number"
            );
            const street = type.types.find(aType => aType == "route");
            const suburb = type.types.find(
              aType => aType == "sublocality_level_1"
            );
            const city = type.types.find(aType => aType == "locality");
            const postcode = type.types.find(aType => aType == "postal_code");
            if (streetNumber) {
              this.address = `${type.short_name}`;
            }
            if (street) {
              this.address += ` ${type.long_name}`;
            }
            if (suburb) {
              this.suburb = `${type.short_name}`;
            }
            if (city) {
              this.city = `${type.short_name}`;
            }
            if (postcode) {
              this.postcode = `${type.short_name}`;
            }
          }
          this.predictions = false;
        }
      });
    },
    async getStreetAddressFrom(lat, long) {
      const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(long)
      };
      const geo = new window.google.maps.Geocoder();
      geo.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.processPredictions(results);
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    },
    processPredictions(res) {
      let results = [];
      res.forEach(element => {
        let pred = {
          place_id: element.place_id,
          description: element.formatted_address
        };
        results.push(pred);
      });

      this.predictions = results;
    }
  },
  computed: {
    city: {
      get() {
        return this.addresses ? this.addresses.city : null;
      },
      set(city) {
        this.$emit("update:city", city);
      }
    },
    suburb: {
      get() {
        return this.addresses ? this.addresses.suburb : null;
      },
      set(suburb) {
        this.$emit("update:suburb", suburb);
      }
    },
    postcode: {
      get() {
        return this.addresses ? this.addresses.postcode : null;
      },
      set(postcode) {
        this.$emit("update:postcode", postcode);
      }
    },
    address: {
      get() {
        return this.addresses ? this.addresses.address : null;
      },
      set(address) {
        this.$emit("update:address", address);
      }
    },
    address2: {
      get() {
        return this.addresses ? this.addresses.address2 : null;
      },
      set(address2) {
        this.$emit("update:address2", address2);
      }
    },
    geolocation: {
      get() {
        return this.addresses ? this.addresses.geolocation : null;
      },
      set(geolocation) {
        this.$emit("update:geolocation", geolocation);
      }
    },
    latitude: {
      get() {
        return this.addresses ? this.addresses.latitude : null;
      },
      set(latitude) {
        this.$emit("update:latitude", latitude);
      }
    },
    longitude: {
      get() {
        return this.addresses ? this.addresses.longitude : null;
      },
      set(longitude) {
        this.$emit("update:longitude", longitude);
      }
    }
  },
  watch: {
    clearAddress(clear) {
      if (clear) {
        this.clear();
        this.$validator.reset();
        this.$emit("update:clearAddress", false);
      }
    },
    address(updated) {
      if (this.predictions == false) {
        this.predictions = null;
        return;
      }
      const getPredictions = (predictions, status) => {
        if (
          status != window.google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          return;
        }
        this.predictions = predictions;
      };

      if (updated && updated.length && updated.length > 1) {
        this.service.getQueryPredictions(
          {
            input: updated,
            bounds: this.boundary,
            offset: 3
          },
          getPredictions
        );
      }
    }
  }
};
</script>
