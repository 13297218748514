<template>
  <v-row>
    <v-col>
      <tour tourKey="chain_of_custody" />
      <v-row>
        <v-col>
          <h3><span class="coc-step pr-3">Chain of Custody</span></h3>
        </v-col>
      </v-row>
      <v-row data-cy="chain-of-custody-module">
        <v-col cols="4">
          <v-select
            class="laboratory-step"
            v-model="laboratory"
            label="laboratory"
            :items="laboratories"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            class="turnaround-step"
            v-model="turnAround"
            label="Turnaround"
            :items="turnAroundItems"
            item-text="name"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="3" cy-data="contaminate-selector">
          <v-select
            class="contaminant-step"
            v-model="contaminate"
            :disabled="!building.is_asbestos || !building.is_lead"
            label="Contaminant"
            :items="customContaminates"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="download-coc-step"
                data-cy="chain-of-custody"
                v-on="on"
                color="primary"
                fab
                @click="downloadCOC()"
                :disabled="!laboratory"
                ><v-icon>fas fa-file-download</v-icon></v-btn
              >
            </template>
            <span v-if="laboratory">Download Chain of Custody</span>
            <span v-else>Please set the laboratory before downloading</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row data-cy="search-data">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="sampledItems"
            :items-per-page="5"
            :search="search"
            sort-by="bag_code"
            class="elevation-1 coc-results-table-step"
          >
            <template v-slot:header.sample.status.name>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="sample-status-step" v-on="on" :attrs="attrs">
                    Sample Status
                    <v-icon color="warning" x-small>fas fa-info</v-icon>
                  </div>
                </template>
                <span v-if="contaminate == ASBESTOS"
                  >Selecting "Negative" will remove item recommendations</span
                >
                <span v-if="contaminate == LEAD"
                  >Please enter results in mg/kg</span
                >
              </v-tooltip>
            </template>
            <template v-slot:item.analysis_type_id="{ item }">
              <analysis-type-select :item="item"></analysis-type-select>
            </template>
            <template v-slot:item.sample.status.name="{ item }">
              <sample-status-select :item="item"></sample-status-select>
            </template>
            <template v-slot:item.bag_code="{ item }">
              <sample-bag
                :id="item.sample.id"
                :bag="item.sample.bag_code"
                :building="building"
              ></sample-bag>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  spellcheck="true"
                  v-model.lazy="search"
                  append-icon="fas fa-search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <return-file-uploads
        :building="building"
        :fileNum="building.file"
        @updated="$emit('updated')"
        :contaminates="customContaminates"
        :contaminate="contaminate"
      ></return-file-uploads>
      <v-row>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="dialog = true"
                fab
                color="success"
                class="float-right pre-review-step"
                :disabled="!isSampleStatusSet"
                ><v-icon>fas fa-check</v-icon></v-btn
              >
            </template>
            <span>Send to pre-review</span>
          </v-tooltip>
          <base-dialog
            :dialog.sync="dialog"
            title="Send to pre-review"
            :message="`Are you sure you want to send ${this.building.name} to pre-review?`"
            :method="updateResultStatus"
            :deny="false"
          ></base-dialog>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AnalysisTypeSelect from "./AnalysisTypeSelect.vue";
import SampleBag from "./SampleBag.vue";
import SampleStatusSelect from "./SampleStatusSelect.vue";
import ReturnFileUploads from "./ReturnFileUploads.vue";
import BaseDialog from "./BaseDialog.vue";
import Tour from "./Tour.vue";

export default {
  components: {
    AnalysisTypeSelect,
    SampleBag,
    ReturnFileUploads,
    SampleStatusSelect,
    BaseDialog,
    Tour,
  },
  props: ["building"],
  data: () => ({
    contaminate: 1,
    search: null,
    dialog: false,
    turnAroundItems: [
      { value: 0, name: "Standard" },
      { value: 1, name: "Urgent" },
    ],
    headers: [
      {
        text: "Sample ID",
        align: "left",
        value: "bag_code",
      },
      {
        text: "Item",
        value: "full_name",
      },
      {
        text: "Floor",
        value: "floor_name",
      },
      {
        text: "Room",
        value: "room_name",
      },
      {
        text: "Analysis Type",
        value: "analysis_type_id",
      },
      {
        text: "Sample Status",
        value: "sample.status.name",
      },
    ],
  }),
  created() {
    this.ASBESTOS = 1;
    this.LEAD = 2;
  },
  mounted() {
    this.getBuildingItems(this.building.id);
  },
  computed: {
    ...mapGetters([
      "items",
      "laboratories",
      "survey",
      "analysisTypes",
      "contaminateTypes",
    ]),
    customContaminates() {
      return this.contaminateTypes.map((contaminate) => {
        if (
          (contaminate.id == 1 && this.building.is_asbestos) ||
          (contaminate.id == 2 && this.building.is_lead)
        ) {
          this.contaminate = contaminate.id;
          return { ...contaminate, disabled: false };
        }
        return { ...contaminate, disabled: true };
      });
    },
    sampledItems() {
      return this.items.filter((item) => {
        if (item.contaminate_id == 1 && this.contaminate == 1) {
          return item.sample != null && item.assessment_method_id == 1;
        }
        if (item.contaminate_id == 2 && this.contaminate == 2) {
          return item.sample != null && item.assessment_method_id == 7;
        }
        return false;
      });
    },
    isSampleStatusSet() {
      const sampledItems = this.items.filter((item) => {
        return (
          (item.sample != null && item.assessment_method_id == 7) ||
          item.assessment_method_id == 1
        );
      });
      const items = sampledItems.filter((item) => {
        return item.contaminate_id == 1;
      });
      const asbestosItems =
        items.find((item) => item.sample.status_id == 1) === undefined;
      const lead = sampledItems.filter((item) => {
        return item.contaminate_id == 2;
      });
      const leadItems =
        lead.find((item) => item.lead.laboratory_analysis == null) ===
        undefined;
      return asbestosItems && leadItems;
    },
    laboratory: {
      get() {
        return this.building.laboratory_id;
      },
      set(updated) {
        this.editBuilding({
          id: this.building.id,
          laboratory_id: updated,
        });
      },
    },
    turnAround: {
      get() {
        return this.building.is_urgent_transfer;
      },
      set(updated) {
        this.editBuilding({
          id: this.building.id,
          is_urgent_transfer: updated == 1,
        });
      },
    },
  },
  methods: {
    ...mapActions([
      "editBuilding",
      "getBuildingItems",
      "approveResult",
      "getCOC",
      "downloadCOCURL",
    ]),
    downloadCOC() {
      this.downloadCOCURL({
        building_id: this.building.id,
        laboratory_id: this.building.laboratory_id,
        updated_at: this.building.updated_at,
        contaminate_id: this.contaminate,
      });
    },
    updateResultStatus() {
      this.approveResult(this.building).then(() => {
        this.$emit("updated");
      });
    },
  },
};
</script>
