import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions.js";
import state from "./state.js";
import mutations from "./mutations.js";
import getters from "./getters.js";
import VuexPersistence from "vuex-persist";
import localforage from "localforage";

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  asyncStorage: true,
  storage: localforage
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main: {
      state,
      mutations,
      actions,
      getters
    }
  },
  plugins: [vuexLocal.plugin]
});
